// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components
const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding-vertical: 10px;
  background-color: ${(props) => (props as any).color};
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Banner = ({color, text}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container color={color}>
    <Text>{text}</Text>
  </Container>
);

export default Banner;
