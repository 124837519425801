// Libraries
import React from 'react';

// App
import {Form, Mutation} from '@supermove/components';
import {CommercialJobTimesheetForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ConfirmCommercialJobTimesheetForm.defaultProps;

// Components

// @ts-expect-error TS(7022): 'ConfirmCommercialJobTimesheetForm' implicitly has... Remove this comment to see the full error message
const ConfirmCommercialJobTimesheetForm = ({job, onSuccess, onError, children}: Props) => (
  <Form
    enableReinitialize
    initialValues={{
      commercialJobTimesheetForm: CommercialJobTimesheetForm.toForm(
        CommercialJobTimesheetForm.edit(job),
      ),
    }}
    onSubmit={(values: any) => {}}
  >
    {(form: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        variables={{
          commercialJobTimesheetForm: CommercialJobTimesheetForm.toMutation(
            form.values.commercialJobTimesheetForm,
          ),
        }}
        mutation={ConfirmCommercialJobTimesheetForm.mutation}
        onCompleted={({confirmCommercialJobTimesheet: {job, errors}}: any) => {
          form.submitForm();
          form.setMutationErrors(errors);

          if (job) {
            onSuccess(job);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) =>
          children({
            ...form,
            loading,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

ConfirmCommercialJobTimesheetForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmCommercialJobTimesheetForm.fragment = gql`
  ${CommercialJobTimesheetForm.edit.fragment}

  fragment ConfirmCommercialJobTimesheetForm on Job {
    id
    ...CommercialJobTimesheetForm_edit
  }
`;

// Note(dan): isPostMoveFinished is used in the onSuccess handler from EditCommercialTimesheetCrewJobPage
ConfirmCommercialJobTimesheetForm.mutation = gql`
  mutation ConfirmCommercialJobTimesheetForm(
    $commercialJobTimesheetForm: CommercialJobTimesheetForm!,
  ) {
    confirmCommercialJobTimesheet(
      commercialJobTimesheetForm: $commercialJobTimesheetForm,
    ) {
      ${gql.errors}
      job {
        id
        isPostMoveFinished
      }
    }
  }
`;

export default ConfirmCommercialJobTimesheetForm;
