// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// Components
import {Avatar} from 'modules/App/components';

const Container = Styled.View`
  align-items: center;
`;

const Name = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
`;

type OwnProps = {
  jobUser: any;
  size?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobUserItem.defaultProps;

// @ts-expect-error TS(7022): 'JobUserItem' implicitly has type 'any' because it... Remove this comment to see the full error message
const JobUserItem = ({size, jobUser}: Props) => (
  <Container>
    <Avatar text={User.getInitials(jobUser.user)} size={size} color={colors.blue.interactive} />
    <Name numberOfLines={1}>{User.getFullName(jobUser.user)}</Name>
  </Container>
);

JobUserItem.defaultProps = {
  size: 60,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserItem.fragment = gql`
  fragment JobUserItem on JobUser {
    id
    jobId
    userId
    user {
      id
      firstName
      lastName
    }
  }
`;

export default JobUserItem;
