// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (document) => {
    return {
      documentId: (document as any).id,
      isSkipAll: false,
    };
  },
  gql`
    fragment SkipDocumentV2Form_edit on Document {
      id
    }
  `,
);

const toForm = ({documentId, isSkipAll}: any) => {
  return {
    documentId,
    isSkipAll,
  };
};

const toMutation = ({documentId, isSkipAll}: any) => {
  return {
    documentId,
    isSkipAll,
  };
};

const SkipDocumentV2Form = {
  edit,
  toForm,
  toMutation,
};

export default SkipDocumentV2Form;
