// Libraries
import React from 'react';

// Supermove
import {Notification, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import CommercialCatalogForm from '@shared/modules/CommercialCatalog/forms/CommercialCatalogForm';
import CommercialEquipmentForm from '@shared/modules/CommercialCatalog/forms/CommercialEquipmentForm';
import useUpdateCommercialCatalogMutation from '@shared/modules/CommercialCatalog/hooks/useUpdateCommercialCatalogMutation';
import DocumentCommercialEquipmentFields from 'modules/Job/Crew/Document/Show/components/DocumentCommercialEquipmentFields';

import DocumentAdditionalInfoGridSearch from './DocumentAdditionalInfoGridSearch';

const Container = Styled.View`
`;

const Content = Styled.View`
  z-index: 100;
`;

const Header = Styled.View`
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 5px;
`;

const Column = Styled.Text`
  ${Typography.Body3}
  margin-left: 10px;
  width: ${(props) => (props as any).width}px;
`;

const FormActions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Actions = Styled.View`
  margin-top: 20px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const DocumentCommercialCatalogEquipmentsFields = ({document, form: parentForm}: any) => {
  const {commercialCatalog, organization} = document.job;
  const commercialCatalogForm = CommercialCatalogForm.edit(commercialCatalog);
  const {form, submitting, handleSubmit} = useUpdateCommercialCatalogMutation({
    kind: 'EQUIPMENT',
    commercialCatalogForm,
    onSuccess: () => {
      parentForm.handleSubmit();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const {commercialEquipmentForms} = form.values.commercialCatalogForm;
  const items = organization.commercialEquipmentTypes.map((commercialEquipmentType: any) => ({
    text: commercialEquipmentType.name,
    commercialEquipmentType,
  }));

  return (
    <Container>
      <Content>
        <Header>
          <Column width={350}>Equipment</Column>
          <Column width={150}>Quantity Requested</Column>
          <Column width={150}>Left on Site</Column>
        </Header>
        {commercialEquipmentForms.map((commercialEquipmentForm: any, index: any) => {
          return (
            <DocumentCommercialEquipmentFields
              key={index}
              isFirst={index === 0}
              isLast={index === commercialEquipmentForms.length - 1}
              field={`commercialCatalogForm.commercialEquipmentForms.${index}`}
              index={index}
              form={form}
            />
          );
        })}
        {/* @ts-expect-error TS(2741): Property 'color' is missing in type '{ text: strin... Remove this comment to see the full error message */}
        {commercialEquipmentForms.length === 0 && <Notification.Warning text={'No Equipment'} />}
        {items.length > 0 && (
          <React.Fragment>
            <Space height={10} />
            <DocumentAdditionalInfoGridSearch
              items={items}
              extraData={form}
              onSelect={({commercialEquipmentType}: any) => {
                const newCommercialEquipmentForms = [
                  ...commercialEquipmentForms,
                  CommercialEquipmentForm.toForm(
                    CommercialEquipmentForm.new({
                      commercialCatalogId: commercialCatalog.id,
                      commercialEquipmentTypeId: commercialEquipmentType.id,
                      name: commercialEquipmentType.name,
                      price: commercialEquipmentType.price,
                    }),
                  ),
                ];

                form.setFieldValue(
                  'commercialCatalogForm.commercialEquipmentForms',
                  newCommercialEquipmentForms,
                );
              }}
            />
          </React.Fragment>
        )}
        <Space height={10} />
        <FormActions>
          <Button
            onPress={() => {
              const newCommercialEquipmentForms = [
                ...commercialEquipmentForms,
                CommercialEquipmentForm.toForm(
                  CommercialEquipmentForm.new({
                    commercialCatalogId: commercialCatalog.id,
                    name: '',
                    price: '',
                  }),
                ),
              ];

              form.setFieldValue(
                'commercialCatalogForm.commercialEquipmentForms',
                newCommercialEquipmentForms,
              );
            }}
          >
            <Text>+ Add Custom</Text>
          </Button>
        </FormActions>
      </Content>
      <Actions>
        <Button loading={submitting || parentForm.loading} onPress={handleSubmit}>
          <Text>Save</Text>
        </Button>
      </Actions>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentCommercialCatalogEquipmentsFields.fragment = gql`
  ${CommercialCatalogForm.edit.fragment}

  fragment DocumentCommercialCatalogEquipmentsFields on Document {
    id
    job {
      id
      organization {
        id
        commercialEquipmentTypes {
          id
          name
          price
        }
      }
      commercialCatalog {
        id
        ...CommercialCatalogForm_edit
      }
    }
  }
`;

export default DocumentCommercialCatalogEquipmentsFields;
