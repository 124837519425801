// Libraries
import React from 'react';

// App
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Json} from '@supermove/utils';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateDocumentForm.defaultProps;

// Components

// @ts-expect-error TS(7022): 'CreateDocumentForm' implicitly has type 'any' bec... Remove this comment to see the full error message
const CreateDocumentForm = ({
  jobId,
  name,
  template,
  step,
  additionalInfo,
  filterAdditionalInfo,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Form
    initialValues={{
      jobId,
      name,
      template,
      step,
      additionalInfo,
    }}
    onSubmit={(values: any) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        variables={{
          jobId: values.jobId,
          name: values.name,
          template: values.template,
          step: values.step,
          additionalInfo: Json.stringify(
            Json.toSnakeCaseKeys(filterAdditionalInfo(values.additionalInfo)),
          ),
        }}
        mutation={CreateDocumentForm.mutation}
        onCompleted={({createDocument: {document, errors}}: any) => {
          submitForm();
          setMutationErrors(errors);

          if (document) {
            onSuccess(document);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

CreateDocumentForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateDocumentForm.mutation = gql`
  mutation CreateDocumentForm(
    $jobId: Int!,
    $name: String!,
    $template: String!,
    $step: String,
    $additionalInfo: JSONString,
  ) {
    createDocument(
      jobId: $jobId,
      name: $name,
      template: $template,
      step: $step,
      additionalInfo: $additionalInfo,
    ) {
      ${gql.errors}
      document {
        id
        uuid
      }
    }
  }
`;

export default CreateDocumentForm;
