// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {
  AssignTruckToJobForm,
  OrganizationTruckItem,
  OrganizationTrucksList,
} from 'modules/Job/Crew/Truck/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Trucks = Styled.View`
  margin-top: 20px;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.Button`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getCurrentTruckId = ({job}: any) => {
  const firstJobTruck = _.first(job.jobTrucks);
  return String(_.get(firstJobTruck, 'truckId', ''));
};

const TruckCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({uuid: params.uuid})}
    query={TruckCrewJobPage.query}
  >
    {({navigator, params, loading, data}: any) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container>
            <CrewJobProgress selected={'truck'} items={CrewJobProgress.items.pre} />
            <ScrollView style={{flex: 1}}>
              <Content>
                <Title>Confirm which truck your crew is driving.</Title>
                <Subtitle>Select from the list of trucks below.</Subtitle>
                <Trucks>
                  <AssignTruckToJobForm
                    jobId={data.job.id}
                    truckId={getCurrentTruckId({job: data.job})}
                    onSuccess={() => {
                      Navigation.navigateFromTruckSuccess({navigator, params, job: data.job});
                    }}
                  >
                    {(form: any) => (
                      <OrganizationTrucksList
                        trucks={data.job.organization.trucks}
                        extraData={form}
                        renderTruck={({truck, index}: any) => (
                          <OrganizationTruckItem
                            isFirst={index === 0}
                            isLast={index === data.job.organization.trucks.length - 1}
                            isSelected={form.values.truckId === truck.id}
                            loading={form.loading}
                            truck={truck}
                            onSelect={() => {
                              form.setFieldValue('truckId', truck.id);
                              form.handleSubmit({variables: {truckId: truck.id}});
                            }}
                          />
                        )}
                      />
                    )}
                  </AssignTruckToJobForm>
                </Trucks>
              </Content>
            </ScrollView>
            <JobFooter>
              <Group>
                <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                  <Text>Back</Text>
                </Button>
              </Group>
            </JobFooter>
          </Container>
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
TruckCrewJobPage.query = gql`
  ${Navigation.navigateFromTruckSuccess.fragment}
  ${CrewJobPage.fragment}
  ${OrganizationTruckItem.fragment}

  query TruckCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      organization {
        id
        trucks {
          id
          ...OrganizationTruckItem
        }
      }
      jobTrucks {
        id
        truckId
      }
      ...Navigation_navigateFromTruckSuccess
      ...CrewJobPage
    }
  }
`;

export default TruckCrewJobPage;
