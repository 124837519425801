// Libraries
import React from 'react';

// Components
import {Loading, Styled} from '@supermove/components';

import Page from './Page';
import PageLoadingIndicator from './PageLoadingIndicator';

const Container = Styled.View`
  flex: 1;
`;

type OwnProps = {
  navigation?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ModalPage.defaultProps;

// @ts-expect-error TS(7022): 'ModalPage' implicitly has type 'any' because it d... Remove this comment to see the full error message
const ModalPage = ({fetchPolicy, getVariables, query, navigation, onFocus, children}: Props) => (
  <Page fetchPolicy={fetchPolicy} getVariables={getVariables} query={query} onFocus={onFocus}>
    {({isFocused, responsive, loading, ...props}: any) => (
      <Container {...responsive}>
        {navigation({isFocused, responsive, loading, ...props})}
        <Loading alwaysUpdate loading={loading || !isFocused} as={PageLoadingIndicator}>
          {() => children({isFocused, responsive, ...props})}
        </Loading>
      </Container>
    )}
  </Page>
);

ModalPage.defaultProps = {
  navigation: () => null,
};

export default ModalPage;
