// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';

export interface RemoveAttachmentModalV2Props {
  isOpen: boolean;
  handleClose: () => void;
  handleRemove: () => void;
}

const RemoveAttachmentModalV2 = ({
  isOpen,
  handleClose,
  handleRemove,
}: RemoveAttachmentModalV2Props) => {
  const handleRemovePress = () => {
    handleClose();
    handleRemove();
  };

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Remove photo?'}
      subtitle={'This cannot be undone.'}
      handleClose={handleClose}
      handleDelete={handleRemovePress}
      isSubmitting={false}
    />
  );
};

export default RemoveAttachmentModalV2;
