// Libraries
import React from 'react';

// Components
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

import Navigation from 'core/Navigation';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateSignatureForDocumentStepForm.defaultProps;

// @ts-expect-error TS(7022): 'CreateSignatureForDocumentStepForm' implicitly ha... Remove this comment to see the full error message
const CreateSignatureForDocumentStepForm = ({
  documentId,
  customerId,
  step,
  date,
  kind,
  name,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Form
    initialValues={{
      documentId,
      customerId,
      step,
      date,
      kind,
      image: '',
      name,
    }}
    onSubmit={(values: any) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        variables={{
          documentId: values.documentId,
          customerId: values.customerId,
          step: values.step,
          date: values.date,
          kind: values.kind,
          image: values.image,
          name: values.name,
        }}
        mutation={CreateSignatureForDocumentStepForm.mutation}
        onCompleted={({createSignatureForDocumentStep: {document, errors}}: any) => {
          submitForm();
          setMutationErrors(errors);

          if (document) {
            onSuccess(document);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

CreateSignatureForDocumentStepForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------

// The Navigation fragments are used for handling navigation in SignDocumentCustomerJobPage,
// which needs the updated job to determine where to navigate.
CreateSignatureForDocumentStepForm.mutation = gql`
  ${Navigation.navigateToNextIncompleteDocumentForAfterTimesheet.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForPreMove.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}
  mutation CreateSignatureForDocumentStepForm(
    $documentId: Int!,
    $customerId: Int!,
    $step: String!,
    $date: String,
    $kind: String,
    $image: String,
    $name: String,
  ) {
    createSignatureForDocumentStep(
      documentId: $documentId,
      customerId: $customerId,
      step: $step,
      date: $date,
      kind: $kind,
      image: $image,
      name: $name,
    ) {
      ${gql.errors}
      document {
        id
        isStepCompleted(step: $step)
        job {
          id
          ...Navigation_navigateToNextIncompleteDocumentForAfterTimesheet
          ...Navigation_navigateToNextIncompleteDocumentForPreMove
          ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
        }
      }
    }
  }
`;

export default CreateSignatureForDocumentStepForm;
