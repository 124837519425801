// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {BillModifierForm, LineItemForm} from '@supermove/forms';
import {useForm} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import EditBillItemModalFields from 'modules/Project/Billing/Edit/components/EditBillItemModalFields';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const ScreenContainer = Styled.View`
  flex: 1;
  padding-horizontal: 240px;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 16px;
`;

const SectionContainer = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const ButtonText = Styled.H6`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const handleZeroQuantity = ({billItemForm, handleRemove}: any) => {
  const isLineItem = LineItemForm.getIsLineItem(billItemForm);
  const isDerivedQuantity = BillItemForm.getHasQuantityFormula(billItemForm);
  if (isLineItem && !isDerivedQuantity) {
    handleRemove();
  }
};

const EditLineItemHeader = ({title}: any) => {
  return (
    <HeaderContainer>
      <Row>
        <Space width={20} />
        <TitleText>{title}</TitleText>
      </Row>
    </HeaderContainer>
  );
};

const EditBillItemModalFooter = ({form, handleRemoveItem, handleClose, isAddingNewItem}: any) => {
  return (
    <SplitRow>
      <Row>
        <Space width={20} />
        {!isAddingNewItem && (
          <Button
            color={colors.alpha(colors.red.warning, 0.1)}
            onPress={() => {
              handleRemoveItem();
              handleClose();
            }}
          >
            <ButtonText color={colors.red.warning}>Remove</ButtonText>
          </Button>
        )}
      </Row>
      <Row>
        <Button color={colors.gray.border} onPress={handleClose}>
          <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
        </Button>
        <Space width={16} />
        {/* The setTimeout is wrapped around the submitForm so that the submitForm happens
        after all the fields have had a chance to blur. This allows any field formatting
        that happens during onBlur to complete, eg PercentInput and CurrencyInput. */}
        <Button color={colors.blue.interactive} onPress={() => setTimeout(form.submitForm, 0)}>
          <ButtonText color={colors.white}>Save</ButtonText>
        </Button>
        <Space width={20} />
      </Row>
    </SplitRow>
  );
};

const EditBillItemModal = ({
  isOpen,
  title,
  handleClose,
  billForm,
  billFormField,
  billItemForm,
  handleEditBillItemForm,
  handleRemoveBillItemForm,
  isEnabledTbdBillItems,
}: any) => {
  const isAddingNewItem = _.isNil(billItemForm.index);
  const form = useForm({
    initialValues: {billItemForm, selectedCustomBillItemForm: null},
    validate: ({billItemForm}) => {
      return BillItemForm.validate({billItemForm, field: 'billItemForm'});
    },
    onSubmit: ({billItemForm}) => {
      handleEditBillItemForm({form: billForm, field: billFormField, billItemForm});
      if (billItemForm.quantity === 0) {
        handleZeroQuantity({
          billItemForm,
          handleRemove: () =>
            handleRemoveBillItemForm({form: billForm, field: billFormField, billItemForm}),
        });
      }
      handleClose();
    },
  });
  const isPreSubtotal = form.values.billItemForm.billStage === BillStage.PRE_SUBTOTAL;

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer>
        <ModalContainer>
          <Space height={12} />
          <EditLineItemHeader title={title} />
          <Space height={12} />
          <Line />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <SectionContainer sectionIndex={0}>
            <EditBillItemModalFields
              form={form}
              field={'billItemForm'}
              isAddingNewItem={isAddingNewItem}
              isPreSubtotal={isPreSubtotal}
              isAmount={BillModifierForm.getIsAmount(form.values.billItemForm)}
              isPercentage={BillModifierForm.getIsPercentage(form.values.billItemForm)}
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
          </SectionContainer>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <SectionContainer sectionIndex={1}>
            <Line />
            <Space height={12} />
            <EditBillItemModalFooter
              form={form}
              handleClose={handleClose}
              isAddingNewItem={isAddingNewItem}
              handleRemoveItem={() =>
                handleRemoveBillItemForm({form: billForm, field: billFormField, billItemForm})
              }
            />
            <Space height={12} />
          </SectionContainer>
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default EditBillItemModal;
