// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompleteTimesheetStepForm from '@shared/modules/Timesheet/forms/CompleteTimesheetStepForm';

const useCompleteDuringMoveJobTimesheetStepMutation = ({
  completeTimesheetStepForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      completeTimesheetStepForm: CompleteTimesheetStepForm.toForm(completeTimesheetStepForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteDuringMoveJobTimesheetStepMutation.mutation,
    variables: {
      completeTimesheetStepForm: CompleteTimesheetStepForm.toMutation(
        form.values.completeTimesheetStepForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCompleteDuringMoveJobTimesheetStepMutation.mutation = gql`
  mutation useCompleteDuringMoveJobTimesheetStepMutation($completeTimesheetStepForm: CompleteTimesheetStepForm!) {
    response: completeDuringMoveJobTimesheetStep(completeTimesheetStepForm: $completeTimesheetStepForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useCompleteDuringMoveJobTimesheetStepMutation;
