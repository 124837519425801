// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {Device} from '@supermove/sdk';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ErrorModal.defaultProps;

// @ts-expect-error TS(7022): 'ErrorModal' implicitly has type 'any' because it ... Remove this comment to see the full error message
const ErrorModal = ({trigger}: Props) => (
  <Modal trigger={trigger}>
    {({handleClose}: any) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={400}>
          <Circle>
            <Emoji component={EmojiText} name={'exclamation'} />
          </Circle>
          <Content>
            <Title>An Error Has Occurred</Title>
            <Subtitle>
              We have been notified and are looking into the issue. Please restart the app. If the
              problem continues, please contact us.
            </Subtitle>
            <Actions>
              <Button
                color={colors.blue.interactive}
                onPress={() => {
                  handleClose();
                  Device.restartApp();
                }}
              >
                <Text>Restart</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

ErrorModal.defaultProps = {};

export default ErrorModal;
