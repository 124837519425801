// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const PageContainer = Styled.View`
  flex: 1;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const PageV2 = ({children}: any) => {
  return <PageContainer>{children}</PageContainer>;
};

PageV2.Title = Title;
PageV2.Subtitle = Subtitle;

export default PageV2;
