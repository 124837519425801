// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {withFragment, List} from '@supermove/utils';

// App
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

import Project from './Project';

const EMAIL_ERROR_HELP_LINK = 'https://supermovehelp.zendesk.com/hc/en-us/articles/15008872535316';

const getAddBillAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {addBillDrawer}: any,
) => ({
  label: 'Add bill',
  onPress: addBillDrawer?.handleOpen,
});

const getSelectBillTemplateAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {selectBillTemplateDrawer}: any,
) => ({
  label: 'Select bill template',
  onPress: selectBillTemplateDrawer?.handleOpen,
});

const getEditBillingValuesAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {editBillingValuesModal}: any,
) => ({
  label: 'Edit billing values',
  onPress: editBillingValuesModal?.handleOpen,
});

const getEditSalesTaxAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {updateSalesTaxModal}: any,
) => ({
  label: 'Edit sales tax',
  onPress: () => updateSalesTaxModal.handleOpen(),
  isDisabled: _.includes([InvoiceStatus.FINALIZED], invoice.status),
});

const getExportAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {handleSendInvoiceToCodatSubmit}: any,
) => ({
  label: 'Queue for Quickbooks Export',

  onPress: () => {
    handleSendInvoiceToCodatSubmit();
  },

  isDisabled: false,
});

const getFinalizeAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {finalizeInvoiceModal}: any,
) => ({
  label: 'Finalize invoice',

  onPress: () => {
    finalizeInvoiceModal.handleOpen();
  },

  isDisabled: invoice.status !== InvoiceStatus.PAID || invoice.remainingBalance !== 0,
});

const getUnfinalizeAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {unfinalizeInvoiceModal}: any,
) => ({
  label: 'Unfinalize invoice',

  onPress: () => {
    unfinalizeInvoiceModal.handleOpen();
  },

  isDisabled: invoice.status !== InvoiceStatus.FINALIZED,
});

const getEditAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {handleEditStorageInvoice, confirmInvoiceEditModal}: any,
) => ({
  label: 'Edit invoice',

  onPress: () => {
    if (invoice.status === InvoiceStatus.PAID) {
      confirmInvoiceEditModal.handleOpen();
    } else {
      handleEditStorageInvoice();
    }
  },

  isDisabled: _.includes([InvoiceStatus.FINALIZED], invoice.status),
});

const getSendAction = (invoice: any, {updateInvoiceDrawer}: any) => {
  const {
    status,
    project: {
      isComplete,
      projectType: {category},
    },
  } = invoice;

  // We don't expose send invoice for move projects unless it is complete
  const isDisabled =
    (category === ProjectTypeCategory.MOVE && !isComplete) ||
    _.includes([InvoiceStatus.PAID, InvoiceStatus.FINALIZED], status);

  return {
    label: 'Send invoice',
    onPress: () => {
      updateInvoiceDrawer.handleOpen();
    },
    isDisabled,
  };
};

const getResendAction = (invoice: any, {handleResendInvoiceSubmit}: any) => {
  const {
    status,
    date,
    emailTemplateId,
    project: {
      isComplete,
      projectType: {category},
    },
  } = invoice;

  // We don't expose resend invoice for move projects unless it is complete
  // Resend invoice for move projects is also disabled when invoice was never
  // sent before
  const isDisabled =
    (category === ProjectTypeCategory.MOVE && !isComplete) ||
    _.includes([InvoiceStatus.DRAFT, InvoiceStatus.SCHEDULED], status) ||
    !date ||
    !emailTemplateId;
  return {
    label: 'Resend invoice',
    onPress: () => {
      handleResendInvoiceSubmit();
    },
    isDisabled,
  };
};

const getViewAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {navigator}: any,
) => ({
  label: 'View invoice document',
  onPress: () => navigator.pushNewTab(`/invoices/${invoice.uuid}/view`),
});

const getManageCreditCardsAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {manageCreditCardsDrawer}: any,
) => ({
  label: 'Manage credit cards',
  onPress: () => manageCreditCardsDrawer.handleOpen(),
});

const getRecordPaymentAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {recordPaymentModal}: any,
) => ({
  label: 'Record payment',
  onPress: () => recordPaymentModal.handleOpen(),
  isDisabled: invoice.status === InvoiceStatus.FINALIZED,
});

const getCancelAction = (
  // @ts-expect-error TS(7006): Parameter 'invoice' implicitly has an 'any' type.
  invoice,
  {cancelInvoiceModal}: any,
) => ({
  label: 'Cancel invoice',

  onPress: () => {
    cancelInvoiceModal.handleOpen();
  },

  isDisabled: _.includes([InvoiceStatus.PAID, InvoiceStatus.FINALIZED], invoice.status),
});

const getInvoiceBalanceColor = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ invoice }: { invoice: any; })... Remove this comment to see the full error message
  ({invoice}) => {
    const hasBalance = invoice.remainingBalance > 0;
    const hasCredit = invoice.remainingBalance < 0;
    if (hasBalance) {
      return colors.red.warning;
    }
    if (hasCredit) {
      return colors.green.status;
    }
    return colors.blue.accentDark;
  },
  gql`
    fragment Invoice_getInvoiceBalanceColor on Invoice {
      id
      remainingBalance
    }
  `,
);

const getBillingSection = ({
  invoice,
  updateSalesTaxModal,
  addBillDrawer,
  selectBillTemplateDrawer,
  editBillingValuesModal,
}: any) => {
  return [
    ...List.insertIf(addBillDrawer, getAddBillAction(invoice, {addBillDrawer})),
    ...List.insertIf(
      selectBillTemplateDrawer,
      getSelectBillTemplateAction(invoice, {selectBillTemplateDrawer}),
    ),
    ...List.insertIf(
      editBillingValuesModal,
      getEditBillingValuesAction(invoice, {editBillingValuesModal}),
    ),
    getEditSalesTaxAction(invoice, {updateSalesTaxModal}),
  ];
};

const getInvoiceSection = ({
  invoice,
  navigator,
  finalizeInvoiceModal,
  unfinalizeInvoiceModal,
  handleEditStorageInvoice,
  confirmInvoiceEditModal,
  updateInvoiceDrawer,
  handleResendInvoiceSubmit,
  cancelInvoiceModal,
  isAuthorizedAccountingActions,
}: any) => {
  const {
    project: {
      projectType: {category},
    },
    status,
  } = invoice;

  return [
    // View option
    getViewAction(invoice, {navigator}),

    // Finalize option
    ...List.insertIf(
      status !== InvoiceStatus.FINALIZED && isAuthorizedAccountingActions,
      getFinalizeAction(invoice, {finalizeInvoiceModal}),
    ),

    // Unfinalize option
    ...List.insertIf(
      status === InvoiceStatus.FINALIZED && isAuthorizedAccountingActions,
      getUnfinalizeAction(invoice, {unfinalizeInvoiceModal}),
    ),

    // Edit option
    ...List.insertIf(
      category === ProjectTypeCategory.STORAGE,
      getEditAction(invoice, {
        handleEditStorageInvoice,
        confirmInvoiceEditModal,
      }),
    ),

    // Send option
    ...List.insertIf(
      category === ProjectTypeCategory.MOVE && isAuthorizedAccountingActions,
      getSendAction(invoice, {updateInvoiceDrawer}),
    ),

    // Resend option
    ...List.insertIf(
      category === ProjectTypeCategory.STORAGE && isAuthorizedAccountingActions,
      getResendAction(invoice, {handleResendInvoiceSubmit}),
    ),

    // Cancel option
    ...List.insertIf(
      category === ProjectTypeCategory.STORAGE,
      getCancelAction(invoice, {cancelInvoiceModal}),
    ),
  ];
};

const getPaymentsSection = ({
  invoice,
  recordPaymentModal,
  chargeCreditCardDrawer,
  manageCreditCardsDrawer,
}: any) => {
  const isEnabledPayengine = Project.getIsEnabledCreditCards(invoice.project);

  return [
    // Record Payment option
    getRecordPaymentAction(invoice, {recordPaymentModal}),

    // Charge Credit Card option
    ...List.insertIf(!!isEnabledPayengine, {
      label: 'Charge credit card',
      onPress: chargeCreditCardDrawer.handleOpen,
      isDisabled: invoice.status === InvoiceStatus.FINALIZED,
    }),

    // Manage Credit Cards option
    ...List.insertIf(
      !!isEnabledPayengine,
      getManageCreditCardsAction(invoice, {manageCreditCardsDrawer}),
    ),
  ];
};

const getAdminSection = ({
  invoice,
  handleSendInvoiceToCodatSubmit,
  isAuthorizedAccountingActions,
}: any) => {
  const {activeCodatIntegration} = invoice.organization;

  return [
    // Export option
    ...List.insertIf(
      activeCodatIntegration && isAuthorizedAccountingActions,
      getExportAction(invoice, {handleSendInvoiceToCodatSubmit}),
    ),
  ];
};

// NOTE(cooper): This gets the possible actions that should populate in the invoice
// dropdown menu. Each invoice will have different options depending on the project
// and status of the invoice.
const getInvoiceActions = withFragment(
  (
    invoice,
    {
      navigator,
      isAuthorizedAccountingActions,
      // Billing
      addBillDrawer,
      selectBillTemplateDrawer,
      editBillingValuesModal,
      updateSalesTaxModal,
      // Invoice
      handleEditStorageInvoice,
      handleResendInvoiceSubmit,
      cancelInvoiceModal,
      finalizeInvoiceModal,
      unfinalizeInvoiceModal,
      updateInvoiceDrawer,
      confirmInvoiceEditModal,
      // Payments
      recordPaymentModal,
      chargeCreditCardDrawer,
      manageCreditCardsDrawer,
      // Admin
      handleSendInvoiceToCodatSubmit,
    },
    visibleSections = ['BILLING', 'INVOICE', 'PAYMENTS', 'ADMIN'],
  ) => {
    // No actions can be taken on a cancelled invoice
    if ((invoice as any).status === InvoiceStatus.CANCELLED) {
      return [];
    }

    const billingSection = _.includes(visibleSections, 'BILLING')
      ? getBillingSection({
          invoice,
          addBillDrawer,
          selectBillTemplateDrawer,
          editBillingValuesModal,
          updateSalesTaxModal,
        })
      : [];
    const invoiceSection = _.includes(visibleSections, 'INVOICE')
      ? getInvoiceSection({
          invoice,
          finalizeInvoiceModal,
          unfinalizeInvoiceModal,
          handleEditStorageInvoice,
          confirmInvoiceEditModal,
          updateInvoiceDrawer,
          handleResendInvoiceSubmit,
          cancelInvoiceModal,
          navigator,
          isAuthorizedAccountingActions,
        })
      : [];
    const paymentsSection = _.includes(visibleSections, 'PAYMENTS')
      ? getPaymentsSection({
          invoice,
          recordPaymentModal,
          chargeCreditCardDrawer,
          manageCreditCardsDrawer,
        })
      : [];
    const adminSection = _.includes(visibleSections, 'ADMIN')
      ? getAdminSection({
          invoice,
          handleSendInvoiceToCodatSubmit,
          isAuthorizedAccountingActions,
        })
      : [];

    return [
      ...List.insertIf(_.some(billingSection), {
        label: 'Billing',
        actions: billingSection,
      }),
      ...List.insertIf(_.some(invoiceSection), {
        label: 'Invoice',
        actions: invoiceSection,
      }),
      ...List.insertIf(_.some(paymentsSection), {
        label: 'Payments',
        actions: paymentsSection,
      }),
      ...List.insertIf(_.some(adminSection), {
        label: 'Admin',
        actions: adminSection,
      }),
    ];
  },
  gql`
    ${Project.getIsEnabledCreditCards.fragment}

    fragment Invoice_getInvoiceActions on Invoice {
      id
      uuid
      status
      emailTemplateId
      remainingBalance
      organization {
        id
        slug
        activeCodatIntegration {
          id
        }
      }
      project {
        id
        uuid
        isComplete
        projectType {
          id
          category
        }
        ...Project_getIsEnabledCreditCards
      }
    }
  `,
);

const Invoice = {
  EMAIL_ERROR_HELP_LINK,
  getInvoiceActions,
  getInvoiceBalanceColor,
};

export default Invoice;
