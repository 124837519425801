// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useIsFocused} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import {ViewerJobBanner} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Footer = Styled.View`
  height: 61px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const CrewJobPageV2 = ({children, refetch}: any) => {
  useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Container>
      <ViewerJobBanner view={'EMPLOYEE'} />
      {children}
    </Container>
  );
};

CrewJobPageV2.Footer = Footer;

export default CrewJobPageV2;
