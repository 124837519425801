// Libraries
import React from 'react';

// Supermove
import {useNavigation} from '@supermove/hooks';

// App
import {UpdateAppView} from 'modules/Settings/Update/components';

const SettingsUpdatePage = () => {
  const {navigator} = useNavigation();

  return <UpdateAppView isRequired={false} onSkip={() => navigator.goBack()} />;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SettingsUpdatePage.propTypes = {};

SettingsUpdatePage.defaultProps = {};

export default SettingsUpdatePage;
