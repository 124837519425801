// Libraries
import React from 'react';

// Supermove
import {
  ExternalLink,
  Form,
  KeyboardView,
  Mutation,
  ScrollView,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useMountEffect, useNavigation, useResponsive, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import LOCAL_STORAGE from 'core/constants/LOCAL_STORAGE';

const Row = Styled.View`
  flex-direction: row;
`;

const Content = Styled.View`
  padding-top: ${(props) => ((props as any).desktop ? 80 : 40)}px;
  padding-bottom: 40px;
  padding-horizontal: ${(props) => ((props as any).desktop ? 100 : 50)}px;
  max-width: 600px;
`;

const Logo = Styled.H1`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Title = Styled.H2`
  margin-top: 40px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Field = Styled.View`
  margin-top: 20px;
`;

const Label = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Input = Styled.TextInput.H6`
  margin-top: 5px;
  height: 50px;
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
`;

const SubmitButton = Styled.LoadingButton`
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 120px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PrivacyPolicyText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const DeniedPermissionContent = ({setIsDenied}: any) => {
  return (
    <React.Fragment>
      <SmallModal.BoldText>
        {`Supermove is unable to continue without this permission.`}
      </SmallModal.BoldText>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button color={colors.blue.interactive} onPress={() => setIsDenied(false)}>
          Go Back
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const RequestPermissionContent = ({handleAcknowledge, setIsDenied}: any) => {
  return (
    <React.Fragment>
      <SmallModal.BoldText>
        {`In order to facilitate payments, Supermove collects phone number and installed ` +
          `applications data even when the app is not in use. This information is shared ` +
          `with our payments processor(s).`}
      </SmallModal.BoldText>
      <Space height={16} />
      <Row>
        <ExternalLink url={`https://supermove.co/privacy-policy`}>
          <SmallModal.ButtonText color={colors.blue.interactive}>
            Privacy Policy
          </SmallModal.ButtonText>
        </ExternalLink>
      </Row>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={() => setIsDenied(true)}>Deny</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.blue.interactive} onPress={handleAcknowledge}>
          Accept
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const AcknowledgeDataCollectionModal = ({isOpen, handleAcknowledge}: any) => {
  const [isDenied, setIsDenied] = useState(false);

  return (
    <SmallModal isOpen={isOpen}>
      {isDenied ? (
        <DeniedPermissionContent setIsDenied={setIsDenied} />
      ) : (
        <RequestPermissionContent handleAcknowledge={handleAcknowledge} setIsDenied={setIsDenied} />
      )}
    </SmallModal>
  );
};

const OrganizationAuthenticationPage = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  const acknowledgeDataCollectionModal = useModal({name: 'Ackowledge Data Collection Modal'});
  const handleCheckAcknowledgedDataCollection = async () => {
    const isAcknowledgedDataCollection = await Storage.getItem(
      LOCAL_STORAGE.DATA_COLLECTION_STATUS,
    );
    if (!isAcknowledgedDataCollection) {
      acknowledgeDataCollectionModal.handleOpen();
    }
  };
  useMountEffect(() => {
    handleCheckAcknowledgedDataCollection();
  });

  return (
    <Form
      initialValues={{
        slug: '',
        pin: '',
      }}
      onSubmit={(values: any) => {}}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        setMutationErrors,
        submitForm,
      }: any) => (
        <Mutation
          // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
          variables={{
            slug: values.slug,
            pin: values.pin,
          }}
          mutation={OrganizationAuthenticationPage.mutation}
          onCompleted={async ({logInOrganization: {token, errors}}: any) => {
            submitForm();
            setMutationErrors(errors);
            if (token) {
              // Store the token and navigate to the users page.
              await Storage.setItem('token', token);
              navigator.navigate('UsersAuthentication');
            }
          }}
        >
          {(handleSubmit: any, {loading, error}: any) => (
            <KeyboardView>
              <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
                <Content {...responsive}>
                  <Logo>Supermove</Logo>
                  <Title>Welcome!</Title>
                  <Field>
                    <Label>Organization</Label>
                    <Input
                      autoCorrect={false}
                      autoCapitalize={'none'}
                      name={'slug'}
                      placeholder={'Enter your tablet login'}
                      value={values.slug}
                      onChangeText={(text: any) => setFieldValue('slug', text)}
                      onBlur={() => setFieldTouched('slug', true)}
                    />
                    {touched.slug && errors.slug && (
                      <ValidationError>{errors.slug}</ValidationError>
                    )}
                  </Field>
                  <Field>
                    <Label>Pin</Label>
                    <Input
                      secureTextEntry
                      keyboardType={'default'}
                      name={'pin'}
                      placeholder={'Enter your pin'}
                      value={values.pin}
                      onChangeText={(text: any) => setFieldValue('pin', text)}
                      onBlur={() => setFieldTouched('pin', true)}
                      onSubmitEditing={handleSubmit}
                    />
                    {touched.pin && errors.pin && <ValidationError>{errors.pin}</ValidationError>}
                  </Field>
                  <Actions>
                    <SubmitButton loading={loading} onPress={handleSubmit}>
                      <SubmitText>Log In</SubmitText>
                    </SubmitButton>
                  </Actions>
                  <Space height={12} />
                  <ExternalLink url={`https://supermove.co/privacy-policy`}>
                    <PrivacyPolicyText>Privacy Policy</PrivacyPolicyText>
                  </ExternalLink>
                </Content>
              </ScrollView>
              <AcknowledgeDataCollectionModal
                isOpen={acknowledgeDataCollectionModal.isOpen}
                handleAcknowledge={() => {
                  Storage.setItem(LOCAL_STORAGE.DATA_COLLECTION_STATUS, 'ACKNOWLEDGED');
                  acknowledgeDataCollectionModal.handleClose();
                }}
              />
            </KeyboardView>
          )}
        </Mutation>
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
OrganizationAuthenticationPage.propTypes = {};

OrganizationAuthenticationPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationAuthenticationPage.mutation = gql`
  mutation OrganizationAuthenticationPage($slug: String!, $pin: String!) {
    logInOrganization(slug: $slug, pin: $pin) {
      ${gql.errors}
      token
    }
  }
`;

export default OrganizationAuthenticationPage;
