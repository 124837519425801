// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import AlreadyPreparedJobNotification from './AlreadyPreparedJobNotification';
import JobCompletedNotification from './JobCompletedNotification';
import NotCurrentJobNotification from './NotCurrentJobNotification';

const shouldShowNotCurrentJob = ({hasCurrentJob, isCurrentJob, job}: any) => {
  if (job.isComplete) {
    // Completed jobs don't show error states.
    return false;
  } else if (hasCurrentJob && !isCurrentJob) {
    // You have a current job and this isn't your current job.
    return true;
  }

  return false;
};

const shouldShowAlreadyPrepared = ({isCurrentJob, job}: any) => {
  if (job.isComplete) {
    // Completed jobs don't show error states.
    return false;
  } else if (job.isPrepareStarted && !isCurrentJob) {
    //  Someone else has started the job and it's not your current job.
    return true;
  }

  return false;
};

const JobNotifications = ({hasCurrentJob, isCurrentJob, job, viewer, refetch}: any) => {
  if (job.isComplete) {
    return <JobCompletedNotification job={job} />;
  }

  if (shouldShowNotCurrentJob({hasCurrentJob, isCurrentJob, job})) {
    return <NotCurrentJobNotification viewer={viewer} refetch={refetch} />;
  }

  if (shouldShowAlreadyPrepared({isCurrentJob, job})) {
    return <AlreadyPreparedJobNotification job={job} />;
  }

  return null;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNotifications.fragment = gql`
  ${JobCompletedNotification.fragment}
  ${NotCurrentJobNotification.fragment}
  ${AlreadyPreparedJobNotification.fragment}

  fragment JobNotifications_Viewer on User {
    id
    ...NotCurrentJobNotification
  }

  fragment JobNotifications_Job on Job {
    id
    isPrepareStarted
    isComplete
    ...JobCompletedNotification
    ...AlreadyPreparedJobNotification
  }
`;

export default JobNotifications;
