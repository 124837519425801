// Libraries
import PropTypes, {InferProps} from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const PaymentAmountBackground = Styled.View`
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border: ${colors.gray.border};
  padding-horizontal: 24px;
  padding-vertical: 16px;
  align-items: center;
  width: 100%;
`;

const Title = Styled.Text`
  ${Typography.Subheading}
  color: ${colors.gray.secondary};
`;

const Amount = Styled.Text`
  ${Typography.PageHeading}
  font-size: 40px;
  line-height: 60px;
`;

const propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const PaymentAmountSection = ({
  title,
  amount,
  children,
}: InferProps<typeof propTypes>): JSX.Element => {
  return (
    <PaymentAmountBackground>
      {title && (
        <>
          <Title>{title}</Title>
          <Space height={8} />
        </>
      )}
      <Amount>{amount}</Amount>
      {children}
    </PaymentAmountBackground>
  );
};

PaymentAmountSection.propTypes = propTypes;

PaymentAmountSection.defaultProps = {
  title: null,
  children: null,
};

export default PaymentAmountSection;
