// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Notification from 'modules/App/components/Notification';
import JobAttachmentsList from 'modules/Job/Attachment/components/JobAttachmentsList';

const JobPhotosList = ({job, refetch}: any) => {
  const {navigator} = useNavigation();

  if (job.filteredCrewAttachments.length === 0) {
    return <Notification color={colors.orange.status} text={`No photos.`} />;
  }

  return (
    <JobAttachmentsList
      uuid={job.uuid}
      attachments={job.filteredCrewAttachments}
      onPress={({jobUuid, attachmentUuid}: any) => {
        navigator.navigate('UpdateAttachmentJob', {
          uuid: jobUuid,
          attachmentUuid,
        });
      }}
      onDelete={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPhotosList.fragment = gql`
  ${JobAttachmentsList.fragment}

  fragment JobPhotosList on Job {
    id
    uuid
    filteredCrewAttachments {
      id
      ...JobAttachmentsList
    }
  }
`;

export default JobPhotosList;
