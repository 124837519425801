// Libraries
import React from 'react';

// App
import {ExternalLink, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// Components

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Value = Styled.H6`
  margin-top: 5px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isLink ? colors.blue.accentDark : colors.gray.primary)};
`;

const Empty = Styled.H6`
  margin-top: 5px;
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Content = ({newTab, numberOfLines, value, link, empty}: any) => {
  if (!value) {
    return <Empty numberOfLines={numberOfLines}>{empty}</Empty>;
  }

  if (link) {
    return (
      <ExternalLink url={link}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Value vars={{isLink: true}} numberOfLines={numberOfLines}>
          {value}
        </Value>
      </ExternalLink>
    );
  }

  return <Value numberOfLines={numberOfLines}>{value}</Value>;
};

const FieldValue = ({newTab, numberOfLines, label, value, link, empty, style}: any) => (
  <Container style={style}>
    <Label>{label}</Label>
    <Content
      newTab={newTab}
      numberOfLines={numberOfLines}
      value={value}
      link={link}
      empty={empty}
    />
  </Container>
);

export default FieldValue;
