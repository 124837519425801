// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useState} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import useCreateJobEventJobTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventJobTimesheetUpdatedMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BillableTimesheetPageContent from 'modules/Job/Crew/Timesheet/components/BillableTimesheetPageContent';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const BillableTimesheetActionPageContent = ({job, refetch}: any) => {
  const {navigator} = useNavigation();
  const [hasUpdates, setHasUpdates] = useState(false);
  const createJobEventTimesheetUpdated = useCreateJobEventJobTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: () => {},
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <ProjectBillingHeader
        leftButtonType={'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
        onBack={() => {
          if (hasUpdates) {
            createJobEventTimesheetUpdated.handleSubmit();
          }
        }}
      />
      <BillableTimesheetPageContent
        job={job}
        navigator={navigator}
        refetch={refetch}
        onUpdate={() => {
          setHasUpdates(true);
        }}
      />
    </React.Fragment>
  );
};

const BillableTimesheetActionPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(BillableTimesheetActionPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.uuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <BillableTimesheetActionPageContent job={data.job} refetch={refetch} />;
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillableTimesheetActionPage.query = gql`
  ${BillableTimesheetPageContent.fragment}
  ${Job.getFullName.fragment}
  ${Project.getName.fragment}

  query BillableTimesheetActionPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      ...BillableTimesheetPageContent
      ...Job_getFullName
      project {
        id
        ...Project_getName
      }
    }
  }
`;

export default BillableTimesheetActionPage;
