// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SkipDocumentV2Form from '@shared/modules/Document/forms/SkipDocumentV2Form';
import useSkipDocumentV2Mutation from '@shared/modules/Document/hooks/useSkipDocumentV2Mutation';

const COLOR = colors.orange.status;

const SkipDocumentV2Modal = ({document, isOpen, handleClose, handleSkipSuccess}: any) => {
  const skipDocumentV2Form = SkipDocumentV2Form.edit(document);
  const {form, handleSubmit, submitting} = useSkipDocumentV2Mutation({
    skipDocumentV2Form,
    onSuccess: handleSkipSuccess,
    onError: (errors: any) => console.log({errors}),
  });
  const isSkipAllField = 'skipDocumentV2Form.isSkipAll';
  const isSkipAll = _.get(form.values, isSkipAllField);

  return (
    <SmallModal
      isOpen={isOpen}
      handlePressOutside={handleClose}
      style={{alignItems: 'center', borderRadius: 16}}
    >
      <SmallModal.HeaderIcon source={Icon.ExclamationTriangle} color={COLOR} />
      <Space height={12} />
      <SmallModal.HeaderText
        style={{color: COLOR, textAlign: 'center'}}
      >{`Skip ${document.displayName}?`}</SmallModal.HeaderText>
      <Space height={12} />
      <SmallModal.Text style={{textAlign: 'center'}}>
        You should only use this option if the customer is not available to sign right now.
      </SmallModal.Text>
      <Space height={12} />
      <Checkbox
        isChecked={isSkipAll}
        style={{alignItems: 'center'}}
        childrenRight
        handleToggle={() => form.setFieldValue(isSkipAllField, !isSkipAll)}
      >
        <Space width={6} />
        <SmallModal.ButtonText color={isSkipAll ? colors.gray.secondary : colors.gray.tertiary}>
          Skip ALL documents for this section
        </SmallModal.ButtonText>
      </Checkbox>
      <Space height={20} />
      <SmallModal.Footer style={{justifyContent: 'center'}}>
        <SmallModal.Button
          onPress={handleClose}
          isDisabled={submitting}
          color={colors.gray.tertiary}
        >
          Go back
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button onPress={handleSubmit} color={COLOR} isSubmitting={submitting}>
          {`Skip document${isSkipAll ? 's' : ''}`}
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

SkipDocumentV2Modal.fragment = gql`
  ${SkipDocumentV2Form.edit.fragment}
  fragment SkipDocumentV2Modal on Document {
    id
    displayName
    ...SkipDocumentV2Form_edit
  }
`;

export default SkipDocumentV2Modal;
