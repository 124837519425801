// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {LineItem} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 7px;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
  width: 140px;
`;

const Info = Styled.View`
  flex: 1;
  margin-right: 10px;
`;

const Name = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Description = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const Quantity = Styled.H6`
  color: ${colors.gray.secondary};
  text-align: right;
`;

const Price = Styled.H6`
  color: ${colors.gray.secondary};
  text-align: right;
`;

const Value = Styled.H5`
  ${fontWeight(700)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isDiscount ? colors.green.status : colors.gray.primary)};
  text-align: right;
`;

const LineItemTotal = ({isDiscount, lineItem}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Value vars={{isDiscount}}>
    {isDiscount
      ? `(${Currency.display(-lineItem.total, {shouldHideCentsIfZero: true})})`
      : LineItem.getEstimateTotal(lineItem)}
  </Value>
);

const BillLineItemItem = ({hidePrices, lineItem}: any) => (
  <Container>
    <Content>
      <Info>
        <Name numberOfLines={2}>{lineItem.name}</Name>
        <Description>{lineItem.description}</Description>
      </Info>
      <Cell>
        <Quantity>{LineItem.getEstimateQuantity(lineItem)}</Quantity>
      </Cell>
      {hidePrices ? null : (
        <React.Fragment>
          <Cell>
            <Price>{LineItem.getDisplayPrice(lineItem)}</Price>
          </Cell>
          <Cell>
            <LineItemTotal
              isDiscount={lineItem.kind === LineItem.KINDS.DISCOUNT}
              lineItem={lineItem}
            />
          </Cell>
        </React.Fragment>
      )}
    </Content>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
BillLineItemItem.fragment = gql`
  ${LineItem.getDisplayPrice.fragment}
  ${LineItem.getEstimateQuantity.fragment}
  ${LineItem.getEstimateTotal.fragment}

  fragment BillLineItemItem on LineItem {
    name
    description
    kind
    total
    ...LineItem_getDisplayPrice
    ...LineItem_getEstimateQuantity
    ...LineItem_getEstimateTotal
  }
`;

export default BillLineItemItem;
