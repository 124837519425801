// Libraries
import React from 'react';

// App
import {Mutation} from '@supermove/components';
import {CommercialJobTimesheetForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateCommercialJobTimesheetMutation.defaultProps;

// Components

// @ts-expect-error TS(7022): 'UpdateCommercialJobTimesheetMutation' implicitly ... Remove this comment to see the full error message
const UpdateCommercialJobTimesheetMutation = ({
  commercialJobTimesheetForm,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    awaitRefetchQueries
    refetchQueries={['EditCommercialTimesheetCrewJobPage']}
    variables={{
      commercialJobTimesheetForm: CommercialJobTimesheetForm.toMutation(commercialJobTimesheetForm),
    }}
    mutation={UpdateCommercialJobTimesheetMutation.mutation}
    onCompleted={({updateCommercialJobTimesheet: {job, errors}}: any) => {
      if (job) {
        onSuccess(job);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateCommercialJobTimesheetMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCommercialJobTimesheetMutation.mutation = gql`
  mutation UpdateCommercialJobTimesheetMutation(
    $commercialJobTimesheetForm: CommercialJobTimesheetForm!,
  ) {
    updateCommercialJobTimesheet(
      commercialJobTimesheetForm: $commercialJobTimesheetForm,
    ) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default UpdateCommercialJobTimesheetMutation;
