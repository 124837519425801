// Libraries
import _ from 'lodash';
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

import ConfirmCrewForJobUserErrorModal from './ConfirmCrewForJobUserErrorModal';
import ConfirmJobErrorModal from './ConfirmJobErrorModal';

const ConfirmCrewForJobMutation = ({
  jobId,
  userId,
  appInstallId,
  employeeIds,
  user,
  onSuccess,
  children,
}: any) => (
  <ConfirmCrewForJobUserErrorModal
    name={user.firstName}
    // @ts-expect-error TS(7031): Binding element 'handleOpenUser' implicitly has an... Remove this comment to see the full error message
    trigger={({handleOpen: handleOpenUser}) => (
      <ConfirmJobErrorModal
        // @ts-expect-error TS(7031): Binding element 'handleOpenEmployeeIds' implicitly... Remove this comment to see the full error message
        trigger={({handleOpen: handleOpenEmployeeIds}) => (
          <Mutation
            // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
            variables={{
              jobId,
              userId,
              appInstallId,
              employeeIds,
              allowUpdate: true,
            }}
            mutation={ConfirmCrewForJobMutation.mutation}
            onCompleted={({confirmCrewForJob: {job, errors}}: any) => {
              if (job) {
                return onSuccess(job);
              }

              const firstError = _.first(errors);
              switch ((firstError as any).field) {
                case 'user_id':
                  return handleOpenUser();
                case 'employee_ids':
                  return handleOpenEmployeeIds();
                default:
                  return;
              }
            }}
          >
            {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
          </Mutation>
        )}
      />
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmCrewForJobMutation.mutation = gql`
  mutation ConfirmCrewForJobMutation(
    $jobId: Int!,
    $userId: Int!,
    $appInstallId: Int,
    $employeeIds: [Int]!,
    $allowUpdate: Boolean!,
  ) {
    confirmCrewForJob(
      jobId: $jobId,
      userId: $userId,
      appInstallId: $appInstallId,
      employeeIds: $employeeIds,
      allowUpdate: $allowUpdate,
    ) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default ConfirmCrewForJobMutation;
