// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import PayrollTimesheetPageContent from 'modules/Job/Crew/Timesheet/components/PayrollTimesheetPageContent';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const PayrollTimesheetActionPageContent = ({job, refetch}: any) => {
  return (
    <React.Fragment>
      <ProjectBillingHeader
        leftButtonType={'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <PayrollTimesheetPageContent job={job} refetch={refetch} />
    </React.Fragment>
  );
};

const PayrollTimesheetActionPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(PayrollTimesheetActionPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.uuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <PayrollTimesheetActionPageContent job={data.job} refetch={refetch} />;
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayrollTimesheetActionPage.query = gql`
  ${PayrollTimesheetPageContent.fragment}
  ${Job.getFullName.fragment}
  ${Project.getName.fragment}

  query PayrollTimesheetActionPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      ...PayrollTimesheetPageContent
      ...Job_getFullName
      project {
        id
        ...Project_getName
      }
    }
  }
`;

export default PayrollTimesheetActionPage;
