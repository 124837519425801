// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {CustomerJobProgress, CustomerJobPage} from 'modules/Job/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Line = Styled.View`
  margin-vertical: 30px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.Button`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const SectionWrapper = Styled.View`
`;

const SectionTitle = Styled.H4`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SectionBody = Styled.H6`
  margin-top: 10px;
  color: ${colors.gray.primary};
`;

const Section = ({title, body, style}: any) => (
  <SectionWrapper style={style}>
    <SectionTitle>{title}</SectionTitle>
    <SectionBody>{body}</SectionBody>
  </SectionWrapper>
);

const StartCustomerJobPage = () => (
  <CustomerJobPage
    getVariables={({params}: any) => ({uuid: params.uuid})}
    query={StartCustomerJobPage.query}
  >
    {({navigator, params, loading, data}: any) => (
      <Loading loading={loading}>
        {() => (
          <Container>
            <CustomerJobProgress selected={'start'} items={CustomerJobProgress.items.start} />
            <ScrollView style={{flex: 1}}>
              <Content>
                <Title>
                  {`Welcome, ${data.job.customer.firstName}! We're excited to move you.`}
                </Title>
                <Subtitle>Please confirm some details before we get started.</Subtitle>
                <Info>
                  <Section
                    title={'Your contact info:'}
                    body={User.getContactInfo(data.job.customer)}
                  />
                  <Line />
                  <Row
                    style={{
                      marginBottom: 30,
                    }}
                  >
                    <Section
                      title={'You are moving from:'}
                      body={`${data.job.startLocation.address}`}
                      style={{
                        flex: 1,
                      }}
                    />
                    <Section
                      title={'You are moving to:'}
                      body={`${data.job.endLocation.address}`}
                      style={{
                        flex: 1,
                      }}
                    />
                  </Row>
                  {data.job.additionalLocations.length > 0 && (
                    <Row
                      style={{
                        marginBottom: 30,
                      }}
                    >
                      <Section
                        title={'Additional Locations'}
                        body={`${data.job.additionalLocations
                          .map((location: any) => location.address)
                          .join('\n')}`}
                        style={{
                          flex: 1,
                        }}
                      />
                    </Row>
                  )}
                </Info>
              </Content>
            </ScrollView>
            <JobFooter>
              <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                <Text>Back</Text>
              </Button>
              <Button
                color={colors.blue.interactive}
                onPress={() => {
                  // We navigate to the rates page if this is a move with pre-move billing
                  // enabled. Otherwise, we skip ahead to the signing portion for the customer.
                  if (data.job.hasJobStepCrewPreMoveBilling) {
                    return navigator.navigate('ViewPreMoveBillingProjectCustomerJobPage', {
                      uuid: params.uuid,
                    });
                  }

                  Navigation.navigateToNextIncompleteDocumentForPreMove({navigator, job: data.job});
                }}
              >
                <Text>Confirm</Text>
              </Button>
            </JobFooter>
          </Container>
        )}
      </Loading>
    )}
  </CustomerJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
StartCustomerJobPage.query = gql`
  ${Navigation.navigateToNextIncompleteDocumentForPreMove.fragment}
  ${User.getContactInfo.fragment}
  ${CustomerJobPage.fragment}

  query StartCustomerJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      hasJobStepCrewPreMoveBilling: hasJobStep(kind: "CREW_PRE_MOVE_BILLING")
      move {
        id
        bill {
          id
          uuid
        }
      }
      customer {
        id
        firstName
        ...User_getContactInfo
      }
      startLocation {
        id
        address
      }
      endLocation {
        id
        address
      }
      additionalLocations {
        id
        address
      }
      ...Navigation_navigateToNextIncompleteDocumentForPreMove
      ...CustomerJobPage
    }
  }
`;

export default StartCustomerJobPage;
