// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, Loading, Ref, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useRef} from '@supermove/hooks';
import {Document} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Navigation from 'core/Navigation';
import {Field, PageLoadingIndicator} from 'modules/App/components';
import {
  CreateSignatureErrorModal,
  CustomerSignDocumentSignaturesList,
  PreviewTemplateModal,
  SignatureCapture,
  SkipSignatureForDocumentStepModal,
} from 'modules/Job/Customer/Document/Sign/components';
import {useCompleteSignatureForDocumentStepMutation} from 'modules/Job/Customer/Document/Sign/logic';
import {CustomerJobProgress, CustomerJobPage} from 'modules/Job/Customer/components';
import {CreateSignatureForDocumentStepForm} from 'modules/Job/Document/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-bottom: 30px;
`;

const Header = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const DocumentSignatures = Styled.View`
  margin-top: 20px;
`;

const Signature = Styled.View`
  margin-horizontal: 40px;
`;

const Name = Styled.View`
  margin-bottom: 40px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
  width: 300px;
`;

const SignatureImage = Styled.Image`
  height: 350px;
  width: 700px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ErrorText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.red.warning};
`;

const getProgressItems = ({step}: any) => {
  switch (Document.formatStepAsEnum(step)) {
    case 'PRE_MOVE':
      return CustomerJobProgress.items.start;
    case 'DURING_MOVE':
      return CustomerJobProgress.items.during;
    case 'AFTER_TIMESHEET':
      return CustomerJobProgress.items.finish;
    case 'POST_MOVE':
      return CustomerJobProgress.items.finish;
    default:
      return [];
  }
};

const CompleteDocumentButton = ({documentId, step, onSuccess, onError}: any) => {
  const {submitting, handleSubmit} = useCompleteSignatureForDocumentStepMutation({
    documentId,
    step,
    onSuccess,
    onError,
  });

  return (
    <Button loading={submitting} color={colors.blue.interactive} onPress={handleSubmit}>
      <Text>Confirm</Text>
    </Button>
  );
};

const MainDocumentForm = ({
  data,
  scrollView,
  params,
  handleNavigate,
  handleOpen,
  handleNotPrepared,
  navigator,
  refetch,
}: any) => {
  const {isOptional, isStepSigned, isTemplateDocument, filteredDocumentSignatures} = data.document;
  const canSkipCustomerSignature = isOptional || data.job.hasJobFeatureCrewAllowSkipDocuments;
  const notPreparedDocumentSignatures = _.filter(
    filteredDocumentSignatures,
    (documentSignature) => !documentSignature.isPrepared,
  );
  const previewTemplateModal = useModal();

  return (
    <React.Fragment>
      <CreateSignatureForDocumentStepForm
        documentId={data.document.id}
        customerId={data.job.customer.id}
        step={Document.formatStepAsEnum(params.step)}
        date={data.job.day.value}
        kind={'FULL_TRACED'}
        name={data.job.customer.latestSignatureName}
        onSuccess={(document: any) => handleNavigate(document.job)}
        onError={(errors: any) => {
          const firstError = _.first(errors);
          switch ((firstError as any).field) {
            case 'image':
              return handleOpen();
            case 'document.step_not_prepared':
              return handleNotPrepared();
            default:
              return;
          }
        }}
      >
        {(form: any) => (
          <Ref>
            {(signatureRef: any) => (
              <Container>
                <KeyboardView>
                  <ScrollView ref={scrollView} alwaysBounceVertical={false} style={{flex: 1}}>
                    <CustomerJobProgress
                      selected={'agreements'}
                      items={getProgressItems({step: params.step})}
                    />
                    <Content>
                      <Header>
                        {/* @ts-expect-error TS(2769): No overload matches this call. */}
                        <Title>{Document.getDisplayNameWithIndex(data.document)}</Title>
                        <Subtitle>
                          {`Here are the sections summarizing the document. ` +
                            `Please read each section in detail.`}
                        </Subtitle>
                      </Header>
                      <DocumentSignatures>
                        <CustomerSignDocumentSignaturesList
                          isStepSigned={isStepSigned}
                          documentSignatures={data.document.filteredDocumentSignatures}
                          document={data.document}
                          refetch={refetch}
                        />
                      </DocumentSignatures>
                      <Signature>
                        <Name>
                          <Field
                            {...form}
                            component={TextInput}
                            name={'name'}
                            label={'Full Name'}
                            input={{
                              placeholder: `Enter your full name`,
                            }}
                          />
                        </Name>
                        {isStepSigned ? (
                          <SignatureImage
                            source={{
                              uri: _.get(
                                data,
                                'document.filteredDocumentSignatures.0.signature.image',
                              ),
                            }}
                          />
                        ) : (
                          <SignatureCapture
                            innerRef={signatureRef}
                            onSave={(image: any) => {
                              form.handleSubmit({variables: {image}});
                            }}
                          />
                        )}
                      </Signature>
                    </Content>
                  </ScrollView>
                </KeyboardView>
                <Footer>
                  <Group>
                    <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                      <Text>Back</Text>
                    </Button>
                    <ButtonSpace />
                    {!isStepSigned && (
                      <Button
                        color={colors.gray.secondary}
                        onPress={() => signatureRef.current.clear()}
                      >
                        <Text>Clear</Text>
                      </Button>
                    )}
                  </Group>
                  {isStepSigned ? (
                    <CompleteDocumentButton
                      documentId={data.document.id}
                      step={Document.formatStepAsEnum(params.step)}
                      onSuccess={() => handleNavigate(data.job)}
                      onError={(errors: any) => console.log({errors})}
                    />
                  ) : (
                    <Group>
                      {isTemplateDocument && (
                        <Button
                          color={colors.blue.interactive}
                          onPress={() => {
                            previewTemplateModal.handleOpen();
                          }}
                        >
                          <Text>View PDF</Text>
                        </Button>
                      )}
                      <Space width={10} />
                      {notPreparedDocumentSignatures.length > 0 && (
                        <React.Fragment>
                          <ErrorText>
                            {`${pluralize(
                              'action',
                              notPreparedDocumentSignatures.length,
                              true,
                            )} required`}
                          </ErrorText>
                          <Space width={10} />
                        </React.Fragment>
                      )}
                      {canSkipCustomerSignature && (
                        <React.Fragment>
                          <SkipSignatureForDocumentStepModal
                            jobId={data.job.id}
                            documentId={data.document.id}
                            step={Document.formatStepAsEnum(params.step)}
                            onSuccess={() => handleNavigate(data.job)}
                            onError={(errors: any) => console.log({errors})}
                          >
                            {({handleOpen}: any) => (
                              <Button color={colors.gray.secondary} onPress={handleOpen}>
                                <Text>Skip</Text>
                              </Button>
                            )}
                          </SkipSignatureForDocumentStepModal>
                          <ButtonSpace />
                        </React.Fragment>
                      )}
                      <Button
                        loading={form.loading}
                        color={
                          notPreparedDocumentSignatures.length === 0
                            ? colors.blue.interactive
                            : colors.gray.tertiary
                        }
                        onPress={() => {
                          // Native module doesn't let us do this declaratively.
                          // We grab the signature at the end and save it.
                          // This triggers onSave which will submit the form.
                          signatureRef.current.save();
                        }}
                      >
                        <Text>Next</Text>
                      </Button>
                    </Group>
                  )}
                </Footer>
              </Container>
            )}
          </Ref>
        )}
      </CreateSignatureForDocumentStepForm>
      <PreviewTemplateModal
        key={previewTemplateModal.isOpen}
        isOpen={previewTemplateModal.isOpen}
        handleClose={previewTemplateModal.handleClose}
        documentId={data.document.id}
      />
    </React.Fragment>
  );
};

// Explicitly pass the navigation.state.key to force a re-render. Without this
// the signature from the previous screen will still be showing.
const SignDocumentCustomerJobPage = ({navigation}: any) => {
  const scrollView = useRef();

  return (
    <CustomerJobPage
      key={navigation.state.key}
      getVariables={({params}: any) => ({
        uuid: params.uuid,
        documentUuid: params.documentUuid,
        step: Document.formatStepAsEnum(params.step),
      })}
      query={SignDocumentCustomerJobPage.query}
    >
      {({navigator, params, loading, data, refetch}: any) => {
        return (
          <React.Fragment>
            <Loading loading={loading} as={PageLoadingIndicator}>
              {() => {
                // Contains the logic to navigate to the next screen regardless of it
                // the customer signed or skipped.
                const handleNavigate = (job: any) => {
                  switch (params.success) {
                    case 'BACK':
                      return navigator.goBack();
                    case 'ListDocumentJob':
                      return navigator.navigate('ListDocumentJob', {uuid: params.uuid});
                    case 'ShowJob':
                      return navigator.navigate('ShowJob', {uuid: params.uuid});
                    default:
                      switch (Document.formatStepAsEnum(params.step)) {
                        case 'PRE_MOVE':
                          return Navigation.navigateToNextIncompleteDocumentForPreMove({
                            navigator,
                            job,
                          });
                        case 'AFTER_TIMESHEET':
                          return Navigation.navigateToNextIncompleteDocumentForAfterTimesheet({
                            navigator,
                            job,
                          });
                        case 'POST_MOVE':
                          return Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling(
                            {
                              navigator,
                              job,
                            },
                          );
                        default:
                          return;
                      }
                  }
                };

                const handleNotPrepared = () => {
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  scrollView.current.scrollTo({y: 0});
                };

                return (
                  <CreateSignatureErrorModal
                    trigger={({handleOpen}: any) => (
                      <MainDocumentForm
                        data={data}
                        scrollView={scrollView}
                        params={params}
                        handleNavigate={handleNavigate}
                        handleOpen={handleOpen}
                        handleNotPrepared={handleNotPrepared}
                        navigator={navigator}
                        refetch={refetch}
                      />
                    )}
                  />
                );
              }}
            </Loading>
          </React.Fragment>
        );
      }}
    </CustomerJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SignDocumentCustomerJobPage.query = gql`
  ${Document.getDisplayNameWithIndex.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForAfterTimesheet.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}
  ${Navigation.navigateToNextIncompleteDocumentForPreMove.fragment}
  ${CustomerJobPage.fragment}
  ${CustomerSignDocumentSignaturesList.fragment}

  query SignDocumentCustomerJobPage($uuid: String!, $documentUuid: String!, $step: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayName
      isOptional
      isTemplateDocument
      isStepSigned(step: $step)
      filteredDocumentSignatures(step: $step) {
        id
        isPrepared
        signature {
          id
          image
        }
        ...CustomerSignDocumentSignaturesList
      }
      ...Document_getDisplayNameWithIndex
      ...CustomerSignDocumentSignaturesList_Document
    }
    job(uuid: $uuid) {
      id
      hasJobFeatureCrewAllowSkipDocuments: hasJobFeature(kind: "CREW_ALLOW_SKIP_DOCUMENTS")
      organization {
        id
        name
        canSkipCustomerSignature
      }
      customer {
        id
        latestSignatureName
      }
      day {
        id
        value
      }
      ...Navigation_navigateToNextIncompleteDocumentForAfterTimesheet
      ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
      ...Navigation_navigateToNextIncompleteDocumentForPreMove
      ...CustomerJobPage
    }
  }
`;

export default SignDocumentCustomerJobPage;
