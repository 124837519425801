// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {Avatar} from 'modules/App/components';

const Container = Styled.View`
  align-items: center;
`;

const Name = Styled.H7`
  margin-top: 5px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

type OwnProps = {
  user: any;
  color: string;
  size?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UserAvatar.defaultProps;

// @ts-expect-error TS(7022): 'UserAvatar' implicitly has type 'any' because it ... Remove this comment to see the full error message
const UserAvatar = ({color, size, user, onSelect}: Props) => (
  <Container>
    <Avatar text={User.getInitials(user)} size={size} color={color} />
    <Name numberOfLines={1}>{`${user.firstName} ${user.lastName}`}</Name>
  </Container>
);

UserAvatar.defaultProps = {
  size: 60,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAvatar.fragment = gql`
  fragment UserAvatar on User {
    id
    firstName
    lastName
  }
`;

export default UserAvatar;
