// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useState} from '@supermove/hooks';
import {Job, PaymentMethod} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import {
  transactionContactMessage,
  transactionErrorMessage,
} from '@shared/modules/Payment/hooks/payengineErrors';
import useBeginPaymentV3WithPayEngine from '@shared/modules/Payment/hooks/useBeginPaymentV3WithPayEngine';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageV2 from 'modules/App/components/PageV2';
import PaymentErrorModal from 'modules/Project/Billing/Payment/New/components/PaymentErrorModal';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  flex: 1;
`;

const TitleContainer = Styled.View`
  align-items: center;
`;

const InputContainer = Styled.View`
  padding-horizontal: 40px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ProjectBillingPayEngineCreditCardPaymentSectionV1 = ({job, paymentMethod}: any) => {
  const {params, navigator} = useNavigation();
  const tokenizeErrorModal = useModal();
  const paymentErrorModal = useModal();
  const [chargeErrorMessage, setChargeErrorMessage] = useState('');

  const paymentAmount = _.toNumber(params.paymentAmount);
  const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(paymentMethod, {
    amount: paymentAmount,
  });
  const amount = paymentMethod ? paymentAmount + paymentFeeAmount : paymentAmount;

  // @ts-expect-error TS(2345): Argument of type '{ billId: any; customerId: any; ... Remove this comment to see the full error message
  const beginPaymentV3Form = BeginPaymentV3Form.new({
    billId: job.project.currentPrimaryBill.id,
    customerId: job.project.currentPrimaryBill.customerId,
    name: params.paymentName,
    amount,
    method: 'PAYENGINE_CREDIT_CARD',
    tipAmount: params.tipAmount,
    tipName: Job.getTipName(job),
    jobId: job.id,
    paymentMethodId: paymentMethod?.id,
    paymentFeeAmount,
  });

  // TODO(atsu): Figure out how to hook into state changes on the PayEngine SDK for form fields.
  //  That will let us enable the 'Submit' button only when the card is valid. For now, we'll allow
  //  invalid cards to be submitted & just show an error modal
  const [isValidCreditCard, setIsValidCreditCard] = React.useState(true); // eslint-disable-line no-unused-vars
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  const {handleSubmit, submitting, setCreditCardClient} = useBeginPaymentV3WithPayEngine({
    jobId: job.id,
    // @ts-expect-error TS(2322): Type 'New' is not assignable to type 'BeginPayment... Remove this comment to see the full error message
    beginPaymentV3Form,
    onSuccess: ({payment}) => Navigation.navigateFromPaymentSuccess({navigator, params, payment}),
    onError: (errors) => {
      console.error('Error executing payment mutations:', errors);
      setChargeErrorMessage(
        `${transactionErrorMessage(errors)} ${transactionContactMessage(job.organization.name)}`,
      );
      paymentErrorModal.handleOpen();
    },
    onTokenizeError: (error) => {
      console.error('Error tokenizing credit card:', error);
      tokenizeErrorModal.handleOpen();
    },
  });

  const isMainFlow = Navigation.isMainFlowPayment({params});

  return (
    <Container>
      <KeyboardView>
        <ScrollView style={{flex: 1}}>
          <TitleContainer>
            <PageV2.Title>Please enter your card information.</PageV2.Title>
            <PageV2.Subtitle>Your payment will be processed shortly.</PageV2.Subtitle>
            <Space height={20} />
            <PaymentAmountSection title={'Payment Amount:'} amount={Currency.display(amount)} />
          </TitleContainer>
          <InputContainer>
            <PayEngineCreditCardInput
              setCreditCardClient={setCreditCardClient}
              mode={job.organization.mode}
              onLoad={onLoad}
            />
          </InputContainer>
        </ScrollView>
        <PageFooter>
          <PageFooter.Row style={{justifyContent: 'space-between'}}>
            {isMainFlow ? <PageFooter.BackButton /> : <Space />}
            <Button
              disabled={!isFormLoaded}
              loading={submitting}
              color={colors.blue.interactive}
              onPress={_.throttle(handleSubmit, 3000)}
            >
              <Text>Submit</Text>
            </Button>
          </PageFooter.Row>
        </PageFooter>
      </KeyboardView>
      <PaymentErrorModal
        isOpen={tokenizeErrorModal.isOpen}
        handleClose={tokenizeErrorModal.handleClose}
        title={'Invalid Credit Card Details'}
        subtitle={'Please fix the invalid fields.'}
      />
      <PaymentErrorModal
        isOpen={paymentErrorModal.isOpen}
        handleClose={paymentErrorModal.handleClose}
        title={'Unable to Process Credit Card'}
        subtitle={chargeErrorMessage}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingPayEngineCreditCardPaymentSectionV1.fragment = gql`
  ${Job.getTipName.fragment}
  ${PaymentMethod.computePaymentFeeAmount.fragment}

  fragment ProjectBillingCreditCardPaymentSectionV1 on Job {
    id
    organization {
      id
      mode
      name
      account {
        id
      }
    }
    project {
      id
      currentPrimaryBill {
        id
        customerId
      }
    }
    customer {
      id
    }
    ...Job_getTipName
  }

  fragment ProjectBillingCreditCardPaymentSectionV1_PaymentMethod on PaymentMethod {
    id
    ...PaymentMethod_computePaymentFeeAmount
  }
`;

export default ProjectBillingPayEngineCreditCardPaymentSectionV1;
