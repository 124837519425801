// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {CustomerJobDocumentsList} from 'modules/Job/Customer/Document/List/components';
import {CustomerJobProgress, CustomerJobPage} from 'modules/Job/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Documents = Styled.View`
  margin-top: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

// Used for CustomerJobDocumentsList.
const STEP = 'POST_MOVE';

const PostMoveListDocumentCustomerJobPage = () => (
  <CustomerJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({uuid: params.uuid, step: STEP})}
    query={PostMoveListDocumentCustomerJobPage.query}
  >
    {({navigator, params, loading, data}: any) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container>
            <CustomerJobProgress selected={'agreements'} items={CustomerJobProgress.items.finish} />
            <ScrollView style={{flex: 1}}>
              <Content>
                <Info>
                  <Title>{`Congrats, ${data.job.customer.fullName}. Your move is complete.`}</Title>
                  <Subtitle>
                    {`The next steps will guide you through these documents to ` +
                      `start the end of move process.`}
                  </Subtitle>
                </Info>
                <Documents>
                  <CustomerJobDocumentsList job={data.job} />
                </Documents>
              </Content>
            </ScrollView>
            <Footer>
              <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                <Text>Back</Text>
              </Button>
              <Button
                loading={loading}
                color={colors.blue.interactive}
                onPress={() => {
                  Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling({
                    navigator,
                    job: data.job,
                  });
                }}
              >
                <Text>Next</Text>
              </Button>
            </Footer>
          </Container>
        )}
      </Loading>
    )}
  </CustomerJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PostMoveListDocumentCustomerJobPage.query = gql`
  ${Navigation.navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}
  ${CustomerJobDocumentsList.fragment}
  ${CustomerJobPage.fragment}

  query PostMoveListDocumentCustomerJobPage($uuid: String!, $step: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
        fullName
      }
      ...CustomerJobDocumentsList
      ...CustomerJobPage
      ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
    }
  }
`;

export default PostMoveListDocumentCustomerJobPage;
