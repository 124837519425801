/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobCurrentEvent from 'modules/Job/Show/components/JobCurrentEvent';
import JobNotifications from 'modules/Job/Show/components/JobNotifications';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 20px;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 50px;
`;

const Text = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const MainAction = Styled.View`
  padding-top: 10px;
`;

const JobNextActionBlock = ({job, viewer, refetch}: any) => {
  const {navigator} = useNavigation();

  return (
    <JobCurrentEvent viewer={viewer} job={job} navigator={navigator} refetch={refetch}>
      {({
        loading,
        text,
        color,
        handlePress,
        disabled,
        hasCurrentJob,
        isCurrentJob,
        event,
        timelineEvent,
        title,
        subtitle,
      }: any) => (
        <Container>
          <JobNotifications
            hasCurrentJob={hasCurrentJob}
            isCurrentJob={isCurrentJob}
            viewer={viewer}
            job={job}
            refetch={refetch}
          />
          {!job.isComplete && (
            <Content>
              <Title>Up Next</Title>
              <MainAction>
                <Button disabled={disabled} loading={loading} color={color} onPress={handlePress}>
                  <Text>{text}</Text>
                </Button>
              </MainAction>
            </Content>
          )}
        </Container>
      )}
    </JobCurrentEvent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNextActionBlock.fragment = gql`
  ${JobCurrentEvent.fragment}
  ${JobNotifications.fragment}

  fragment JobNextActionBlock_Viewer on User {
    id
    ...JobCurrentEvent_Viewer
    ...JobNotifications_Viewer
  }

  fragment JobNextActionBlock_Job on Job {
    id
    isComplete
    ...JobCurrentEvent_Job
    ...JobNotifications_Job
  }
`;

export default JobNextActionBlock;
