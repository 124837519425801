// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import {
  CommercialJobTimesheetHeader,
  ConfirmCommercialJobTimesheetErrorModal,
  JobUserTimesheetFormsList,
} from 'modules/Job/Crew/Timesheet/Commercial/Edit/components';
import CommercialJobTimesheetSkipAllModal from 'modules/Job/Crew/Timesheet/Commercial/Edit/components/CommercialJobTimesheetSkipAllModal';
import {
  ConfirmCommercialJobTimesheetForm,
  UpdateCommercialJobTimesheetMutation,
} from 'modules/Job/Crew/Timesheet/Commercial/components';
import CrewJobPage from 'modules/Job/Crew/components/CrewJobPage';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-top: 20px;
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const JobUsers = Styled.View`
  margin-top: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const isBreak = ({timeRanges, index}: any) => {
  const timeRangeKind = _.get(timeRanges, `${index}.kind`);
  const previousTimeRangeKind = _.get(timeRanges, `${index - 1}.kind`);

  if (timeRangeKind === 'BREAK') {
    return true;
  }

  // "Start Lunch" = "BREAK" (start of break time)
  // "End Lunch" == "WORK" (start of work time)
  if (previousTimeRangeKind === 'BREAK') {
    return true;
  }

  return false;
};

const handleSkipBreaks = ({form, onUpdate, handleClose}: any) => {
  const kind = 'SKIP';
  const {commercialJobTimesheetForm} = form.values;
  const newCommercialJobTimesheetForm = _.cloneDeep(commercialJobTimesheetForm);
  const {timeRanges, jobUserTimesheetForms} = commercialJobTimesheetForm;

  jobUserTimesheetForms.forEach((jobUserTimesheetForm: any, jobUserIndex: any) => {
    const timeRangeFormsPath = `jobUserTimesheetForms.${jobUserIndex}.timeRangeForms`;

    jobUserTimesheetForm.timeRangeForms.forEach((timeRangeForm: any, timeRangeIndex: any) => {
      const isLast = timeRangeIndex === jobUserTimesheetForm.timeRangeForms.length - 1;

      // If this isn't a break, we skip the field and keep it unchanged.
      if (!isBreak({timeRanges, index: timeRangeIndex})) {
        return;
      }

      // Everything except the last timeRange sets the start time.
      if (!isLast && !timeRangeForm.start) {
        _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${timeRangeIndex}.kind`, kind);
      }

      // The last timeRange sets the end time.
      if (isLast && !timeRangeForm.end) {
        _.set(newCommercialJobTimesheetForm, `${timeRangeFormsPath}.${timeRangeIndex}.kind`, kind);
      }
    });
  });

  form.setFieldValue('commercialJobTimesheetForm', newCommercialJobTimesheetForm);
  form.setFieldError('commercialJobTimesheetForm', []);
  setTimeout(() => onUpdate().then(() => handleClose()), 0);
};

const EditCommercialTimesheetCrewJobPage = () => {
  const {
    isOpen: isSkipAllOpen,
    handleOpen: handleOpenSkipAll,
    handleClose: handleCloseSkipAll,
  } = useModal();

  return (
    <CrewJobPage
      fetchPolicy={'network-only'}
      getVariables={({params}: any) => ({uuid: params.uuid})}
      query={EditCommercialTimesheetCrewJobPage.query}
      onFocus={({refetch}: any) => refetch()}
    >
      {({navigator, params, loading: queryLoading, data, refetch}: any) => (
        <Loading loading={queryLoading} as={PageLoadingIndicator}>
          {() => (
            <ConfirmCommercialJobTimesheetErrorModal
              trigger={({handleOpen}: any) => (
                <ConfirmCommercialJobTimesheetForm
                  job={data.job}
                  onSuccess={(job: any) => {
                    switch (params.success) {
                      case 'BACK':
                        return navigator.goBack();
                      default:
                        if (_.get(job, 'isPostMoveFinished')) {
                          return navigator.navigate('ShowJob', {uuid: params.uuid});
                        }

                        return Navigation.navigateFromTimesheetSuccess({navigator, job: data.job});
                    }
                  }}
                  onError={(errors: any) => handleOpen()}
                >
                  {(form: any) => (
                    <React.Fragment>
                      <UpdateCommercialJobTimesheetMutation
                        commercialJobTimesheetForm={form.values.commercialJobTimesheetForm}
                        onError={(errors: any) => console.log({errors})}
                      >
                        {({loading: mutationLoading, handleSubmit}: any) => (
                          <Container>
                            <ScrollView style={{flex: 1}}>
                              <Content>
                                <Title>Commercial Move Timesheet</Title>
                                <Subtitle>
                                  {`Make sure all times are recorded. Include drive time ` +
                                    `as well as any breaks that the crew took.`}
                                </Subtitle>
                              </Content>
                              <JobUsers>
                                <JobUserTimesheetFormsList
                                  job={data.job}
                                  jobUserTimesheetForms={
                                    form.values.commercialJobTimesheetForm.jobUserTimesheetForms
                                  }
                                  form={form}
                                  header={() => (
                                    <CommercialJobTimesheetHeader
                                      loading={queryLoading || mutationLoading}
                                      commercialJobTimesheetForm={
                                        form.values.commercialJobTimesheetForm
                                      }
                                      form={form}
                                      onUpdate={handleSubmit}
                                    />
                                  )}
                                />
                              </JobUsers>
                            </ScrollView>
                            <Footer>
                              <Group>
                                <Button
                                  color={colors.gray.secondary}
                                  onPress={() => navigator.goBack()}
                                >
                                  <Text>Back</Text>
                                </Button>
                                <ButtonSpace />
                                <Button
                                  onPress={() => {
                                    navigator.navigate('ConfirmJobUsersForJobPage', {
                                      uuid: data.job.uuid,
                                      success: 'ConfirmCrewSlotsForJobPage',
                                    });
                                  }}
                                >
                                  <Text>Edit Crew</Text>
                                </Button>
                              </Group>
                              <Group>
                                <Button color={colors.gray.secondary} onPress={handleOpenSkipAll}>
                                  <Text>Skip Breaks</Text>
                                </Button>
                                <ButtonSpace />
                                <Button
                                  loading={form.loading}
                                  color={colors.blue.interactive}
                                  onPress={form.handleSubmit}
                                >
                                  <Text>Submit</Text>
                                </Button>
                              </Group>
                            </Footer>
                            <CommercialJobTimesheetSkipAllModal
                              isOpen={isSkipAllOpen}
                              isLoading={mutationLoading}
                              onSkipAll={() =>
                                handleSkipBreaks({
                                  form,
                                  onUpdate: handleSubmit,
                                  handleClose: handleCloseSkipAll,
                                })
                              }
                              onClose={handleCloseSkipAll}
                            />
                          </Container>
                        )}
                      </UpdateCommercialJobTimesheetMutation>
                    </React.Fragment>
                  )}
                </ConfirmCommercialJobTimesheetForm>
              )}
            />
          )}
        </Loading>
      )}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCommercialTimesheetCrewJobPage.query = gql`
  ${Navigation.navigateFromTimesheetSuccess.fragment}
  ${ConfirmCommercialJobTimesheetForm.fragment}
  ${CrewJobPage.fragment}
  ${JobUserTimesheetFormsList.fragment}

  query EditCommercialTimesheetCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      isPostMoveFinished
      ...Navigation_navigateFromTimesheetSuccess
      ...ConfirmCommercialJobTimesheetForm
      ...CrewJobPage
      ...JobUserTimesheetFormsList
    }
  }
`;

export default EditCommercialTimesheetCrewJobPage;
