// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Json} from '@supermove/utils';

// App
import DocumentAdditionalInfoRadioButtonWithTextInput from 'modules/Job/Crew/Document/Show/components/DocumentAdditionalInfoRadioButtonWithTextInput';

const Item = Styled.View`
  margin-vertical: 5px;
`;

const getValue = ({form, documentAdditionalInfo, defaultValue}: any) => {
  return _.get(form, `values.additionalInfo.${documentAdditionalInfo.name}`, defaultValue);
};

const DocumentAdditionalInfoRadioButtonsWithTextInputsFields = ({
  documentAdditionalInfo,
  form,
}: any) => {
  const data = getValue({form, documentAdditionalInfo, defaultValue: []});
  const metadata = Json.toObject(documentAdditionalInfo.metadata);

  if (!data) {
    return null;
  }

  const items = _.get(metadata, 'items', []);

  return (
    <React.Fragment>
      {items.map((item: any, index: any) => {
        const value = _.get(data, index, []);

        return (
          <Item key={index}>
            <DocumentAdditionalInfoRadioButtonWithTextInput
              isFirst={index === 0}
              isLast={index === items.length - 1}
              name={`additionalInfo.${documentAdditionalInfo.name}.${index}`}
              index={index}
              item={item}
              value={value}
              errors={form.errors}
              setFieldValue={form.setFieldValue}
              setFieldTouched={form.setFieldTouched}
            />
          </Item>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentAdditionalInfoRadioButtonsWithTextInputsFields.fragment = gql`
  fragment DocumentAdditionalInfoRadioButtonsWithTextInputsFields on DocumentAdditionalInfo {
    name
    metadata
  }
`;

export default DocumentAdditionalInfoRadioButtonsWithTextInputsFields;
