/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useCodePushChecker, useEffect, useNavigation, useQuery} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';

// After we check for CodePush updates, we do the normal startup and check for the logged in
// user or organization.
const StartPageContent = () => {
  const {loading, data} = useQuery(StartPage.query);
  const {navigator} = useNavigation();

  useEffect(() => {
    if (loading) {
      return;
    }

    if (data.viewer) {
      navigator.navigate('Authenticated');
    } else if (data.organization) {
      navigator.navigate('UsersAuthentication');
    } else {
      navigator.navigate('OrganizationAuthentication');
    }
  }, [loading, data, navigator]);

  return null;
};

// First we check for CodePush updates and if there's an update, redirect to the update screen.
const StartPage = () => {
  const {navigator} = useNavigation();
  const {checking, update} = useCodePushChecker({
    shouldCheck: !Environment.isLocal(),
  });

  useEffect(() => {
    if (checking) {
      return;
    }

    if (update) {
      navigator.navigate('SettingsCodePush');
    }
  }, [checking, update, navigator]);

  if (checking) {
    return null;
  }

  return <StartPageContent />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StartPage.query = gql`
  query StartPage {
    ${gql.query}
    viewer {
      id
    }
    organization {
      id
    }
  }
`;

export default StartPage;
