// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';

import {Header} from 'modules/App/components';
import {JobsList} from 'modules/Job/List/components';
import {ViewerJobBanner} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const ListJobsPage = () => (
  <Container>
    <ViewerJobBanner />
    <Header />
    <JobsList />
  </Container>
);

export default ListJobsPage;
