const PENDING = 'PENDING';
const SKIPPED = 'SKIPPED';
const COMPLETED = 'COMPLETED';

const DocumentCompletionStatus = {
  PENDING,
  SKIPPED,
  COMPLETED,
};

export default DocumentCompletionStatus;
