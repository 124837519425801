// Libraries
import {useCurrentGeolocation} from '@supermove/hooks';

// Logic
import {useCreatePositionForDeviceMutation} from 'modules/Device/logic';

const useManualLogDeviceCurrentPosition = ({deviceId}: any) => {
  // @ts-expect-error TS(2345): Argument of type '{ timeout: number; }' is not ass... Remove this comment to see the full error message
  const {fetchLocation, location} = useCurrentGeolocation({
    timeout: 10, // Time out every 10 seconds if no response.
  });

  useCreatePositionForDeviceMutation({deviceId, location});

  return {
    fetchLocation,
  };
};

export default useManualLogDeviceCurrentPosition;
