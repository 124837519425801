// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import TextBadge from 'modules/App/components/TextBadge';
import EditJobUserModal from 'modules/Job/User/Confirm/components/EditJobUserModal';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 15px;
  padding-horizontal: 20px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Name = Styled.H5`
  flex: 2;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Details = Styled.View`
  flex: 4;
  flex-direction: row;
  align-items: center;
`;

const BranchCode = Styled.H6`
  flex: 1;
  color: ${colors.gray.secondary};
`;

const Touchable = Styled.Touchable`
  width: 40px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Positions = ({jobUserForm, organization}: any) => {
  const organizationMoverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;

  const moverPositions = organizationMoverPositions.filter(
    (moverPosition: any) => moverPosition.id === _.toString(jobUserForm.primaryMoverPositionId),
  );

  return (
    <Row>
      {moverPositions.map((moverPosition: any) => (
        <React.Fragment key={moverPosition.id}>
          <TextBadge text={moverPosition.name} color={colors.gray.secondary} />
          <Space width={4} />
        </React.Fragment>
      ))}
    </Row>
  );
};

type OwnJobUserFormItemProps = {
  field: string;
  form: any;
  jobUserForm: any;
};

// @ts-expect-error TS(2456): Type alias 'JobUserFormItemProps' circularly refer... Remove this comment to see the full error message
type JobUserFormItemProps = OwnJobUserFormItemProps & typeof JobUserFormItem.defaultProps;
// @ts-expect-error TS(7022): 'JobUserFormItem' implicitly has type 'any' becaus... Remove this comment to see the full error message
const JobUserFormItem = ({
  field,
  form,
  jobUserForm,
  organization,
  onDelete,
}: JobUserFormItemProps) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <Container>
      <Name numberOfLines={1}>{jobUserForm.firstName}</Name>
      <Details>
        <Positions jobUserForm={jobUserForm} organization={organization} />
        {organization.features.isEnabledJobUserShowBranchCode && (
          <BranchCode numberOfLines={1}>{jobUserForm.branchCode}</BranchCode>
        )}
      </Details>
      <Touchable onPress={handleOpen}>
        <Icon source={Icon.Edit} color={colors.blue.interactive} size={24} />
      </Touchable>
      <EditJobUserModal
        field={field}
        form={form}
        isOpen={isOpen}
        organization={organization}
        onClose={handleClose}
        onDelete={onDelete}
      />
    </Container>
  );
};

JobUserFormItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserFormItem.fragment = gql`
  ${EditJobUserModal.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment JobUserFormItem on Organization {
    id
    companySettings {
      moverPositions {
        id
        color
      }
    }
    moverPositions {
      id
      name
      color
    }
    features {
      isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
    }
    ...EditJobUserModal
    ...Organization_getCompanySettingsMoverPositionsByRole
  }
`;

export default JobUserFormItem;
