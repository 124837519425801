const ITEM_TAG = 'ITEM_TAG' as const;
const EXCEPTION_TYPE = 'EXCEPTION_TYPE' as const;
const EXCEPTION_LOCATION = 'EXCEPTION_LOCATION' as const;

const ITEM_TAG_KINDS = [ITEM_TAG, EXCEPTION_TYPE, EXCEPTION_LOCATION] as const;

export type ItemTagKindType = (typeof ITEM_TAG_KINDS)[number];

const ItemTagKind = {
  ITEM_TAG,
  EXCEPTION_TYPE,
  EXCEPTION_LOCATION,
};

export default ItemTagKind;
