// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import ErrorState from '@shared/design/components/EmptyState/ErrorState';
import ErrorCatcher from '@shared/modules/Error/components/ErrorCatcher';
import {PageLoadingIndicator} from 'modules/App/components';
import ProjectBillsList from 'modules/Project/Billing/components/ProjectBillsList';

const Container = Styled.View`
`;

const ProjectBillsListQueryContent = ({
  projectUuid,
  isCollapsible,
  showAllBillRules,
  showGrandTotal,
  showBalance,
}: any) => {
  const {data, loading} = useQuery(ProjectBillsListQuery.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <ProjectBillsList
            project={data.project}
            isCollapsible={isCollapsible}
            showAllBillRules={showAllBillRules}
            showGrandTotal={showGrandTotal}
            showBalance={showBalance}
          />
        );
      }}
    </Loading>
  );
};

const ProjectBillsListQuery = ({
  projectUuid,
  isCollapsible,
  showAllBillRules,
  showGrandTotal,
  showBalance,
}: any) => {
  return (
    <ErrorCatcher
      ErrorComponent={() => (
        <Container style={{height: 200}}>
          <ErrorState.Centered title={'Could not load bills'} />
        </Container>
      )}
    >
      <ProjectBillsListQueryContent
        projectUuid={projectUuid}
        isCollapsible={isCollapsible}
        showAllBillRules={showAllBillRules}
        showGrandTotal={showGrandTotal}
        showBalance={showBalance}
      />
    </ErrorCatcher>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillsListQuery.query = gql`
  ${ProjectBillsList.fragment}

  query ProjectBillsListQuery($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...ProjectBillsList
    }
  }
`;

export default ProjectBillsListQuery;
