// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization, TimesheetBillableEntryModel, TimesheetBlock} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Duration} from '@supermove/utils';

// App
import {TimesheetBillableEntryMockType} from '@shared/modules/Timesheet/components/TimesheetMockData';
import TimesheetPayrollEntriesLegend from '@shared/modules/Timesheet/components/TimesheetPayrollEntriesLegend';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TimeTextRow = Styled.View`
  flex-direction: row;
  height: 20px;
`;

const TimelineBlockWrapper = Styled.View`
  flex-direction: row;
`;

const TimelineBlockTypeContainer = Styled.View<{isFirst: boolean; isLast: boolean; color: string}>`
  padding: 8px;
  border-top-left-radius: ${({isFirst}) => (isFirst ? 4 : 0)}px;
  border-bottom-left-radius: ${({isFirst}) => (isFirst ? 4 : 0)}px;
  border-top-right-radius: ${({isLast}) => (isLast ? 4 : 0)}px;
  border-bottom-right-radius: ${({isLast}) => (isLast ? 4 : 0)}px;
  background-color: ${({color}) => color};
`;

const TimelineBlockText = Styled.Text<{color: string}>`
  ${Typography.Mobile.Micro}
  color: ${({color}) => color};
`;

const TimelineBlockTimeText = Styled.Text`
  ${Typography.Mobile.Micro}
`;

const TimelineBlock = ({
  timesheetBlock,
  timezone,
  isFirst,
  isLast,
  blockMinutes,
  totalMinutes,
  startTime,
  isComplete,
  isCondensed,
  showTimes,
}: any) => {
  const {kind} = timesheetBlock;

  return (
    <TimelineBlockWrapper style={{flex: totalMinutes ? blockMinutes / totalMinutes : 1}}>
      <Column style={{flex: 1}}>
        {showTimes && (
          <React.Fragment>
            <TimeTextRow>
              {isFirst && (
                <TimelineBlockTimeText numberOfLines={1}>
                  {startTime || TimesheetBlock.getDisplayRangeFrom(timesheetBlock, {timezone})}
                </TimelineBlockTimeText>
              )}
              {isLast && (
                <React.Fragment>
                  <Space style={{flex: 1}} />
                  <TimelineBlockTimeText numberOfLines={1}>
                    {TimesheetBlock.getDisplayRangeTo(timesheetBlock, {timezone})}
                  </TimelineBlockTimeText>
                </React.Fragment>
              )}
            </TimeTextRow>
            <Space height={4} />
          </React.Fragment>
        )}
        <TimelineBlockTypeContainer
          isFirst={isFirst}
          isLast={isLast}
          color={kind ? TimesheetBlockKind.getColor(kind) : colors.gray.disabled}
        >
          <TimelineBlockText numberOfLines={1} color={kind ? colors.white : colors.gray.tertiary}>
            {TimesheetBlockKind.getDisplay(kind) || 'In progress...'}
          </TimelineBlockText>
        </TimelineBlockTypeContainer>
        {!showTimes && (
          <React.Fragment>
            <Space height={4} />
            <Row>
              <Space width={8} />
              {(!isCondensed || isFirst) && (
                <TimelineBlockTimeText numberOfLines={1}>
                  {startTime || TimesheetBlock.getDisplayRangeFrom(timesheetBlock, {timezone})}
                </TimelineBlockTimeText>
              )}
              {isLast && isComplete && (
                <React.Fragment>
                  <Space style={{flex: 1}} />
                  <TimelineBlockTimeText numberOfLines={1}>
                    {TimesheetBlock.getDisplayRangeTo(timesheetBlock, {timezone})}
                  </TimelineBlockTimeText>
                </React.Fragment>
              )}
            </Row>
          </React.Fragment>
        )}
      </Column>
    </TimelineBlockWrapper>
  );
};

const TotalTime = ({
  timesheetBillableEntry,
  kind,
}: {
  timesheetBillableEntry: TimesheetBillableEntryModel | TimesheetBillableEntryMockType;
  kind: string;
}) => {
  const responsive = useResponsive();
  const totalMinutes = timesheetBillableEntry.timesheetBlocks.reduce(
    (total: any, timesheetBlock: any) => {
      const blockMinutes = TimesheetBlock.getMinutes(timesheetBlock);
      if (blockMinutes && timesheetBlock.kind === kind) {
        return total + blockMinutes;
      }
      return total;
    },
    0,
  );

  const displayTime = Duration.toDisplayTime(totalMinutes, {
    hoursLabel: 'H',
    minutesLabel: 'M',
    alwaysShowHours: true,
    alwaysShowMinutes: true,
  });

  return (
    <TimesheetPayrollEntriesLegend.LegendKey
      label={`${TimesheetBlockKind.getDisplay(kind)} ${displayTime}`}
      color={TimesheetBlockKind.getColor(kind)}
      responsive={responsive}
    />
  );
};

const TimelineTotals = ({
  timesheetBillableEntry,
}: {
  timesheetBillableEntry: TimesheetBillableEntryModel | TimesheetBillableEntryMockType;
}) => {
  const {organization} = timesheetBillableEntry.job;

  return (
    <Row>
      {Organization.getTimesheetBlockKinds(organization).map((kind, index) => {
        return (
          <React.Fragment key={kind}>
            {index > 0 && <Space width={12} />}
            <TotalTime timesheetBillableEntry={timesheetBillableEntry} kind={kind} />
          </React.Fragment>
        );
      })}
    </Row>
  );
};

const TimelineTimes = ({
  timesheetBillableEntry,
}: {
  timesheetBillableEntry: TimesheetBillableEntryModel | TimesheetBillableEntryMockType;
}) => {
  const {timesheetBlocks} = timesheetBillableEntry;
  const {organization} = timesheetBillableEntry.job;

  return (
    <TimelineBlockTimeText>
      {timesheetBlocks
        .map(
          (timesheetBlock) =>
            `${TimesheetBlockKind.getDisplay(timesheetBlock.kind)} ` +
            `${TimesheetBlock.getDisplayRangeFrom(timesheetBlock, {timezone: organization.timezone})}`,
        )
        .join(' - ')}
    </TimelineBlockTimeText>
  );
};

const TimesheetBillableEntryTimeline = ({
  timesheetBillableEntry,
  isComplete,
  showTotals = false,
  showTimes = false,
  isCondensed = false,
}: {
  timesheetBillableEntry: TimesheetBillableEntryModel | TimesheetBillableEntryMockType;
  isComplete: boolean;
  showTotals?: boolean;
  showTimes?: boolean;
  isCondensed?: boolean;
}) => {
  const {timesheetBlocks} = timesheetBillableEntry;
  const {organization} = timesheetBillableEntry.job;
  const totalMinutes = timesheetBlocks.reduce((total: any, timesheetBlock: any) => {
    return total + TimesheetBlock.getMinutes(timesheetBlock);
  }, 0);
  const totalBlocks = isComplete ? timesheetBlocks.length : timesheetBlocks.length + 1;

  // When we generate documents on a headless browser, the timezone is in utc. In this
  // case we need to manually pass in a timezone so that the document shows the correct
  // times. If we pass in a timezone when local has a timezone, then the timezone gets
  // double computed.
  const isLocalUtc = new Date().getTimezoneOffset() === 0;

  return (
    <Column>
      <Row>
        {timesheetBlocks.map((timesheetBlock: any, index: any) => {
          const isFirst = index === 0;
          const isLast = index === totalBlocks - 1;
          const blockMinutes = TimesheetBlock.getMinutes(timesheetBlock, 60);
          return (
            <React.Fragment key={timesheetBlock.id}>
              {!isFirst && <Space width={1} />}
              <TimelineBlock
                timesheetBlock={timesheetBlock}
                timezone={isLocalUtc ? organization.timezone : null}
                isFirst={isFirst}
                isLast={isLast}
                blockMinutes={blockMinutes}
                totalMinutes={totalMinutes}
                isComplete={isComplete}
                isCondensed={isCondensed}
                showTimes={showTimes}
              />
              {index === timesheetBlocks.length - 1 && !isComplete && (
                <React.Fragment>
                  <Space width={1} />
                  <TimelineBlock
                    timesheetBlock={{kind: null}}
                    isLast
                    blockMinutes={60}
                    totalMinutes={totalMinutes}
                    startTime={TimesheetBlock.getDisplayRangeTo(timesheetBlock, {
                      timezone: isLocalUtc ? organization.timezone : null,
                    })}
                    showTimes={showTimes}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
      </Row>
      {showTotals && (
        <React.Fragment>
          <Space height={12} />
          <TimelineTotals timesheetBillableEntry={timesheetBillableEntry} />
        </React.Fragment>
      )}
      {showTimes && (
        <React.Fragment>
          <Space height={12} />
          <TimelineTimes timesheetBillableEntry={timesheetBillableEntry} />
        </React.Fragment>
      )}
    </Column>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBillableEntryTimeline.fragment = gql`
  ${TimesheetBlock.getMinutes.fragment}
  ${TimesheetBlock.getDisplayRangeFrom.fragment}
  ${TimesheetBlock.getDisplayRangeTo.fragment}
  ${Organization.getTimesheetBlockKinds.fragment}

  fragment TimesheetBillableEntryTimeline on TimesheetBillableEntry {
    id
    job {
      id
      organization {
        id
        timezone
        ...Organization_getTimesheetBlockKinds
      }
    }
    timesheetBlocks {
      id
      kind
      rangeTo
      ...TimesheetBlock_getMinutes
      ...TimesheetBlock_getDisplayRangeFrom
      ...TimesheetBlock_getDisplayRangeTo
    }
  }
`;

export default TimesheetBillableEntryTimeline;
