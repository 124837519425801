// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import EditBillItemModal from 'modules/Project/Billing/Edit/components/EditBillItemModal';

const Text = Styled.Text`
  ${Typography.Body2}
  color: ${(props) => (props as any).color};
  min-height: 24px;
`;

const RowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RowItemContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-top-width: ${(props) => ((props as any).isFirstRow ? '1px' : '0px')};
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: ${colors.gray.border};
`;

const NameContainer = Styled.View`
  flex: 1;
  padding-horizontal: 8px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const AddContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
`;

const LargeButton = Styled.ButtonV2`
  height: 36px;
  width: 170px;
  border-radius: 24px;
  background-color: ${(props) => (props as any).color};
  align-items: center;
  justify-content: center;
`;

const Button = Styled.ButtonV2`
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background-color: ${colors.blue.accent};
  align-items: center;
  justify-content: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Heading6}
  color: ${(props) => (props as any).color};
`;

const handleAddBillItemForm = ({form, field, billItemForm}: any) => {
  const billItemForms = _.get(form.values, field);
  form.setFieldValue(field, [...billItemForms, {...billItemForm, index: billItemForms.length}]);
};

const BillItemName = ({billItemForm, isEditable}: any) => {
  const displayAmount =
    billItemForm.kind === BillItemTypeKind.PERCENTAGE
      ? billItemForm.percentage
      : billItemForm.amount;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <NameContainer isEditable={isEditable}>
      <Space height={6} />
      <Text color={colors.gray.primary} numberOfLines={2}>
        {billItemForm.name}
      </Text>
      <Space height={2} />
      <Text color={colors.gray.tertiary}>
        {billItemForm.isEnabledTbdBillItems && displayAmount === '' ? 'TBD' : displayAmount}
      </Text>
      <Space height={2} />
    </NameContainer>
  );
};

const AddBillItem = ({form, field, billItemForm, handleRemoveBillItemForm, isEditable}: any) => {
  const {isDisabledAdd} = billItemForm;
  return (
    <AddContainer>
      <LargeButton
        disabled={!isEditable && isDisabledAdd}
        onPress={() =>
          isEditable
            ? handleRemoveBillItemForm({form, field, billItemForm})
            : handleAddBillItemForm({form, field, billItemForm})
        }
        color={
          isEditable
            ? colors.gray.border
            : isDisabledAdd
              ? colors.gray.border
              : colors.blue.interactive
        }
      >
        <ButtonText color={isEditable ? colors.gray.tertiary : colors.white}>
          {isEditable ? 'Remove' : 'Add'}
        </ButtonText>
      </LargeButton>
    </AddContainer>
  );
};

const BillItemRowPostSubtotal = ({
  form,
  billItemForm,
  index,
  handleEditBillItemForm,
  handleRemoveBillItemForm,
  isEditable,
  isEnabledTbdBillItems,
}: any) => {
  const editBillItemModal = useModal({name: 'Edit Bill Item Modal'});
  const field = 'billForm.billItemFormsPostSubtotal';
  return (
    <RowContainer>
      <Space width={16} />
      {isEditable && (
        <React.Fragment>
          <Button onPress={editBillItemModal.handleOpen}>
            <Icon source={Icon.Edit} size={18} color={colors.blue.interactive} />
          </Button>
          <Space width={16} />
        </React.Fragment>
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <RowItemContainer isFirstRow={index === 0}>
        <BillItemName billItemForm={billItemForm} />
        <AddBillItem
          form={form}
          field={field}
          billItemForm={billItemForm}
          handleRemoveBillItemForm={handleRemoveBillItemForm}
          isEditable={isEditable}
        />
      </RowItemContainer>
      <Space width={16} />
      {isEditable && (
        <EditBillItemModal
          key={editBillItemModal.isOpen}
          isOpen={editBillItemModal.isOpen}
          title={'Edit Item'}
          handleClose={editBillItemModal.handleClose}
          billForm={form}
          billFormField={field}
          billItemForm={billItemForm}
          handleEditBillItemForm={handleEditBillItemForm}
          handleRemoveBillItemForm={handleRemoveBillItemForm}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
      )}
    </RowContainer>
  );
};

export default BillItemRowPostSubtotal;
