// App
import UploadFileForm, {UploadFileFormType} from '@shared/modules/File/forms/UploadFileForm';

export interface UploadBulkFileFormTypeWrapper {
  uploadFileForms: UploadFileFormType[];
}

const toForm = ({uploadFileForms}: UploadBulkFileFormTypeWrapper) => {
  return {uploadFileForms};
};

const toUpdateAttachmentsForm = (bulkAttachmentsForm: UploadBulkFileFormTypeWrapper) => {
  return bulkAttachmentsForm.uploadFileForms.map(UploadFileForm.toForm);
};

const BulkAttachmentsForm = {
  toForm,
  toUpdateAttachmentsForm,
};

export default BulkAttachmentsForm;
