// Libraries
import React from 'react';

// App
import {SignatureInput, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  align-items: center;
  justify-content: flex-end;
  height: 350px;
  width: 700px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const Guide = Styled.View`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
`;

const Line = Styled.View`
  align-self: stretch;
  margin-horizontal: 50px;
  height: 1px;
  background-color: ${colors.gray.tertiary};
`;

const Label = Styled.H5`
  margin-top: 5px;
  color: ${colors.blue.accent};
  text-align: center;
`;

const SignatureCapture = ({innerRef, onSave}: any) => (
  <Container>
    {/* @ts-expect-error TS(2741): Property 'isEnabledAutoSave' is missing in type '{... Remove this comment to see the full error message */}
    <SignatureInput ref={innerRef} color={colors.black} onSave={onSave} />
    <Guide>
      <Line />
      <Label>Draw Signature</Label>
    </Guide>
  </Container>
);

export default SignatureCapture;
