// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage} from 'modules/App/components';

// TODO(jay): This should be moved to ModalPage.js and Page.js when they are moved to TS, and this should just use that interface
interface ChildrenProps<QueryParams, QueryData> {
  isFocused: boolean;
  params: any;
  navigator: any;
  loading: boolean;
  data: QueryData;
  refetch: (params?: QueryParams) => QueryData;
}

export interface JobPageProps<QueryParams = any, QueryData = any> {
  fetchPolicy: string;
  getVariables: (props: any) => any;
  query: (params?: QueryParams) => QueryData;
  navigation: (navProps: any) => React.ReactNode;
  onFocus?: () => void;
  children: (props: ChildrenProps<QueryParams, QueryData>) => React.ReactNode;
}

const JobPage = ({
  fetchPolicy,
  getVariables,
  query,
  navigation,
  onFocus,
  children,
}: JobPageProps) => (
  <ModalPage
    fetchPolicy={fetchPolicy}
    getVariables={getVariables}
    query={query}
    navigation={navigation}
    onFocus={onFocus}
    children={children}
  />
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
JobPage.propTypes = {
  navigation: PropTypes.func,
};

JobPage.defaultProps = {
  navigation: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPage.fragment = gql`
  fragment JobPage on Job {
    id
  }
`;

export default JobPage;
