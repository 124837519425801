// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {Form, useCallback} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ValidationError from './ValidationError';

const Container = Styled.View`
  z-index: ${(props: {index: number}) => 100 - props.index};
`;

const Label = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Input = Styled.TextInput.H7`
  margin-top: 3px;
`;

export interface FieldProps<T> extends Form<T> {
  index?: number;
  name: string;
  label?: string;
  // TODO (jay) type the props for input
  component?: (props: any) => JSX.Element;
  input?: any;
  style?: any;
}

// eslint-disable-next-line
const Field = <T extends unknown>({
  index = 0,
  name,
  label,
  touched,
  errors,
  values,
  component: InputComponent = Input,
  input = {},
  setFieldValue,
  setFieldTouched,
  style,
}: FieldProps<T>) => {
  const onChangeText = useCallback(
    (text: string) => setFieldValue(name, text),
    [name, setFieldValue],
  );
  const onBlur = useCallback(() => setFieldTouched(name, true), [name, setFieldTouched]);

  return (
    <Container index={index} style={style}>
      <Label>{label}</Label>
      <InputComponent
        {...input}
        name={name}
        value={_.get(values, name)}
        onChangeText={onChangeText}
        onBlur={onBlur}
      />
      <ValidationError name={name} touched={touched} errors={errors} />
    </Container>
  );
};

export default Field;
