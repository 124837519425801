/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Content = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 80px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-left-color: ${(props) => ((props as any).isFirst ? colors.blue.accent : colors.transparent)};
  border-right-color: ${colors.blue.accent};
  background-color: ${(props) => ((props as any).isRequired ? colors.alpha(colors.yellow.hover, 0.2) : colors.white)};
`;

const Skip = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.tertiary};
`;

const DisplayTime = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Placeholder = Styled.H6`
  color: ${colors.gray.tertiary};
`;

const NextDay = Styled.H8`
  color: ${colors.orange.status};
`;

const getIsRequired = ({timeRangeForm, isFirst, isLast}: any) => {
  return isLast ? timeRangeForm.endIsRequired : timeRangeForm.startIsRequired;
};

const getFormTime = ({isFirst, isLast, timeRangeForm}: any) => {
  return isLast ? timeRangeForm.end : timeRangeForm.start;
};

const getDisplayTime = ({isFirst, isLast, timeRangeForm}: any) => {
  return Datetime.convertToDisplayTime(
    getFormTime({isFirst, isLast, timeRangeForm}),
    Datetime.FORM_TIME,
  );
};

const isNextDayTimeRange = ({timeRangeForm}: any) => {
  if (!timeRangeForm) {
    return false;
  }

  return TimeRange.getIsNextDay({
    start: timeRangeForm.start,
    end: timeRangeForm.end,
  });
};

const TimeRangeFormItem = ({isFirst, isLast, index, previousTimeRangeForm, timeRangeForm}: any) => {
  const isRequired = getIsRequired({timeRangeForm, isFirst, isLast});

  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Content isFirst={isFirst} isLast={isLast} isRequired={isRequired}>
        {getFormTime({isFirst, isLast, timeRangeForm}) ? (
          <DisplayTime>{getDisplayTime({isFirst, isLast, timeRangeForm})}</DisplayTime>
        ) : timeRangeForm.kind === 'SKIP' ? (
          <Skip>N/A</Skip>
        ) : (
          <Placeholder />
        )}
        {isNextDayTimeRange({timeRangeForm: previousTimeRangeForm}) && (
          <NextDay>(next day)</NextDay>
        )}
      </Content>
    </Container>
  );
};

export default TimeRangeFormItem;
