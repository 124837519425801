// Libraries
import _ from 'lodash';
import pluralize from 'pluralize';
import React, {memo} from 'react';

// Supermove
import {Styled, ScrollView, Space, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  Form,
  ResponsiveType,
  useForm,
  useModal,
  useNavigation,
  useQuery,
  useResponsive,
  useToast,
} from '@supermove/hooks';
import {JobModel, UserModel} from '@supermove/models';
import {Platform} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import BulkUploadFileForm, {
  UploadBulkFileFormTypeWrapper,
} from '@shared/modules/File/forms/BulkUploadFileForm';
import CreateAttachmentForm, {
  CreateAttachmentFormType,
} from '@shared/modules/File/forms/CreateAttachmentForm';
import UploadFileForm, {UploadFileFormType} from '@shared/modules/File/forms/UploadFileForm';
import useCreateAttachmentsMutation from '@shared/modules/File/hooks/useCreateAttachmentsMutation';
import Navigation from 'modules/App/components/Navigation';
import AddPhotos from 'modules/Job/Attachment/JobAttachmentsV2/components/AddPhotos';
import CancelAddPhotosModal from 'modules/Job/Attachment/JobAttachmentsV2/components/CancelAddPhotosModal';
import ErrorSavingAttachmentsModal from 'modules/Job/Attachment/JobAttachmentsV2/components/ErrorSavingAttachmentsModal';
import JobAttachmentErrorBanner from 'modules/Job/Attachment/JobAttachmentsV2/components/JobAttachmentErrorBanner';
import JobAttachmentItemV2 from 'modules/Job/Attachment/JobAttachmentsV2/components/JobAttachmentItemV2';
import ListEmptyComponent from 'modules/Job/Attachment/JobAttachmentsV2/components/JobAttachmentListEmpty';
import JobAttachmentsV2Footer from 'modules/Job/Attachment/JobAttachmentsV2/components/JobAttachmentsV2Footer';
import JobPhotosList from 'modules/Job/Attachment/components/JobPhotosList';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';
import JobNavigation from 'modules/Job/components/JobNavigation';
import JobPage from 'modules/Job/components/JobPage';

const Container = Styled.View`
  flex: 1;
  backgroundColor: ${colors.gray.background};
`;

const InnerContentContainer = Styled.View`
  width: ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? '800px' : '80%')};
  alignSelf: center;
`;

const getFormattedPhoto = (file: File) => {
  // Replaces spaces from file name, as the generated downloadURL is generated from the name.
  // Less needed on mobile, default file name has no spaces, and mobile doesn't have blob support
  // TODO(jay) - Long term this should be handled in the backend, replacing all instances of invalid characters.
  return Platform.select({
    web: () =>
      new File([file], file.name.replace(/\s/g, '_'), {
        type: file.type,
        lastModified: file.lastModified,
      }),
    default: () => file,
  })();
};

// TODO: If we start seeing performance issues on this page, these can be memoized
const getCreateAttachmentFormIndexById = (
  forms: CreateAttachmentFormType[],
  id?: string,
): number => {
  return forms.findIndex((form) => form._id === id);
};

const getUploadFormIndexById = (forms: UploadFileFormType[], id?: string): number => {
  return forms.findIndex((form) => form._id === id);
};

const useAttachPhotos = ({job, creatorId = ''}: {job: JobModel; creatorId: string}) => {
  const {navigator} = useNavigation();
  const errorSavingModal = useModal();
  const cancelAddPhotosModal = useModal();

  // Used for uploading files to S3
  const uploadFilesForm: Form<UploadBulkFileFormTypeWrapper> = useForm({
    initialValues: {
      uploadFileForms: BulkUploadFileForm.toForm({uploadFileForms: []}).uploadFileForms,
    },
  });

  // Used for attaching to the job and updating attachments
  const {
    form: createAttachmentsForm,
    handleSubmit: handleSubmitAttachments,
    submitting,
  } = useCreateAttachmentsMutation({
    createAttachmentForms: [],
    onSuccess: () => {
      navigator.goBack();
      successAttachPhotos.handleToast();
    },
    onError: (e) => {
      console.log('Error attaching photos', e);
      errorSavingModal.handleOpen();
    },
  });

  const attachmentCount = createAttachmentsForm.values.createAttachmentForms.length;

  const successAttachPhotos = useToast({
    ToastComponent: SuccessToast,
    message: `${pluralize('photo', attachmentCount, true)} uploaded`,
    isCloseable: true,
  });

  const uploadFileForms = uploadFilesForm.values.uploadFileForms ?? [];

  const handleSubmit = () => {
    // Don't submit if there aren't any photos or some aren't successfully uploaded
    if (
      !createAttachmentsForm.values.createAttachmentForms.length ||
      createAttachmentsForm.values.createAttachmentForms.some((photo) => !photo.fileId)
    ) {
      return;
    }
    handleSubmitAttachments();
  };

  const handleUploadAttachmentSuccess = (id?: string, fileId?: string) => {
    const index = getCreateAttachmentFormIndexById(
      createAttachmentsForm.values.createAttachmentForms,
      id,
    );
    const createAttachmentItem = createAttachmentsForm.values.createAttachmentForms[index];
    const form = CreateAttachmentForm.toForm({...createAttachmentItem, fileId});

    createAttachmentsForm.setFieldValue(`createAttachmentForms.${index}`, form);
  };

  const handleRemoveAttachment = (id?: string) => {
    const createAttachmentIndexToRemove = getCreateAttachmentFormIndexById(
      createAttachmentsForm.values.createAttachmentForms,
      id,
    );
    const uploadAttachmentIndexToRemove = getUploadFormIndexById(uploadFileForms, id);

    const newCreateAttachmentForms = [...createAttachmentsForm.values.createAttachmentForms];
    newCreateAttachmentForms.splice(createAttachmentIndexToRemove, 1);
    createAttachmentsForm.setFieldValue('createAttachmentForms', newCreateAttachmentForms);

    const attachmentForms = [...uploadFileForms];
    attachmentForms.splice(uploadAttachmentIndexToRemove, 1);
    // Make sure the other removal is processed first, so the row is removed before this can be accessed
    setImmediate(() => uploadFilesForm.setValues({uploadFileForms: attachmentForms}));

    if (createAttachmentsForm.errors.createAttachmentForms?.[createAttachmentIndexToRemove]) {
      const fieldErrors = [...createAttachmentsForm.errors.createAttachmentForms];
      fieldErrors.splice(createAttachmentIndexToRemove, 1);
      createAttachmentsForm.setErrors({createAttachmentForms: fieldErrors as string[]});
    }
  };

  const handleAddPhotos = (files: File[]) => {
    const organizationId = job.organization.id ?? '';
    const {newUploadFileForms, newCreateAttachmentForms} = files.reduce(
      (acc, file) => {
        const formattedFile = getFormattedPhoto(file);

        const newUploadFileForm = UploadFileForm.new({
          organizationId,
          creatorId,
          attachmentCategoryKinds: ['CREW_PHOTO'],
        });

        const uploadFileForm = UploadFileForm.toForm({
          ...newUploadFileForm,
          requestUploadFileForm: {
            organizationId,
            creatorId,
            mimetype: formattedFile.type,
            filename: formattedFile.name,
          },
          file: formattedFile,
          error: null,
        });

        const createAttachmentForm = CreateAttachmentForm.new({
          projectId: job.project.id,
          jobId: job.id,
          attachmentCategoryKinds: ['CREW_PHOTO'],
          description: '',
          isVisibleToCrew: true,
          uploading: true,
          _id: newUploadFileForm._id,
        });

        return {
          newUploadFileForms: [...acc.newUploadFileForms, uploadFileForm],
          newCreateAttachmentForms: [...acc.newCreateAttachmentForms, createAttachmentForm],
        };
      },
      {
        newUploadFileForms: [] as UploadFileFormType[],
        newCreateAttachmentForms: [] as CreateAttachmentFormType[],
      },
    );

    uploadFilesForm.setValues({
      uploadFileForms: [...uploadFileForms, ...newUploadFileForms],
    });

    // Adds to the form without overwriting changes to existing values
    newCreateAttachmentForms.forEach((newForm, index) => {
      createAttachmentsForm.setFieldValue(
        `createAttachmentForms.${
          createAttachmentsForm.values.createAttachmentForms.length + index
        }`,
        newForm,
      );
    });
  };

  const handleBackPress = () => {
    // If they have any photos, warn user they will be lost if they go back
    if (createAttachmentsForm.values.createAttachmentForms.length) {
      cancelAddPhotosModal.handleOpen();
    } else {
      navigator.goBack();
    }
  };

  return {
    cancelAddPhotosModal,
    createAttachmentsForm,
    errorSavingModal,
    handleSubmit,
    handleBackPress,
    handleAddPhotos,
    handleRemoveAttachment,
    handleUploadAttachmentSuccess,
    navigator,
    submitting,
    uploadFilesForm,
  };
};

interface JobAttachmentV2ContentProps {
  job: JobModel;
  viewer: UserModel;
}

const JobAttachmentV2Content = memo(({job, viewer}: JobAttachmentV2ContentProps) => {
  const responsive = useResponsive();
  const {
    cancelAddPhotosModal,
    createAttachmentsForm,
    errorSavingModal,
    handleSubmit,
    handleBackPress,
    handleAddPhotos,
    handleRemoveAttachment,
    handleUploadAttachmentSuccess,
    navigator,
    submitting,
    uploadFilesForm,
  } = useAttachPhotos({job, creatorId: viewer.id});

  const uploadFormItems = uploadFilesForm.values.uploadFileForms;
  const createFormItems = createAttachmentsForm.values.createAttachmentForms;

  const photosUploading = createFormItems.filter((form) => form.uploading).length;
  const photosUploaded = createFormItems.filter((form) => form.fileId).length;

  return (
    <React.Fragment>
      <JobNavigation
        data={{job}}
        navigator={navigator}
        loading={false}
        params={undefined}
        renderLeft={() => <Navigation.CloseButton onPress={handleBackPress} />}
      />
      <Container>
        <Space height={24} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{paddingHorizontal: responsive.mobile ? 16 : '20%'}}
        >
          <AddPhotos responsive={responsive} handleAddPhotos={handleAddPhotos} />
          <Space height={24} />
          <JobAttachmentErrorBanner createAttachmentsForm={createAttachmentsForm} />
          {createFormItems.length ? null : <ListEmptyComponent responsive={responsive} />}
          {createFormItems.map((form, index) => (
            <React.Fragment key={form._id}>
              <JobAttachmentItemV2
                formItemName={`createAttachmentForms.${index}`}
                key={form._id}
                handleRemove={() => handleRemoveAttachment(form._id)}
                handleUploadAttachmentSuccess={(id) => handleUploadAttachmentSuccess(form._id, id)}
                responsive={responsive}
                uploadFileForm={uploadFormItems[getUploadFormIndexById(uploadFormItems, form._id)]}
                createAttachmentsForm={createAttachmentsForm}
              />
              <Space height={24} />
            </React.Fragment>
          ))}
        </ScrollView>
        <JobAttachmentsV2Footer
          disabled={_.isEmpty(createFormItems) || createFormItems.length !== photosUploaded}
          submitting={photosUploading > 0 || submitting}
          handleSubmit={handleSubmit}
          numberOfPhotos={photosUploaded}
          responsive={responsive}
        />
        <ErrorSavingAttachmentsModal
          isOpen={errorSavingModal.isOpen}
          handleClose={errorSavingModal.handleClose}
        />
        <CancelAddPhotosModal
          isOpen={cancelAddPhotosModal.isOpen}
          handleGoBack={navigator.goBack}
          handleStay={cancelAddPhotosModal.handleClose}
        />
      </Container>
    </React.Fragment>
  );
});

const JobAttachmentsV2Page = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(JobAttachmentsV2Page.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.jobUuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <JobAttachmentV2Content job={data.job} viewer={data.viewer} />;
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobAttachmentsV2Page.query = gql`
  ${JobPage.fragment}
  ${JobPhotosList.fragment}
  ${JobNavigation.fragment}

  query JobAttachmentsV2Page($uuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    job(uuid: $uuid) {
      id
      uuid
      organization {
        id
      }
      project {
        id
      }
      ...JobPage
      ...JobPhotosList
      ...JobNavigation
    }
  }
`;

export default JobAttachmentsV2Page;
