// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  align-self: stretch;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const FlatListSpace = Styled.View`
  height: 10px;
`;

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
  width: 100px;
`;

const Text = Styled.H6`
  color: ${colors.gray.secondary};
  font-weight: 500;
`;

const Name = Styled.H6`
`;

const Quantity = Styled.H6`
  color: ${colors.gray.secondary};
`;

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.Text`
  ${Typography.Body3}
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ListHeader = () => (
  <Header>
    <Cell
      style={{
        flex: 1,
        minWidth: 140,
      }}
    >
      <Text>Equipment</Text>
    </Cell>
    <Cell
      style={{
        alignItems: 'flex-end',
        minWidth: 200,
      }}
    >
      <Text>Quantity Requested</Text>
    </Cell>
    <Cell
      style={{
        alignItems: 'flex-end',
      }}
    >
      <Text>Left on Site</Text>
    </Cell>
  </Header>
);

const CommercialEquipmentItem = ({commercialEquipment}: any) => {
  return (
    <Item>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
        }}
      >
        <Name>{commercialEquipment.name}</Name>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        <Quantity>{commercialEquipment.quantityRequested}</Quantity>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        <Quantity>{commercialEquipment.quantityDelivered}</Quantity>
      </Cell>
    </Item>
  );
};

type OwnCommercialEquipmentsListProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'CommercialEquipmentsListProps' circula... Remove this comment to see the full error message
type CommercialEquipmentsListProps = OwnCommercialEquipmentsListProps &
  typeof CommercialEquipmentsList.defaultProps;

// @ts-expect-error TS(7022): 'CommercialEquipmentsList' implicitly has type 'an... Remove this comment to see the full error message
const CommercialEquipmentsList = ({job}: CommercialEquipmentsListProps) => {
  return (
    <Container>
      <FlatList
        listKey={'CommercialEquipmentsList'}
        data={job.commercialCatalog.commercialEquipments}
        keyExtractor={(commercialEquipment: any, index: any) => String(index)}
        // @ts-expect-error TS(7031): Binding element 'commercialEquipment' implicitly h... Remove this comment to see the full error message
        renderItem={({item: commercialEquipment}) => (
          <CommercialEquipmentItem commercialEquipment={commercialEquipment} />
        )}
        CellRendererComponent={FlatListCell}
        ItemSeparatorComponent={FlatListSpace}
        ListHeaderComponent={() => (
          <React.Fragment>
            <ListHeader />
            <FlatListSpace />
          </React.Fragment>
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No Equipment</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

CommercialEquipmentsList.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialEquipmentsList.fragment = gql`
  fragment CommercialEquipmentsList on Job {
    id
    commercialCatalog {
      id
      commercialEquipments {
        id
        name
        quantityDelivered
        quantityRequested
      }
    }
  }
`;

export default CommercialEquipmentsList;
