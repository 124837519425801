// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 15px;
  padding-horizontal: 10px;
`;

const Info = Styled.View`
  flex: 1;
`;

const DisplayName = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const CustomerJobDocumentItem = ({isFirst, isLast, document}: any) => {
  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Info>
          <DisplayName>{`${document.indexForStep + 1}. ${document.displayName}`}</DisplayName>
        </Info>
      </Content>
      <Line />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerJobDocumentItem.fragment = gql`
  fragment CustomerJobDocumentItem on Document {
    id
    displayName
    indexForStep(step: $step)
  }
`;

export default CustomerJobDocumentItem;
