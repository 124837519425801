/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import Field from 'modules/App/components/Field';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: 600px;
  background-color: ${colors.white};
  border-radius: 5px;
`;

const Content = Styled.View`
  margin-vertical: 24px;
  margin-horizontal: 40px;
`;

const Title = Styled.H4`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  text-align: center;
`;

const TextInput = Styled.TextInput.H7`
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.LoadingButton`
  width: 100px;
  height: 40px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

type OwnProps = {
  isOpen: boolean;
  user: any;
  form: any;
  handleClose: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LogInEmployeeTabletPinModal.defaultProps;

// @ts-expect-error TS(7022): 'LogInEmployeeTabletPinModal' implicitly has type ... Remove this comment to see the full error message
const LogInEmployeeTabletPinModal = ({
  isOpen,
  submitting,
  user,
  form,
  handleClose,
  handleSubmit,
}: Props) => {
  const {fullName, isPinNumeric} = user;

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper>
          <Content>
            <Title>Please enter your PIN</Title>
            <Subtitle>{`Welcome back, ${fullName}!`}</Subtitle>
            <Space height={20} />
            <Field
              {...form}
              component={TextInput}
              name={'logInEmployeeForm.tabletPin'}
              label={'PIN'}
              input={{
                secureTextEntry: true,
                keyboardType: isPinNumeric ? 'numeric' : 'default',
                placeholder: 'Enter your pin',
                onSubmitEditing: handleSubmit,
                style: {
                  marginTop: 3,
                },
              }}
            />
            <Space height={40} />
            <Actions>
              <Button color={colors.gray.secondary} disabled={submitting} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <Space width={12} />
              <Button loading={submitting} onPress={handleSubmit}>
                <Text>Submit</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

LogInEmployeeTabletPinModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
LogInEmployeeTabletPinModal.fragment = gql`
  fragment LogInEmployeeTabletPinModal on User {
    id
    fullName
    isPinNumeric
  }
`;

export default LogInEmployeeTabletPinModal;
