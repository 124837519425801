// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const Button = Styled.ButtonV2`
  padding-vertical: 8px;
  padding-horizontal: 10px;
  background-color: ${colors.blue.interactive}
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
`;

const ButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const EditBillsAndAddPaymentsButton = ({jobUuid}: any) => {
  const {navigator} = useNavigation();
  const handleNavigation = () => {
    navigator.navigate('EditBillingProjectJobPage', {jobUuid});
  };

  return (
    <Button onPress={handleNavigation}>
      <Icon source={Icon.Pen} color={colors.white} size={14} />
      <Space width={8} />
      <ButtonText>Edit Bills & Add Payments</ButtonText>
    </Button>
  );
};

export default EditBillsAndAddPaymentsButton;
