// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const HeaderRow = Styled.View`
  flex-direction: row;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding-horizontal: 20px;
  background-color: ${colors.gray.background};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const SideColumn = Styled.View`
  width: 40px;
`;

const MiddleColumn = Styled.View`
  align-items: center;
`;

const BackButton = Styled.ButtonV2`
  padding: 10px;
`;

const TitleText = Styled.H7`
  color: ${colors.gray.primary}
  ${fontWeight(500)}
`;

const ProjectBillingHeader = ({leftButtonType, title, subtitle, onBack}: any) => {
  const {navigator} = useNavigation();
  return (
    <HeaderRow>
      <SideColumn>
        <BackButton
          onPress={() => {
            navigator.goBack();
            if (onBack) {
              onBack();
            }
          }}
        >
          {leftButtonType === 'CLOSE' && (
            <Icon source={Icon.Xmark} color={colors.gray.primary} size={24} />
          )}
          {leftButtonType === 'BACK' && (
            <Icon source={Icon.ArrowLeft} color={colors.gray.primary} size={22} />
          )}
        </BackButton>
      </SideColumn>
      <MiddleColumn>
        <TitleText>{title}</TitleText>
        {!!subtitle && (
          <React.Fragment>
            <Space height={4} />
            <TitleText>{subtitle}</TitleText>
          </React.Fragment>
        )}
      </MiddleColumn>
      <SideColumn />
    </HeaderRow>
  );
};

export default ProjectBillingHeader;
