// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import AssignMoversMover from 'modules/Job/Crew/Timesheet/components/AssignMoversMover';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const BranchNameText = Styled.Text`
  ${Typography.Mobile.Heading2}
`;

const MoversListContainer = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const AddMoversModalContent = ({crew, viewerId, form, field}: any) => {
  const responsive = useResponsive();
  const jobUserForms = _.get(form.values, field);
  const userIds = jobUserForms.map((jobUserForm: any) => _.toString(jobUserForm.userId));

  return (
    <React.Fragment>
      <BranchNameText>{crew.organization.name}</BranchNameText>
      <Space height={16} />
      <WarningCallout
        text={'Updating the crew on an in-progress job will impact the timesheet and crew hours.'}
      />
      <Space height={16} />
      <MoversListContainer>
        {crew.organization.movers.map((mover: any, index: any) => {
          const jobUserForm = jobUserForms.find(
            (jobUserForm: any) => _.toString(jobUserForm.userId) === _.toString(mover.id),
          );
          const jobUserFormIndex = jobUserForms.findIndex(
            (jobUserForm: any) => _.toString(jobUserForm?.userId) === _.toString(mover.id),
          );

          return (
            <React.Fragment key={mover.id}>
              {index > 0 && <Line />}
              {!jobUserForm ? (
                <AssignMoversMover.User
                  user={mover}
                  organization={crew.organization}
                  handleToggle={() => {
                    const jobUserForm = JobUserForm.new({
                      crewId: crew.id,
                      jobId: crew.jobId,
                      userId: mover.id,
                      firstName: mover.fullName,
                      position: mover.position,
                      branchCode: mover.branchCode,
                      moverPositionIds: mover.userMoverPositions.map(
                        (userMoverPosition: any) => userMoverPosition.moverPositionId,
                      ),
                      primaryMoverPositionId: _.find(mover.userMoverPositions, {isPrimary: true})
                        ?.moverPositionId,
                    });
                    form.setFieldValue(field, [...jobUserForms, jobUserForm]);
                  }}
                  isSelected={userIds.includes(mover.id)}
                  responsive={responsive}
                />
              ) : (
                <AssignMoversMover
                  jobUserForm={jobUserForm}
                  organization={crew.organization}
                  handleToggle={() => {
                    const updatedJobUserForms = jobUserForms.filter(
                      (jobUserForm: any) => _.toString(jobUserForm.userId) !== _.toString(mover.id),
                    );
                    form.setFieldValue(field, updatedJobUserForms);
                  }}
                  handlePrimaryMoverPosition={(moverPosition: any) => {
                    form.setFieldValue(
                      `${field}.${jobUserFormIndex}.primaryMoverPositionId`,
                      moverPosition.id,
                    );
                  }}
                  isSelected
                  isViewer={_.toString(jobUserForm.userId) === _.toString(viewerId)}
                  responsive={responsive}
                />
              )}
            </React.Fragment>
          );
        })}
      </MoversListContainer>
    </React.Fragment>
  );
};

const AddMoversModal = ({isOpen, handleClose, crew, viewerId, form, field}: any) => {
  const responsive = useResponsive();
  const jobUserFormsField = `${field}.jobUserForms`;
  const modalForm = useForm({
    initialValues: {
      jobUserForms: [],
    },
  });

  return (
    <LargeModal
      isOpen={isOpen}
      title={'Add Movers'}
      handlePrimaryAction={() => {
        const currentForms = _.get(form.values, jobUserFormsField);
        form.setFieldValue(jobUserFormsField, [...currentForms, ...modalForm.values.jobUserForms]);
        handleClose();
      }}
      handleSecondaryAction={handleClose}
      primaryActionText={'Done'}
      secondaryActionText={'Cancel'}
      // @ts-expect-error TS(2322): Type '{ children: Element; isOpen: any; title: str... Remove this comment to see the full error message
      screenContainerStyle={{paddingHorizontal: responsive.mobile ? 0 : 96}}
      bodyStyle={{paddingVertical: 16}}
      isScrollable
      isResponsive
    >
      <AddMoversModalContent
        crew={crew}
        viewerId={viewerId}
        form={modalForm}
        field={'jobUserForms'}
      />
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddMoversModal.fragment = gql`
  ${AssignMoversMover.fragment}
  ${(AssignMoversMover.User as any).fragment}

  fragment AddMoversModal on Crew {
    id
    jobId
    organization {
      id
      name
      movers {
        id
        fullName
        position
        branchCode
        userMoverPositions {
          id
          isPrimary
          moverPositionId
        }
        ...AssignMoversMover_User
      }
      ...AssignMoversMover
    }
  }
`;

export default AddMoversModal;
