// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';
import useDeleteAttachmentForm from '@shared/modules/File/hooks/useDeleteAttachmentForm';

const DeleteAttachmentModal = ({isOpen, attachmentId, handleClose, onSuccess}: any) => {
  const {submitting, handleSubmit} = useDeleteAttachmentForm({
    deleteAttachmentForm: DeleteAttachmentForm.new({
      attachmentId,
    }),
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to delete this attachment?'}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

export default DeleteAttachmentModal;
