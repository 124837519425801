// Libraries
import React from 'react';

// Supermove
import {PdfView, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {AttachmentModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

const ATTACHMENT_VIEWER_WIDTH = 600;
const PDF_PAGE_HEIGHT = ATTACHMENT_VIEWER_WIDTH * PdfView.PAGE_ASPECT_RATIO;

const Container = Styled.View`
  height: 600px;
`;

const Content = Styled.View`
  align-self: center;
  width: ${ATTACHMENT_VIEWER_WIDTH}px;
  background-color: ${colors.gray.border}
  justify-content: center;
`;

const Image = Styled.Image`
  height: 600px;
  width: ${ATTACHMENT_VIEWER_WIDTH}px;
`;

const Text = Styled.Text`
  ${Typography.Heading6}
  text-align: center;
`;

// Assumes the pdf dimensions are 8.5 X 11.

const PdfContainer = Styled.View`
  height: ${({height}: {height: number}) => height}px;
  width: ${ATTACHMENT_VIEWER_WIDTH}px;
`;

const AttachmentPdfItem = ({attachment}: {attachment: AttachmentModel}) => {
  /** *
   PdfView for react native wraps the pdf in a ScrollView. However this component
   also gets wrapped in a ScrollView by its parent components. This nesting does not
   work well on Android. Android has a separate NestedScrollView component to handle
   this. To make our pdf viewer work on android we need to manually set the container
   height to ensure we show all pages of the pdf.
  */

  // The intitial 200 height is for the loading component.
  const [pdfHeight, setPdfHeight] = useState(200);
  return (
    <PdfContainer key={pdfHeight} height={pdfHeight}>
      <PdfView
        source={{uri: attachment.file.downloadUrl}}
        onLoad={({numberOfPages}: {numberOfPages: number}) => {
          setPdfHeight(numberOfPages * PDF_PAGE_HEIGHT);
        }}
      />
    </PdfContainer>
  );
};

const AttachmentImageItem = ({attachment}: {attachment: AttachmentModel}) => {
  return (
    <ScrollView style={{flex: 1}}>
      <Content>
        <Image
          resizeMode={'contain'}
          source={{uri: attachment.file.downloadUrl}}
          accessibilityLabel={attachment.file.name}
        />
      </Content>
    </ScrollView>
  );
};

const AttachmentNoPreviewItem = ({attachment}: {attachment: AttachmentModel}) => {
  return (
    <Container>
      <Content style={{flex: 1}}>
        <Text>{attachment.file.name}</Text>
        <Text>[Preview not available]</Text>
      </Content>
      <Space height={24} />
    </Container>
  );
};

const AttachmentViewer = ({attachment}: {attachment: AttachmentModel}) => {
  switch (attachment.file.mimetype) {
    case 'application/pdf':
      return <AttachmentPdfItem attachment={attachment} />;
    case 'image/jpeg':
    case 'image/png':
      return <AttachmentImageItem attachment={attachment} />;
    default:
      return <AttachmentNoPreviewItem attachment={attachment} />;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentViewer.fragment = gql`
  fragment AttachmentViewer on Attachment {
    id
    file {
      id
      mimetype
      name
      downloadUrl
    }
  }
`;

export default AttachmentViewer;
