// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {TimeRangeModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime, withFragment} from '@supermove/utils';

const getColor = withFragment(
  ({kind}: TimeRangeModel) => {
    switch (kind) {
      case 'BREAK':
        return colors.gray.secondary;
      case 'DRIVE':
        return colors.yellow.hover;
      case 'WAIT':
        return colors.red.warning;
      case 'WORK':
        return colors.green.status;
      case 'FILLER':
      default:
        return colors.gray.border;
    }
  },
  gql`
    fragment TimeRange_getColor on TimeRange {
      kind
    }
  `,
);

const getTooltipText = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ start, end, name }: { start: ... Remove this comment to see the full error message
  ({start, end, name}) => {
    if (end) {
      return `${name}: ${Datetime.convertToDisplayTime(start)}-${Datetime.convertToDisplayTime(
        end,
      )}`;
    }
    return `${name}: ${Datetime.convertToDisplayTime(start)}`;
  },
  gql`
    fragment TimeRange_getTooltipText on TimeRange {
      start
      end
      name
    }
  `,
);

const TimeRange = {
  KINDS: {
    BREAK: 'BREAK',
    DRIVE: 'DRIVE',
    WAIT: 'WAIT',
    WORK: 'WORK',
    SKIP: 'SKIP',
    FILLER: 'FILLER',
  },
  toForm: ({name, kind, start, end}: any) => ({
    name,
    kind,
    start: Datetime.toFormTime(start),
    end: Datetime.toFormTime(end),
  }),

  toMutation: ({name, kind, start, end}: any) => ({
    name: name || `${_.startCase(_.toLower(kind))} Time`,
    kind,
    start: Datetime.toMutationTime(start),
    end: Datetime.toMutationTime(end),
  }),

  getColor,
  getTooltipText,

  getEstimatedRemainingMinutes: (timeRanges: any, {hasConfirmedTimes}: any = {}) => {
    const lastTimeRange = _.last(timeRanges);
    if (hasConfirmedTimes && (lastTimeRange as any).end) {
      // If the last timeRange has an end time, assume that is the end.
      return 0;
    }

    // 1 hour worth of space.
    return 60;
  },

  /**
   * Used to render the Timebar vertically on the manager app.
   */
  getHeight: (timeRanges: any, {index}: any) => {
    const totalMinutes = TimeRange.getTotalMinutes(timeRanges);
    const minutes = TimeRange.getMinutes(timeRanges[index]);
    return Math.max(30, Math.ceil((minutes / totalMinutes) * 200));
  },

  getIsLong: ({start, end}: any) => {
    if (!start || !end) {
      return false;
    }

    // We consider 12 hours to be a "long" time between start and end. This is often
    // when they are entering a time with the wrong meridiem (am/pm).
    const minutes = TimeRange.getMinutes({start, end});
    return minutes >= 60 * 12;
  },

  getIsNextDay: ({start, end}: any) => {
    const startTime = Datetime.fromTime(start, Datetime.FORM_TIME);
    const endTime = Datetime.fromTime(end, Datetime.FORM_TIME);
    return endTime < startTime;
  },

  getMinutes: ({start, end}: any) => {
    if (!start) {
      return 0;
    }

    if (!end) {
      // Creates a small in progress wedge.
      return 20;
    }

    const startTime = Datetime.fromTime(start, Datetime.FORM_TIME);
    const endTime = Datetime.fromTime(end, Datetime.FORM_TIME);
    const adjustment = startTime <= endTime ? 0 : 1;

    // Returns the number of minutes between the times.
    return endTime.add(adjustment, 'days').diff(startTime, 'minutes', true);
  },

  getMinutesText: ({start, end}: any) => {
    if (!start) {
      return '';
    }

    if (!end) {
      return '';
    }

    const time = TimeRange.getMinutes({start, end});
    const hours = Math.floor(time / 60);
    const minutes = Math.floor(time % 60);

    if (hours === 0 && minutes === 0) {
      return '0 min';
    }

    // Hide hr if 0 and hide min if 0.
    return `${hours === 0 ? '' : `${hours} hr`}${minutes === 0 ? '' : ` ${Math.abs(minutes)} min`}`;
  },

  getName: ({kind}: any) => {
    switch (kind) {
      case 'BREAK':
        return 'Break Time';
      case 'DRIVE':
        return 'Drive Time';
      case 'WAIT':
        return 'Wait Time';
      case 'WORK':
        return 'Work Time';
      default:
        return '';
    }
  },

  getTotalMinutes: (timeRanges: any) => {
    return timeRanges.reduce(
      (sum: any, timeRange: any) => sum + TimeRange.getMinutes(timeRange),
      0,
    );
  },

  create: ({name, kind, start, end}: any) => ({
    name: name || '',
    kind: kind || '',
    start: start || '',
    end: end || '',
  }),
};

export default TimeRange;
