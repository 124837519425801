// Libraries
import React from 'react';

// Supermove
import {Notification, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import CommercialCatalogForm from '@shared/modules/CommercialCatalog/forms/CommercialCatalogForm';
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';
import useUpdateCommercialCatalogMutation from '@shared/modules/CommercialCatalog/hooks/useUpdateCommercialCatalogMutation';
import DocumentCommercialMaterialFields from 'modules/Job/Crew/Document/Show/components/DocumentCommercialMaterialFields';

import DocumentAdditionalInfoGridSearch from './DocumentAdditionalInfoGridSearch';

const Container = Styled.View`
`;

const Content = Styled.View`
  z-index: 100;
`;

const Header = Styled.View`
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 5px;
`;

const Column = Styled.Text`
  ${Typography.Body3}
  margin-left: 10px;
  width: ${(props) => (props as any).width}px;
`;

const FormActions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Actions = Styled.View`
  margin-top: 20px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const DocumentCommercialCatalogMaterialsFields = ({document, form: parentForm}: any) => {
  const {commercialCatalog, organization} = document.job;
  const commercialCatalogForm = CommercialCatalogForm.edit(commercialCatalog);
  const {form, submitting, handleSubmit} = useUpdateCommercialCatalogMutation({
    kind: 'MATERIAL',
    commercialCatalogForm,
    onSuccess: () => {
      parentForm.handleSubmit();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const {commercialMaterialForms} = form.values.commercialCatalogForm;
  const items = organization.commercialMaterialTypes.map((commercialMaterialType: any) => ({
    text: commercialMaterialType.name,
    commercialMaterialType,
  }));

  return (
    <Container>
      <Content>
        <Header>
          <Column width={350}>Material</Column>
          <Column width={150}>Quantity Requested</Column>
          <Column width={150}>Used</Column>
        </Header>
        {commercialMaterialForms.map((commercialMaterialForm: any, index: any) => {
          return (
            <DocumentCommercialMaterialFields
              key={index}
              isFirst={index === 0}
              isLast={index === commercialMaterialForms.length - 1}
              field={`commercialCatalogForm.commercialMaterialForms.${index}`}
              index={index}
              form={form}
            />
          );
        })}
        {/* @ts-expect-error TS(2741): Property 'color' is missing in type '{ text: strin... Remove this comment to see the full error message */}
        {commercialMaterialForms.length === 0 && <Notification.Warning text={'No Materials'} />}
        {items.length > 0 && (
          <React.Fragment>
            <Space height={10} />
            <DocumentAdditionalInfoGridSearch
              items={items}
              extraData={form}
              onSelect={({commercialMaterialType}: any) => {
                const newCommercialMaterialForms = [
                  ...commercialMaterialForms,
                  CommercialMaterialForm.toForm(
                    CommercialMaterialForm.new({
                      commercialCatalogId: commercialCatalog.id,
                      commercialMaterialTypeId: commercialMaterialType.id,
                      name: commercialMaterialType.name,
                      price: commercialMaterialType.price,
                    }),
                  ),
                ];
                form.setFieldValue(
                  'commercialCatalogForm.commercialMaterialForms',
                  newCommercialMaterialForms,
                );
              }}
            />
          </React.Fragment>
        )}
        <Space height={10} />
        <FormActions>
          <Button
            onPress={() => {
              const newCommercialMaterialForms = [
                ...commercialMaterialForms,
                CommercialMaterialForm.toForm(
                  CommercialMaterialForm.new({
                    commercialCatalogId: commercialCatalog.id,
                    name: '',
                    price: '',
                  }),
                ),
              ];

              form.setFieldValue(
                'commercialCatalogForm.commercialMaterialForms',
                newCommercialMaterialForms,
              );
            }}
          >
            <Text>+ Add Custom</Text>
          </Button>
        </FormActions>
      </Content>
      <Actions>
        <Button loading={submitting || parentForm.loading} onPress={handleSubmit}>
          <Text>Save</Text>
        </Button>
      </Actions>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentCommercialCatalogMaterialsFields.fragment = gql`
  ${CommercialCatalogForm.edit.fragment}

  fragment DocumentCommercialCatalogMaterialsFields on Document {
    id
    job {
      id
      organization {
        id
        commercialMaterialTypes {
          id
          name
          price
        }
      }
      commercialCatalog {
        id
        ...CommercialCatalogForm_edit
      }
    }
  }
`;

export default DocumentCommercialCatalogMaterialsFields;
