// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import {Notification} from 'modules/App/components';

const JobCompletedNotification = ({job}: any) => (
  <Notification
    color={colors.green.status}
    text={`${Job.getFullName(job)} is complete. View all details below.`}
    style={{
      height: 60,
    }}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCompletedNotification.fragment = gql`
  ${Job.getFullName.fragment}

  fragment JobCompletedNotification on Job {
    id
    ...Job_getFullName
  }
`;

export default JobCompletedNotification;
