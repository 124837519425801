// Libraries
import React from 'react';

// App
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';
import CrewJobProgress from 'modules/Job/Crew/components/CrewJobProgress';
import ProjectBillsList from 'modules/Project/Billing/components/ProjectBillsList';

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Caption = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

const Group = Styled.View`
  flex-direction: row;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const FooterContent = ({data}: any) => {
  const {params, navigator} = useNavigation();

  return (
    <FooterContainer>
      <Group>
        <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
          <Text>Back</Text>
        </Button>
        <ButtonSpace />
        <Button
          color={colors.blue.interactive}
          onPress={() => {
            const screen = Job.getTimesheetScreen(data.job);
            navigator.push(screen, {uuid: params.uuid});
          }}
        >
          <Text>Edit Times</Text>
        </Button>
      </Group>
      <Button
        color={colors.blue.interactive}
        onPress={() => {
          if (data.job.isComplete) {
            return navigator.navigate('PassFinishCustomerJob', {uuid: params.uuid});
          }

          navigator.navigate('PassFinishCrewJob', {uuid: params.uuid});
        }}
      >
        <Text>Next</Text>
      </Button>
    </FooterContainer>
  );
};

const PageContent = ({job, refetch}: any) => {
  return (
    <ScrollView style={{flex: 1}} keyboardShouldPersistTaps={'handled'}>
      <CrewJobProgress selected={'bill'} items={CrewJobProgress.items.finish} />
      <Content>
        <ProjectBillsList
          title={<Title>Finalize Bill and Supplies</Title>}
          caption={
            <React.Fragment>
              <Caption>
                {`Based on the times you entered, here is the customer's ` +
                  `bill. Please confirm that the hours and fees are correct and add ` +
                  `any additional items if needed.`}
              </Caption>
              <Space height={12} />
            </React.Fragment>
          }
          project={job.project}
          isEditable={job.hasJobFeatureEditBillingAndPayments}
          showGrandTotal
          showOpenAllButton
          showBalance
          showBillRules
          refetch={refetch}
          isCollapsible
        />
      </Content>
    </ScrollView>
  );
};

const BillingProjectCrewJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(BillingProjectCrewJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <PageContent refetch={refetch} job={data.job} />;
        }}
      </Loading>
      <CrewJobPageV2.Footer>
        <Loading loading={loading}>{() => <FooterContent data={data} />}</Loading>
      </CrewJobPageV2.Footer>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectCrewJobPage.query = gql`
  ${Job.getTimesheetScreen.fragment}
  ${ProjectBillsList.fragment}

  query BillingProjectCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      isComplete
      hasJobFeatureEditBillingAndPayments: hasJobFeature(kind: "CREW_EDIT_BILLING_AND_PAYMENTS")
      project {
        id
        ...ProjectBillsList
      }
      ...Job_getTimesheetScreen
    }
  }
`;

export default BillingProjectCrewJobPage;
