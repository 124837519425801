// Libraries
import React from 'react';

// Components
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateSignatureForDocumentForm.defaultProps;

// @ts-expect-error TS(7022): 'CreateSignatureForDocumentForm' implicitly has ty... Remove this comment to see the full error message
const CreateSignatureForDocumentForm = ({
  documentId,
  customerId,
  date,
  kind,
  placement,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Form
    initialValues={{
      documentId,
      customerId,
      date,
      kind,
      image: '',
      placement,
    }}
    onSubmit={(values: any) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        variables={{
          documentId: values.documentId,
          customerId: values.customerId,
          date: values.date,
          kind: values.kind,
          image: values.image,
          placement: values.placement,
        }}
        mutation={CreateSignatureForDocumentForm.mutation}
        onCompleted={({createSignatureForDocument: {documentSignature, errors}}: any) => {
          submitForm();
          setMutationErrors(errors);

          if (documentSignature) {
            onSuccess(documentSignature);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

CreateSignatureForDocumentForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------

// NOTE(mark): We fetch the isStepCompleted for the BILL document so that we
// can navigate to the correct next incomplete document.
CreateSignatureForDocumentForm.mutation = gql`
  mutation CreateSignatureForDocumentForm(
    $documentId: Int!,
    $customerId: Int!,
    $date: String,
    $kind: String,
    $image: String,
    $placement: String,
  ) {
    createSignatureForDocument(
      documentId: $documentId,
      customerId: $customerId,
      date: $date,
      kind: $kind,
      image: $image,
      placement: $placement,
    ) {
      ${gql.errors}
      documentSignature {
        id
        document {
          id
          isStepCompleted(step: "POST_MOVE")
        }
      }
    }
  }
`;

export default CreateSignatureForDocumentForm;
