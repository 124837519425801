// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {
  ConfirmPreparedDocumentsForMoveErrorModal,
  ConfirmPreparedDocumentsForMoveMutation,
  CrewJobDocumentsList,
} from 'modules/Job/Crew/Document/List/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Documents = Styled.View`
  margin-top: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

// Hard-coded step for this list of documents.
const STEP = 'POST_MOVE';

const PreparePostMoveListDocumentCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({uuid: params.uuid, step: STEP})}
    query={PreparePostMoveListDocumentCrewJobPage.query}
    onFocus={({refetch}: any) => refetch()}
  >
    {({isFocused, navigator, params, loading, data}: any) => (
      <Loading alwaysUpdate loading={loading || !isFocused} as={PageLoadingIndicator}>
        {() => (
          <ConfirmPreparedDocumentsForMoveErrorModal
            trigger={({handleOpen}: any) => (
              <ConfirmPreparedDocumentsForMoveMutation
                move={data.job.move}
                step={STEP}
                onSuccess={() => {
                  Navigation.navigateFromPostMovePrepareDocuments({navigator, job: data.job});
                }}
                onError={(errors: any) => handleOpen()}
              >
                {({loading, handleSubmit}: any) => (
                  <Container>
                    <CrewJobProgress selected={'documents'} items={CrewJobProgress.items.finish} />
                    <ScrollView style={{flex: 1}}>
                      <Content>
                        <Info>
                          <Title>Documents</Title>
                          <Subtitle>
                            These documents have been automatically created for the end of the move.
                          </Subtitle>
                        </Info>
                        <Documents>
                          <CrewJobDocumentsList step={STEP} job={data.job} />
                        </Documents>
                      </Content>
                    </ScrollView>
                    <Footer>
                      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                        <Text>Back</Text>
                      </Button>
                      <Button
                        loading={loading}
                        color={colors.blue.interactive}
                        onPress={handleSubmit}
                      >
                        <Text>Next</Text>
                      </Button>
                    </Footer>
                  </Container>
                )}
              </ConfirmPreparedDocumentsForMoveMutation>
            )}
          />
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PreparePostMoveListDocumentCrewJobPage.query = gql`
  ${Navigation.navigateFromPostMovePrepareDocuments.fragment}
  ${ConfirmPreparedDocumentsForMoveMutation.fragment}
  ${CrewJobDocumentsList.fragment}
  ${CrewJobPage.fragment}

  query PreparePostMoveListDocumentCrewJobPage($uuid: String!, $step: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      organization {
        id
        settings {
          id
          jobRates {
            name
          }
        }
      }
      move {
        id
        ...ConfirmPreparedDocumentsForMoveMutation
      }
      ...Navigation_navigateFromPostMovePrepareDocuments
      ...CrewJobDocumentsList
      ...CrewJobPage
    }
  }
`;

export default PreparePostMoveListDocumentCrewJobPage;
