// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  (job) => ({
    jobId: (job as any).id,
  }),
  gql`
    fragment CreateDriverInventoryForJobForm_new on Job {
      id
    }
  `,
);

const toForm = ({jobId}: any) => ({
  jobId,
});

const toMutation = ({jobId}: any) => ({
  jobId,
});

const CreateDriverInventoryForJobForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateDriverInventoryForJobForm;
