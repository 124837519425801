const _new = ({jobId}: any) => ({
  jobId,
});

const toForm = ({jobId}: any) => ({
  jobId,
});

const toMutation = ({jobId}: any) => ({
  jobId,
});

const CompleteTimesheetStepForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CompleteTimesheetStepForm;
