// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, List} from '@supermove/utils';

// App
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

const FEATURES = {
  TIMESHEETS_V2: 'timesheets_v2',
  AUTOMATICALLY_FINALIZE_JOBS: 'automatically_finalize_jobs',
};

const getDisplayCategory = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ category }: { category: any; ... Remove this comment to see the full error message
  ({category}) => {
    switch (category) {
      case ProjectTypeCategory.MOVE:
        return 'Moves';
      case ProjectTypeCategory.STORAGE:
        return 'Storage';
      default:
        return '';
    }
  },
  gql`
    fragment ProjectType_getDisplayCategory on ProjectType {
      id
      category
    }
  `,
);

const COLOR_OPTIONS = {
  Purple: '#9B51E0',
  Blue: '#34ACE0',
  Green: '#27AE60',
  Yellow: '#F1C40F',
  Orange: '#F2A12C',
  Red: '#E74C3C',
  Pink: '#E84393',
  Grey: '#616161',
  Black: '#000000',
};

const getColorOptions = () =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Object.keys(COLOR_OPTIONS).map((key, index) => ({label: key, value: COLOR_OPTIONS[key]}));

const getJobBlocks = withFragment(
  (projectType) => {
    return [
      ProjectBlockKind.Job.JOB_DETAILS,
      ProjectBlockKind.Job.STOPS,
      ProjectBlockKind.Job.DISPATCH,
      ...List.insertIf(
        (projectType as any).organization.settings.isEquipmentAndMaterialsEnabled,
        ProjectBlockKind.Job.EQUIPMENT_AND_MATERIALS,
      ),
      ...((projectType as any).features.timesheetsV2
        ? [
            ProjectBlockKind.Job.JOB_TIMESHEET,
            ...((projectType as any).features.timesheetsV2
              ? [ProjectBlockKind.Job.CREW_HOURS, ProjectBlockKind.Job.TIP_PAYOUTS]
              : [ProjectBlockKind.Job.CREW_HOURS_AND_TIPS]),
          ]
        : [ProjectBlockKind.Job.TIMESHEETS]),
    ];
  },
  gql`
    fragment ProjectType_getJobBlocks on ProjectType {
      id
      features {
        timesheetsV2
      }
      organization {
        id
        settings {
          id
          isEquipmentAndMaterialsEnabled
        }
      }
    }
  `,
);

const ProjectType = {
  FEATURES,

  getColorOptions,
  getDisplayCategory,
  getJobBlocks,
};

export default ProjectType;
