// Libraries
import React from 'react';

// Components
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AssignTruckToJobForm.defaultProps;

// @ts-expect-error TS(7022): 'AssignTruckToJobForm' implicitly has type 'any' b... Remove this comment to see the full error message
const AssignTruckToJobForm = ({jobId, truckId, onSuccess, onError, children}: Props) => (
  <Form
    enableReinitialize
    initialValues={{
      jobId,
      truckId,
    }}
    onSubmit={(values: any) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        variables={{
          jobId: values.jobId,
          truckId: values.truckId,
        }}
        mutation={AssignTruckToJobForm.mutation}
        onCompleted={({assignTruckToJob: {jobTruck, errors}}: any) => {
          submitForm();
          setMutationErrors(errors);

          if (jobTruck) {
            onSuccess(jobTruck);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

AssignTruckToJobForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignTruckToJobForm.mutation = gql`
  mutation AssignTruckToJobForm(
    $jobId: Int!,
    $truckId: Int!,
  ) {
    assignTruckToJob(
      jobId: $jobId,
      truckId: $truckId,
    ) {
      ${gql.errors}
      jobTruck {
        id
      }
    }
  }
`;

export default AssignTruckToJobForm;
