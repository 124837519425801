// Libraries
import React from 'react';

// Supermove
import {Space, FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BillingDocumentBill from '@shared/modules/Document/Billing/components/BillingDocumentBill';
import BillingDocumentBillBillTips from '@shared/modules/Document/Billing/components/BillingDocumentBillBillTips';
import BillingDocumentBillPaymentFees from '@shared/modules/Document/Billing/components/BillingDocumentBillPaymentFees';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FlexContainer = Styled.View`
  align-items: flex-end;
  justify-content: center;
  flex: ${(props) => (props as any).flex};
`;

const LabelContainer = Styled.View`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: ${colors.gray.border};
  padding-left: ${(props) => ((props as any).isMobile ? '0px' : '12px')};
  flex-direction: row;
  justify-content: ${(props) => (props as any).justify};
  align-items: center;
`;

const BoldLabelText = Styled.Text`
  ${Typography.Label2}
  ${fontWeight(900)}
`;

const AmountContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
  flex: ${(props) => (props as any).flex};
  border-color: ${colors.gray.border};
`;

const BoldAmountText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
  ${fontWeight(900)}
`;

const TotalLabel = ({isProposal}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <LabelContainer justify={'flex-end'}>
      <BoldLabelText>{isProposal ? 'Estimated Total' : 'Grand Total'}</BoldLabelText>
    </LabelContainer>
  );
};

const GrandTotal = ({isProposal, aggregateBill}: any) => {
  const responsive = useResponsive();
  const {isTotalAvailable, minTotal, maxTotal} = aggregateBill;
  return (
    <Row style={{backgroundColor: colors.white}}>
      {responsive.mobile ? (
        <TotalLabel isProposal={isProposal} />
      ) : (
        // @ts-expect-error TS(2769): No overload matches this call.
        <FlexContainer flex={8}>
          <TotalLabel isProposal={isProposal} />
        </FlexContainer>
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <AmountContainer flex={3}>
        <BoldAmountText>
          {/* @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message */}
          {isTotalAvailable ? Currency.formatRange({min: minTotal, max: maxTotal}) : 'TBD'}
        </BoldAmountText>
      </AmountContainer>
    </Row>
  );
};

type OwnBillingDocumentProjectBillsProps = {
  hasQuantity?: boolean;
  hasTotal?: boolean;
  hasGrandTotal?: boolean;
  hasAllBills?: boolean;
  isProposal?: boolean;
  project: any;
};

// @ts-expect-error TS(2456): Type alias 'BillingDocumentProjectBillsProps' circ... Remove this comment to see the full error message
type BillingDocumentProjectBillsProps = OwnBillingDocumentProjectBillsProps &
  typeof BillingDocumentProjectBills.defaultProps;

// @ts-expect-error TS(7022): 'BillingDocumentProjectBills' implicitly has type ... Remove this comment to see the full error message
const BillingDocumentProjectBills = ({
  isProposal,
  hasAllBills,
  hasQuantity,
  hasTotal,
  hasGrandTotal,
  project,
}: BillingDocumentProjectBillsProps) => {
  const {activeJobsAggregateBill, currentAggregateBill} = project;
  const aggregateBill = hasAllBills ? activeJobsAggregateBill : currentAggregateBill;
  return (
    <Container>
      <FlatList
        data={aggregateBill.bills}
        keyExtractor={(bill: any) => bill.id}
        renderItem={({item: bill, index}: any) => (
          <BillingDocumentBill
            bill={bill}
            key={`${index}_BILL`}
            hasQuantity={hasQuantity}
            hasTotal={hasTotal}
            hasEmptyTotals={!isProposal}
          />
        )}
        ItemSeparatorComponent={() => <Space height={12} />}
      />
      {hasGrandTotal && (
        <React.Fragment>
          {aggregateBill.primaryBill.hasBillTips && (
            <React.Fragment>
              <BillingDocumentBillBillTips bill={aggregateBill.primaryBill} />
              <Space height={8} />
            </React.Fragment>
          )}
          {aggregateBill.primaryBill.hasPaymentFees && (
            <React.Fragment>
              <BillingDocumentBillPaymentFees bill={aggregateBill.primaryBill} />
              <Space height={8} />
            </React.Fragment>
          )}
          <GrandTotal isProposal={isProposal} aggregateBill={aggregateBill} />
        </React.Fragment>
      )}
    </Container>
  );
};

BillingDocumentProjectBills.defaultProps = {
  hasQuantity: true,
  hasTotal: true,
  hasGrandTotal: true,
  hasAllBills: true,
  isProposal: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingDocumentProjectBills.fragment = gql`
  ${BillingDocumentBill.fragment}
  ${BillingDocumentBillBillTips.fragment}
  ${BillingDocumentBillPaymentFees.fragment}

  fragment BillingDocumentProjectBills_AggregateBill on AggregateBill {
    minTotal
    maxTotal
    isTotalAvailable
    primaryBill {
      id
      hasBillTips
      hasPaymentFees
      ...BillingDocumentBillBillTips
      ...BillingDocumentBillPaymentFees
    }
    bills {
      id
      ...BillingDocumentBill
    }
  }

  fragment BillingDocumentProjectBills on Project {
    id
    activeJobsAggregateBill {
      ...BillingDocumentProjectBills_AggregateBill
    }
    currentAggregateBill {
      ...BillingDocumentProjectBills_AggregateBill
    }
  }
`;

export default BillingDocumentProjectBills;
