/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import DeleteAttachmentModal from 'modules/Job/Attachment/components/DeleteAttachmentModal';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  border-radius: 10px;
  width: 100px;
`;

const Image = Styled.Image`
  height: 100px;
  width: 100px;
`;

const FilenamePreview = Styled.View`
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100px;
  width: 100px;
  background-color: ${colors.gray.tertiary};
`;

const Text = Styled.Text`
  ${Typography.Body3}
  text-align: center;
`;

const DeleteButton = Styled.Touchable`
  position: absolute;
  top: 2px;
  right: -8px;
  background-color: ${colors.gray.border};
  padding-horizontal: 4px;
  border-radius: 12px;
  width: 24px;
  height: 24px;
`;

const ButtonIconContainer = Styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const AttachmentContainer = ({attachment, index, children, onPress, onDelete}: any) => {
  const deleteAttachmentModal = useModal();

  return (
    <React.Fragment key={index}>
      <Touchable onPress={onPress}>
        <Container>
          <Space height={12} />
          {children}
          <Space height={4} />
          <Text numberOfLines={2}>{attachment.description}</Text>
        </Container>
        <DeleteButton onPress={deleteAttachmentModal.handleOpen}>
          <ButtonIconContainer>
            <Icon source={Icon.Times} color={colors.gray.secondary} size={Icon.Sizes.Large} />
          </ButtonIconContainer>
        </DeleteButton>
      </Touchable>
      <DeleteAttachmentModal
        isOpen={deleteAttachmentModal.isOpen}
        attachmentId={attachment.id}
        handleClose={deleteAttachmentModal.handleClose}
        onSuccess={onDelete}
      />
      <Space width={10} />
    </React.Fragment>
  );
};

const JobAttachmentFileItem = ({attachment, onPress, index, onDelete}: any) => {
  return (
    <AttachmentContainer
      attachment={attachment}
      index={index}
      onPress={onPress}
      onDelete={onDelete}
    >
      <FilenamePreview>
        <Text numberOfLines={2}>{attachment.file.name}</Text>
      </FilenamePreview>
    </AttachmentContainer>
  );
};

const JobAttachmentImageItem = ({attachment, onPress, index, onDelete}: any) => {
  return (
    <AttachmentContainer
      attachment={attachment}
      index={index}
      onPress={onPress}
      onDelete={onDelete}
    >
      <Image
        resizeMode={'cover'}
        source={{uri: attachment.file.downloadUrl}}
        accessibilityLabel={attachment.file.name}
      />
    </AttachmentContainer>
  );
};

const JobAttachmentItem = ({attachment, onPress, index, onDelete}: any) => {
  switch (attachment.file.mimetype) {
    case 'image/jpeg':
    case 'image/png':
      return (
        <JobAttachmentImageItem
          attachment={attachment}
          onPress={onPress}
          index={index}
          onDelete={onDelete}
        />
      );
    default:
      return (
        <JobAttachmentFileItem
          attachment={attachment}
          onPress={onPress}
          index={index}
          onDelete={onDelete}
        />
      );
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobAttachmentItem.fragment = gql`
  fragment JobAttachmentItem on Attachment {
    id
    description
    file {
      id
      mimetype
      name
      downloadUrl
    }
  }
`;

export default JobAttachmentItem;
