// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

// Components

const getWidth = ({isFirst, isLast, isSelected}: any) => {
  if (!isSelected) {
    return '100%';
  }

  if (isFirst) {
    return '0%';
  } else if (isLast) {
    return '100%';
  } else {
    return '50%';
  }
};

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 40px;
`;

const Line = Styled.View`
  top: 12px;
  align-self: stretch;
  height: 4px;
  background-color: ${(props) => (props as any).color};
`;

const Content = Styled.View`
  flex-direction: row;
`;

const Item = Styled.View`
  flex: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isFirst || vars.isLast ? 1 : 2)};
  align-items: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isFirst ? 'flex-start' : vars.isLast ? 'flex-end' : 'center')};
`;

const SelectedLine = Styled.View`
  position: absolute;
  top: 8px;
  left: 0px;
  right: 0px;
  align-self: stretch;
  height: 4px;
  width: ${(props) => getWidth(props)};
  background-color: ${(props) => (props as any).color};
`;

const Circle = Styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isSelected ? vars.selectedColor : vars.defaultColor)};
`;

const Name = Styled.Text`
  ${Typography.Label2}
  margin-top: 5px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isSelected ? vars.selectedColor : vars.defaultColor)};
`;

const NavigationItem = ({
  showAll,
  isFirst,
  isLast,
  selectedIndex,
  index,
  selectedColor,
  defaultColor,
  item,
}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Item vars={{isFirst, isLast}}>
    {selectedIndex >= index && (
      <SelectedLine
        // @ts-expect-error TS(2769): No overload matches this call.
        isFirst={isFirst}
        isLast={isLast}
        isSelected={selectedIndex === index}
        color={selectedColor}
      />
    )}
    <Circle
      // @ts-expect-error TS(2769): No overload matches this call.
      vars={{
        isSelected: selectedIndex >= index,
        selectedColor,
        defaultColor,
      }}
    />
    {(selectedIndex >= index || showAll) && (
      <Name
        // @ts-expect-error TS(2769): No overload matches this call.
        vars={{
          isSelected: selectedIndex >= index,
          selectedColor,
          defaultColor,
        }}
      >
        {item.name}
      </Name>
    )}
  </Item>
);

const getSelectedIndex = ({selected, items}: any) => {
  return _.findIndex(items, (item) => (item as any).key === selected);
};

const Timeline = ({showAll, selectedColor, defaultColor, selected, items}: any) => (
  <Container>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Line color={defaultColor} />
    <Content>
      {items.map((item: any, index: any) => (
        <NavigationItem
          key={item.key}
          showAll={showAll}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          selectedIndex={getSelectedIndex({selected, items})}
          index={index}
          selectedColor={selectedColor}
          defaultColor={defaultColor}
          item={item}
        />
      ))}
    </Content>
  </Container>
);

export default Timeline;
