// Libraries
import {useCurrentGeolocation} from '@supermove/hooks';

// Logic
import {useCreatePositionForDeviceMutation} from 'modules/Device/logic';

const useLogDeviceCurrentPosition = ({deviceId}: any) => {
  const isEnabled = !!deviceId;
  const {location} = useCurrentGeolocation({
    isEnabled,
    timeout: 10, // Time out every 10 seconds if no response.
  });

  useCreatePositionForDeviceMutation({deviceId, location});
};

export default useLogDeviceCurrentPosition;
