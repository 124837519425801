// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  padding-horizontal: 10px;
`;

const BalanceName = Styled.H3`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const BalanceValue = Styled.H3`
  width: 200px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: right;
`;

const BalanceLine = Styled.View`
  margin-vertical: 20px;
  height: 5px;
  background-color: ${colors.gray.border};
`;

const BillBalanceItem = ({bill}: any) => {
  const balance = Currency.format({value: bill.project.currentAggregateBill.balance});
  return (
    <Container>
      <BalanceLine />
      <Row>
        <BalanceName>Remaining Balance</BalanceName>
        <BalanceValue>{balance}</BalanceValue>
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillBalanceItem.fragment = gql`
  ${Bill.getEstimateBalance.fragment}

  fragment BillBalanceItem on Bill {
    id
    project {
      id
      currentAggregateBill {
        balance
      }
    }
    ...Bill_getEstimateBalance
  }
`;

export default BillBalanceItem;
