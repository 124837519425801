// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, PercentInput, Space, Styled} from '@supermove/components';
import {BillItem} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import StepperInput from 'modules/App/components/StepperInput';

const SplitRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldsContainer = Styled.View`
  padding-horizontal: 20px;
`;

const FieldContainer = Styled.View`
  z-index: ${(props) => 100 - (props as any).fieldIndex};
`;

const TextInput = Styled.TextInput.H6`
`;

const QuantityButtonsRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 188px;
`;

const handleChangeCategory = ({form, customBillItemForm}: any) => {
  form.setFieldValue('billItemForm', customBillItemForm);
};

const getBillItemKindOptions = ({billId, isEnabledTbdBillItems}: any) => {
  const options = BillItem.CUSTOM_BILL_ITEMS.map((customBillItem) => ({
    value: customBillItem.displayName,
    label: customBillItem.displayName,
    customBillItemForm: BillItemForm.new({
      ...customBillItem,
      billId,
      isEnabledTbdBillItems,
      name: '',
    }),
  }));
  return options;
};

const getDecrementedValue = ({form, minQuantityField}: any) => {
  const quantity = _.toNumber(_.get(form.values, minQuantityField));
  return _.toString(quantity - 1);
};

const getIncrementedValue = ({form, minQuantityField}: any) => {
  const quantity = _.toNumber(_.get(form.values, minQuantityField));
  return _.toString(quantity + 1);
};

const BillItemKind = ({form, isEnabledTbdBillItems}: any) => {
  const {billId} = form.values.billItemForm;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldContainer fieldIndex={0}>
      <Space height={12} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={'selectedCustomBillItemForm'}
        label={'Category'}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          options: getBillItemKindOptions({billId, isEnabledTbdBillItems}),
          placeholder: 'Select a category',
          setFieldValue: form.setFieldValue,
          required: !_.get(form.values, 'billItemForm.category'),
          style: {width: '100%'}, // flex: 1 here causes the field spacing to collapse on native
          onChangeValue: (value: any, option: any) => {
            // The dropdown input on android has a 'Select a category' option, which is no option at all.
            // In that case we will reset the customBillItemForm to a new BillItemForm.
            handleChangeCategory({
              form,
              customBillItemForm: option
                ? option.customBillItemForm
                : BillItemForm.new({billId, isEnabledTbdBillItems}),
            });
          },
        }}
      />
    </FieldContainer>
  );
};

const BillItemName = ({form, field}: any) => {
  const hasFormula = !!_.get(form.values, `${field}.nameFormulaId`);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldContainer fieldIndex={1}>
      <Space height={12} />
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Name'}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          disabled: hasFormula,
          placeholder: 'Enter item name',
          required: !_.get(form.values, field),
        }}
      />
    </FieldContainer>
  );
};

const BillItemAmount = ({form, field, isEnabledTbdBillItems}: any) => {
  const hasFormula = !!_.get(form.values, `${field}.amountFormulaId`);
  const isDiscount = BillItemForm.getIsDiscount(form.values.billItemForm);
  const placeholder = isEnabledTbdBillItems
    ? `Enter a ${isDiscount ? 'negative' : ''} price`
    : isDiscount
      ? '-$100'
      : '$100';
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldContainer fieldIndex={2}>
      <Space height={12} />
      <FieldInput
        {...form}
        name={`${field}.amount`}
        label={'Price'}
        component={CurrencyInput}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          disabled: hasFormula,
          component: TextInput,
          placeholder,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
        }}
      />
    </FieldContainer>
  );
};

const BillItemPercentage = ({form, field, isEnabledTbdBillItems}: any) => {
  const hasFormula = !!_.get(form.values, `${field}.amountFormulaId`);
  const isDiscount = BillItemForm.getIsDiscount(form.values.billItemForm);
  const placeholder = isEnabledTbdBillItems
    ? `Enter ${isDiscount ? 'a negative' : 'an'} amount`
    : isDiscount
      ? '-20%'
      : '20%';
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldContainer fieldIndex={5}>
      <Space height={12} />
      <FieldInput
        {...form}
        name={`${field}.percentage`}
        label={'Amount'}
        component={PercentInput}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          disabled: hasFormula,
          component: TextInput,
          placeholder,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          required: !_.get(form.values, field),
        }}
      />
    </FieldContainer>
  );
};

const BillItemQuantity = ({form, field, isEnabledTbdBillItems}: any) => {
  const minQuantityField = `${field}.minQuantity`;
  const maxQuantityField = `${field}.maxQuantity`;
  const quantity = Number(_.get(form.values, minQuantityField));
  const hasFormula = !!_.get(form.values, `${field}.minQuantityFormulaId`);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldContainer fieldIndex={3}>
      <Space height={20} />
      <SplitRow>
        <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>Quantity</FieldInput.LabelText>
        <QuantityButtonsRow>
          <StepperInput
            form={form}
            field={minQuantityField}
            onDecrement={() =>
              form.setFieldValue(maxQuantityField, getDecrementedValue({form, minQuantityField}))
            }
            onIncrement={() =>
              form.setFieldValue(maxQuantityField, getIncrementedValue({form, minQuantityField}))
            }
            disabledField={hasFormula}
            disabledDecrement={hasFormula || quantity <= 0 || isNaN(quantity)}
            disabledIncrement={hasFormula || isNaN(quantity)}
            required={!_.get(form.values, field)}
            placeholder={isEnabledTbdBillItems ? '' : '0'}
          />
        </QuantityButtonsRow>
      </SplitRow>
    </FieldContainer>
  );
};

const EditBillItemModalFields = ({
  form,
  field,
  isAddingNewItem,
  isPreSubtotal,
  isAmount,
  isPercentage,
  isEnabledTbdBillItems,
}: any) => {
  return (
    <FieldsContainer>
      {isAddingNewItem && (
        <BillItemKind form={form} isEnabledTbdBillItems={isEnabledTbdBillItems} />
      )}
      <BillItemName form={form} field={field} />
      {isAmount && (
        <BillItemAmount form={form} field={field} isEnabledTbdBillItems={isEnabledTbdBillItems} />
      )}
      {isPercentage && (
        <BillItemPercentage
          form={form}
          field={field}
          isEnabledTbdBillItems={isEnabledTbdBillItems}
        />
      )}
      {isAmount && isPreSubtotal && (
        <BillItemQuantity form={form} field={field} isEnabledTbdBillItems={isEnabledTbdBillItems} />
      )}
      <Space height={20} />
    </FieldsContainer>
  );
};

export default EditBillItemModalFields;
