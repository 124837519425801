// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, KeyboardView, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App

// Components
import DocumentStepKind from '@shared/modules/Document/enums/DocumentStepKind';
import {
  RiskyMoveDocumentActions,
  RiskyMoveDocumentItemInput,
} from 'modules/Job/Document/RiskyMove/components';
import {CreateDocumentForm} from 'modules/Job/Document/components';
import {JobNavigation, JobPage} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.H2`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Items = Styled.View`
  margin-top: 20px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const NotToExceedSection = Styled.View`
  margin-top: 20px;
`;

const NotToExceedText = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Input = Styled.TextInput.H7`
  margin-top: 10px;
  width: 200px;
`;

const ChangeOrderDocumentJobPage = () => (
  <JobPage
    fetchPolicy={'network-only'}
    getVariables={({params}) => ({uuid: params.uuid})}
    query={ChangeOrderDocumentJobPage.query}
    navigation={(props) => <JobNavigation {...props} />}
  >
    {({navigator, params, data}) => (
      <CreateDocumentForm
        jobId={data.job.id}
        name={`Change Order for ${Job.getFullName(data.job)}`}
        template={'CHANGE_ORDER'}
        step={DocumentStepKind.DURING_MOVE}
        additionalInfo={{
          notToExceedPrice: Currency.toForm(data.job.notToExceedPrice),
          items: ['', '', ''],
        }}
        filterAdditionalInfo={(additionalInfo: any) => ({
          // Convert to a server currency value.
          notToExceedPrice: Currency.toMutation(additionalInfo.notToExceedPrice),

          // Filter out empty strings.
          items: additionalInfo.items.filter((item: any) => !!item),
        })}
        onSuccess={(document: any) => {
          navigator.navigate('SignDocumentJob', {
            uuid: params.uuid,
            documentUuid: document.uuid,
            success: params.success,
          });
        }}
        onError={(errors: any) => console.log({errors})}
      >
        {(form: any) => (
          <Container>
            <KeyboardView>
              <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
                <Content>
                  <Info>
                    <Title>Change Order</Title>
                    <Subtitle>
                      The following items have changed resulting in a change of scope.
                    </Subtitle>
                  </Info>
                  <Items>
                    {form.values.additionalInfo.items.map((item: any, index: any) => (
                      <RiskyMoveDocumentItemInput
                        key={index}
                        isFirst={index === 0}
                        isLast={index === form.values.additionalInfo.items.length - 1}
                        name={`additionalInfo.items.${index}`}
                        placeholder={'Enter a reason'}
                        index={index}
                        values={form.values}
                        setFieldValue={form.setFieldValue}
                        setFieldTouched={form.setFieldTouched}
                      />
                    ))}
                    <RiskyMoveDocumentActions addText={'Add Reason'} form={form} />
                    <NotToExceedSection>
                      <NotToExceedText>The "Not To Exceed" price is now:</NotToExceedText>
                      {/* @ts-expect-error TS(2739): Type '{ name: string; placeholder: string; value: ... Remove this comment to see the full error message */}
                      <CurrencyInput
                        name={'additionalInfo.notToExceedPrice'}
                        placeholder={'Not To Exceed Price'}
                        value={form.values.additionalInfo.notToExceedPrice}
                        component={Input}
                        setFieldValue={form.setFieldValue}
                        setFieldTouched={form.setFieldTouched}
                      />
                    </NotToExceedSection>
                  </Items>
                </Content>
              </ScrollView>
            </KeyboardView>
            <Footer>
              <Button
                loading={form.loading}
                color={colors.blue.interactive}
                onPress={form.handleSubmit}
              >
                <Text>Submit</Text>
              </Button>
            </Footer>
          </Container>
        )}
      </CreateDocumentForm>
    )}
  </JobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ChangeOrderDocumentJobPage.query = gql`
  ${JobPage.fragment}
  ${JobNavigation.fragment}
  ${Job.getFullName.fragment}

  query ChangeOrderDocumentJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      notToExceedPrice
      customer {
        id
        firstName
        lastName
      }
      ...JobPage
      ...JobNavigation
      ...Job_getFullName
    }
  }
`;

export default ChangeOrderDocumentJobPage;
