// Libraries
import React from 'react';

// App
import {Loading, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {StripeTerminalView} from '@supermove/payments';
import {Config} from '@supermove/sdk';

// Components

const StripeTerminalWrapper = ({children}: any) => (
  <Query fetchPolicy={'network-only'} query={StripeTerminalWrapper.query}>
    {({loading, data}: any) => (
      <Loading loading={loading}>
        {() => (
          <StripeTerminalView.Provider
            // @ts-expect-error TS(2769): No overload matches this call.
            host={Config.getAPIHost()}
            endpoint={'/stripe/v1/terminal_connection_token'}
            stripeAccountId={Organization.getStripeAccountId(data.viewer.organization)}
          >
            {({isInitialized}) => (
              <StripeTerminalView.Consumer isInitialized={isInitialized} children={children} />
            )}
          </StripeTerminalView.Provider>
        )}
      </Loading>
    )}
  </Query>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
StripeTerminalWrapper.query = gql`
  ${Organization.getStripeAccountId.fragment}
  query StripeTerminalWrapper {
    ${gql.query}
    viewer {
      id
      organization {
        id
        ...Organization_getStripeAccountId
      }
    }
  }
`;

export default StripeTerminalWrapper;
