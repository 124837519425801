// Libraries
import React from 'react';

// Supermove
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Json} from '@supermove/utils';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PrepareDocumentSignatureForm.defaultProps;

// @ts-expect-error TS(7022): 'PrepareDocumentSignatureForm' implicitly has type... Remove this comment to see the full error message
const PrepareDocumentSignatureForm = ({
  documentSignatureId,
  additionalInfo,
  onSuccess,
  onError,
  refetchQueries,
  children,
}: Props) => (
  <Form
    initialValues={{
      documentSignatureId,
      additionalInfo: Json.toForm(additionalInfo),
    }}
    onSubmit={(values: any) => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        awaitRefetchQueries
        variables={{
          documentSignatureId: values.documentSignatureId,
          additionalInfo: Json.toMutation(values.additionalInfo),
        }}
        mutation={PrepareDocumentSignatureForm.mutation}
        refetchQueries={refetchQueries}
        onCompleted={({prepareDocumentSignature: {documentSignature, errors}}: any) => {
          submitForm();
          setMutationErrors(errors);

          if (documentSignature) {
            onSuccess(documentSignature);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

PrepareDocumentSignatureForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrepareDocumentSignatureForm.mutation = gql`
  mutation PrepareDocumentSignatureForm(
    $documentSignatureId: Int!,
    $additionalInfo: JSONString,
  ) {
    prepareDocumentSignature(
      documentSignatureId: $documentSignatureId,
      additionalInfo: $additionalInfo,
    ) {
      ${gql.errors}
      documentSignature {
        id
      }
    }
  }
`;

export default PrepareDocumentSignatureForm;
