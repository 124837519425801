// Libraries
import {useBackgroundGeolocation} from '@supermove/hooks';

// Logic
// @ts-expect-error TS(2307): Cannot find module 'config' or its corresponding t... Remove this comment to see the full error message
import {AppInfo} from 'config';
import {useCreatePositionForDeviceMutation, useRegisterDeviceMutation} from 'modules/Device/logic';

const useWatchDevice = ({userId}: any) => {
  const {deviceId} = useRegisterDeviceMutation({
    appName: 'Crew',
    releaseBuild: AppInfo.getBuildNumber(),
    userId,
  });
  const {location} = useBackgroundGeolocation({
    timeout: 10, // Time out every 10 seconds if no response.
    distanceFilter: 100, // Every 100 meters.
  });

  useCreatePositionForDeviceMutation({deviceId, location});
};

export default useWatchDevice;
