// Libraries
import React from 'react';

// App
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Event} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';

// Components
import {PageLoadingIndicator} from 'modules/App/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';
import {LogEventForJobMutation} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  align-items: center;
  margin-vertical: 60px;
`;

const TrackButton = Styled.Button`
  height: 80px;
  width: 350px;
`;

const TrackText = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PositionCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({uuid: params.uuid})}
    query={PositionCrewJobPage.query}
  >
    {({navigator, params, loading, data}: any) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Container>
            <CrewJobProgress selected={'position'} items={CrewJobProgress.items.pre} />
            <ScrollView style={{flex: 1}}>
              <Content>
                <Title>Start tracking your location.</Title>
                <Subtitle>Notify the customer you are on the way.</Subtitle>
                <Actions>
                  <LogEventForJobMutation
                    jobId={data.job.id}
                    name={Event.MOVE_POSITION_TRACKING_STARTED}
                    onSuccess={() => navigator.navigate('LocationCrewJob', {uuid: params.uuid})}
                    onError={(errors: any) => console.log({errors})}
                  >
                    {({loading, handleSubmit}: any) => (
                      <TrackButton
                        loading={loading}
                        color={colors.blue.interactive}
                        onPress={handleSubmit}
                      >
                        <TrackText>Start Tracking</TrackText>
                      </TrackButton>
                    )}
                  </LogEventForJobMutation>
                </Actions>
              </Content>
            </ScrollView>
            <Footer>
              <Group>
                <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                  <Text>Back</Text>
                </Button>
              </Group>
            </Footer>
          </Container>
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PositionCrewJobPage.query = gql`
  ${CrewJobPage.fragment}

  query PositionCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
        firstName
        lastName
        phoneNumber
        email
      }
      ...CrewJobPage
    }
  }
`;

export default PositionCrewJobPage;
