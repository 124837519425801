// Libraries
import React from 'react';

// Supermove
import {Loading, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useIsFocused, useNavigation} from '@supermove/hooks';

// Components
import JobInProgressBanner from './JobInProgressBanner';

const getView = ({isCurrent, view}: any) => {
  // Special case for when we normally show the "Tap to view" banner BUT we're on the current job
  // already. In this case, we show the employee mode.
  return isCurrent && view === 'NONE' ? 'EMPLOYEE' : view;
};

const hasCurrentJob = (data: any) => {
  // HACK(mark): Protects against network errors when `data` is undefined.
  return !!data && !!data.viewer && !!data.viewer.currentJob;
};

const ViewerJobBannerContent = ({loading, data, refetch, view}: any) => {
  const {params} = useNavigation();

  useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Loading loading={loading}>
      {() =>
        hasCurrentJob(data) ? (
          <JobInProgressBanner
            view={getView({isCurrent: params.uuid === data.viewer.currentJob.uuid, view})}
            job={data.viewer.currentJob}
          />
        ) : null
      }
    </Loading>
  );
};

type OwnViewerJobBannerProps = {
  view?: string;
};

// @ts-expect-error TS(2456): Type alias 'ViewerJobBannerProps' circularly refer... Remove this comment to see the full error message
type ViewerJobBannerProps = OwnViewerJobBannerProps & typeof ViewerJobBanner.defaultProps;

// @ts-expect-error TS(7022): 'ViewerJobBanner' implicitly has type 'any' becaus... Remove this comment to see the full error message
const ViewerJobBanner = ({view}: ViewerJobBannerProps) => (
  <Query fetchPolicy={'network-only'} query={ViewerJobBanner.query}>
    {({loading, data, refetch}: any) => (
      <ViewerJobBannerContent loading={loading} data={data} refetch={refetch} view={view} />
    )}
  </Query>
);

ViewerJobBanner.defaultProps = {
  view: 'NONE',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewerJobBanner.query = gql`
  ${JobInProgressBanner.fragment}

  query ViewerJobBanner {
    ${gql.query}
    viewer {
      id
      currentJob {
        id
        uuid
        ...JobInProgressBanner
      }
    }
  }
`;

export default ViewerJobBanner;
