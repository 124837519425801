// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Document} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import JobStepKind from '@shared/modules/Job/enums/JobStepKind';

// --------------------------------------------------
// Project Billing
// --------------------------------------------------

/**
 * Navigates to the next post-move document that the customer needs to sign.
 * If all documents have been signed, we navigate to the "finish" screen.
 * This method is specifically tailored for when project billing is enabled.
 */

/**
 * Navigates to the next post-move document that the customer needs to sign.
 * If all documents have been signed, we navigate to the "finish" screen.
 * This method is specifically tailored for when project billing is enabled.
 */

const _findIncompleteDocument = withFragment(
  (documents: any[]) => {
    return _.find(
      documents,
      (document) => !(document as any).isCompleted && !(document as any).isSkipped,
    );
  },
  gql`
    fragment _findIncompleteDocument on Document {
      id
      isCompleted
      isSkipped
    }
  `,
);

const _goToSignDocument = ({navigator, documentUuid, jobUuid, step}: any) => {
  // Explicitly calling `push` will generate a new `navigation.state.key` which
  // will properly trigger a re-render.
  navigator.push('SignDocumentV2CustomerJobPage', {jobUuid, documentUuid, step});
};

const navigateToNextIncompleteDocumentForPostMoveProjectBilling = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    const incompleteDocument = _.find(job.documentsV2PostMove, (document) => {
      return !document.isCompleted && !document.isSkipped;
    });

    if (incompleteDocument) {
      // Explicitly calling `push` will generate a new `navigation.state.key` which
      // properly triggers a re-render.
      return navigator.push('SignDocumentV2CustomerJobPage', {
        jobUuid: job.uuid,
        documentUuid: incompleteDocument.uuid,
        step: Document.formatStepAsParam('POST_MOVE'),
      });
    }

    // We want to show after move payroll timesheets if enabled
    if (
      job.project.projectType.features.timesheetsV2 &&
      job.hasJobStepCrewAfterMovePayrollTimesheets
    ) {
      return navigator.navigate('ShowJob', {uuid: job.uuid});
    }
    // All billing is handled, navigate to the finish page.
    return navigator.navigate('PassFinishCustomerJob', {uuid: job.uuid});
  },
  // If changing this fragment in this function, then also change the fragment in useFinalizePaymentMutation
  gql`
    fragment Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling on Job {
      uuid
      hasJobStepCrewAfterMovePayrollTimesheets: hasJobStep(
        kind: "CREW_AFTER_MOVE_PAYROLL_TIMESHEET"
      )
      documentsV2PostMove: documentsV2ForStep(step: "POST_MOVE") {
        id
        uuid
        isCompleted
        isSkipped
      }
      project {
        id
        projectType {
          id
          features {
            timesheetsV2
          }
        }
      }
    }
  `,
);

const navigateToPostMoveBilling = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    // Currently, we only proceed to billing if the rolling jobs bill is enabled OR this is the
    // last active job.
    const shouldHandlePostMoveBilling =
      job.hasJobStepCrewPostMoveBilling &&
      (job.project.projectType.isJobLevelBilling || job.isLastActiveJob);

    // If payment has already been made in full, we can skip as well.
    if (shouldHandlePostMoveBilling && !job.hasJobEventPostMovePaymentsFinished) {
      return navigator.navigate('ReviewBillingProjectCustomerJobPage', {
        uuid: job.uuid,
      });
    }

    // Navigate to the next document to complete and sign.
    return navigateToNextIncompleteDocumentForPostMoveProjectBilling({navigator, job});
  },
  gql`
    ${navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}

    fragment Navigation_navigateToPostMoveBilling on Job {
      uuid
      isLastActiveJob
      hasJobStepCrewPostMoveBilling: hasJobStep(kind: "CREW_POST_MOVE_BILLING")
      project {
        id
        projectType {
          id
          isJobLevelBilling
        }
      }
      ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
    }
  `,
);

const navigateToPostMovePayments = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    // If enabled, make payments after reviewing the bill.
    if (job.hasJobStepCrewPostMovePayments) {
      return navigator.navigate('NewPaymentBillingProjectJobPage', {
        jobUuid: job.uuid,
        step: 'CREW_POST_MOVE_PAYMENTS',
      });
    }

    // Otherwise, navigate to the next document to complete and sign.
    return navigateToNextIncompleteDocumentForPostMoveProjectBilling({navigator, job});
  },
  gql`
    ${navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}

    fragment Navigation_navigateToPostMovePayments on Job {
      uuid
      isLastActiveJob
      hasJobStepCrewPostMovePayments: hasJobStep(kind: "CREW_POST_MOVE_PAYMENTS")
      ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
    }
  `,
);

/**
 * Navigates to the signature page if the bill is complete, otherwise goes back
 * to the payment page to submit another payment.
 */
const _navigateFromBillSuccessPostMovePaymentsProjectBilling = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, params, bill }: { ... Remove this comment to see the full error message
  ({navigator, params, bill}) => {
    const jobUuid = params.jobUuid || params.uuid;
    const job = _.find(bill.project.activeMoveJobs, (job) => job.uuid === jobUuid);

    // If we're not done with payments, navigate back to the payments page.
    if (!job.hasJobEventPostMovePaymentsFinished) {
      return navigator.navigate('NewPaymentBillingProjectJobPage', {
        jobUuid: job.uuid,
        step: 'CREW_POST_MOVE_PAYMENTS',
      });
    }

    return navigateToNextIncompleteDocumentForPostMoveProjectBilling({navigator, job});
  },
  // If changing this fragment in this function, then also change the fragment in useFinalizePaymentMutation
  gql`
    ${navigateToNextIncompleteDocumentForPostMoveProjectBilling.fragment}

    fragment Navigation_navigateFromBillSuccessPostMovePaymentsProjectBilling on Bill {
      id
      project {
        id
        activeMoveJobs {
          id
          uuid
          hasJobEventPostMovePaymentsFinished: hasJobEvent(kind: "POST_MOVE_PAYMENTS_FINISHED")
          ...Navigation_navigateToNextIncompleteDocumentForPostMoveProjectBilling
        }
      }
    }
  `,
);

// --------------------------------------------------
// Documents
// --------------------------------------------------
const navigateFromPreMovePrepareDocument = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, document }: { navi... Remove this comment to see the full error message
  ({navigator, document}) => {
    if (document.isCommercialBillOfLading) {
      return navigator.navigate('StartTimeCommercialShowDocumentCrewJob', {
        uuid: document.job.uuid,
        documentUuid: document.uuid,
      });
    }

    const index = _.findIndex(document.preMoveFilteredDocumentSignatures, (documentSignature) => {
      return (documentSignature as any).isCrewPrepareRequired;
    });

    // Navigate directly to the DocumentSignature prepare page.
    return navigator.push('PreparePreMoveShowDocumentSignatureCrewJob', {
      uuid: document.job.uuid,
      documentUuid: document.uuid,
      index,
    });
  },
  gql`
    fragment Navigation_navigateFromPreMovePrepareDocument on Document {
      id
      uuid
      isCommercialBillOfLading
      job {
        id
        uuid
      }
      preMoveFilteredDocumentSignatures: filteredDocumentSignatures(step: "PRE_MOVE") {
        id
        isCrewPrepareRequired
      }
    }
  `,
);

const navigateFromPreMovePrepareDocumentSignature = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    const document = _.find(job.preMoveFilteredDocuments, (document) => {
      return !document.isPreMoveCrewStepPrepared;
    });

    if (!document) {
      // When there are no more unprepared documents, we navigate as if we are done.
      return navigator.navigate('PassStartCrewJob', {uuid: job.uuid});
    }

    return navigateFromPreMovePrepareDocument({navigator, document});
  },
  gql`
    ${navigateFromPreMovePrepareDocument.fragment}

    fragment Navigation_navigateFromPreMovePrepareDocumentSignature on Job {
      uuid
      preMoveFilteredDocuments: filteredDocuments(step: "PRE_MOVE") {
        id
        isPreMoveCrewStepPrepared
        ...Navigation_navigateFromPreMovePrepareDocument
      }
    }
  `,
);

const navigateToPreMovePrepareDocuments = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    navigateFromPreMovePrepareDocumentSignature({navigator, job});
  },
  gql`
    ${navigateFromPreMovePrepareDocumentSignature.fragment}

    fragment Navigation_navigateToPreMovePrepareDocuments on Job {
      ...Navigation_navigateFromPreMovePrepareDocumentSignature
    }
  `,
);

const navigateFromPostMovePrepareDocuments = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    // Check for the crew step flag and that a bill exists
    if (job.hasJobStepCrewPostMoveBilling) {
      // If we have a bill, figure out which screen to navigate to so the crew can prepare it.
      if (job.organization.settings.jobRates.length === 0) {
        // Skip the rates page and go straight to the Bill.
        return navigator.navigate('BillingProjectCrewJobPage', {uuid: job.uuid});
      }

      // Otherwise, go to the rates page.
      return navigator.navigate('RatesCrewJob', {uuid: job.uuid});
    }

    const incompleteDocument = _findIncompleteDocument(job.documentsV2PostMove);
    if (incompleteDocument) {
      // Go to page that instructs crew to pass the tablet to customer for post move sequence
      return navigator.navigate('PassFinishCrewJob', {uuid: job.uuid});
    }

    // If no bill, AND no documents, there's nothing for the customer to do.
    return navigator.navigate('ShowJob', {uuid: job.uuid});
  },
  gql`
    ${_findIncompleteDocument.fragment}
    fragment Navigation_navigateFromPostMovePrepareDocuments on Job {
      uuid
      hasJobStepCrewPostMoveBilling: hasJobStep(kind: "CREW_POST_MOVE_BILLING")
      organization {
        id
        settings {
          id
          jobRates {
            name
          }
        }
      }
      documentsV2PostMove: documentsV2ForStep(step: "POST_MOVE") {
        id
        ..._findIncompleteDocument
      }
    }
  `,
);

const navigateFromPostMovePrepareDocument = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, document }: { navi... Remove this comment to see the full error message
  ({navigator, document}) => {
    const index = _.findIndex(document.postMoveFilteredDocumentSignatures, (documentSignature) => {
      return !(documentSignature as any).isPrepared;
    });

    // Navigate directly to the DocumentSignature prepare page.
    return navigator.push('PreparePostMoveShowDocumentSignatureCrewJob', {
      uuid: document.job.uuid,
      documentUuid: document.uuid,
      index,
    });
  },
  gql`
    fragment Navigation_navigateFromPostMovePrepareDocument on Document {
      id
      uuid
      job {
        id
        uuid
      }
      postMoveFilteredDocumentSignatures: filteredDocumentSignatures(step: "POST_MOVE") {
        id
        isPrepared
      }
    }
  `,
);

const navigateFromPostMovePrepareDocumentSignature = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    const incompleteDocument = _findIncompleteDocument(job.documentsV2PostMove);
    if (incompleteDocument) {
      return _goToSignDocument({
        navigator,
        jobUuid: job.uuid,
        documentUuid: (incompleteDocument as any).uuid,
        step: Document.formatStepAsParam('POST_MOVE'),
      });
    }

    return navigateFromPostMovePrepareDocuments({navigator, job});
  },
  gql`
    ${_findIncompleteDocument.fragment}
    ${navigateFromPostMovePrepareDocuments.fragment}

    fragment Navigation_navigateFromPostMovePrepareDocumentSignature on Job {
      uuid
      documentsV2PostMove: documentsV2ForStep(step: "POST_MOVE") {
        id
        uuid
        ..._findIncompleteDocument
      }
      ...Navigation_navigateFromPostMovePrepareDocuments
    }
  `,
);

/**
 * Navigates to the next pre-move document that the customer needs to sign.
 * If all documents have been signed, we navigate to the "pass" screen.
 */
const navigateToNextIncompleteDocumentForPreMove = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    const incompleteDocument = _.find(job.documentsV2PreMove, (document) => {
      return !document.isCompleted && !document.isSkipped;
    });

    if (incompleteDocument) {
      // Explicitly calling `push` will generate a new `navigation.state.key` which
      // properly triggers a re-render.
      return navigator.push('SignDocumentV2CustomerJobPage', {
        jobUuid: job.uuid,
        documentUuid: incompleteDocument.uuid,
        step: Document.formatStepAsParam('PRE_MOVE'),
      });
    }

    return navigator.navigate('PassStartCustomerJob', {uuid: job.uuid});
  },
  gql`
    fragment Navigation_navigateToNextIncompleteDocumentForPreMove on Job {
      uuid
      documentsV2PreMove: documentsV2ForStep(step: "PRE_MOVE") {
        id
        uuid
        isCompleted
        isSkipped
      }
    }
  `,
);

/**
 * Navigates to the next after-timesheet document that the customer needs to sign.
 * If all documents have been signed, we navigate to the start of the "post-move" flow.
 */
const navigateToNextIncompleteDocumentForAfterTimesheet = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    const incompleteDocument = _.find(job.documentsV2AfterTimesheet, (document) => {
      return !document.isCompleted && !document.isSkipped;
    });

    if (incompleteDocument) {
      // Explicitly calling `push` will generate a new `navigation.state.key` which
      // properly triggers a re-render.
      return navigator.push('SignDocumentV2CustomerJobPage', {
        jobUuid: job.uuid,
        documentUuid: incompleteDocument.uuid,
        step: Document.formatStepAsParam('AFTER_TIMESHEET'),
      });
    }

    // If no other documents, go straight into the post-move flow start.
    navigateFromPostMovePrepareDocuments({navigator, job});
  },
  gql`
    ${navigateFromPostMovePrepareDocuments.fragment}

    fragment Navigation_navigateToNextIncompleteDocumentForAfterTimesheet on Job {
      uuid
      documentsV2AfterTimesheet: documentsV2ForStep(step: "AFTER_TIMESHEET") {
        id
        uuid
        isCompleted
        isSkipped
      }
      ...Navigation_navigateFromPostMovePrepareDocuments
    }
  `,
);

// --------------------------------------------------
// Billing / Payments
// --------------------------------------------------

/**
 * Navigates back to the payment page if we still have a balance, otherwise, navigates
 * to the first unsigned document.
 */
const _navigateFromBillSuccessPreMovePayments = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, params, bill }: { ... Remove this comment to see the full error message
  ({navigator, params, bill}) => {
    if (bill.hasPreMoveBalance) {
      return navigator.navigate('PaymentPreMoveBillCustomerJob', {
        uuid: params.uuid,
        billUuid: params.billUuid,
      });
    }

    navigateToNextIncompleteDocumentForPreMove({navigator, job: bill.job});
  },
  gql`
    ${navigateToNextIncompleteDocumentForPreMove.fragment}

    fragment Navigation_navigateFromBillSuccessPreMovePayments on Bill {
      id
      hasPreMoveBalance
      job {
        id
        ...Navigation_navigateToNextIncompleteDocumentForPreMove
      }
    }
  `,
);

const navigateFromBillSuccess = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, params, bill }: { ... Remove this comment to see the full error message
  ({navigator, params, bill}) => {
    switch (params.step) {
      case 'CREW_PRE_MOVE_PAYMENTS':
        return _navigateFromBillSuccessPreMovePayments({navigator, params, bill});
      case 'CREW_POST_MOVE_PAYMENTS':
        return _navigateFromBillSuccessPostMovePaymentsProjectBilling({navigator, params, bill});
      case 'PROJECT_BILLING_ADDITIONAL_PAYMENT':
        return navigator.navigate('ShowJob', {uuid: params.jobUuid});
      default:
        return;
    }
  },
  // If changing this fragment in this function, then also change the fragment in useFinalizePaymentMutation
  gql`
    ${_navigateFromBillSuccessPostMovePaymentsProjectBilling.fragment}
    ${_navigateFromBillSuccessPreMovePayments.fragment}

    fragment Navigation_navigateFromBillSuccess on Bill {
      id
      ...Navigation_navigateFromBillSuccessPostMovePaymentsProjectBilling
      ...Navigation_navigateFromBillSuccessPreMovePayments
    }
  `,
);

const navigateFromPaymentSuccess = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, params, payment }:... Remove this comment to see the full error message
  ({navigator, params, payment}) => {
    navigateFromBillSuccess({navigator, params, bill: payment.bill});
  },
  // If changing this fragment in this function, then also change the fragment in useFinalizePaymentMutation
  gql`
    ${navigateFromBillSuccess.fragment}

    fragment Navigation_navigateFromPaymentSuccess on Payment {
      id
      bill {
        id
        ...Navigation_navigateFromBillSuccess
      }
    }
  `,
);

// --------------------------------------------------
// Customer
// --------------------------------------------------
const navigateToPostMoveCustomerStart = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    if (!_.isEmpty(job.documentsV2ForStep)) {
      return navigator.navigate('PostMoveListDocumentV2CustomerJobPage', {jobUuid: job.uuid});
    }
    // If there are 0 POST_MOVE documents, navigate to billing.
    navigateToPostMoveBilling({navigator, job});
  },
  gql`
    ${navigateToPostMoveBilling.fragment}

    fragment Navigation_navigateToPostMoveCustomerStart on Job {
      uuid
      documentsV2ForStep(step: "POST_MOVE") {
        id
      }
      ...Navigation_navigateToPostMoveBilling
    }
  `,
);

// --------------------------------------------------
// Timesheet
// --------------------------------------------------
const navigateFromTimesheetSuccess = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job }: { navigator... Remove this comment to see the full error message
  ({navigator, job}) => {
    if (!job.hasJobEventAfterTimesheetDocumentsCompleted && !_.isEmpty(job.documentsV2ForStep)) {
      return navigator.navigate('AfterTimesheetListDocumentV2CustomerJobPage', {
        jobUuid: job.uuid,
      });
    }
    navigateFromPostMovePrepareDocuments({navigator, job});
  },
  gql`
    ${navigateFromPostMovePrepareDocuments.fragment}

    fragment Navigation_navigateFromTimesheetSuccess on Job {
      id
      uuid
      hasJobEventAfterTimesheetDocumentsCompleted: hasJobEvent(
        kind: "AFTER_TIMESHEET_DOCUMENTS_COMPLETED"
      )
      documentsV2ForStep(step: "AFTER_TIMESHEET") {
        id
      }
      ...Navigation_navigateFromPostMovePrepareDocuments
    }
  `,
);

// --------------------------------------------------
// Trucks
// --------------------------------------------------

/**
 * Navigates to the Position screen if the step is enabled, otherwise goes to the Location
 * screen to direct the crew to where to go.
 */
const navigateFromTruckSuccess = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, params, job }: { n... Remove this comment to see the full error message
  ({navigator, params, job}) => {
    if (job.hasJobStepCrewLocationStartTracking) {
      navigator.navigate('PositionCrewJob', {uuid: params.uuid});
    } else {
      navigator.navigate('LocationCrewJob', {uuid: params.uuid});
    }
  },
  gql`
    fragment Navigation_navigateFromTruckSuccess on Job {
      id
      hasJobStepCrewLocationStartTracking: hasJobStep(kind: "CREW_LOCATION_START_TRACKING")
    }
  `,
);

const navigateToPreMoveStep = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ navigator, job, step }: { nav... Remove this comment to see the full error message
  ({navigator, job, step}) => {
    if (step === JobStepKind.CREW_PRE_MOVE_BILLABLE_TIMESHEET) {
      navigator.navigate('JobTimesheetPreMoveCrewJob', {uuid: job.uuid});
    } else if (step === JobStepKind.CREW_PRE_MOVE_PAYROLL_TIMESHEET) {
      navigator.navigate('CrewTimesheetPreMoveCrewJob', {uuid: job.uuid});
    } else {
      navigator.navigate('ShowJob', {uuid: job.uuid});
    }
  },
  gql`
    fragment Navigation_navigateToPreMoveStep on Job {
      id
      uuid
    }
  `,
);

const isMainFlowPayment = ({params}: any) => {
  return params.step === 'CREW_POST_MOVE_PAYMENTS';
};

const Navigation = {
  // Billing / Payments
  navigateFromBillSuccess,
  navigateFromPaymentSuccess,
  navigateToPostMoveBilling,

  // Customer
  navigateToPostMoveCustomerStart,

  // Documents
  navigateFromPreMovePrepareDocument,
  navigateFromPreMovePrepareDocumentSignature,
  navigateToNextIncompleteDocumentForPreMove,
  navigateToPreMovePrepareDocuments,

  navigateFromPostMovePrepareDocument,
  navigateFromPostMovePrepareDocuments,
  navigateFromPostMovePrepareDocumentSignature,
  navigateToNextIncompleteDocumentForAfterTimesheet,
  navigateToPostMovePayments,
  navigateToNextIncompleteDocumentForPostMoveProjectBilling,

  // Timesheet
  navigateFromTimesheetSuccess,

  // Trucks
  navigateFromTruckSuccess,

  // Getters
  isMainFlowPayment,

  // PreMove
  navigateToPreMoveStep,
};

export default Navigation;
