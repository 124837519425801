// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Bill, BillModifier} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 7px;
  padding-horizontal: 10px;
`;

const Info = Styled.View`
  flex: 1;
`;

const Name = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Description = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const Value = Styled.H5`
  width: 300px;
  ${fontWeight(700)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isDiscount ? colors.green.status : colors.gray.primary)};
  text-align: right;
`;

const TotalName = Styled.H3`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const TotalValue = Styled.H3`
  width: 300px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: right;
`;

const TotalLine = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 10px;
  height: 5px;
  background-color: ${colors.gray.border};
`;

const BillTotalItem = ({isEstimate, bill}: any) => {
  if (!bill.isSubtotalAvailable && !bill.hasBillModifiers) {
    return null;
  }

  return (
    <Container>
      {bill.isSubtotalAvailable && bill.isSubtotalDifferent && (
        <React.Fragment>
          <Row>
            <Info>
              <Name>Subtotal</Name>
            </Info>
            <Value>{Bill.getEstimateSubtotal(bill)}</Value>
          </Row>
        </React.Fragment>
      )}
      {bill.salesTaxAmount > 0 && (
        <Row>
          <Info>
            <Name>{`Sales Tax (${Percent.display(bill.project.salesTaxRate)})`}</Name>
          </Info>
          <Value>{Bill.getEstimateSalesTaxAmount(bill)}</Value>
        </Row>
      )}
      {bill.discount < 0 && (
        <Row>
          <Info>
            <Name>{`Discount (${Percent.display(-bill.discountPercentage)})`}</Name>
          </Info>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Value vars={{isDiscount: true}}>{Bill.getEstimateDiscount(bill)}</Value>
        </Row>
      )}
      {bill.isPaid && bill.tip > 0 && (
        <Row>
          <Info>
            <Name>Tip</Name>
          </Info>
          <Value>{Currency.display(bill.tip)}</Value>
        </Row>
      )}
      {bill.billModifiers.map((billModifier: any, index: any) => (
        <Row key={index}>
          <Info>
            <Name numberOfLines={2}>{BillModifier.getDisplayName(billModifier)}</Name>
            <Description>{billModifier.description}</Description>
          </Info>
          <Value>
            {BillModifier.getDisplayTotal(billModifier, {
              isSubtotalAvailable: bill.isSubtotalAvailable,
            })}
          </Value>
        </Row>
      ))}
      <TotalLine />
      <Row>
        <TotalName>{isEstimate ? 'Estimated Total' : 'Total'}</TotalName>
        <TotalValue>{bill.isEstimateAvailable ? Bill.getEstimateTotal(bill) : 'TBD'}</TotalValue>
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillTotalItem.fragment = gql`
  ${Bill.getEstimateDiscount.fragment}
  ${Bill.getEstimateSalesTaxAmount.fragment}
  ${Bill.getEstimateSubtotal.fragment}
  ${Bill.getEstimateTotal.fragment}
  ${BillModifier.getDisplayName.fragment}
  ${BillModifier.getDisplayTotal.fragment}

  fragment BillTotalItem on Bill {
    id
    hasBillModifiers
    isEstimateAvailable
    isSubtotalAvailable
    isSubtotalDifferent
    isPaid
    discountPercentage
    discount
    salesTaxAmount
    tip
    billModifiers {
      description
      ...BillModifier_getDisplayName
      ...BillModifier_getDisplayTotal
    }
    project {
      id
      salesTaxRate
    }
    ...Bill_getEstimateDiscount
    ...Bill_getEstimateSalesTaxAmount
    ...Bill_getEstimateSubtotal
    ...Bill_getEstimateTotal
  }
`;

export default BillTotalItem;
