// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ConfirmCrewSlotsForJobForm from '@shared/modules/Dispatch/forms/ConfirmCrewSlotsForJobForm';

const useConfirmCrewSlotsForJobMutation = ({
  confirmCrewSlotsForJobForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      confirmCrewSlotsForJobForm: ConfirmCrewSlotsForJobForm.toForm(confirmCrewSlotsForJobForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useConfirmCrewSlotsForJobMutation.mutation,
    variables: {
      confirmCrewSlotsForJobForm: ConfirmCrewSlotsForJobForm.toMutation(
        form.values.confirmCrewSlotsForJobForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useConfirmCrewSlotsForJobMutation.mutation = gql`
  mutation useConfirmCrewSlotsForJobMutation(
    $confirmCrewSlotsForJobForm: ConfirmCrewSlotsForJobForm!
  ) {
    response: confirmCrewSlotsForJob(confirmCrewSlotsForJobForm: $confirmCrewSlotsForJobForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useConfirmCrewSlotsForJobMutation;
