// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import ConfirmJobUsersForJobForm from '@shared/modules/Dispatch/forms/ConfirmJobUsersForJobForm';
import useConfirmJobUsersForJobMutation from '@shared/modules/Dispatch/hooks/useConfirmJobUsersForJobMutation';
import PageActionFooter from 'modules/App/components/PageActionFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AssignMoversCrews from 'modules/Job/Crew/Timesheet/components/AssignMoversCrews';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Mobile.PageHeading}
`;

const DispatchNotesBox = Styled.View`
  padding: 12px;
  background-color: ${colors.gray.border}
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.tertiary}
`;

const DispatchNotesText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const EmptyNotesText = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const DispatchNotes = ({job}: any) => {
  const [showNotes, setShowNotes] = useState(true);

  return (
    <React.Fragment>
      <Row>
        {showNotes && <DispatchNotesText>Dispatch Notes</DispatchNotesText>}
        <Space style={{flex: 1}} />
        <TertiaryButton
          text={`${showNotes ? 'Hide' : 'Show'} Notes`}
          isResponsive
          onPress={() => setShowNotes(!showNotes)}
        />
      </Row>
      <Space height={8} />
      {showNotes && (
        <React.Fragment>
          <DispatchNotesBox>
            {job.dispatchNotes ? (
              <DispatchNotesText>{job.dispatchNotes}</DispatchNotesText>
            ) : (
              <EmptyNotesText>No notes provided</EmptyNotesText>
            )}
          </DispatchNotesBox>
          <Space height={24} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const AssignMoversPageContent = ({job, viewerId, navigator}: any) => {
  const showConfirmBackModal = useModal();
  const {form, submitting, handleSubmit} = useConfirmJobUsersForJobMutation({
    confirmJobUsersForJobForm: ConfirmJobUsersForJobForm.initialize(job),
    onSuccess: () => navigator.goBack(),
    onError: (errors: any) => console.log({errors}),
  });

  const field = 'confirmJobUsersForJobForm';

  return (
    <React.Fragment>
      <ScrollView
        contentContainerStyle={{paddingHorizontal: 24}}
        showsVerticalScrollIndicator={false}
      >
        <Space height={24} />
        <HeaderText>Assign Movers</HeaderText>
        <Space height={24} />
        <DispatchNotes job={job} />
        <AssignMoversCrews crews={job.crews} viewerId={viewerId} form={form} field={field} />
        <Space height={24} />
      </ScrollView>
      <PageActionFooter
        primaryActionText={'Save'}
        handlePrimaryAction={handleSubmit}
        isSubmitting={submitting}
        handleBack={() => {
          if (form.dirty) {
            showConfirmBackModal.handleOpen();
          } else {
            navigator.goBack();
          }
        }}
      />
      <CautionModal
        isOpen={showConfirmBackModal.isOpen}
        title={'Discard changes?'}
        message={'There are unsaved changes on this page. Leaving will discard these changes.'}
        handlePrimaryAction={() => navigator.goBack()}
        handleSecondaryAction={showConfirmBackModal.handleClose}
        primaryActionText={'Confirm'}
        secondaryActionText={'Cancel'}
      />
    </React.Fragment>
  );
};

const AssignMoversPage = () => {
  const {params, navigator} = useNavigation();
  const {loading, data, refetch} = useQuery(AssignMoversPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.uuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <AssignMoversPageContent
              job={data.job}
              viewerId={data.viewer.id}
              navigator={navigator}
            />
          );
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignMoversPage.query = gql`
  ${AssignMoversCrews.fragment}
  ${ConfirmJobUsersForJobForm.initialize.fragment}

  query AssignMoversPage($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    job(uuid: $jobUuid) {
      id
      dispatchNotes
      crews {
        id
        ...AssignMoversCrews
      }
      ...ConfirmJobUsersForJobForm_initialize
    }
  }
`;

export default AssignMoversPage;
