// Libraries
import React from 'react';

// App
import {Form, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';

// Components

const getInitialTimeRanges = ({move, initialTimeRanges}: any) => {
  if (move.timeRanges && move.timeRanges.length > 0) {
    return move.timeRanges.map((timeRange: any) => TimeRange.toForm(timeRange));
  }

  return initialTimeRanges;
};

type OwnEmployeeMoveTimesFormProps = {
  initialTimeRanges?: any[];
  refetchQueries?: any[];
};

// @ts-expect-error TS(2456): Type alias 'EmployeeMoveTimesFormProps' circularly... Remove this comment to see the full error message
type EmployeeMoveTimesFormProps = OwnEmployeeMoveTimesFormProps &
  typeof EmployeeMoveTimesForm.defaultProps;

// @ts-expect-error TS(7022): 'EmployeeMoveTimesForm' implicitly has type 'any' ... Remove this comment to see the full error message
const EmployeeMoveTimesForm = ({
  move,
  initialTimeRanges,
  refetchQueries,
  onSuccess,
  onError,
  children,
}: EmployeeMoveTimesFormProps) => (
  <Form
    initialValues={{
      moveId: move.id,
      timeRanges: getInitialTimeRanges({move, initialTimeRanges}),
    }}
    onSubmit={(values: any) => {}}
  >
    {({setMutationErrors, submitForm, ...form}: any) => (
      <Mutation
        // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
        awaitRefetchQueries
        refetchQueries={refetchQueries}
        variables={{
          moveId: form.values.moveId,
          timeRanges: form.values.timeRanges.map((timeRange: any) =>
            TimeRange.toMutation(timeRange),
          ),
        }}
        mutation={EmployeeMoveTimesForm.mutation}
        onCompleted={({trackTimeForMove: {move, errors}}: any) => {
          submitForm();
          setMutationErrors(errors);

          if (move) {
            onSuccess(move);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit: any, {loading, error}: any) => children({...form, loading, handleSubmit})}
      </Mutation>
    )}
  </Form>
);

EmployeeMoveTimesForm.defaultProps = {
  initialTimeRanges: [],
  refetchQueries: [],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeMoveTimesForm.fragment = gql`
  fragment EmployeeMoveTimesForm on Move {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

EmployeeMoveTimesForm.mutation = gql`
  mutation EmployeeMoveTimesForm(
    $moveId: Int!,
    $timeRanges: [TimeRangeInput]!,
  ) {
    trackTimeForMove(
      moveId: $moveId,
      timeRanges: $timeRanges,
    ) {
      ${gql.errors}
      move {
        id
      }
    }
  }
`;

export default EmployeeMoveTimesForm;
