// Libaries
import React from 'react';

// Supermove
import {Device} from '@supermove/sdk';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

interface PermissionDeniedModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const PermissionDeniedModal = ({isOpen, handleClose}: PermissionDeniedModalProps) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={
        'Supermove is unable to continue without the camera and file storage permissions. Please grant these permissions in the settings.'
      }
      primaryActionText={'Open Settings'}
      secondaryActionText={'Close'}
      handlePrimaryAction={() => {
        Device.openSettings();
        handleClose();
      }}
      handleSecondaryAction={handleClose}
      handlePressOutside={handleClose}
      isMobileSheet={false}
    />
  );
};

export default PermissionDeniedModal;
