// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Navigation from 'core/Navigation';
import {
  EmployeeMoveTimesForm,
  StartTimeErrorModal,
  StartTimeItem,
} from 'modules/Job/Crew/Timesheet/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Times = Styled.View`
  margin-top: 40px;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const StartTimeCommercialShowDocumentCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({uuid: params.uuid})}
    query={StartTimeCommercialShowDocumentCrewJobPage.query}
  >
    {({navigator, params, loading, data, refetch}: any) => (
      <Loading loading={loading}>
        {() => {
          const startTime = Job.getStartTimeAt(data.job);

          return (
            <StartTimeErrorModal
              trigger={({handleOpen}: any) => (
                <EmployeeMoveTimesForm
                  initialTimeRanges={[
                    {
                      name: 'Work Time',
                      kind: 'WORK',
                      start: Datetime.toTime(startTime, Datetime.FORM_TIME),
                      end: '',
                    },
                  ]}
                  move={data.job.move}
                  onSuccess={() => {
                    Navigation.navigateFromPreMovePrepareDocumentSignature({
                      navigator,
                      job: data.job,
                    });
                  }}
                  onError={() => handleOpen()}
                >
                  {(form: any) => (
                    <Container>
                      <CrewJobProgress selected={'start'} items={CrewJobProgress.items.start} />
                      <ScrollView style={{flex: 1}}>
                        <Content>
                          <Title>Set Start Time</Title>
                          <Subtitle>Enter the time this move will officially start.</Subtitle>
                          <Times>
                            <StartTimeItem
                              timeRange={_.first(form.values.timeRanges)}
                              form={form}
                            />
                          </Times>
                        </Content>
                      </ScrollView>
                      <JobFooter>
                        <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                          <Text>Back</Text>
                        </Button>
                        <Button
                          loading={form.loading}
                          color={colors.blue.interactive}
                          onPress={form.handleSubmit}
                        >
                          <Text>Submit</Text>
                        </Button>
                      </JobFooter>
                    </Container>
                  )}
                </EmployeeMoveTimesForm>
              )}
            />
          );
        }}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
StartTimeCommercialShowDocumentCrewJobPage.query = gql`
  ${Job.getStartTimeAt.fragment}
  ${Navigation.navigateFromPreMovePrepareDocumentSignature.fragment}
  ${CrewJobPage.fragment}
  ${EmployeeMoveTimesForm.fragment}

  query StartTimeCommercialShowDocumentCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      move {
        id
        ...EmployeeMoveTimesForm
      }
      ...Job_getStartTimeAt
      ...Navigation_navigateFromPreMovePrepareDocumentSignature
      ...CrewJobPage
    }
  }
`;

export default StartTimeCommercialShowDocumentCrewJobPage;
