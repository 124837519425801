// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, FlatList} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {JobModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Collection, Datetime, Duration} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button/SecondaryButton';
import TimesheetBillableEntry from '@shared/modules/Timesheet/components/TimesheetBillableEntry';
import {
  TimesheetBillableEntryMockType,
  JobMockType,
} from '@shared/modules/Timesheet/components/TimesheetMockData';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Wrapper = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

const TableContainer = Styled.View`
  border-left-width: 1px;
  border-color: ${colors.gray.border};
`;

const HeaderCell = Styled.View`
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: flex-end;
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Cell = Styled.View`
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const ButtonRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const sortJobUsers = ({jobUsers}: any) => {
  return _.sortBy(jobUsers, ['user.fullName']);
};

const formatTimeRanges = ({timeRanges}: any) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getJobUserTimes = ({jobUser}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(jobUser, 'timesheet.timeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).end : (timeRange as any).start;
  });
};

const getColumnNames = ({job}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(job, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).endName : (timeRange as any).startName;
  });
};

const TimesheetBillableEntriesMock = ({
  isShowNames,
  isShowTotals,
  isShowTimes,
}: {
  isShowNames: boolean;
  isShowTotals: boolean;
  isShowTimes: boolean;
}) => {
  return (
    <React.Fragment>
      {MOCK_TIMESHEET_BILLABLE_ENTRIES_JOB.timesheetBillableEntries.map(
        (mockTimesheetBillableEntry, index) => (
          <React.Fragment key={mockTimesheetBillableEntry.id}>
            {index > 0 && <Space height={24} />}
            <TimesheetBillableEntry
              job={MOCK_TIMESHEET_BILLABLE_ENTRIES_JOB}
              timesheetBillableEntry={mockTimesheetBillableEntry}
              isShowNames={isShowNames}
              isViewOnly
              showTotals={isShowTotals}
              showTimes={isShowTimes}
            />
          </React.Fragment>
        ),
      )}
    </React.Fragment>
  );
};

const CommercialJobUser = ({jobUser, job, columnNames}: any) => {
  return (
    <Row>
      {job.organization.features.isEnabledJobUserShowBranchCode && (
        <Cell key={0} style={{width: 80}}>
          <Text>{jobUser.branchCode}</Text>
        </Cell>
      )}
      <Cell key={1} style={{width: 140}}>
        <Text numberOfLines={1}>{jobUser.user.fullName}</Text>
      </Cell>
      {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
        <Cell key={2} style={{width: 140}}>
          <Text numberOfLines={1}>{jobUser.position}</Text>
          {job.organization.features.isEnabledJobUserShowOrganizationName && (
            <Text numberOfLines={1}>{jobUser.user.organization.name}</Text>
          )}
        </Cell>
      )}
      {columnNames.map((name: any, index: any) => {
        const jobUserTimes = getJobUserTimes({jobUser});
        const columnTime = _.get(jobUserTimes, index);
        return (
          <Cell key={`time-${index}`} style={{flex: 1}}>
            <Text numberOfLines={1}>
              {columnTime ? Datetime.convertToDisplayTime(columnTime) : '--'}
            </Text>
          </Cell>
        );
      })}
      <Cell key={3} style={{flex: 1}}>
        <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeDrive)}</Text>
      </Cell>
      <Cell key={4} style={{flex: 1}}>
        <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeWithoutBreak)}</Text>
      </Cell>
    </Row>
  );
};

const TableHeader = ({columnNames, job}: any) => (
  <Row>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <HeaderCell style={{width: 80}}>
        <HeaderText>Branch / IC Code</HeaderText>
      </HeaderCell>
    )}
    <HeaderCell style={{width: 140}}>
      <HeaderText>Name</HeaderText>
    </HeaderCell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <HeaderCell style={{width: 140}}>
        <HeaderText>Position</HeaderText>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <HeaderText>Labor Source</HeaderText>
        )}
      </HeaderCell>
    )}
    {columnNames.map((name: string, index: number) => (
      <HeaderCell key={index} style={{flex: 1}}>
        <HeaderText>{name}</HeaderText>
      </HeaderCell>
    ))}
    <HeaderCell style={{flex: 1}}>
      <HeaderText>Travel Hours</HeaderText>
    </HeaderCell>
    <HeaderCell style={{flex: 1}}>
      <HeaderText>Total Hours</HeaderText>
    </HeaderCell>
  </Row>
);

const DocumentV2PerMoverTimesheetTable = ({job}: any) => {
  const columnNames = getColumnNames({job});
  return (
    <TableContainer>
      <TableHeader columnNames={columnNames} job={job} />
      <FlatList
        data={sortJobUsers({jobUsers: job.confirmedJobUsers})}
        keyExtractor={(jobUser: any) => jobUser.id}
        renderItem={({item: jobUser, index}: any) => (
          <CommercialJobUser jobUser={jobUser} job={job} columnNames={columnNames} />
        )}
      />
    </TableContainer>
  );
};

const TimesheetBillableEntries = ({
  job,
  isShowNames,
  isShowTotals,
  isShowTimes,
}: {
  job: JobModel;
  isShowNames: boolean;
  isShowTotals: boolean;
  isShowTimes: boolean;
}) => {
  return (
    <React.Fragment>
      {job.timesheetBillableEntryKind === TimesheetBillableEntryKind.POSITION && (
        <React.Fragment>
          {job.timesheetBillableEntries.map((timesheetBillableEntry, index) => (
            <React.Fragment key={timesheetBillableEntry.id}>
              {index > 0 && <Space height={24} />}
              <TimesheetBillableEntry
                job={job}
                timesheetBillableEntry={timesheetBillableEntry}
                isViewOnly
                isShowNames={isShowNames}
                showTotals={isShowTotals}
                showTimes={isShowTimes}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DocumentV2InputPerMoverTimesheetContent = ({
  job,
  isEditable,
  isShowNames,
  isShowTotals,
  isShowTimes,
}: {
  job: JobModel;
  isEditable: boolean;
  isShowNames: boolean;
  isShowTotals: boolean;
  isShowTimes: boolean;
}) => {
  const {navigator} = useNavigation();
  return (
    <Container>
      {isEditable && (
        <ButtonRow>
          <Button
            text={'Edit'}
            iconLeft={Icon.Pen}
            onPress={() => {
              // NOTE(dan) This is a crew app navigation as the document should
              // only be editable from crew app.
              navigator.push('EditCommercialTimesheetCrewJob', {
                uuid: job.uuid,
                success: 'BACK',
              });
            }}
          />
        </ButtonRow>
      )}
      <Space height={10} />
      {job.project.projectType.features.timesheetsV2 ? (
        <TimesheetBillableEntries
          job={job}
          isShowNames={isShowNames}
          isShowTotals={isShowTotals}
          isShowTimes={isShowTimes}
        />
      ) : (
        <DocumentV2PerMoverTimesheetTable job={job} />
      )}
    </Container>
  );
};

type DocumentV2InputPerMoverTimesheetPreviewProps = {
  isPreview: true;
  isEditable: never;
  job: never;
};
type DocumentV2InputPerMoverTimesheetDocumentProps = {
  isPreview: false | undefined;
  isEditable?: boolean;
  job: JobModel;
};
const DocumentV2InputPerMoverTimesheet = ({
  index,
  isShowNames = false,
  isPreview = false,
  isEditable = false,
  isShowTotals = false,
  isShowTimes = false,
  job,
}: {
  index: number;
  isShowNames?: boolean;
  isShowTotals?: boolean;
  isShowTimes?: boolean;
} & (
  | DocumentV2InputPerMoverTimesheetPreviewProps
  | DocumentV2InputPerMoverTimesheetDocumentProps
)) => {
  return (
    <Wrapper index={index}>
      {isPreview ? (
        <TimesheetBillableEntriesMock
          isShowNames={isShowNames}
          isShowTotals={isShowTotals}
          isShowTimes={isShowTimes}
        />
      ) : (
        <DocumentV2InputPerMoverTimesheetContent
          job={job}
          isEditable={isEditable}
          isShowNames={isShowNames}
          isShowTotals={isShowTotals}
          isShowTimes={isShowTimes}
        />
      )}
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2InputPerMoverTimesheet.fragment = gql`
  ${TimesheetBillableEntry.fragment}

  fragment DocumentV2InputPerMoverTimesheet on Job {
    id
    uuid
    hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo: hasJobFeature(
      kind: "CREW_COMMERCIAL_TIMESHEET_SHOW_ADDITIONAL_JOB_USER_INFO"
    )
    project {
      id
      projectType {
        id
        features {
          timesheetsV2
        }
      }
    }
    organization {
      id
      defaultTimesheetTimeRanges {
        startName
        endName
      }
      features {
        isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        isEnabledJobUserShowOrganizationName: isEnabled(feature: "JOB_USER_SHOW_ORGANIZATION_NAME")
      }
    }
    confirmedJobUsers {
      id
      position
      branchCode
      timesheet {
        timeRanges {
          start
          end
          startName
          endName
        }
        totalTimeDrive
        totalTimeWithoutBreak
      }
      user {
        id
        fullName
        organization {
          id
          name
        }
      }
    }
    ...TimesheetBillableEntry
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_ORGANIZATION = {
  id: 1,
  features: {
    isEnabledTimesheetBlockKindWait: false,
  },
};

const MOCK_TIMESHEET_BILLABLE_ENTRY_1 = {
  id: 1,
  kind: TimesheetBillableEntryKind.POSITION,
  job: {
    id: 1,
    organization: MOCK_ORGANIZATION,
  },
  moverPosition: {
    id: 1,
    name: 'Position 1',
  },
  timesheetPayrollEntries: [
    {
      id: 1,
      user: {
        id: 1,
        fullName: 'Mover 1',
      },
    },
    {
      id: 1,
      user: {
        id: 1,
        fullName: 'Mover 2',
      },
    },
  ],
  timesheetBlocks: [
    {
      id: 1,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T15:00:00Z',
      rangeTo: '2021-08-01T16:30:00Z',
    },
    {
      id: 2,
      kind: TimesheetBlockKind.BREAK,
      rangeFrom: '2021-08-01T16:30:00Z',
      rangeTo: '2021-08-01T17:00:00Z',
    },
    {
      id: 3,
      kind: TimesheetBlockKind.DRIVE,
      rangeFrom: '2021-08-01T17:00:00Z',
      rangeTo: '2021-08-01T18:00:00Z',
    },
    {
      id: 4,
      kind: TimesheetBlockKind.WAIT,
      rangeFrom: '2021-08-01T18:00:00Z',
      rangeTo: '2021-08-01T18:30:00Z',
    },
    {
      id: 5,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T18:30:00Z',
      rangeTo: '2021-08-01T19:30:00Z',
    },
  ],
};

const MOCK_TIMESHEET_BILLABLE_ENTRY_2 = {
  id: 2,
  kind: TimesheetBillableEntryKind.POSITION,
  job: {
    id: 1,
    organization: MOCK_ORGANIZATION,
  },
  moverPosition: {
    id: 2,
    name: 'Position 2',
  },
  timesheetPayrollEntries: [
    {
      id: 1,
      user: {
        id: 1,
        fullName: 'Mover 3',
      },
    },
  ],
  timesheetBlocks: [
    {
      id: 1,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T14:00:00Z',
      rangeTo: '2021-08-01T16:30:00Z',
    },
    {
      id: 2,
      kind: TimesheetBlockKind.BREAK,
      rangeFrom: '2021-08-01T16:30:00Z',
      rangeTo: '2021-08-01T17:00:00Z',
    },
    {
      id: 3,
      kind: TimesheetBlockKind.DRIVE,
      rangeFrom: '2021-08-01T17:00:00Z',
      rangeTo: '2021-08-01T18:00:00Z',
    },
    {
      id: 5,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T18:00:00Z',
      rangeTo: '2021-08-01T19:30:00Z',
      test: 'test',
    },
  ],
} as TimesheetBillableEntryMockType;

const MOCK_TIMESHEET_BILLABLE_ENTRIES_JOB = {
  id: 1,
  isComplete: true,
  hasJobEventDuringMoveJobTimesheetCompleted: false,
  timesheetBillableEntries: [MOCK_TIMESHEET_BILLABLE_ENTRY_1, MOCK_TIMESHEET_BILLABLE_ENTRY_2],
} as JobMockType;

export default DocumentV2InputPerMoverTimesheet;
