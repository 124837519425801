// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: 24px;
  padding-vertical: 16px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const FooterButton = (props: any) => {
  return <Button {...props} style={{minWidth: 140}} isResponsive />;
};

const ActionFooter = ({
  handleBack,
  secondaryActionText,
  handleSecondaryAction,
  primaryActionText,
  handlePrimaryAction,
  isSubmitting,
}: any) => {
  const {navigator} = useNavigation();

  return (
    <Footer>
      <FooterButton
        text={'Back'}
        onPress={handleBack || (() => navigator.goBack())}
        color={colors.gray.secondary}
      />
      <Space style={{flex: 1}} />
      {secondaryActionText && (
        <React.Fragment>
          <SecondaryButton
            text={secondaryActionText}
            onPress={handleSecondaryAction}
            isResponsive
          />
          <Space width={16} />
        </React.Fragment>
      )}
      <FooterButton
        text={primaryActionText || 'Next'}
        onPress={handlePrimaryAction}
        isSubmitting={isSubmitting}
      />
    </Footer>
  );
};

export default ActionFooter;
