// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ExternalLink, Notification, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useState} from '@supermove/hooks';
import {Device} from '@supermove/sdk';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
// @ts-expect-error TS(2307): Cannot find module 'config' or its corresponding t... Remove this comment to see the full error message
import {AppInfo} from 'config';
import {FieldValue} from 'modules/App/components';

const Container = Styled.View`
  margin: 20px;
`;

const Section = Styled.View`
`;

const Title = Styled.H4`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Line = Styled.View`
  margin-vertical: 20px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  margin-top: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isFirst ? 10 : 15)}px;
`;

const Touchable = Styled.Touchable`
`;

const Actions = Styled.View`
  margin-left: 20px;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PrivacyPolicyText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const SettingsFieldValues = ({data}: any) => {
  const {navigator} = useNavigation();
  const [count, setCount] = useState(0);

  return (
    <Container>
      <Section>
        <Title>Organization</Title>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row vars={{isFirst: true}}>
          <FieldValue
            label={'Name'}
            value={data.viewer.viewingOrganization.name}
            style={{flex: 1}}
          />
        </Row>
        <Line />
      </Section>
      <Section>
        <Title>Device</Title>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row vars={{isFirst: true}}>
          <FieldValue label={'Name'} value={_.get(data, 'device.name')} style={{flex: 1}} />
        </Row>
        <Line />
      </Section>
      <Section>
        <Title>App</Title>
        <Row>
          {_.get(data, 'device.appInstall.release.isLatest', false) ? (
            // @ts-expect-error TS(2741): Property 'color' is missing in type '{ text: strin... Remove this comment to see the full error message
            <Notification.Info
              text={'Your device is running the latest version.'}
              style={{flex: 1}}
            />
          ) : (
            // @ts-expect-error TS(2741): Property 'color' is missing in type '{ children: E... Remove this comment to see the full error message
            <Notification.Warning text={'An update is available for Supermove.'} style={{flex: 1}}>
              <Actions>
                <Button onPress={() => Device.openUpdateApp()}>
                  <Text>Update App</Text>
                </Button>
              </Actions>
            </Notification.Warning>
          )}
        </Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row vars={{isFirst: true}}>
          <FieldValue label={'Version'} value={AppInfo.getVersion()} style={{flex: 1}} />
        </Row>
        <Row>
          <Touchable
            activeOpacity={1}
            onPress={() => {
              if (count >= 4) {
                navigator.navigate('SettingsDebug');
              } else {
                setCount(count + 1);
              }
            }}
          >
            <FieldValue label={'Build Number'} value={AppInfo.getBuildNumber()} style={{flex: 1}} />
          </Touchable>
        </Row>
        <Line />
      </Section>
      <Section>
        <Title>Supermove</Title>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row vars={{isFirst: true}}>
          <ExternalLink url={`https://supermove.co/privacy-policy`}>
            <PrivacyPolicyText>Privacy Policy</PrivacyPolicyText>
          </ExternalLink>
        </Row>
      </Section>
      <Space height={16} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SettingsFieldValues.fragment = gql`
  fragment SettingsFieldValues on Query {
    viewer {
      id
      viewingOrganization {
        id
        name
      }
    }
    device(uniqueId: $uniqueId) {
      id
      name
      appInstall(appName: $appName, appPlatform: $appPlatform, releaseBuild: $releaseBuild) {
        id
        release {
          id
          isLatest
        }
      }
    }
  }
`;

export default SettingsFieldValues;
