// Libraries
import React from 'react';

// App
import {Responsive, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  z-index: 100;
`;

const Center = Styled.View`
  flex: 1;
  align-items: center;
  margin-horizontal: 10px;
`;

const Title = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? Typography.Body4 : Typography.Body3)}
  color: ${colors.gray.secondary};
`;

const Subtitle = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? Typography.Body3 : Typography.Body2)}
  color: ${colors.gray.primary};
`;

const Side = Styled.View`
  width: ${(props) => (props as any).width}px;
`;

type OwnProps = {
  renderLeft?: (...args: any[]) => any;
  renderRight?: (...args: any[]) => any;
  getSideWidth?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Navigation.defaultProps;

// @ts-expect-error TS(7022): 'Navigation' implicitly has type 'any' because it ... Remove this comment to see the full error message
const Navigation = ({getSideWidth, title, subtitle, renderLeft, renderRight}: Props) => (
  <Responsive>
    {(responsive: any) => (
      <Container>
        <Side width={getSideWidth({responsive})}>{renderLeft()}</Side>
        <Center>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Title vars={responsive} numberOfLines={1}>
            {title}
          </Title>
          <Space height={2} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Subtitle vars={responsive} numberOfLines={1}>
            {subtitle}
          </Subtitle>
        </Center>
        <Side width={getSideWidth({responsive})}>{renderRight()}</Side>
      </Container>
    )}
  </Responsive>
);

Navigation.defaultProps = {
  renderLeft: () => null,
  renderRight: () => null,
  getSideWidth: () => 0,
};

const Touchable = Styled.Touchable`
`;

const CloseIcon = Styled.Image`
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? 20 : 30)}px;
  height: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? 20 : 30)}px;
  margin: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? 10 : 15)}px;
`;

Navigation.CloseButton = ({onPress}: any) => (
  <Responsive>
    {(responsive: any) => (
      <Touchable onPress={onPress}>
        <CloseIcon source={require('./assets/close.png')} responsive={responsive} />
      </Touchable>
    )}
  </Responsive>
);

Navigation.Button = Styled.Button`
  height: 40px;
  width: ${(props) => (props as any).width}px;
  ${(props) => (props as any).style};
`;

Navigation.ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

export default Navigation;
