// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useIsFocused} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import {ViewerJobBanner} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const FooterContainer = Styled.View`
`;

const FooterBorder = Styled.View`
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const FooterContentContainer = Styled.View`
  height: 60px;
  background-color: ${colors.gray.background};
`;

const Footer = ({children, style}: any) => {
  return (
    <FooterContainer>
      <FooterBorder />
      <FooterContentContainer style={style}>{children}</FooterContentContainer>
    </FooterContainer>
  );
};

const CustomerJobPageV2 = ({children, refetch}: any) => {
  useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Container>
      <ViewerJobBanner view={'CUSTOMER'} />
      {children}
    </Container>
  );
};

CustomerJobPageV2.Footer = Footer;

export default CustomerJobPageV2;
