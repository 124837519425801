// Libraries
import React from 'react';

// App
import {Badge} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';

// Components

const JobStatusBadge = ({job}: any) => (
  <Badge color={Job.getCrewPrimaryStatusColor(job)} style={{width: 150}}>
    {Job.getCrewPrimaryStatusText(job)}
  </Badge>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobStatusBadge.fragment = gql`
  fragment JobStatusBadge on Job {
    id
    crewPrimaryStatus
  }
`;

export default JobStatusBadge;
