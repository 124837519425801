// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import ConfirmJobUsersForJobForm from '@shared/modules/Dispatch/forms/ConfirmJobUsersForJobForm';
import AddMoversModal from 'modules/Job/Crew/Timesheet/components/AddMoversModal';
import AssignMoversMover from 'modules/Job/Crew/Timesheet/components/AssignMoversMover';
import EditJobUserModal from 'modules/Job/User/Confirm/components/EditJobUserModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const CrewHeaderContainer = Styled.View`
  padding: 24px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border}
  background-color: ${colors.gray.background}
`;

const OrganizationNameText = Styled.Text`
  ${Typography.Mobile.Heading2}
`;

const BadgeContainer = Styled.View<{isSelected: boolean; backgroundColor: string}>`
  padding-vertical: 2px;
  padding-horizontal: 8px;
  border-width: 1px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background-color: ${({isSelected, backgroundColor}) =>
    isSelected ? colors.blue.accent : backgroundColor}
  border-color: ${({isSelected}) => (isSelected ? colors.blue.accent : colors.gray.border)}
`;

const BadgeText = Styled.Text<{vars: {isSelected: boolean}}>`
  ${Typography.Mobile.Label}
  color: ${({vars}) => (vars?.isSelected ? colors.blue.interactive : colors.gray.secondary)}
`;

const MoversListContainer = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const MoverContainer = Styled.View`
  padding-horizontal: 24px;
  padding-vertical: 16px;
`;

const NameText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const handleRemoveMover = ({form, field, index}: any) => {
  const jobUserFormsField = `${field}.jobUserForms`;
  const jobUserForms = _.get(form.values, jobUserFormsField);
  const updatedJobUserForms = jobUserForms.filter(
    (currentJobUserForm: any, currentJobUserIndex: any) => currentJobUserIndex !== index,
  );
  form.setFieldValue(jobUserFormsField, updatedJobUserForms);
};

const Badge = ({text, icon, isSelected, color}: any) => {
  return (
    <BadgeContainer isSelected={isSelected} backgroundColor={color}>
      {icon && (
        <React.Fragment>
          <Icon
            source={icon}
            color={isSelected ? colors.blue.interactive : colors.gray.secondary}
            size={16}
          />
          <Space width={8} />
        </React.Fragment>
      )}
      <BadgeText vars={{isSelected}}>{text}</BadgeText>
    </BadgeContainer>
  );
};

const CrewHeader = ({crew, jobUserForms, viewerId, form, field}: any) => {
  const addMoversModal = useModal({name: 'Add Movers Modal'});
  const {numberOfAssignedTrucks, numberOfRequiredTrucks, numberOfRequiredMovers} = crew;

  return (
    <CrewHeaderContainer>
      <Row>
        <OrganizationNameText>{crew.organization.name}</OrganizationNameText>
        {crew.organization.hasMultipleOrganizations && crew.isPrimary && (
          <React.Fragment>
            <Space width={16} />
            <Badge text={'Primary'} isSelected />
          </React.Fragment>
        )}
        <Space width={16} />
        <Badge
          icon={Icon.Truck}
          text={`${numberOfAssignedTrucks}/${numberOfRequiredTrucks}`}
          color={colors.white}
        />
        <Space width={16} />
        <Badge
          icon={Icon.User}
          text={`${jobUserForms.length}/${numberOfRequiredMovers}`}
          color={colors.white}
        />
        <Space style={{flex: 1}} />
        <TertiaryButton
          text={'Add Movers'}
          iconLeft={Icon.Plus}
          onPress={addMoversModal.handleOpen}
          isResponsive
          isHitSlop
        />
      </Row>
      <AddMoversModal
        key={addMoversModal.key}
        isOpen={addMoversModal.isOpen}
        handleClose={addMoversModal.handleClose}
        crew={crew}
        viewerId={viewerId}
        form={form}
        field={field}
      />
    </CrewHeaderContainer>
  );
};

const Mover = ({jobUserForm, crew, onRemove}: any) => {
  return (
    <MoverContainer>
      <Row>
        <NameText>{jobUserForm.firstName}</NameText>
        {jobUserForm.moverPositionIds.map((id: any) => {
          const moverPosition = crew.organization.moverPositions.find(
            (moverPosition: any) => _.toString(moverPosition.id) === _.toString(id),
          );
          return (
            <React.Fragment key={id}>
              <Space width={16} />
              <Badge text={moverPosition.name} color={colors.gray.background} />
            </React.Fragment>
          );
        })}
        <Space style={{flex: 1}} />
        {onRemove && <TertiaryButton text={'Remove'} onPress={onRemove} isResponsive isHitSlop />}
      </Row>
    </MoverContainer>
  );
};

const CannotDeleteMoverModal = ({isOpen, handleClose}: any) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={'Cannot remove crew lead.'}
      message={'You are the crew lead and must be assigned to the job.'}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleClose}
      handlePressOutside={handleClose}
    />
  );
};

const JobUser = ({form, field, jobUserForm, organization, handleRemove}: any) => {
  const editJobUserModal = useModal({name: 'Edit JobUser Modal'});

  return (
    <React.Fragment>
      <AssignMoversMover
        jobUserForm={jobUserForm}
        organization={organization}
        handleRemove={handleRemove}
        handleEdit={editJobUserModal.handleOpen}
      />
      <EditJobUserModal
        field={field}
        form={form}
        isOpen={editJobUserModal.isOpen}
        onClose={editJobUserModal.handleClose}
        organization={organization}
        primaryActionText={'Done'}
      />
    </React.Fragment>
  );
};

const Crew = ({crew, viewerId, form, field}: any) => {
  const cannotDeleteMoverModal = useModal();
  const jobUserFormsForCrew = ConfirmJobUsersForJobForm.getJobUserFormsForCrew(
    _.get(form.values, field),
    {crew, field},
  );

  return (
    <React.Fragment>
      <CrewHeader
        crew={crew}
        jobUserForms={jobUserFormsForCrew}
        viewerId={viewerId}
        form={form}
        field={field}
      />
      {_.some(jobUserFormsForCrew) && (
        <React.Fragment>
          <Space height={24} />
          <MoversListContainer>
            {jobUserFormsForCrew.map((jobUserForm: any, index: any) => {
              const handleRemove = () =>
                _.toString(jobUserForm.userId) === _.toString(viewerId)
                  ? cannotDeleteMoverModal.handleOpen()
                  : handleRemoveMover({form, field, index});

              return (
                <React.Fragment key={jobUserForm.field}>
                  {index > 0 && <Line />}
                  <JobUser
                    form={form}
                    field={`${field}.jobUserForms.${index}`}
                    jobUserForm={jobUserForm}
                    organization={crew.organization}
                    handleRemove={handleRemove}
                  />
                </React.Fragment>
              );
            })}
          </MoversListContainer>
        </React.Fragment>
      )}
      <CannotDeleteMoverModal
        isOpen={cannotDeleteMoverModal.isOpen}
        handleClose={cannotDeleteMoverModal.handleClose}
      />
    </React.Fragment>
  );
};

const AssignMoversCrews = ({crews, viewerId, form, field}: any) => {
  return (
    <React.Fragment>
      {crews.map((crew: any, index: any) => {
        return (
          <React.Fragment key={crew.id}>
            {index > 0 && <Space height={24} />}
            <Crew crew={crew} viewerId={viewerId} form={form} field={field} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

AssignMoversCrews.Line = Line;
AssignMoversCrews.MoversListContainer = MoversListContainer;
AssignMoversCrews.Mover = Mover;

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignMoversCrews.fragment = gql`
  ${AssignMoversMover.fragment}
  ${AddMoversModal.fragment}
  ${EditJobUserModal.fragment}

  fragment AssignMoversCrews on Crew {
    id
    isPrimary
    numberOfAssignedTrucks
    numberOfRequiredTrucks
    numberOfRequiredMovers
    organization {
      id
      name
      hasMultipleOrganizations
      moverPositions {
        id
        name
      }
      ...AssignMoversMover
      ...EditJobUserModal
    }
    ...AddMoversModal
  }
`;

export default AssignMoversCrews;
