/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobPhotosList from 'modules/Job/Attachment/components/JobPhotosList';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Button = Styled.ButtonV2`
  padding-vertical: 8px;
  padding-horizontal: 10px;
  background-color: ${colors.blue.interactive}
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const JobPhotosBlock = ({job, refetch}: any) => {
  const {navigator} = useNavigation();

  return (
    <Container>
      <Row>
        <Title>{`Photos (${job.filteredCrewAttachments?.length ?? 0})`}</Title>
        <Button
          color={colors.white}
          onPress={() => navigator.navigate('NewJobAttachmentsV2', {jobUuid: job.uuid})}
        >
          <Text>Add Photos</Text>
        </Button>
      </Row>
      <Content>
        <JobPhotosList job={job} refetch={refetch} />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPhotosBlock.fragment = gql`
  ${JobPhotosList.fragment}

  fragment JobPhotosBlock on Job {
    id
    uuid
    organization {
      id
    }
    filteredCrewAttachments {
      id
    }
    ...JobPhotosList
  }
`;

export default JobPhotosBlock;
