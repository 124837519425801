// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useCallback, useState} from '@supermove/hooks';
import {Device, DeviceInfo, ErrorHandler} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
import {ModalPage, Navigation} from 'modules/App/components';
import {useManualLogDeviceCurrentPosition} from 'modules/App/logic';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin: 20px;
`;

const Section = Styled.View`
  margin-bottom: 20px;
`;

const Label = Styled.H7`
  ${fontWeight(900)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Row = Styled.View`
  flex-direction: row;
  margin-top: 3px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const SettingsDebugPage = () => {
  // TODO(mark): Hooks migration.
  const [deviceId, setDeviceId] = useState();
  const {fetchLocation} = useManualLogDeviceCurrentPosition({deviceId});
  const triggerError = useCallback(() => ErrorHandler.jsCrash(), []);

  return (
    <ModalPage
      query={SettingsDebugPage.query}
      getVariables={() => ({uniqueId: DeviceInfo.getUniqueId()})}
      navigation={({loading, data, navigator, params}: any) => (
        <Navigation
          getSideWidth={({responsive}: any) => 130}
          title={'Debug'}
          subtitle={'App testing utilities'}
          renderLeft={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
        />
      )}
    >
      {({navigator, params, data}: any) => {
        // TODO(mark): Hooks migration.
        setDeviceId(_.get(data, 'device.id'));

        return (
          <Container>
            <ScrollView style={{flex: 1}}>
              <Content>
                <Section>
                  <Label>Update</Label>
                  <Row>
                    <Button onPress={() => navigator.navigate('SettingsUpdate')}>
                      <Text>Open Update Screen</Text>
                    </Button>
                  </Row>
                </Section>
                <Section>
                  <Label>Device</Label>
                  <Row>
                    <Button onPress={() => Device.restartApp()}>
                      <Text>Restart App</Text>
                    </Button>
                  </Row>
                </Section>
                <Section>
                  <Label>Errors</Label>
                  <Row>
                    <Button onPress={triggerError}>
                      <Text>Trigger Render Error</Text>
                    </Button>
                    <ButtonSpace />
                    <Button onPress={() => ErrorHandler.jsCrash()}>
                      <Text>Trigger JS Error</Text>
                    </Button>
                    <ButtonSpace />
                    <Button onPress={() => ErrorHandler.nativeCrash()}>
                      <Text>Trigger Native Error</Text>
                    </Button>
                  </Row>
                </Section>
                <Section>
                  <Label>Location</Label>
                  <Row>
                    <Button onPress={fetchLocation}>
                      <Text>Fetch Location</Text>
                    </Button>
                  </Row>
                </Section>
              </Content>
            </ScrollView>
          </Container>
        );
      }}
    </ModalPage>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SettingsDebugPage.propTypes = {};

SettingsDebugPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SettingsDebugPage.query = gql`
  query SettingsDebugPage($uniqueId: String!) {
    ${gql.query}
    device(uniqueId: $uniqueId) {
      id
    }
  }
`;

export default SettingsDebugPage;
