/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Loading, Modal, PdfView, Space, Styled} from '@supermove/components';
import {useMountEffect} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import useDownloadDocument from '@shared/modules/Document/hooks/useDownloadDocument';

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const UnavailableText = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary}
`;

const ScreenContainer = Styled.View`
  flex: 1;
  padding-horizontal: 64px;
  padding-vertical: 32px;
`;

const ContentContainer = Styled.View`
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: ${colors.white};
  padding-horizontal: 36px;
  border-radius: 8px;
`;
const Content = Styled.View`
  flex: 1;
  min-width: 600px;
  align-self: center;
  background-color: ${colors.gray.border}
`;

const PreviewTemplateModal = ({isOpen, handleClose, documentId}: any) => {
  // @ts-expect-error TS(2339): Property 'loading' does not exist on type '{ submi... Remove this comment to see the full error message
  const {handleSubmit, loading, data} = useDownloadDocument({documentId});

  useMountEffect(() => {
    handleSubmit();
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
  }, []);

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ContentContainer>
          <Loading
            loading={loading || !data}
            as={
              <React.Fragment>
                <Space height={16} />
                <UnavailableText>No Preview Available</UnavailableText>
                <Space height={16} />
              </React.Fragment>
            }
          >
            {() => (
              <Content>
                <PdfView source={{uri: data.downloadDocument.url}} />
              </Content>
            )}
          </Loading>
          <Button color={colors.gray.secondary} onPress={handleClose}>
            <Text>Close</Text>
          </Button>
          <Space height={10} />
        </ContentContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default PreviewTemplateModal;
