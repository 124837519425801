// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useState, useMountEffect} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

import {getMoverAppUrl} from '@shared/modules/Inventory/components/EmbeddedInventoryCapture/utils/getMoverAppUrl';

const Container = Styled.View`
  flex: 1;
`;

type Props = {
  inventoryUuid: string;
};

// @ts-expect-error TS(2339): Property 'jobUuid' does not exist on type 'Props'.
const EmbeddedInventoryCapture = ({inventoryUuid, jobUuid}: Props) => {
  const [authToken, setAuthToken] = useState('');
  const setAuth = async () => {
    const token = await Storage.getItem('token');
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    setAuthToken(token);
  };

  useMountEffect(() => {
    setAuth();
  });

  return (
    <Container>
      <iframe
        title={'Embedded Inventory Capture'}
        src={getMoverAppUrl({inventoryUuid, jobUuid, authToken})}
        style={{
          flex: 1,
          borderWidth: 0,
        }}
      />
    </Container>
  );
};

export default EmbeddedInventoryCapture;
