// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';

const useCreateJobEventPayrollTimesheetUpdatedMutation = ({
  jobEventForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      jobEventForm: JobEventForm.toForm(jobEventForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateJobEventPayrollTimesheetUpdatedMutation.mutation,
    variables: {
      jobEventForm: JobEventForm.toMutation(form.values.jobEventForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateJobEventPayrollTimesheetUpdatedMutation.mutation = gql`
  mutation useCreateJobEventPayrollTimesheetUpdatedMutation($jobEventForm: JobEventForm!) {
    response: createJobEventPayrollTimesheetUpdated(jobEventForm: $jobEventForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useCreateJobEventPayrollTimesheetUpdatedMutation;
