// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';

// Components
import CrewJobDocumentStatusBadge from './CrewJobDocumentStatusBadge';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Badges = Styled.View`
  margin-right: 20px;
`;

const Info = Styled.View`
  flex: 1;
`;

const DisplayName = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.Button`
  padding-horizontal: 20px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const CrewJobDocumentItem = ({isFirst, isLast, index, step, document}: any) => {
  const {navigator} = useNavigation();

  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Badges>
          <CrewJobDocumentStatusBadge document={document} />
        </Badges>
        <Info>
          <DisplayName>{`${index + 1}. ${document.displayName}`}</DisplayName>
        </Info>
        <Actions>
          <Button
            // TODO(mark): Right now, we disable when it's prepared. This
            // will change in the future when we support editing.
            disabled={document.isStepPrepared}
            onPress={() => {
              switch (step) {
                case 'PRE_MOVE':
                  return Navigation.navigateFromPreMovePrepareDocument({navigator, document});
                case 'POST_MOVE':
                  return Navigation.navigateFromPostMovePrepareDocument({navigator, document});
                default:
                  return;
              }
            }}
          >
            <Text>Edit</Text>
          </Button>
        </Actions>
      </Content>
      <Line />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobDocumentItem.fragment = gql`
  ${Navigation.navigateFromPreMovePrepareDocument.fragment}
  ${Navigation.navigateFromPostMovePrepareDocument.fragment}
  ${CrewJobDocumentStatusBadge.fragment}

  fragment CrewJobDocumentItem on Document {
    id
    displayName
    isStepPrepared(step: $step)
    ...Navigation_navigateFromPreMovePrepareDocument
    ...Navigation_navigateFromPostMovePrepareDocument
    ...CrewJobDocumentStatusBadge
  }
`;

export default CrewJobDocumentItem;
