// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 36px;
  width: 400px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading5}
  color: ${colors.gray.primary};
  text-align: center;
`;

const HeaderCaption = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: center;
`;

const Button = Styled.Button`
  height: 40px;
  width: 100px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const EstimatedRangeJobAlreadyStartedModal = ({isOpen}: any) => {
  const {navigator} = useNavigation();

  return (
    <Modal.Content isOpen={isOpen} onClose={() => {}}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ContentContainer>
          <Space height={36} />
          <HeaderText>Job Already Started</HeaderText>
          <Space height={4} />
          <HeaderCaption>
            {`This job has already been started for today. ` +
              `Please re-select the job from the list.`}
          </HeaderCaption>
          <Space height={20} />
          <Actions>
            <Button onPress={() => navigator.navigate('ListJobs')}>
              <Text>Got It</Text>
            </Button>
          </Actions>
          <Space height={24} />
        </ContentContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default EstimatedRangeJobAlreadyStartedModal;
