// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UpdateInventoryIsFinalizedStatusForm from '@shared/modules/Inventory/forms/UpdateInventoryIsFinalizedStatusForm';

const useUpdateInventoryIsFinalizedStatusMutation = ({
  updateInventoryIsFinalizedStatusForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      updateInventoryIsFinalizedStatusForm: UpdateInventoryIsFinalizedStatusForm.toForm(
        updateInventoryIsFinalizedStatusForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateInventoryIsFinalizedStatusMutation.mutation,
    variables: {
      updateInventoryIsFinalizedStatusForm: UpdateInventoryIsFinalizedStatusForm.toMutation(
        form.values.updateInventoryIsFinalizedStatusForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateInventoryIsFinalizedStatusMutation.mutation = gql`
  mutation useUpdateInventoryIsFinalizedStatusMutation($updateInventoryIsFinalizedStatusForm: UpdateInventoryIsFinalizedStatusForm!) {
    response: updateInventoryIsFinalizedStatus(updateInventoryIsFinalizedStatusForm: $updateInventoryIsFinalizedStatusForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useUpdateInventoryIsFinalizedStatusMutation;
