// Libraries
import {gql} from '@supermove/graphql';
import {useMutation, useMutationCallbacks} from '@supermove/hooks';

const MUTATION = gql`
  mutation CompleteSignatureForDocumentStepMutation(
    $documentId: Int!,
    $step: String!,
  ) {
    response: completeSignatureForDocumentStep(
      documentId: $documentId,
      step: $step,
    ) {
      ${gql.errors}
      document {
        id
        isStepCompleted(step: $step)
      }
    }
  }
`;

const useCompleteSignatureForDocumentStepMutation = ({
  documentId,
  step,
  onSuccess,
  onError,
}: any) => {
  const [handleSubmit, {loading, data}] = useMutation(MUTATION, {
    variables: {
      documentId,
      step,
    },
  });

  useMutationCallbacks({data, onSuccess, onError});

  return {
    submitting: loading,
    handleSubmit,
  };
};

export default useCompleteSignatureForDocumentStepMutation;
