// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Notification, Styled, TimeInput} from '@supermove/components';
import {Organization, TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {Field, Modal} from 'modules/App/components';

const Container = Styled.View`
  padding: 40px;
  width: 600px;
  background-color: ${colors.white};
`;

const Title = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const FieldSpace = Styled.View`
  width: 10px;
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Button = Styled.Button`
  height: 40px;
  width: 100px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const getTitle = ({type}: any) => {
  switch (type) {
    case 'finish':
      return 'Add End Time';
    case 'edit':
      return 'Edit Time';
    default:
      return '';
  }
};

const GroupButton = Styled.Touchable`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: ${(props) => ((props as any).isSelected ? colors.blue.interactive : colors.white)};
  border-width: 1px;
  border-color: ${(props) => ((props as any).isSelected ? colors.blue.interactive : colors.blue.interactive)};
`;

const GroupText = Styled.H7`
  ${fontWeight(700)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isSelected ? colors.white : colors.blue.interactive)};
  letter-spacing: 1;
  text-transform: uppercase;
`;

// We allow delete if the last one is incomplete (no end time) or
// it's the last one (that isn't the start time).
const canDelete = ({index, form}: any) => {
  // Never delete the first time row.
  if (index === 0) {
    return false;
  }

  // If the timeRange is the last one, we can delete.
  return index === form.values.timeRanges.length - 1;
};

const deleteTimeRange = ({index, form}: any) => {
  const newTimeRanges = [...form.values.timeRanges];
  const removedTimeRanges = _.pullAt(newTimeRanges, index); // eslint-disable-line
  form.setFieldValue('timeRanges', newTimeRanges);
};

const KindButton = ({isSelected, kind, onPress}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <GroupButton isSelected={isSelected} activeOpacity={1} onPress={onPress}>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <GroupText vars={{isSelected}}>{kind}</GroupText>
  </GroupButton>
);

const EmployeeMoveTimeModal = ({type, index, timeRange, organization, form, trigger}: any) => (
  <Modal trigger={trigger}>
    {({handleClose}: any) => (
      <Container>
        <Title>{getTitle({type})}</Title>
        <Subtitle>Enter details about this event.</Subtitle>
        <Content>
          <Label>Event</Label>
          <Row style={{marginTop: 3, marginBottom: 20}}>
            {Organization.getTimeRangeKinds(organization).map((kind) => (
              <KindButton
                key={kind}
                isSelected={kind === timeRange.kind}
                kind={kind}
                onPress={() => {
                  form.setFieldValue(`timeRanges.${index}.kind`, kind);
                  form.setFieldValue(`timeRanges.${index}.name`, TimeRange.getName({kind}));
                }}
              />
            ))}
          </Row>
          <Row>
            <Field
              {...form}
              component={TimeInput}
              name={`timeRanges.${index}.start`}
              label={'Start Time'}
              input={{
                disabled: index === 0 ? !organization.canCrewEditMoveStartTime : true,
                component: TextInput,
                placeholder: 'Start Time',
                setFieldValue: form.setFieldValue,
                style: {
                  width: 200,
                },
              }}
            />
            <FieldSpace />
            <Field
              {...form}
              component={TimeInput}
              name={`timeRanges.${index}.end`}
              label={'End Time'}
              input={{
                component: TextInput,
                placeholder: 'End Time',
                initialValue: _.get(form, `values.timeRanges.${index}.start`),
                setFieldValue: (name: any, value: any) => {
                  const nextTimeRange = _.get(form, `values.timeRanges.${index + 1}`);

                  // Set the current value.
                  form.setFieldValue(name, value);

                  // If there's a next timeRange, set the start time.
                  if (nextTimeRange) {
                    form.setFieldValue(`timeRanges.${index + 1}.start`, value);
                  }
                },
                style: {
                  width: 200,
                },
              }}
            />
          </Row>
        </Content>
        {TimeRange.getIsLong(timeRange) && (
          // @ts-expect-error TS(2741): Property 'color' is missing in type '{ text: strin... Remove this comment to see the full error message
          <Notification.Warning text={'This time is longer than 12 hours. Please double check.'} />
        )}
        <Actions>
          {canDelete({index, form}) && (
            <React.Fragment>
              <Button
                color={colors.red.warning}
                onPress={() => {
                  deleteTimeRange({index, form});
                  setTimeout(() => form.handleSubmit(), 0);
                }}
              >
                <Text>Delete</Text>
              </Button>
              <ButtonSpace />
            </React.Fragment>
          )}
          <Button
            onPress={() => {
              form.handleSubmit().then(
                ({
                  data: {
                    trackTimeForMove: {move, errors},
                  },
                }: any) => {
                  if (move) {
                    handleClose();
                  }
                },
              );
            }}
          >
            <Text>Save</Text>
          </Button>
        </Actions>
      </Container>
    )}
  </Modal>
);

export default EmployeeMoveTimeModal;
