// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Label = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Input = Styled.TextInput.H7`
  margin-top: 5px;
  width: 500px;
  padding-vertical: 9px;
  height: 150px;
`;

const DocumentAdditionalInfoSectionTextAreaInput = ({
  isFirst,
  isLast,
  name,
  index,
  values,
  setFieldValue,
  setFieldTouched,
}: any) => {
  const [label, value] = _.get(values, name) || ['', ''];

  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Label>{label}</Label>
        <Input
          multiline
          name={name}
          placeholder={'Enter text'}
          value={value}
          onChangeText={(text: any) => setFieldValue(name, [label, text])}
          onBlur={() => setFieldTouched(name, true)}
        />
      </Content>
      <Line />
    </Container>
  );
};

export default DocumentAdditionalInfoSectionTextAreaInput;
