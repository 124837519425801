// Libraries
import React from 'react';

// Supermove
import {PermissionsView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Permissions} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
import {ModalPage, Navigation} from 'modules/App/components';
import {StripeReaderItem} from 'modules/Settings/Stripe/Reader/components';
import {StripeTerminalWrapper} from 'modules/Settings/Stripe/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Indicator = Styled.Loading`
  margin-vertical: 10px;
`;

const Actions = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const StatusReaderStripeSettingsPage = () => (
  <PermissionsView type={Permissions.LOCATION}>
    {({isAuthorized, isUndetermined}) => (
      <StripeTerminalWrapper>
        {({isConnecting, reader}: any) => (
          <ModalPage
            query={StatusReaderStripeSettingsPage.query}
            navigation={({loading, data, navigator, params}: any) => (
              <Navigation
                getSideWidth={({responsive}: any) => 60}
                title={'Credit Card Reader Settings'}
                subtitle={'View or connect to readers'}
                renderLeft={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
              />
            )}
          >
            {({navigator, params, data}: any) => (
              <Container>
                <Content>
                  <Title>Card Reader Settings</Title>
                  <Subtitle>View and manage your card readers.</Subtitle>
                </Content>
                {reader ? (
                  <StripeReaderItem isFirst isSelected reader={reader} />
                ) : isConnecting ? (
                  <Indicator size={'large'} color={colors.gray.secondary} />
                ) : null}
                <Actions>
                  <Button
                    color={colors.blue.interactive}
                    onPress={() => navigator.navigate('ConnectReaderStripeSettings')}
                  >
                    <Text>Connect Reader</Text>
                  </Button>
                </Actions>
              </Container>
            )}
          </ModalPage>
        )}
      </StripeTerminalWrapper>
    )}
  </PermissionsView>
);

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
StatusReaderStripeSettingsPage.propTypes = {};

StatusReaderStripeSettingsPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
StatusReaderStripeSettingsPage.query = gql`
  query StatusReaderStripeSettingsPage {
    ${gql.query}
    viewer {
      id
    }
  }
`;

export default StatusReaderStripeSettingsPage;
