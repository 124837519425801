// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CompleteJobStepForm from '@shared/modules/Job/forms/CompleteJobStepForm';

const useCompleteJobStepMutation = ({completeJobStepForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      completeJobStepForm: CompleteJobStepForm.toForm(completeJobStepForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteJobStepMutation.mutation,
    variables: {
      completeJobStepForm: CompleteJobStepForm.toMutation(form.values.completeJobStepForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCompleteJobStepMutation.mutation = gql`
  mutation useCompleteJobStepMutation($completeJobStepForm: CompleteJobStepForm!) {
    response: completeJobStep(completeJobStepForm: $completeJobStepForm) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default useCompleteJobStepMutation;
