// Libraries
import React from 'react';

// App
import {Badge, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// Components
import CrewPrepareDocumentSignatureModal from './CrewPrepareDocumentSignatureModal';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Info = Styled.View`
  flex: 1;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Description = Styled.Text`
  ${Typography.Body3}
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.disabled ? colors.white : colors.blue.interactive)};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const CrewPrepareDocumentSignatureItem = ({
  isFirst,
  isLast,
  documentSignature,
  document,
  refetch,
}: any) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Info>
        <Title>{documentSignature.documentSection.title}</Title>
        <Description>{documentSignature.documentSection.description}</Description>
      </Info>
      <Badge
        color={documentSignature.isPrepared ? colors.green.status : colors.gray.secondary}
        style={{width: 150, marginRight: 20}}
      >
        {documentSignature.isPrepared ? 'Prepared' : 'Not Prepared'}
      </Badge>
      <CrewPrepareDocumentSignatureModal
        documentSignature={documentSignature}
        document={document}
        refetch={refetch}
        trigger={({handleOpen}: any) => (
          <Button
            disabled={documentSignature.documentSection.isAutoPrepared}
            color={colors.blue.interactive}
            onPress={handleOpen}
          >
            <Text
              // @ts-expect-error TS(2769): No overload matches this call.
              vars={{
                disabled: documentSignature.documentSection.isAutoPrepared,
              }}
            >
              Edit
            </Text>
          </Button>
        )}
      />
    </Content>
    <Line />
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPrepareDocumentSignatureItem.fragment = gql`
  ${CrewPrepareDocumentSignatureModal.fragment}

  fragment CrewPrepareDocumentSignatureItem_Document on Document {
    id
    ...CrewPrepareDocumentSignatureModal_Document
  }

  fragment CrewPrepareDocumentSignatureItem on DocumentSignature {
    id
    isPrepared
    placement
    fieldData {
      placement
      kind
      options
      value
    }
    documentSection {
      title
      description
      isAutoPrepared
    }
    ...CrewPrepareDocumentSignatureModal
  }
`;

export default CrewPrepareDocumentSignatureItem;
