// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useState} from '@supermove/hooks';

// App
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import CompleteTimesheetStepForm from '@shared/modules/Timesheet/forms/CompleteTimesheetStepForm';
import useCompleteDuringMoveJobTimesheetStepMutation from '@shared/modules/Timesheet/hooks/useCompleteDuringMoveJobTimesheetStepMutation';
import useCreateJobEventJobTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventJobTimesheetUpdatedMutation';
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BillableTimesheetPageContent from 'modules/Job/Crew/Timesheet/components/BillableTimesheetPageContent';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';

const BillableTimesheetDuringMovePageContent = ({job, navigator, refetch}: any) => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const completeDuringMoveJobTimesheetStep = useCompleteDuringMoveJobTimesheetStepMutation({
    completeTimesheetStepForm: CompleteTimesheetStepForm.new({jobId: job.id}),
    onSuccess: () => {
      Navigation.navigateFromTimesheetSuccess({navigator, job});
    },
    onError: (errors: any) => {
      console.log({errors});
      setIsSubmitting(false);
    },
  });

  const createJobEventTimesheetUpdated = useCreateJobEventJobTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: completeDuringMoveJobTimesheetStep.handleSubmit,
    onError: (errors: any) => {
      console.log({errors});
      setIsSubmitting(false);
    },
  });

  return (
    <BillableTimesheetPageContent
      job={job}
      handleSubmit={() => {
        setIsSubmitting(true);
        if (hasUpdates) {
          createJobEventTimesheetUpdated.handleSubmit();
        } else {
          completeDuringMoveJobTimesheetStep.handleSubmit();
        }
      }}
      isSubmitting={isSubmitting}
      navigator={navigator}
      refetch={refetch}
      onUpdate={() => {
        setHasUpdates(true);
      }}
      isCompletionRequired={job.timesheetBillableEntryKind === TimesheetBillableEntryKind.JOB}
    />
  );
};

const BillableTimesheetDuringMovePage = () => {
  const {params, navigator} = useNavigation();
  const {loading, data, refetch} = useQuery(BillableTimesheetDuringMovePage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.uuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <BillableTimesheetDuringMovePageContent
              job={data.job}
              navigator={navigator}
              refetch={refetch}
            />
          );
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillableTimesheetDuringMovePage.query = gql`
  ${Navigation.navigateFromTimesheetSuccess.fragment}
  ${BillableTimesheetPageContent.fragment}

  query BillableTimesheetDuringMovePage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      timesheetBillableEntryKind
      ...Navigation_navigateFromTimesheetSuccess
      ...BillableTimesheetPageContent
    }
  }
`;

export default BillableTimesheetDuringMovePage;
