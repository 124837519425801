// Libraries
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import {Timeline} from 'modules/Job/components';

const CustomerJobProgress = ({selected, items}: any) => (
  <Timeline
    selectedColor={colors.blue.interactive}
    defaultColor={colors.gray.border}
    selected={selected}
    items={items}
  />
);

// --------------------------------------------------
// Navigation
// --------------------------------------------------
CustomerJobProgress.items = {
  start: [
    {
      key: 'start',
      name: 'Details',
    },
    {
      key: 'rates',
      name: 'Rates',
    },
    {
      key: 'agreements',
      name: 'Agreements',
    },
  ],
  during: [
    {
      key: 'prepare',
      name: 'Prepare',
    },
    {
      key: 'agreements',
      name: 'Sign',
    },
  ],
  finish: [
    // For bill.kind === 'JOB_MAIN'
    {
      key: 'agreements',
      name: 'Agreements',
    },
    {
      key: 'bill',
      name: 'Review Bill',
    },
    {
      key: 'tip',
      name: 'Tip',
    },
    {
      key: 'payment',
      name: 'Payment',
    },
    {
      key: 'sign',
      name: 'Sign',
    },
  ],
  bill: [
    // For bill.kind === 'JOB_ADDITIONAL'
    {
      key: 'bill',
      name: 'Review Bill',
    },
    {
      key: 'payment',
      name: 'Payment',
    },
    {
      key: 'sign',
      name: 'Sign',
    },
  ],
};

CustomerJobProgress.getItems = ({kind}: any) => {
  switch (kind) {
    case 'JOB_MAIN':
      return CustomerJobProgress.items.finish;
    case 'JOB_ADDITIONAL':
    case 'CUSTOM':
    default:
      return CustomerJobProgress.items.bill;
  }
};

export default CustomerJobProgress;
