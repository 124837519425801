// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, KeyboardView, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// Components
import {PageLoadingIndicator} from 'modules/App/components';
import {CustomerSignDocumentSignaturesList} from 'modules/Job/Customer/Document/Sign/components';
import {CustomerJobProgress, CustomerJobPage} from 'modules/Job/Customer/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-bottom: 30px;
`;

const Header = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const DocumentSignatures = Styled.View`
  margin-top: 20px;
`;

const Signature = Styled.View`
  margin-horizontal: 40px;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Name = Styled.H5`
  ${fontWeight(500)}
  margin-bottom: 40px;
`;

const SignatureImage = Styled.Image`
  height: 350px;
  width: 700px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

// Explicitly pass the navigation.state.key to force a re-render. Without this
// the signature from the previous screen will still be showing.
const ViewDocumentCustomerJobPage = ({navigation}: any) => {
  return (
    <CustomerJobPage
      key={navigation.state.key}
      getVariables={({params}: any) => ({
        uuid: params.uuid,
        documentUuid: params.documentUuid,
      })}
      query={ViewDocumentCustomerJobPage.query}
    >
      {({navigator, params, loading, data, refetch}: any) => {
        return (
          <Loading loading={loading} as={PageLoadingIndicator}>
            {() => {
              return (
                <Container>
                  <KeyboardView>
                    <ScrollView alwaysBounceVertical={false} style={{flex: 1}}>
                      <CustomerJobProgress
                        selected={'agreements'}
                        items={CustomerJobProgress.items.finish}
                      />
                      <Content>
                        <Header>
                          <Title>{data.document.displayName}</Title>
                          <Subtitle>
                            {`Here are the sections summarizing the document. ` +
                              `Please read each section in detail.`}
                          </Subtitle>
                        </Header>
                        <DocumentSignatures>
                          <CustomerSignDocumentSignaturesList
                            isStepSigned
                            documentSignatures={data.document.filteredDocumentSignatures}
                            document={data.document}
                            refetch={refetch}
                          />
                        </DocumentSignatures>
                        <Signature>
                          <Label>Full Name</Label>
                          <Name>
                            {_.get(data, 'document.filteredDocumentSignatures.0.signature.name')}
                          </Name>
                          <SignatureImage
                            source={{
                              uri: _.get(
                                data,
                                'document.filteredDocumentSignatures.0.signature.image',
                              ),
                            }}
                          />
                        </Signature>
                      </Content>
                    </ScrollView>
                  </KeyboardView>
                  <Footer>
                    <Group>
                      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                        <Text>Back</Text>
                      </Button>
                    </Group>
                  </Footer>
                </Container>
              );
            }}
          </Loading>
        );
      }}
    </CustomerJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewDocumentCustomerJobPage.query = gql`
  ${CustomerJobPage.fragment}
  ${CustomerSignDocumentSignaturesList.fragment}

  query ViewDocumentCustomerJobPage($uuid: String!, $documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayName
      filteredDocumentSignatures {
        id
        signature {
          id
          name
          image
        }
        ...CustomerSignDocumentSignaturesList
      }
      ...CustomerSignDocumentSignaturesList_Document
    }
    job(uuid: $uuid) {
      id
      ...CustomerJobPage
    }
  }
`;

export default ViewDocumentCustomerJobPage;
