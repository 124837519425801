// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const Container = Styled.View`
`;

const TextInput = Styled.TextInput.H7`
`;

const Touchable = Styled.Touchable`
`;

const Results = Styled.View`
  margin-top: 10px;
  height: 300px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 5px;
`;

const Item = Styled.View`
  padding-vertical: 8px;
  padding-horizontal: 10px;
  background-color: ${(props) => ((props as any).index % 2 === 0 ? colors.white : colors.blue.accent)};
`;

const Text = Styled.H6`
  color: ${colors.gray.primary};
`;

const DocumentAdditionalInfoGridSearch = ({items, extraData, onSelect}: any) => {
  const [text, setText] = useState('');
  const [results, setResults] = useState(items);
  const onChangeText = (text: any) => {
    if (text) {
      const filteredResults = items.filter((item: any) => {
        return item.text.toLowerCase().includes(text.toLowerCase());
      });

      setText(text);
      setResults(filteredResults);
    } else {
      setText('');
      setResults(items);
    }
  };

  return (
    <Container>
      <TextInput placeholder={'Search for an item...'} onChangeText={onChangeText} value={text} />
      <Results>
        <ScrollView nestedScrollEnabled>
          {results.map((item: any, index: any) => (
            <Touchable
              key={index}
              onPress={() => {
                onSelect(item);
                onChangeText('');
              }}
            >
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Item index={index}>
                <Text>{item.text}</Text>
              </Item>
            </Touchable>
          ))}
        </ScrollView>
      </Results>
    </Container>
  );
};

export default DocumentAdditionalInfoGridSearch;
