// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';
import {countDecimalPlaces} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Button = Styled.ButtonV2`
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
  align-items: center;
  justify-content: center;
`;

type OwnProps = {
  form: any;
  field: string;
  onDecrement?: (...args: any[]) => any;
  onIncrement?: (...args: any[]) => any;
  disabledDecrement?: boolean;
  disabledIncrement?: boolean;
  disabledField?: boolean;
  required?: boolean;
  onChangeText?: (...args: any[]) => any;
  fieldWidth?: number;
  index?: number;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof StepperInput.defaultProps;

// @ts-expect-error TS(7022): 'StepperInput' implicitly has type 'any' because i... Remove this comment to see the full error message
const StepperInput = ({
  form,
  field,
  onDecrement,
  onIncrement,
  disabledDecrement,
  disabledIncrement,
  disabledField,
  required,
  onChangeText,
  fieldWidth,
  index,
  style,
  placeholder = '0',
}: Props) => {
  const handleDecrement = () => {
    const quantity = _.get(form.values, field);
    if (quantity <= 1) {
      form.setFieldValue(field, '0');
    } else {
      const getPrecision = countDecimalPlaces(Number(quantity));
      const newQuantity = _.subtract(Number(quantity), 1).toFixed(getPrecision);
      form.setFieldValue(field, String(newQuantity));
    }
  };

  const handleIncrement = () => {
    const quantity = _.get(form.values, field);
    const getPrecision = countDecimalPlaces(Number(quantity));
    const newQuantity = _.add(Number(quantity), 1).toFixed(getPrecision);
    form.setFieldValue(field, String(newQuantity));
  };

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index}>
      <Button
        onPress={() => {
          handleDecrement();
          onDecrement();
        }}
        disabled={disabledDecrement}
        color={disabledDecrement ? colors.gray.border : colors.blue.interactive}
      >
        <Icon source={Icon.Minus} size={16} color={colors.white} />
      </Button>
      <Space width={8} />
      <FieldInput
        {...form}
        name={field}
        input={{
          disabled: disabledField,
          editable: !disabledField,
          required,
          placeholder,
          keyboardType: 'numeric',
          onChangeText: (quantity: any) => {
            form.setFieldValue(field, String(quantity));
            onChangeText();
          },
          style: {textAlign: 'center'},
        }}
        style={{flex: 1, width: fieldWidth, height: 36, alignSelf: 'flex-start', ...style}}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          handleIncrement();
          onIncrement();
        }}
        disabled={disabledIncrement}
        color={disabledIncrement ? colors.gray.border : colors.blue.interactive}
      >
        <Icon source={Icon.Plus} size={16} color={colors.white} />
      </Button>
    </Container>
  );
};

StepperInput.defaultProps = {
  onDecrement: () => {},
  onIncrement: () => {},
  disabledDecrement: false,
  disabledIncrement: false,
  disabledField: false,
  required: false,
  onChangeText: () => {},
  fieldWidth: 100,
  index: 0,
  style: {},
};

export default StepperInput;
