// Libraries
import React from 'react';

// Components
import {FlatList} from '@supermove/components';

const OrganizationTrucksList = ({trucks, extraData, renderTruck}: any) => (
  <FlatList
    data={trucks}
    keyExtractor={(truck: any) => truck.id}
    extraData={extraData}
    renderItem={({item: truck, index}: any) => renderTruck({truck, index})}
  />
);

export default OrganizationTrucksList;
