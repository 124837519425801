// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Modal, Styled} from '@supermove/components';
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CreateJobUserItem from 'modules/Job/User/Confirm/components/CreateJobUserItem';
import {CreateCrewEmployeeButton} from 'modules/User/components';

const Wrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Container = Styled.View`
  padding-vertical: 20px;
  width: 600px;
  background-color: ${colors.white};
`;

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

const Name = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  height: 280px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-horizontal: 20px;
`;

const Group = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const createJobUserForm = ({form, user, jobId, crewId}: any) => {
  const {jobUserForms} = form.values;
  const newJobUserForms = [
    ...jobUserForms,
    JobUserForm.toForm(
      JobUserForm.new({
        jobId,
        crewId,
        userId: user.id,
        firstName: user.firstName,
        position: user.position,
        branchCode: user.branchCode,
        moverPositionIds: user.moverPositions.map((moverPosition: any) => Number(moverPosition.id)),
        primaryMoverPositionId: user.moverPosition.id,
      }),
    ),
  ];

  form.setFieldValue('jobUserForms', newJobUserForms);
};

const removeJobUserForm = ({form, user}: any) => {
  const {jobUserForms} = form.values;
  const removeIndex = _.findIndex(jobUserForms, (jobUserForm) => {
    return (jobUserForm as any).userId === user.id;
  });

  const newJobUserForms = _.filter(jobUserForms, (jobUserForm, index) => {
    // @ts-expect-error TS(2367): This condition will always return 'true' since the... Remove this comment to see the full error message
    return index !== removeIndex;
  });

  form.setFieldValue('jobUserForms', newJobUserForms);
};

const CreateJobUsersForCrewModalContent = ({
  jobId,
  crewId,
  organization,
  onClose,
  onSubmit,
  refetch,
}: any) => {
  const form = useForm({
    initialValues: {
      jobUserForms: [],
    },
  });

  return (
    <Container>
      <Name>Add Movers</Name>
      <Subtitle>Select movers to assign to this job.</Subtitle>
      <Content>
        <FlatList
          data={organization.users}
          keyExtractor={(user: any) => user.id}
          extraData={form}
          renderItem={({item: user, index}: any) => {
            const jobUserForm = _.find(form.values.jobUserForms, (jobUserForm) => {
              return (jobUserForm as any).userId === user.id;
            });
            const isSelected = !!jobUserForm;

            return (
              <CreateJobUserItem
                isSelected={isSelected}
                user={user}
                onSelect={() => {
                  if (isSelected) {
                    removeJobUserForm({form, user});
                  } else {
                    createJobUserForm({form, user, jobId, crewId});
                  }
                }}
              />
            );
          }}
        />
      </Content>
      <Actions>
        {organization.canCrewCreateEmployee && (
          <CreateCrewEmployeeButton organization={organization} onClose={() => refetch()} />
        )}
        <Group>
          <Button color={colors.gray.secondary} onPress={onClose}>
            <Text>Cancel</Text>
          </Button>
          <ButtonSpace />
          <Button onPress={() => onSubmit(form.values.jobUserForms)}>
            <Text>Save</Text>
          </Button>
        </Group>
      </Actions>
    </Container>
  );
};

const CreateJobUsersForCrewModal = ({crew, isOpen, onClose, onSubmit}: any) => {
  const {loading, data, refetch} = useQuery(CreateJobUsersForCrewModal.query, {
    variables: {
      organizationId: crew.organizationId,
      role: 'employee',
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Wrapper pointerEvents={'box-none'}>
        {loading ? (
          <Indicator size={'large'} color={colors.gray.secondary} />
        ) : (
          <CreateJobUsersForCrewModalContent
            jobId={crew.jobId}
            crewId={crew.id}
            organization={data.organization}
            onClose={onClose}
            onSubmit={onSubmit}
            refetch={refetch}
          />
        )}
      </Wrapper>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateJobUsersForCrewModal.fragment = gql`
  fragment CreateJobUsersForCrewModal on Crew {
    id
    jobId
    organizationId
  }
`;

CreateJobUsersForCrewModal.query = gql`
  ${CreateJobUserItem.fragment}
  ${CreateCrewEmployeeButton.fragment}

  query CreateJobUsersForCrewModal($role: String!, $organizationId: Int!) {
    ${gql.query}
    organization(organizationId: $organizationId) {
      id
      canCrewCreateEmployee
      users: filteredUsers(role: $role) {
        id
        firstName
        position
        branchCode
        moverPositions {
          id
        }
        moverPosition {
          id
        }
        ...CreateJobUserItem
      }
      ...CreateCrewEmployeeButton
    }
  }
`;

export default CreateJobUsersForCrewModal;
