// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import ProjectBillsList from 'modules/Project/Billing/components/ProjectBillsList';

import BillBillRulesList from './BillBillRulesList';
import BillLineItemsList from './BillLineItemsList';
import BillPaymentsSummary from './BillPaymentsSummary';
import BillTotalItem from './BillTotalItem';

const Container = Styled.View`
`;

const Total = Styled.View`
`;

const Rules = Styled.View`
  margin-top: 20px;
`;

const JobBillSummary = ({hidePrices, isEstimate, showBillRules, bill}: any) => (
  <Container>
    <BillLineItemsList hidePrices={hidePrices} bill={bill} />
    <Total>
      <BillTotalItem isEstimate={isEstimate} bill={bill} />
    </Total>
    {showBillRules && (
      <Rules>
        <BillBillRulesList bill={bill} />
      </Rules>
    )}
    {!bill.isPaid && bill.hasPayments && (
      <BillPaymentsSummary isEstimate={isEstimate} bill={bill} />
    )}
  </Container>
);

const BillSummary = ({
  title,
  caption,
  isCollapsible,
  hidePrices,
  isEstimate,
  showBillRules,
  bill,
  showJobBill,
  showOpenAllButton,
  refetch,
}: any) => {
  if (showJobBill) {
    return (
      <JobBillSummary
        hidePrices={hidePrices}
        isEstimate={isEstimate}
        showBillRules={showBillRules}
        bill={bill}
      />
    );
  }
  return (
    <ProjectBillsList
      project={bill.project}
      title={title}
      caption={caption}
      showGrandTotal
      showBalance
      isCollapsible={isCollapsible}
      showPrices={!hidePrices}
      showBillRules={showBillRules}
      showOpenAllButton={showOpenAllButton}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillSummary.fragment = gql`
  ${BillBillRulesList.fragment}
  ${BillLineItemsList.fragment}
  ${BillPaymentsSummary.fragment}
  ${BillTotalItem.fragment}
  ${ProjectBillsList.fragment}

  fragment BillSummary on Bill {
    id
    isPaid
    hasPayments
    job {
      id
      hasJobFeatureEditBillingAndPayments: hasJobFeature(kind: "CREW_EDIT_BILLING_AND_PAYMENTS")
    }
    project {
      id
      ...ProjectBillsList
    }
    ...BillBillRulesList
    ...BillLineItemsList
    ...BillPaymentsSummary
    ...BillTotalItem
  }
`;

export default BillSummary;
