// Supermove
import {EmployeeForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation CreateCrewEmployeeForm($employeeForm: EmployeeForm!) {
    response: createCrewEmployee(employeeForm: $employeeForm) {
      ${gql.errors}
      user {
        id
        uuid
      }
    }
  }
`;

const useCreateCrewEmployeeForm = ({employeeForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      employeeForm: EmployeeForm.toForm(employeeForm),
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      employeeForm: EmployeeForm.toMutation(form.values.employeeForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

export default useCreateCrewEmployeeForm;
