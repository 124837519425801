// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import BackButton from 'components/BackButton';

const Footer = Styled.View`
  height: 61px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
`;

const ButtonTouchable = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color}
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const BackButtonContainer = Styled.View`
`;

const Button = ({onPress, color, disabled, children}: any) => {
  return (
    <ButtonTouchable onPress={onPress} color={color} disabled={disabled}>
      <ButtonText>{children}</ButtonText>
    </ButtonTouchable>
  );
};

const Back = ({disabled}: any) => {
  return (
    <BackButtonContainer>
      {/* @ts-expect-error TS(2322): Type '{ disabled: any; }' is not assignable to typ... Remove this comment to see the full error message */}
      <BackButton disabled={disabled} />
    </BackButtonContainer>
  );
};

const PageFooter = ({children}: any) => {
  return <Footer>{children}</Footer>;
};

PageFooter.Row = Row;
PageFooter.Button = Button;
PageFooter.BackButton = Back;

export default PageFooter;
