import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
    justify-content: flex-end;
    align-items: center;
    ${({responsive}: {responsive: ResponsiveType}) => (responsive.desktop ? 'height: 100%;' : '')}
    `;

const ListEmptyTitle = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary}
`;

const ListEmptyDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary}
  text-align: center;
`;

export interface ListEmptyComponentProps {
  responsive: ResponsiveType;
}

const ListEmptyComponent = ({responsive}: ListEmptyComponentProps) => {
  return (
    <Container responsive={responsive}>
      <Space height={24} />
      <ListEmptyTitle responsive={responsive}>No photos yet.</ListEmptyTitle>
      <Space height={16} />
      <ListEmptyDescription responsive={responsive}>
        Any photos taken with the camera or uploaded from the device will appear here.
      </ListEmptyDescription>
    </Container>
  );
};

export default ListEmptyComponent;
