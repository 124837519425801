/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App;
import DocumentItemSection from 'modules/Job/Document/components/DocumentItemSection';
import PrepareDocumentAdditionalInfoFields from 'modules/Job/Document/components/PrepareDocumentAdditionalInfoFields';

const Container = Styled.View`
`;

const CrewPrepareDocumentSignatureFields = ({documentSignature, document, form}: any) => {
  const {documentSection} = documentSignature;
  const {documentItems, documentAdditionalInfo} = documentSection;

  return (
    <Container>
      {documentItems.map((documentItem: any, index: any) => (
        <DocumentItemSection
          key={index}
          index={index}
          documentItem={documentItem}
          document={document}
          section={(kind: any) => {
            switch (kind) {
              case 'ADDITIONAL_INFO':
                return (
                  <PrepareDocumentAdditionalInfoFields
                    documentAdditionalInfo={documentAdditionalInfo}
                    document={document}
                    form={form}
                  />
                );
              default:
                return null;
            }
          }}
        />
      ))}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPrepareDocumentSignatureFields.fragment = gql`
  ${PrepareDocumentAdditionalInfoFields.fragment}
  ${DocumentItemSection.fragment}

  fragment CrewPrepareDocumentSignatureFields_Document on Document {
    id
    ...DocumentAdditionalInfoFields_Document
    ...DocumentItemSection_Document
    ...PrepareDocumentAdditionalInfoFields_Document
  }

  fragment CrewPrepareDocumentSignatureFields on DocumentSignature {
    id
    placement
    additionalInfo
    documentSection {
      title
      documentAdditionalInfo {
        ...PrepareDocumentAdditionalInfoFields
      }
      documentItems {
        ...DocumentItemSection
      }
    }
  }
`;

export default CrewPrepareDocumentSignatureFields;
