// Libraries
import React from 'react';

// Components
import {FlatList} from '@supermove/components';

type OwnProps = {
  renderUser: (...args: any[]) => any;
  users: any[];
  extraData?: any;
  renderEmpty?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof OrganizationUsersGrid.defaultProps;

// @ts-expect-error TS(7022): 'OrganizationUsersGrid' implicitly has type 'any' ... Remove this comment to see the full error message
const OrganizationUsersGrid = ({users, extraData, renderUser, renderEmpty}: Props) => (
  <FlatList
    data={users}
    keyExtractor={(user: any) => user.id}
    numColumns={6}
    extraData={extraData}
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    renderItem={({item: user}) => renderUser({user})}
    ListEmptyComponent={renderEmpty}
  />
);

OrganizationUsersGrid.defaultProps = {
  extraData: undefined,
  renderEmpty: () => null,
};

export default OrganizationUsersGrid;
