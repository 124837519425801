/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import CompleteJobMutation from 'modules/Job/Customer/Finish/Pass/components/CompleteJobMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: center;
  margin-vertical: 20px;
`;

const Button = Styled.LoadingButton`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CompleteJobModal.defaultProps;

// @ts-expect-error TS(7022): 'CompleteJobModal' implicitly has type 'any' becau... Remove this comment to see the full error message
const CompleteJobModal = ({isOpen, jobId, onSuccess, onClose}: Props) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={400}>
          <Circle>
            <Emoji component={EmojiText} name={'white_check_mark'} />
          </Circle>
          <Content>
            <Title>Complete Job</Title>
            <Subtitle>
              This will submit the job information to the office. This action cannot be undone.
            </Subtitle>
            <CompleteJobMutation
              jobId={jobId}
              onSuccess={onSuccess}
              onError={(errors: any) => console.log({errors})}
            >
              {({loading, handleSubmit}: any) => (
                <Actions>
                  <Button disabled={loading} color={colors.gray.secondary} onPress={onClose}>
                    <Text>Cancel</Text>
                  </Button>
                  <ButtonSpace />
                  <Button loading={loading} color={colors.green.status} onPress={handleSubmit}>
                    <Text>Submit</Text>
                  </Button>
                </Actions>
              )}
            </CompleteJobMutation>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

CompleteJobModal.defaultProps = {};

export default CompleteJobModal;
