// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Label = Styled.H6`
  margin-right: 20px;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Description = Styled.H8`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Input = Styled.TextInput.H7`
  width: 500px;
`;

const DocumentAdditionalInfoListV2Input = ({
  isFirst,
  name,
  values,
  item,
  field,
  setFieldValue,
  setFieldTouched,
}: any) => {
  if (_.get(field, 'isHidden') === true) {
    return null;
  }
  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Label numberOfLines={1}>{_.get(field, 'label')}</Label>
        <Description numberOfLines={1}>{_.get(field, 'description')}</Description>
        <Space height={4} />
        <Input
          name={name}
          placeholder={'Enter the value'}
          value={_.get(values, name)}
          onChangeText={(text: any) => setFieldValue(name, text)}
          onBlur={() => setFieldTouched(name, true)}
        />
      </Content>
      <Line />
    </Container>
  );
};

export default DocumentAdditionalInfoListV2Input;
