// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {Document} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import useCreateDocumentForJobMutation from 'modules/Job/Document/logic/useCreateDocumentForJobMutation';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Info = Styled.View`
  flex: 1;
`;

const Name = Styled.Text`
  ${Typography.Body1}
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const JobDocumentTemplateV2Item = ({jobId, documentTemplateV2}: any) => {
  const {navigator, params} = useNavigation();
  const {submitting, handleSubmit} = useCreateDocumentForJobMutation({
    jobId,
    template: documentTemplateV2.identifier,
    onSuccess: ({document}: any) => {
      return navigator.push('SignDocumentV2CustomerJobPage', {
        jobUuid: params.uuid,
        documentUuid: document.uuid,
        step: Document.formatStepAsParam('DURING_MOVE'),
        success: 'BACK',
      });
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <Container>
      <Content>
        <Info>
          <Name>{documentTemplateV2.name}</Name>
        </Info>
        <Actions>
          <Button
            text={'New'}
            isSubmitting={submitting}
            onPress={handleSubmit}
            style={{width: 80}}
          />
        </Actions>
      </Content>
      <Line />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDocumentTemplateV2Item.fragment = gql`
  fragment JobDocumentTemplateV2Item on DocumentTemplateV2 {
    id
    name
    identifier
  }
`;

export default JobDocumentTemplateV2Item;
