const _new = ({authToken}: any) => ({
  authToken,
  tabletPin: '',
});

const toForm = ({authToken, tabletPin}: any) => ({
  authToken,
  tabletPin,
});

const toMutation = ({authToken, tabletPin}: any) => ({
  authToken,
  tabletPin,
});

const LogInEmployeeForm = {
  new: _new,
  toForm,
  toMutation,
};

export default LogInEmployeeForm;
