// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  (inventory, {isFinalized, jobId}) => ({
    inventoryId: (inventory as any).id,
    isFinalized,
    jobId,
  }),
  gql`
    fragment UpdateInventoryIsFinalizedStatusForm_new on Inventory {
      id
    }
  `,
);

const toForm = ({inventoryId, isFinalized, jobId}: any) => ({
  inventoryId,
  isFinalized,
  jobId,
});

const toMutation = ({inventoryId, isFinalized, jobId}: any) => ({
  inventoryId,
  isFinalized,
  jobId,
});

const UpdateInventoryIsFinalizedStatusForm = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateInventoryIsFinalizedStatusForm;
