// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const UnsetCurrentJobMutation = ({userId, onSuccess, onError, children}: any) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      userId,
    }}
    mutation={UnsetCurrentJobMutation.mutation}
    onCompleted={({unsetCurrentJob: {user, errors}}: any) => {
      if (user) {
        onSuccess(user);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
UnsetCurrentJobMutation.mutation = gql`
  mutation UnsetCurrentJobMutation(
    $userId: Int!,
  ) {
    unsetCurrentJob(
      userId: $userId,
    ) {
      ${gql.errors}
      user {
        id
      }
    }
  }
`;

export default UnsetCurrentJobMutation;
