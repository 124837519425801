// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import UserAvatar from './UserAvatar';

const Touchable = Styled.Touchable`
`;

type OwnProps = {
  onSelect: (...args: any[]) => any;
  user: any;
  isSelected?: boolean;
  size?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SelectableUser.defaultProps;

// @ts-expect-error TS(7022): 'SelectableUser' implicitly has type 'any' because... Remove this comment to see the full error message
const SelectableUser = ({isSelected, size, user, onSelect}: Props) => (
  <Touchable onPress={() => onSelect(user)}>
    <UserAvatar
      color={isSelected ? colors.green.status : colors.blue.interactive}
      size={size}
      user={user}
    />
  </Touchable>
);

SelectableUser.defaultProps = {
  isSelected: false,
  size: 60,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SelectableUser.fragment = gql`
  ${UserAvatar.fragment}

  fragment SelectableUser on User {
    id
    ...UserAvatar
  }
`;

export default SelectableUser;
