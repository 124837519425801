// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CreateDriverInventoryForJobForm from '@shared/modules/Inventory/forms/CreateDriverInventoryForJobForm';

const useCreateDriverInventoryForJobMutation = ({
  createDriverInventoryForJobForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      createDriverInventoryForJobForm: CreateDriverInventoryForJobForm.toForm(
        createDriverInventoryForJobForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateDriverInventoryForJobMutation.mutation,
    variables: {
      createDriverInventoryForJobForm: CreateDriverInventoryForJobForm.toMutation(
        form.values.createDriverInventoryForJobForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateDriverInventoryForJobMutation.mutation = gql`
  mutation useCreateDriverInventoryForJobMutation($createDriverInventoryForJobForm: CreateDriverInventoryForJobForm!) {
    response: createDriverInventoryForJob(createDriverInventoryForJobForm: $createDriverInventoryForJobForm) {
      ${gql.errors}
      driverInventory {
        id
        uuid
      }
    }
  }
`;

export default useCreateDriverInventoryForJobMutation;
