// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Job, Project} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// Components
import {Navigation} from 'modules/App/components';

const getSubtitle = (job: any) => {
  const today = Datetime.toDisplayDate(Datetime.today, 'YYYY/MM/DD');
  const jobCounterText = Job.getEstimatedRangeJobCounter(job, today);
  const jobNameText = Job.getDisplayText(job);
  if (jobCounterText) {
    return `[${jobCounterText}] ${jobNameText}`;
  }
  return jobNameText;
};

const JobNavigation = ({loading, data, navigator, params, ...props}: any) => {
  return (
    <Navigation
      getSideWidth={({responsive}: any) => 60}
      title={loading ? 'Loading...' : Project.getDisplayText(data.job.project)}
      subtitle={loading ? '' : getSubtitle(data.job)}
      renderLeft={() => <Navigation.CloseButton onPress={() => navigator.goBack()} />}
      {...props}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNavigation.fragment = gql`
  ${Job.getDisplayText.fragment}
  ${Job.getEstimatedRangeJobCounter.fragment}
  ${Project.getDisplayText.fragment}

  fragment JobNavigation on Job {
    id
    project {
      id
      ...Project_getDisplayText
    }
    ...Job_getDisplayText
    ...Job_getEstimatedRangeJobCounter
  }
`;

export default JobNavigation;
