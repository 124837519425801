// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: ${(props) => ((props as any).editable ? 10 : 5)}px;
  padding-horizontal: 10px;
`;

const Name = Styled.H5`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Value = Styled.H5`
  width: 200px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: right;
`;

const BillPaymentItem = ({isFirst, isLast, payment}: any) => (
  <Container>
    <Content>
      <Name numberOfLines={1}>{`Paid by ${payment.displayMethod}`}</Name>
      <Value>{Currency.display(payment.totalAmount, {shouldHideCentsIfZero: true})}</Value>
    </Content>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
BillPaymentItem.fragment = gql`
  fragment BillPaymentItem on Payment {
    id
    displayMethod
    totalAmount
  }
`;

export default BillPaymentItem;
