// Supermove
import {Json} from '@supermove/utils';

const _new = ({organizationId}: any) => ({
  organizationId,
  names: '["", ""]',
  position: '',
  branchCode: '',
  features: '{"VIEW_CUSTOMER_INFO": false}',
  moverPositionIds: [],
});

const toForm = ({
  organizationId,
  names,
  position,
  branchCode,
  features,
  moverPositionIds,
}: any) => ({
  organizationId,
  names: Json.toForm(names),
  position,
  branchCode,
  features: Json.toForm(features),
  moverPositionIds,
});

const toMutation = ({
  organizationId,
  names,
  position,
  branchCode,
  features,
  moverPositionIds,
}: any) => ({
  organizationId,
  names: Json.toMutation(names),
  position,
  branchCode,
  moverPositionIds,

  // Special case since we want our keys to be {"VIEW_CUSTOMER_INFO": false}
  features: Json.stringify(Json.toUpperCaseKeys(Json.toSnakeCaseKeys(features))),
});

const EmployeeForm = {
  new: _new,
  toForm,
  toMutation,
};

export default EmployeeForm;
