// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  height: ${(props) => (props as any).size}px;
  width: ${(props) => (props as any).size}px;
  border-radius: ${(props) => (props as any).size / 2}px;
  background-color: ${(props) => (props as any).color};
`;

const Text = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {
  color?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Avatar.defaultProps;

// @ts-expect-error TS(7022): 'Avatar' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Avatar = ({text, size, color}: Props) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container color={color} size={size}>
    <Text numberOfLines={1}>{text}</Text>
  </Container>
);

Avatar.defaultProps = {
  color: colors.blue.interactive,
};

export default Avatar;
