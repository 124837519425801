/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding-top: 10px;
`;

const Text = Styled.H6`
  color: ${colors.gray.primary};
`;

const Empty = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const JobProjectBlock = ({job}: any) => {
  return (
    <Container>
      <Title>Project Description</Title>
      <Content>
        {job.project.description ? <Text>{job.project.description}</Text> : <Empty>None</Empty>}
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectBlock.fragment = gql`
  fragment JobProjectBlock on Job {
    id
    project {
      id
      description
    }
  }
`;

export default JobProjectBlock;
