// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

export interface ErrorSavingAttachmentsModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ErrorSavingAttachmentsModal = ({isOpen, handleClose}: ErrorSavingAttachmentsModalProps) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={'The photos could not be saved.'}
      message={'Please try again.'}
      primaryActionText={'Close'}
      handlePrimaryAction={handleClose}
      handlePressOutside={handleClose}
      isMobileSheet={false}
    />
  );
};

export default ErrorSavingAttachmentsModal;
