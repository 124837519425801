/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ValuationCoverageForm from '@shared/modules/Insurance/forms/ValuationCoverageForm';
import useUpdateValuationCoverageForm from '@shared/modules/Insurance/hooks/useUpdateValuationCoverageForm';
import Field from 'modules/App/components/Field';
import FieldValue from 'modules/App/components/FieldValue';

const Container = Styled.View`
`;

const Content = Styled.View`
  z-index: 100;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Label = Styled.H7`
  margin-bottom: 5px;
`;

const Actions = Styled.View`
  margin-top: 20px;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const getValuationCoverageDropdownOptions = ({valuationCoverageOptions}: any) => {
  // @ts-expect-error TS(7006): Parameter 'valuationCoverageOption' implicitly has... Remove this comment to see the full error message
  return valuationCoverageOptions.map((valuationCoverageOption, index) => ({
    label: valuationCoverageOption.displayName,
    value: index,
  }));
};

const getAmountValueOptions = ({values, valuationCoverageMinimum}: any) => {
  const filteredValues = values.filter((value: any) => value >= valuationCoverageMinimum);
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  return filteredValues.map((value, index) => ({
    label: Currency.display(value),
    value: Currency.toForm(value),
  }));
};

const getAdditionalCostFromValuationCoverageOption = ({
  amountValue,
  values,
  valuationCoverageOption,
}: any) => {
  const index = _.findIndex(values, (value) => value === amountValue);
  return _.get(valuationCoverageOption, `values.${index}`);
};

const getAdditionalCost = ({form, values, valuationCoverageOption}: any) => {
  if (!valuationCoverageOption) {
    return 0;
  }
  const amountValue = Currency.toMutation(form.values.valuationCoverageForm.amountValue);
  const {transportationRateAmount, transportationRatePer} = valuationCoverageOption;

  if (values.length > 0) {
    return getAdditionalCostFromValuationCoverageOption({
      amountValue,
      values,
      valuationCoverageOption,
    });
  }

  if (!transportationRateAmount || !transportationRatePer) {
    return 0;
  }

  return (amountValue * transportationRateAmount) / transportationRatePer;
};

const DocumentAdditionalInfoValuationCoverageFields = ({document, form: parentForm}: any) => {
  const {valuationCoverage} = document.job;
  const valuationCoverageForm = ValuationCoverageForm.edit(valuationCoverage);
  const {form, submitting, handleSubmit} = useUpdateValuationCoverageForm({
    valuationCoverageForm,
    onSuccess: () => {
      // After we have updated the valuation coverage or submitted it, we then
      // submit the parentForm which sets this document section as prepared.
      parentForm.handleSubmit();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const {valuationCoverageMinimum} = document.job.project;
  const {valuationCoverageOptions, values} = document.job.jobType.valuationCoverageConfig;
  const {selectedIndex} = form.values.valuationCoverageForm;
  const selectedValuationCoverageOption = _.get(valuationCoverageOptions, selectedIndex);
  const additionalCost = getAdditionalCost({
    form,
    values,
    valuationCoverageOption: selectedValuationCoverageOption,
  });

  return (
    <Container>
      <Content>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section index={0}>
          <Label>Selected valuation option</Label>
          <Field
            {...form}
            component={DropdownInput}
            name={'valuationCoverageForm.selectedIndex'}
            input={{
              options: getValuationCoverageDropdownOptions({valuationCoverageOptions}),
              placeholder: 'Select one',
              setFieldValue: (name: any, value: any) => {
                const valuationCoverageOption = _.get(valuationCoverageOptions, value);
                const {category, deductible, amountMinimum} = valuationCoverageOption;
                const deductibleInCents = deductible === null ? null : Currency.toForm(deductible);
                const amountMinimumInCents =
                  amountMinimum === null ? null : Currency.toForm(amountMinimum);

                form.setFieldValue(name, value);
                form.setFieldValue('valuationCoverageForm.category', category);
                form.setFieldValue('valuationCoverageForm.deductible', deductibleInCents);
                form.setFieldValue('valuationCoverageForm.amountValue', amountMinimumInCents);
              },
              style: {
                width: 300,
              },
            }}
          />
          <ValidationError>{_.get(form.errors, 'valuationCoverageForm.category')}</ValidationError>
        </Section>
        <React.Fragment>
          <Space height={20} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={1}>
            <Label>Enter the entire value of the contents you will be shipping today:</Label>
            {values.length > 0 ? (
              <Field
                {...form}
                component={DropdownInput}
                name={'valuationCoverageForm.amountValue'}
                input={{
                  options: getAmountValueOptions({values, valuationCoverageMinimum}),
                  placeholder: 'Select option',
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  style: {
                    width: 300,
                  },
                }}
              />
            ) : (
              <Field
                {...form}
                component={CurrencyInput}
                name={'valuationCoverageForm.amountValue'}
                input={{
                  component: TextInput,
                  placeholder: 'Enter Amount',
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  style: {
                    width: 300,
                  },
                }}
              />
            )}
          </Section>
          <Space height={20} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={2}>
            <Label>Additional cost for non-basic valuation coverage</Label>
            <FieldValue numberOfLines={1} value={Currency.display(additionalCost)} />
          </Section>
        </React.Fragment>
      </Content>
      <Actions>
        <Button loading={submitting || parentForm.loading} onPress={handleSubmit}>
          <Text>Save</Text>
        </Button>
      </Actions>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentAdditionalInfoValuationCoverageFields.fragment = gql`
  ${ValuationCoverageForm.edit.fragment}

  fragment DocumentAdditionalInfoValuationCoverageFields on Document {
    id
    job {
      id
      jobType {
        id
        valuationCoverageConfig {
          values
          valuationCoverageOptions {
            displayName
            category
            deductible
            amountMinimum
            transportationRateAmount
            transportationRatePer
            values
          }
        }
      }
      project {
        id
        valuationCoverageMinimum
      }
      valuationCoverage {
        id
        amountMinimum
        amountInterval
        ...ValuationCoverageForm_edit
      }
    }
  }
`;

export default DocumentAdditionalInfoValuationCoverageFields;
