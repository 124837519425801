// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, SectionList, Styled, Switch} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useIsFocused, useNavigation, useQuery, useState} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {Platform} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CrewVisibleJobsForMover from '@shared/modules/Settings/enums/CrewVisibleJobsForMover';
// @ts-expect-error TS(2305): Module '"modules/Job/components"' has no exported ... Remove this comment to see the full error message
import {JobItem, PageLoadingIndicator} from 'modules/Job/components';

// Components
import JobDateItem from './JobDateItem';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  height: 60px;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Title = Styled.H3`
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Group = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-horizontal: 10px;
`;

const FilterText = Styled.H7`
  margin-right: 5px;
  color: ${colors.gray.primary};
`;

const JobWrapper = Styled.View`
`;

const DateItem = Styled.View`
`;

const JobsEmpty = Styled.View`
  align-items: center;
  padding-horizontal: 30px;
  padding-vertical: 80px;
`;

const JobsEmptyMessage = Styled.H5`
  color: ${colors.gray.primary};
  text-align: center;
`;

const filteredJobs = ({jobs, data}: any) => {
  if (_.get(data, 'viewer.organization.features.isEnabledCrewShowCancelledJobs')) {
    return jobs;
  }
  const activeJobs = _.filter(jobs, (job) => !job.isCancelled);
  return activeJobs;
};

const getJobs = ({filter, data}: any) => {
  const jobUsers = _.get(data, 'viewer.filteredJobUsersV2', []);
  const viewerJobs = _.uniqBy(
    _.map(jobUsers, (jobUser) => jobUser.job),
    (job) => job.id,
  );
  const organizationJobs = _.get(data, 'viewer.organization.filteredMoveJobs', []);

  switch (filter) {
    case 'VIEWER':
      return filteredJobs({jobs: viewerJobs, data});
    case 'ORGANIZATION':
      return filteredJobs({jobs: organizationJobs, data});
    default:
      return [];
  }
};

const getJobDate = ({job}: any) => {
  // All jobs should be for today
  if (job.day) {
    return Datetime.toDisplayDate(Datetime.fromDate(job.day.value), 'YYYY/MM/DD');
  }
  // Also return today's date for all estimated range jobs. This will
  // group in estimated range jobs with today's jobs.
  if (Job.getIsEstimatedRange(job)) {
    return Datetime.toDisplayDate(Datetime.today, 'YYYY/MM/DD');
  }
  return '';
};

const getSections = ({filter, data}: any) => {
  const createSection = (sections: any, jobs: any, date: any) => {
    return [...sections, {date, data: _.orderBy(jobs, ['startTime1'])}];
  };

  const jobs = getJobs({filter, data});
  const groups = _.groupBy(jobs, (job) => getJobDate({job}));
  const sections = _.reduce(groups, createSection, []);
  return _.orderBy(sections, ['date']).map((section, index) => ({
    ...section,
    index,
  }));
};

const getCanSeeToggle = (data: any) => {
  const isAllOrganizationJobsVisible =
    data.viewer.organization.settings.crewVisibleJobsForMover ===
    CrewVisibleJobsForMover.ALL_ORGANIZATION_JOBS;

  if (!isAllOrganizationJobsVisible) {
    return false;
  }

  // The user can see the toggle if they don't have a tablet pin.
  return !_.get(data, 'viewer.hasTabletPin');
};

const JobsListContent = ({loading, data, jobsListDate, refetch}: any) => {
  const {params} = useNavigation();
  const isFocused = useIsFocused({
    onFocus: () => refetch(),
  });
  const [filter, setFilter] = useState('VIEWER');
  const canSeeToggle = getCanSeeToggle(data);

  return (
    <Container>
      <Header>
        <Title>Today's Jobs</Title>
        <Group>
          {canSeeToggle && (
            <React.Fragment>
              <FilterText>Only Your Jobs</FilterText>
              <Switch
                // @ts-expect-error TS(2322): Type '{ isOn: boolean; onTintColor: "#276EF1"; thu... Remove this comment to see the full error message
                isOn={filter === 'VIEWER'}
                onTintColor={colors.blue.interactive}
                thumbTintColor={Platform.select({
                  // On iOS, we want to keep the drop shadow which gets
                  // disabled if we set a color.
                  ios: undefined,
                  android: colors.white,
                })}
                onChange={(isOn: any) => setFilter(isOn ? 'VIEWER' : 'ORGANIZATION')}
              />
            </React.Fragment>
          )}
        </Group>
      </Header>
      <SectionList
        refreshing={!isFocused}
        sections={getSections({filter, data})}
        keyExtractor={(job: any) => job.id}
        extraData={data}
        onRefresh={refetch}
        // @ts-expect-error TS(7031): Binding element 'job' implicitly has an 'any' type... Remove this comment to see the full error message
        renderItem={({item: job}) => (
          <JobWrapper>
            <JobItem
              isSelected={job.uuid === params.uuid}
              job={job}
              jobsListDate={jobsListDate}
              refetch={refetch}
            />
          </JobWrapper>
        )}
        renderSectionHeader={({section: {index, date}}: any) => (
          <DateItem>
            <JobDateItem date={date} />
          </DateItem>
        )}
        ListEmptyComponent={() => (
          <JobsEmpty>
            <JobsEmptyMessage>
              {filter === 'VIEWER'
                ? 'You do not have any jobs assigned to you for today.'
                : 'There are no jobs for today.'}
            </JobsEmptyMessage>
          </JobsEmpty>
        )}
      />
    </Container>
  );
};

const JobsList = () => {
  const jobsListDate = Datetime.toDate(Datetime.today);
  const isFocused = useIsFocused();
  const {loading, data, refetch} = useQuery(JobsList.query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      date: jobsListDate,
      kinds: [],
      useProjectStatusFilter: true,
    },
  });

  useEffect(() => {
    // Instead of polling the query, we use this setInterval to refetch the list
    // when a date change is detected. We are unable to utilize pollInterval and
    // skip from graphql because if we skip the poll, rather than persisting the
    // data from the previous fetch, the data gets cleared.
    const handleChangeDate = setInterval(() => {
      const dateAtThisMoment = Datetime.toDate(Datetime.today);
      if (jobsListDate !== dateAtThisMoment) {
        refetch();
      }
    }, 1000 * 30);

    // On mobile the component does not unmount as we navigate to the next page,
    // so we handle cleaning up the setInterval by watching the isFocused flag.
    if (!isFocused) {
      clearInterval(handleChangeDate);
    }

    // On web isFocused is always true, but the component unmounts everytime the
    // user navigates away, so we can handle the cleanup here. Also, with each
    // subsequent run of this useEffect, this return statement ensures that the
    // setInterval gets cleaned up before the next run creates it again.
    return () => {
      clearInterval(handleChangeDate);
    };
  }, [isFocused, jobsListDate, refetch]);

  return (
    <Loading loading={!data && loading} as={PageLoadingIndicator}>
      {() => (
        <JobsListContent
          loading={loading}
          data={data}
          jobsListDate={jobsListDate}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobsList.query = gql`
  ${JobItem.fragment}
  ${Job.getIsEstimatedRange.fragment}

  query JobsList($date: String!, $kinds: [String]!, $useProjectStatusFilter: Boolean!) {
    ${gql.query}
    viewer {
      id
      hasTabletPin
      filteredJobUsersV2(date: $date, kinds: $kinds, useProjectStatusFilter: $useProjectStatusFilter ) {
        jobId
        userId
        job {
          id
          uuid
          startTime1
          isCancelled
          day {
            id
            value
          }
          ...JobItem
          ...Job_getIsEstimatedRange
        }
      }
      organization {
        id
        settings {
          id
          crewVisibleJobsByProjectStatus
          crewVisibleJobsForMover
        }
        features {
          isEnabledCrewShowCancelledJobs: isEnabled(feature: "CREW_SHOW_CANCELLED_JOBS")
        }
        filteredMoveJobs(date: $date) {
          id
          uuid
          startTime1
          isCancelled
          day {
            id
            value
          }
          ...JobItem
          ...Job_getIsEstimatedRange
        }
      }
    }
  }
`;

export default JobsList;
