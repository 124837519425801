// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CreateBulkAttachmentsForm, {
  CreateAttachmentsFormType,
} from '@shared/modules/File/forms/BulkCreateAttachmentsForm';
import {CreateAttachmentFormType} from '@shared/modules/File/forms/CreateAttachmentForm';

const useCreateAttachmentsMutation = ({
  createAttachmentForms,
  onSuccess,
  onError,
}: {
  createAttachmentForms: CreateAttachmentFormType[];
  onSuccess: () => void;
  onError: (e: any) => void;
}) => {
  const form = useForm<CreateAttachmentsFormType>({
    initialValues: {
      createAttachmentForms: CreateBulkAttachmentsForm.toForm({
        createAttachmentForms,
      }).createAttachmentForms,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateAttachmentsMutation.mutation,
    variables: {
      createAttachmentsForm: {
        createAttachmentForms: CreateBulkAttachmentsForm.toMutation({
          createAttachmentForms: form.values.createAttachmentForms,
        }).createAttachmentForms,
      },
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateAttachmentsMutation.mutation = gql`
  mutation useCreateAttachmentsMutation($createAttachmentsForm: CreateAttachmentsForm!) {
    response: createAttachments(createAttachmentsForm: $createAttachmentsForm) {
      ${gql.errors}
    }
  }
`;

export default useCreateAttachmentsMutation;
