const _new = ({jobId, jobStepKind}: any) => ({
  jobId,
  jobStepKind,
});

const toForm = ({jobId, jobStepKind}: any) => ({
  jobId,
  jobStepKind,
});

const toMutation = ({jobId, jobStepKind}: any) => ({
  jobId,
  jobStepKind,
});

const JobEventForm = {
  new: _new,
  toForm,
  toMutation,
};

export default JobEventForm;
