/**
 * If you make changes to this file, make sure to update the web one at
 * CustomerDocumentSignatureItem.
 */

// Libraries
import React, {useState} from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Section = Styled.View`
`;

const Table = Styled.View`
`;

const TableHeader = Styled.View`
  flex-direction: row;
`;

const TableCell = Styled.View`
  width: 120px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const TableCellHeaderText = Styled.H8`
  ${fontWeight(700)}
`;

const TableCellText = Styled.H8`
`;

const TableRow = Styled.View`
  flex-direction: row;
`;

const ValuationDeclarationTableButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ValuationDeclarationTableButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const ValuationDeclarationTable = ({job}: any) => {
  const {valuationCoverageTable} = job.jobType.valuationCoverageConfig;
  const [isShowingValuationCoverageTable, setIsShowingValuationCoverageTable] = useState(false);

  return (
    <Section>
      <ValuationDeclarationTableButton
        onPress={() => setIsShowingValuationCoverageTable(!isShowingValuationCoverageTable)}
      >
        <ValuationDeclarationTableButtonText>{`${
          isShowingValuationCoverageTable ? 'Hide' : 'Show'
        } Rates Table`}</ValuationDeclarationTableButtonText>
        <Space width={8} />
        <Icon
          color={colors.blue.interactive}
          size={18}
          source={isShowingValuationCoverageTable ? Icon.CaretUp : Icon.CaretDown}
        />
      </ValuationDeclarationTableButton>
      {isShowingValuationCoverageTable && (
        <Table>
          {valuationCoverageTable.map((row: any, rowIndex: any) => {
            if (rowIndex === 0) {
              return (
                <TableHeader key={rowIndex}>
                  {row.map((column: any, columnIndex: any) => (
                    <TableCell key={columnIndex}>
                      <TableCellHeaderText>{column}</TableCellHeaderText>
                    </TableCell>
                  ))}
                </TableHeader>
              );
            }

            return (
              <TableRow key={rowIndex}>
                {row.map((column: any, columnIndex: any) => (
                  <TableCell key={columnIndex}>
                    <TableCellText>{column}</TableCellText>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </Table>
      )}
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ValuationDeclarationTable.fragment = gql`
  fragment ValuationDeclarationTable on Job {
    id
    jobType {
      id
      valuationCoverageConfig {
        valuationCoverageTable
      }
    }
  }
`;

export default ValuationDeclarationTable;
