// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {Form} from '@supermove/hooks';
import {pluralize} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import {CreateAttachmentsFormType} from '@shared/modules/File/forms/BulkCreateAttachmentsForm';

const getErrorCount = (createAttachmentErrors?: string | unknown[]) => {
  if (!createAttachmentErrors) {
    return 0;
  }
  if (Array.isArray(createAttachmentErrors)) {
    return createAttachmentErrors.filter(Boolean).length;
  }
  return 1;
};

export interface JobAttachmentErrorBannerProps {
  createAttachmentsForm: Form<CreateAttachmentsFormType>;
}

const JobAttachmentErrorBanner = ({createAttachmentsForm}: JobAttachmentErrorBannerProps) => {
  const photosErrored = getErrorCount(createAttachmentsForm.errors.createAttachmentForms);

  if (!photosErrored) {
    return null;
  }

  return (
    <React.Fragment>
      <ErrorCallout
        text={`${pluralize('photo', photosErrored, true)} could not be uploaded. Please remove them and try again.`}
      />
      <Space height={24} />
    </React.Fragment>
  );
};

export default JobAttachmentErrorBanner;
