// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const PaymentSummaryTitle = Styled.Text`
  ${Typography.Heading2}
`;

const PaymentSummaryRows = Styled.View`
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
`;

const PaymentSummaryRowContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  padding-vertical: 12px;
  padding-horizontal: 24px;
  ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => (isLast ? '' : 'border-bottom-width: 1px;')}
  ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => (isLast ? '' : `border-bottom-color: ${colors.gray.border};`)}
`;

const PaymentSummaryText = Styled.Text`
  ${Typography.Mobile.Body}
  ${(props) => (props as any).style}
`;

const PaymentSummaryRow = ({description, amount, style, isLast}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <PaymentSummaryRowContainer isLast={isLast}>
      <PaymentSummaryText style={style}>{description}</PaymentSummaryText>
      <PaymentSummaryText style={style}>{amount}</PaymentSummaryText>
    </PaymentSummaryRowContainer>
  );
};

const PaymentSummaryTable = ({rows}: any) => {
  return (
    <React.Fragment>
      <PaymentSummaryTitle>Payment Summary</PaymentSummaryTitle>
      <Space height={16} />
      <PaymentSummaryRows>
        {rows
          .filter((row: any) => !row.isHidden)
          .map((row: any, index: any) => (
            <PaymentSummaryRow
              key={index}
              description={row.description}
              amount={row.amount}
              style={row.style}
              isLast={index === rows.length - 1}
            />
          ))}
      </PaymentSummaryRows>
      <Space height={24} />
    </React.Fragment>
  );
};

export default PaymentSummaryTable;
