// Libraries
import _ from 'lodash';
import React from 'react';

// Components
import {FlatList, Styled} from '@supermove/components';

import EmployeeMoveTimeItem from './EmployeeMoveTimeItem';
import MoveAddTimeRangeItem from './MoveAddTimeRangeItem';
import MoveAddTimeRangeModal from './MoveAddTimeRangeModal';

const Time = Styled.View`
`;

const hasAllEndTimes = ({form}: any) => {
  const {timeRanges} = form.values;
  return _.every(timeRanges, (timeRange) => !!timeRange.end);
};

const getInitialStart = ({form}: any) => {
  const {timeRanges} = form.values;
  const lastTimeRange = _.last(timeRanges);
  const previousEnd = _.get(lastTimeRange, 'end');
  return previousEnd || '';
};

const EmployeeMoveTimesList = ({organization, form}: any) => (
  <MoveAddTimeRangeModal
    initialStart={getInitialStart({form})}
    organization={organization}
    parentForm={form}
    trigger={({handleOpen}: any) => (
      <FlatList
        data={form.values.timeRanges}
        keyExtractor={(timeRange: any, index: any) => String(index)}
        extraData={form}
        renderItem={({item: timeRange, index}: any) => (
          <Time>
            <EmployeeMoveTimeItem
              isFirst={index === 0}
              isLast={index === form.values.timeRanges.length - 1}
              index={index}
              timeRange={timeRange}
              organization={organization}
              form={form}
            />
          </Time>
        )}
        ListFooterComponent={() => (
          <MoveAddTimeRangeItem hasAllEndTimes={hasAllEndTimes({form})} onOpen={handleOpen} />
        )}
      />
    )}
  />
);

export default EmployeeMoveTimesList;
