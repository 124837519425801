// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommercialCatalogForm from '@shared/modules/CommercialCatalog/forms/CommercialCatalogForm';

const useUpdateCommercialCatalogMutation = ({
  kind,
  commercialCatalogForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      kind,
      commercialCatalogForm: CommercialCatalogForm.toForm(commercialCatalogForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCommercialCatalogMutation.mutation,
    variables: {
      kind: form.values.kind,
      commercialCatalogForm: CommercialCatalogForm.toMutation(form.values.commercialCatalogForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCommercialCatalogMutation.mutation = gql`
  mutation useUpdateCommercialCatalogMutation(
    $kind: String!,
    $commercialCatalogForm: CommercialCatalogForm!,
  ) {
    response: updateCommercialCatalog(kind: $kind, commercialCatalogForm: $commercialCatalogForm) {
      ${gql.errors}
      commercialCatalog {
        id
      }
    }
  }
`;

export default useUpdateCommercialCatalogMutation;
