// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import AddTimesheetPayrollBlockModal from '@shared/modules/Timesheet/components/AddTimesheetPayrollBlockModal';
import TimesheetPayrollEntries from '@shared/modules/Timesheet/components/TimesheetPayrollEntries';
import useCreateJobEventPayrollTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventPayrollTimesheetUpdatedMutation';

const Container = Styled.View`
  flex: 1;
`;

const PageHeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 24px;
`;

const PageHeaderText = Styled.Text`
  ${Typography.Mobile.PageHeading}
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: 24px;
  padding-vertical: 16px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const PageButton = (props: any) => {
  return <Button {...props} width={140} isResponsive />;
};

const PageHeader = ({job, onUpdate}: any) => {
  const addTimesheetPayrollBlockModal = useModal({name: 'Add Timesheet Payroll Block Modal'});
  return (
    <PageHeaderRow>
      <PageHeaderText>Crew Hours</PageHeaderText>
      <Space style={{flex: 1}} />
      <PageButton
        iconLeft={Icon.Plus}
        text={'Add Time'}
        onPress={addTimesheetPayrollBlockModal.handleOpen}
      />
      <AddTimesheetPayrollBlockModal
        key={addTimesheetPayrollBlockModal.key}
        isOpen={addTimesheetPayrollBlockModal.isOpen}
        handleClose={addTimesheetPayrollBlockModal.handleClose}
        job={job}
        onUpdate={onUpdate}
      />
    </PageHeaderRow>
  );
};

const PayrollTimesheetPageContent = ({job, handleSubmit, isSubmitting, refetch}: any) => {
  const {navigator} = useNavigation();
  const [isUpdated, setIsUpdated] = useState(false);

  const createJobEventTimesheetUpdated = useCreateJobEventPayrollTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: () => setIsUpdated(true),
    onError: (errors: any) => console.log({errors}),
  });

  const onUpdate = () => {
    if (!isUpdated) {
      createJobEventTimesheetUpdated.handleSubmit();
    }
    refetch();
  };

  return (
    <Container>
      <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={false}>
        <Space height={24} />
        <PageHeader job={job} onUpdate={onUpdate} />
        <Space height={24} />
        <TimesheetPayrollEntries job={job} onUpdate={onUpdate} />
        <Space height={24} />
      </ScrollView>
      {handleSubmit && (
        <Footer>
          <PageButton
            text={'Back'}
            onPress={() => navigator.goBack()}
            color={colors.gray.secondary}
          />
          <Space style={{flex: 1}} />
          <SecondaryButton
            text={'Assign Movers'}
            isResponsive
            onPress={() => navigator.navigate('MoversCrewJob', {uuid: job.uuid})}
          />
          <Space width={16} />
          <PageButton
            text={'Next'}
            onPress={handleSubmit}
            isSubmitting={isSubmitting}
            color={colors.blue.interactive}
          />
        </Footer>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayrollTimesheetPageContent.fragment = gql`
  ${AddTimesheetPayrollBlockModal.fragment}
  ${TimesheetPayrollEntries.fragment}

  fragment PayrollTimesheetPageContent on Job {
    id
    uuid
    ...AddTimesheetPayrollBlockModal
    ...TimesheetPayrollEntries
  }
`;

export default PayrollTimesheetPageContent;
