// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const CreateCustomerPaymentProfileMutation = ({
  customerId,
  paymentMethodId,
  onSuccess,
  onError,
  children,
}: any) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      customerId,
      paymentMethodId,
    }}
    mutation={CreateCustomerPaymentProfileMutation.mutation}
    onCompleted={({response: {account, error, errors}}: any) => {
      if (!error && !errors) {
        onSuccess(account);
      } else {
        onError({error, errors});
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCustomerPaymentProfileMutation.mutation = gql`
  mutation CreateCustomerPaymentProfileMutation(
    $customerId: Int!,
    $paymentMethodId: String!,
  ) {
    response: createCustomerPaymentProfile(
      customerId: $customerId,
      paymentMethodId: $paymentMethodId,
    ) {
      ${gql.errors}
      error
      account {
        id
        stripePaymentMethodId
      }
    }
  }
`;

export default CreateCustomerPaymentProfileMutation;
