// Libraries
import {gql} from '@supermove/graphql';
import {useEffect, useMutation, useState} from '@supermove/hooks';
import {DeviceInfo} from '@supermove/sdk';

const MUTATION = gql`
  mutation RegisterDeviceMutation(
    $userId: Int,
    $appName: String,
    $appPlatform: String,
    $releaseBuild: Int,
    $deviceUniqueId: String,
    $deviceBrand: String,
    $deviceManufacturer: String,
    $deviceModel: String,
    $deviceOs: String,
    $deviceOsVersion: String,
    $deviceUserAgent: String,
  ) {
    registerDevice(
      userId: $userId,
      appName: $appName,
      appPlatform: $appPlatform,
      releaseBuild: $releaseBuild,
      deviceUniqueId: $deviceUniqueId,
      deviceBrand: $deviceBrand,
      deviceManufacturer: $deviceManufacturer,
      deviceModel: $deviceModel,
      deviceOs: $deviceOs,
      deviceOsVersion: $deviceOsVersion,
      deviceUserAgent: $deviceUserAgent,
    ) {
      ${gql.errors}
      device {
        id
      }
    }
  }
`;

const useRegisterDeviceMutation = ({appName, releaseBuild, userId}: any) => {
  const [deviceId, setDeviceId] = useState();
  const [errors, setErrors] = useState();
  const [handleSubmit, {loading}] = useMutation(MUTATION, {
    onCompleted: ({registerDevice: {device, errors}}) => {
      if (device) {
        console.log(`[Device]: Registered device: ${device.id}.`);
        setDeviceId(device.id);
      } else {
        console.log(`[Device]: Error when registering: "${appName}" for user: ${userId}.`);
        setErrors(errors);
      }
    },
  });

  useEffect(() => {
    const registerDevice = async () => {
      if (!appName || !releaseBuild || !userId) {
        return;
      }

      const deviceManufacturer = await DeviceInfo.getManufacturer();
      const deviceUserAgent = await DeviceInfo.getUserAgent();

      return handleSubmit({
        variables: {
          userId,
          appName,
          appPlatform: DeviceInfo.getPlatform(),
          releaseBuild,
          deviceUniqueId: DeviceInfo.getUniqueId(),
          deviceBrand: DeviceInfo.getBrand(),
          deviceManufacturer,
          deviceModel: DeviceInfo.getDeviceId(),
          deviceOs: DeviceInfo.getSystemName(),
          deviceOsVersion: DeviceInfo.getSystemVersion(),
          deviceUserAgent,
        },
      });
    };

    registerDevice();
  }, [appName, releaseBuild, userId, handleSubmit]);

  return {
    loading,
    deviceId,
    errors,
  };
};

export default useRegisterDeviceMutation;
