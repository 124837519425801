// Libraries
import _ from 'lodash';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import Location from '@supermove/models/src/Location';
import {colors} from '@supermove/styles';
import {Currency, Datetime, titleize, withFragment} from '@supermove/utils';

// App
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import StorageProjectStatus from '@shared/modules/Project/enums/StorageProjectStatus';

import Organization from './Organization';

const getName = withFragment(
  (project) => {
    if ((project as any).name) {
      return `Project ${(project as any).identifier}: ${(project as any).name}`;
    }
    return `Project ${(project as any).identifier}`;
  },
  gql`
    fragment Project_getName on Project {
      id
      identifier
      name
    }
  `,
);

const getDate = withFragment(
  (project) => {
    if (!(project as any).startDate) {
      return 'TBD';
    }

    const startDate = Datetime.convertToDisplayDate(
      (project as any).startDate,
      Datetime.DISPLAY_SHORT_DATE,
    );
    const endDate = Datetime.convertToDisplayDate(
      (project as any).endDate,
      Datetime.DISPLAY_SHORT_DATE,
    );
    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} - ${endDate}`;
  },
  gql`
    fragment Project_getDate on Project {
      id
      startDate
      endDate
    }
  `,
);

const getLocation = withFragment(
  (project) => {
    // @ts-expect-error TS(2339): Property 'origin' does not exist on type 'unknown'... Remove this comment to see the full error message
    const {origin, destination} = project;

    if (origin && destination) {
      return `${Location.getDisplayCityState(origin)} - ${Location.getDisplayCityState(
        destination,
      )}`;
    }

    if (origin || destination) {
      return Location.getDisplayCityState(origin || destination);
    }

    return 'TBD';
  },
  gql`
    ${Location.getDisplayCityState.fragment}
    fragment Project_getLocation on Project {
      id
      origin {
        id
        ...Location_getDisplayCityState
      }
      destination {
        id
        ...Location_getDisplayCityState
      }
    }
  `,
);

const getProjectAndActiveJobsText = withFragment(
  (project) => {
    const jobStrings = (project as any).activeJobs.map((job: any) => {
      const dateText = Job.getDisplayDate(job, 'M/DD', 'TBD');
      return `Job ${job.identifier} (${dateText})`;
    });
    return `Project ${(project as any).identifier}: ${jobStrings.join(', ')}`;
  },
  // Note(dan): We should be using Job.getDisplayDate.fragment below,
  // but we are unable to because of the circular dependency between
  // Job and Project. Fields required for Job.getDisplayDate have been
  // independently added instead.
  gql`
    fragment Project_getProjectAndActiveJobsText on Project {
      id
      identifier
      activeJobs {
        id
        identifier
        startDate
        endDate
        scheduleStatus
        day {
          id
          value
        }
      }
    }
  `,
);

const getDisplayAllJobsExcludingChildJobs = withFragment(
  (project, {showCancelledJobs}) => {
    return (project as any).allJobsExcludingChildJobs.filter(
      (job: any) => showCancelledJobs || !job.isCancelled,
    );
  },

  gql`
    fragment Project_getDisplayAllJobsExcludingChildJobs on Project {
      id
      allJobsExcludingChildJobs {
        id
        isCancelled
      }
    }
  `,
);

/**
 * Gets the display text for the project. Will omit the project name
 * if it is the same as the given jobName.
 * ie. "Project 43: Commercial move for Peter"
 */
const getDisplayText = withFragment(
  (project, jobName) => {
    if (!(project as any).name || jobName === (project as any).name) {
      return `Project ${(project as any).identifier}`;
    }
    return `Project ${(project as any).identifier}: ${(project as any).name}`;
  },
  gql`
    fragment Project_getDisplayText on Project {
      id
      identifier
      name
    }
  `,
);

const getProjectsSearchDropdownOption = withFragment(
  (project) => {
    return {
      value: (project as any).id,
      label: `Project ${(project as any).identifier}`,
      description: (project as any).client.name,
      project,
    };
  },
  gql`
    fragment Project_getProjectsSearchDropdownOption on Project {
      id
      identifier
      client {
        id
        name
      }
    }
  `,
);

const getEstimateDate = withFragment(
  (project) => {
    return (project as any).estimateDate;
  },
  gql`
    fragment Project_getEstimateDate on Project {
      id
      estimateDate
    }
  `,
);

const getEstimateArrivalWindow = withFragment(
  (project) => {
    return (project as any).estimateArrivalWindow;
  },
  gql`
    fragment Project_getEstimateArrivalWindow on Project {
      id
      estimateArrivalWindow
    }
  `,
);

const getMovingServicesText = withFragment(
  (project) => {
    const name = (project as any).organization.businessName || (project as any).organization.name;
    return `${name} providing moving services to ${(project as any).customer.fullName}`;
  },
  gql`
    fragment Project_getMovingServicesText on Project {
      id
      organization {
        id
        name
        businessName
      }
      customer {
        id
        fullName
      }
    }
  `,
);

const getStatusText = withFragment(
  (project) => {
    if ((project as any).isCancelled) {
      return 'Cancelled';
    }
    return titleize((project as any).bookingStatus.replace('_', ' '));
  },
  gql`
    fragment Project_getStatusText on Project {
      id
      isCancelled
      bookingStatus
    }
  `,
);

const getProjectStatus = withFragment(
  (project) => {
    return ProjectStatus.getStatusLabel((project as any).status);
  },
  gql`
    fragment Project_getProjectStatus on Project {
      id
      status
    }
  `,
);

const getProjectStatusColor = withFragment(
  (project) => {
    switch ((project as any).projectType.category) {
      case ProjectTypeCategory.STORAGE:
        switch ((project as any).status) {
          case StorageProjectStatus.ACTIVE:
            return colors.blue.interactive;
          case StorageProjectStatus.COMPLETED:
            return colors.green.status;
          case StorageProjectStatus.CANCELLED:
          default:
            return colors.gray.secondary;
        }
      default:
        return ProjectStatus.getStatusColor((project as any).status);
    }
  },
  gql`
    fragment Project_getProjectStatusColor on Project {
      id
      status
      projectType {
        id
        category
      }
    }
  `,
);

const getProjectStatusIcon = withFragment(
  (project) => {
    switch ((project as any).projectType.category) {
      case ProjectTypeCategory.STORAGE:
        switch ((project as any).status) {
          case StorageProjectStatus.ACTIVE:
            return Icon.Truck;
          case StorageProjectStatus.COMPLETED:
            return Icon.Check;
          case StorageProjectStatus.CANCELLED:
          default:
            return Icon.XmarkLarge;
        }
      default:
        return ProjectStatus.getStatusIcon((project as any).status);
    }
  },
  gql`
    fragment Project_getProjectStatusIcon on Project {
      id
      status
      projectType {
        id
        category
      }
    }
  `,
);

const getSalesStatus = withFragment(
  (project) => {
    // HACK(mark): We currently don't have a way to skip the booking status to indicate that
    // the job is booked except via confirmation. Until we solve this, we will show "Booked"
    // for projects if at least one move job is complete.
    const isJobComplete = _.some((project as any).activeMoveJobs, (job) => {
      return ['NOT_FINAL', 'FINAL'].includes(job.calendarPrimaryStatus);
    });

    if (isJobComplete) {
      return 'BOOKED';
    }

    if (!(project as any).organization.features.isEnabledSalesModule) {
      return 'BOOKED';
    }

    switch ((project as any).bookingStatus) {
      case 'NEW':
        return 'LEAD';
      case 'CUSTOMER_SUBMITTED':
        return 'LEAD';
      case 'QUOTE_PENDING':
        return 'LEAD';
      case 'QUOTE_ACCEPTED':
        return 'LEAD';
      case 'DEPOSIT_PENDING':
        return 'HOLD';
      case 'DEPOSIT_RECEIVED':
        return 'HOLD';
      case 'CONFIRMATION_PENDING':
        return 'HOLD';
      case 'CONFIRMATION_COMPLETED':
        return 'BOOKED';
      default:
        return 'UNKNOWN';
    }
  },
  gql`
    fragment Project_getSalesStatus on Project {
      id
      bookingStatus
      organization {
        id
        features {
          isEnabledSalesModule: isEnabled(feature: "SALES_MODULE")
        }
      }
      activeMoveJobs {
        id
        calendarPrimaryStatus
      }
    }
  `,
);

const getSalesStatusText = withFragment(
  (project) => {
    const salesStatus = getSalesStatus(project);
    switch (salesStatus) {
      case 'LEAD':
        return 'Lead';
      case 'HOLD':
        return 'Hold';
      case 'BOOKED':
        return 'Booked';
      default:
        return 'Unknown';
    }
  },
  gql`
    ${getSalesStatus.fragment}
    fragment Project_getSalesStatusText on Project {
      id
      ...Project_getSalesStatus
    }
  `,
);

const getSalesStatusColor = (salesStatus: any) => {
  switch (salesStatus) {
    case 'LEAD':
    case 'HOLD':
      return colors.orange.status;
    case 'BOOKED':
      return colors.gray.primary;
    default:
      return colors.gray.primary;
  }
};

const getSalesStatusIcon = (salesStatus: any) => {
  switch (salesStatus) {
    case 'LEAD':
    case 'HOLD':
      return Icon.Trophy;
    case 'BOOKED':
      return Icon.CalendarCheck;
    default:
      return '';
  }
};

const hasEstimateJob = withFragment(
  (project) => {
    return (
      (project as any).activeJobs.filter((job: any) => job.jobType.kind === 'ESTIMATE').length > 0
    );
  },
  gql`
    fragment Project_hasEstimateJob on Project {
      id
      activeJobs {
        id
        jobType {
          id
          kind
        }
      }
    }
  `,
);

const getJobIdDropdownOptions = withFragment(
  (project) => {
    const ALL_JOBS_DROPDOWN_OPTION = {label: 'All Jobs', value: null};

    const jobs = (project as any).activeJobs.map((job: any) => ({
      label: `Job ${job.identifier}: ${job.name}`,
      value: job.id,
    }));

    // Return only the "All Jobs" dropdown option if there is only 1 job
    if (jobs.length === 1 || jobs.length === 0) {
      return [ALL_JOBS_DROPDOWN_OPTION];
    }
    return [ALL_JOBS_DROPDOWN_OPTION, ...jobs];
  },
  gql`
    fragment Project_getJobIdDropdownOptions on Project {
      id
      activeJobs {
        id
        identifier
        name
        jobType {
          id
          kind
        }
      }
    }
  `,
);

const getActiveJobsDropdownOptions = withFragment(
  (project) => {
    return (project as any).activeJobs.map((job: any) => {
      return {
        value: _.toNumber(job.id),
        label: `${job.jobType.name}: ${job.identifier}`,
        tipName: Job.getTipName(job),
        description: Datetime.convertToDisplayDate(job.endDate, Datetime.DISPLAY_SHORT_DATE),
      };
    });
  },
  // Job.getTipName has a fragment here but due to file dependency
  // we can not use the fragment, activeJobs.fullName is appended as
  // part of importing Job.getTipName
  gql`
    fragment Project_getActiveJobsDropdownOptions on Project {
      id
      endDate
      identifier
      activeJobs {
        id
        endDate
        identifier
        fullName
        jobType {
          id
          name
        }
      }
    }
  `,
);

const getMoverDropdownOptions = withFragment(
  (project) => {
    return (project as any).movers.map((user: any) => {
      return {
        label: user.fullName,
        value: user.id,
      };
    });
  },
  gql`
    fragment Project_getMoverDropdownOptions on Project {
      id
      movers {
        id
        fullName
      }
    }
  `,
);

const getProjectBillBalanceColor = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ aggregateBill }: { aggregateB... Remove this comment to see the full error message
  ({aggregateBill}) => {
    const min = aggregateBill.minBalance;
    const max = aggregateBill.maxBalance;
    const hasBalance = min > 0 || max > 0;
    const hasCredit = min < 0 && max <= 0;
    if (hasBalance) {
      return colors.red.warning;
    }
    if (hasCredit) {
      return colors.green.status;
    }
    return colors.blue.accentDark;
  },
  gql`
    fragment Project_getProjectBillBalanceColor on AggregateBill {
      minBalance
      maxBalance
    }
  `,
);

const getActiveJobsBalanceColor = withFragment(
  (project) => {
    const aggregateBill = (project as any).activeJobsAggregateBill;
    if (!aggregateBill.isTotalAvailable) {
      return colors.gray.primary;
    }

    const min = aggregateBill.minBalance;
    const max = aggregateBill.maxBalance;
    const hasBalance = min > 0 || max > 0;
    const hasCredit = min < 0 && max <= 0;
    if (hasBalance) {
      return colors.red.warning;
    }
    if (hasCredit) {
      return colors.green.status;
    }
    return colors.blue.accentDark;
  },
  gql`
    fragment Project_getActiveJobsBalanceColor on Project {
      id
      activeJobsAggregateBill {
        isTotalAvailable
        minBalance
        maxBalance
      }
    }
  `,
);

const getIsEnabledCreditCards = withFragment(
  (project) => {
    return Organization.getIsEnabledPayengine((project as any).paymentsOrganization);
  },
  gql`
    ${Organization.getIsEnabledPayengine.fragment}

    fragment Project_getIsEnabledCreditCards on Project {
      id
      paymentsOrganization {
        id
        ...Organization_getIsEnabledPayengine
      }
    }
  `,
);

const getIsLongDistance = withFragment(
  (project) => {
    return (project as any).projectType.isLongDistance;
  },
  gql`
    fragment Project_getIsLongDistance on Project {
      id
      projectType {
        id
        isLongDistance
      }
    }
  `,
);

const getNoDemoSlug = (slug: any) => {
  return slug.replace('-demo', '');
};

/* eslint-disable */

/**
 * Shown on the page where the customer accepts the quote.
 */
const getQuoteMessage = withFragment(
  (project) => {
    switch (getNoDemoSlug((project as any).organization.slug)) {
      case 'caremoremoving':
        return (
          `Dear ${(project as any).customer.firstName},\n\nMoving is hard. ` +
          `We're here to make your move go as smoothly as possible.\n\n` +
          `Please note this is our best estimate based on the information that you provided. ` +
          `The final price may differ from the estimate unless an onsite or video estimate ` +
          `has already been performed. If you would like to request one or if you have ` +
          `any questions please give us a call on 415-822-8547.\n\n` +
          `Please review your information and follow the prompt at the bottom to continue.\n\n`
        );
      case 'easternshoretransfer':
        return (
          `Dear ${(project as any).customer.firstName},\n\nWe at Eastern Shore Transfer are ` +
          `here to make your move go as smoothly as possible. ` +
          `Please review your information and follow the prompt at the bottom to continue.\n\n` +
          `To avoid unexpected costs, please review this information carefully including information below. This estimate is based on the information you have provided which is detailed in the attached inventory. Please contact us if there is anything we have missed or overlooked or if anything changes as far as the work we will be doing for you as any changes to the services requested may impact your final cost.\n\n` +
          `Please let me know if you have any questions or if you need any additional information.\n\n` +
          `MC# 1020428\nAPSC# 4037\nDOT# 2644092\n\n` +
          `For your protection please verify your mover is licensed with the Alabama Public Service Commission and FMCSA and maintains **cargo, auto, liability and workman’s compensation insurance**. Any properly licensed mover in Alabama should be able to provide you with a copy of their license and certificate of insurance upon request.\n\n` +
          `Overtime rates apply on weekends and holidays. If you will be moving on a weekend or the hourly rate increases by $10.00 per hour per man.\n\n` +
          `If the scope of work associated with your move changes please let us know so we can revise your estimate.\n\n` +
          `Insurance- Unless the shipper expressly releases the shipment to a value of .60 per lb per article, the carrier’s maximum liability for loss and damage shall be either the lump sum value declared by the shipper or an amount equal to $1.25 for each pound of weight in the shipment, whichever is greater. Declared value insurance is $120.00 per $20,000.00 increment of value declared by the shipper.\n\n` +
          `Firearms, ammunition, gasoline, paints, aerosol or anything else considered flammable or explosive are excluded from all shipments handled by this carrier.\n\n` +
          `Pets must be confined or removed from the premises when we are working onsite.`
        );
      case 'pinpointmovers':
      case 'pinpointmovers-george':
      case 'pinpointmovers-ismael':
      case 'pinpointmovers-manuel':
      case 'pinpointmovers-mario':
      case 'pinpointmovers-pablo':
      case 'pinpointmovers-ramon':
        return (
          `Dear ${(project as any).customer.firstName},\n\nMoving is hard. ` +
          `We're here to make your move go as smoothly as possible.\n\n` +
          `Why Choose Pinpoint Movers?\n` +
          `*We love to serve our TEXAS & U.S. families! *Our Strong & Professional Movers have 10+ years of moving experience. *We are fully licensed with USDOT and TXDMV *We are fully Insured. *We are A+ Rated with the BBB. *There are no surprises nor hidden fees!\n\n` +
          `Need Packing Services?\n` +
          `We offer a full packing service and box materials to help relieve some of the packing stress. Please contact us for further details. For the most cost-efficient move, we recommend being completely packed and prepared prior to the movers' arrival.\n\n` +
          `Is There a Minimum Rate?\n` +
          `We charge an hourly labor rate for all local moves depending on the crew size. There is a two hour minimum charge. All time after two hours is pro-rated in quarter hour increments.\n\n` +
          `When is Payment Required?\n` +
          `The initial payment is due prior to the moving truck being loaded and will be processed by the foreman of the crew. Should the move exceed two hours, the pro-rate amount will be processed upon completion of move.\n\n` +
          `Do you Charge Extra For Stairs, Piano, and Safes?\n` +
          `Extra charges for select pianos and safes may apply pending the layout of the pickup and delivery location. Please contact us with additional details of the location for a more specific estimation.\n\n` +
          `Will the Movers Disconnect my Appliances?\n` +
          `For reasons such as expertise, liability or safety, Pinpoint Movers does not disconnect/reconnect washer, dryers, or any appliance that requires removing hoses, gas/electrical from the structure of the wall or pipes.\n\n` +
          `Please review your information and follow the prompt at the bottom to continue.`
        );
      default:
        return (
          `Dear ${(project as any).customer.firstName},\n\n` +
          `We're here to make your move go as smoothly as possible. ` +
          `Please review your information and follow the prompt at the bottom to continue.`
        );
    }
  },
  gql`
    fragment Project_getQuoteMessage on Project {
      organization {
        id
        slug
      }
      customer {
        id
        firstName
      }
    }
  `,
);

const getAuthorizeDotNetUrlWithAmount = withFragment(
  (project, {amount}) => {
    return `${(project as any).authorizeDotNetUrl}&id3=${Currency.display(amount)}`;
  },
  gql`
    fragment Project_getAuthorizeDotNetUrlWithAmount on Project {
      id
      authorizeDotNetUrl
    }
  `,
);

// NOTE(dan) Keep this number in sync with MAX_JOB_COUNT in project_jobs_form.py
const MAX_JOB_COUNT = 15;

const getIsAtMaxJobs = withFragment(
  (project) => {
    return (
      (project as any).activeJobsCount >= MAX_JOB_COUNT &&
      !(project as any).organization.features.isEnabledUnlimitedProjectJobs
    );
  },
  gql`
    fragment Project_getIsAtMaxJobs on Project {
      id
      activeJobsCount
      organization {
        id
        features {
          isEnabledUnlimitedProjectJobs: isEnabled(feature: "UNLIMITED_PROJECT_JOBS")
        }
      }
    }
  `,
);

/* eslint-enable */

/**
 * Building types need to map to building size options for the customer app.
 * This is currently used just in the BuildingTypes component on the PrepareInventoryPage
 * Later may want to serve this from the db in order to customize for different moving companies.
 */
const APARTMENT = {
  name: 'Apartment',
  sizes: [
    {value: 'Apartment: Studio', label: 'Studio'},
    {value: 'Apartment: 1 Bedroom', label: '1 Bedroom'},
    {value: 'Apartment: 2 Bedroom', label: '2 Bedroom'},
    {value: 'Apartment: 3 Bedroom', label: '3 Bedroom'},
    {value: 'Apartment: 4 Bedroom', label: '4 Bedroom'},
    {value: 'Apartment: 5+ Bedroom', label: '5+ Bedroom'},
  ],
};
const CONDO = {
  name: 'Condo',
  sizes: [
    {value: 'Condo: Studio', label: 'Studio'},
    {value: 'Condo: 1 Bedroom', label: '1 Bedroom'},
    {value: 'Condo: 2 Bedroom', label: '2 Bedroom'},
    {value: 'Condo: 3 Bedroom', label: '3 Bedroom'},
    {value: 'Condo: 4 Bedroom', label: '4 Bedroom'},
    {value: 'Condo: 5+ Bedroom', label: '5+ Bedroom'},
  ],
};
const HOUSE = {
  name: 'House',
  sizes: [
    {value: 'House: Studio', label: 'Studio'},
    {value: 'House: 1 Bedroom', label: '1 Bedroom'},
    {value: 'House: 2 Bedroom', label: '2 Bedroom'},
    {value: 'House: 3 Bedroom', label: '3 Bedroom'},
    {value: 'House: 4 Bedroom', label: '4 Bedroom'},
    {value: 'House: 5+ Bedroom', label: '5 Bedroom'},
  ],
};
const OFFICE = {
  name: 'Office',
  sizes: [
    {value: 'Office: 0-500 sq ft', label: '0-500 sq ft'},
    {value: 'Office: 500-1500 sq ft', label: '500-1500 sq ft'},
    {value: 'Office: 1500-2500 sq ft', label: '1500-2500 sq ft'},
    {value: 'Office: 2500+ sq ft', label: '2500+ sq ft'},
  ],
};
const BUILDING_TYPES = [APARTMENT, CONDO, HOUSE, OFFICE];

const JOB_ACTIONS = {
  ADD: 'ADD',
  DUPLICATE: 'DUPLICATE',
};

const goToAddJob = withFragment(
  (project, {navigator}) => {
    navigator.push(
      `/projects/${(project as any).uuid}/edit/jobs?action=${Project.JOB_ACTIONS.ADD}`,
    );
  },
  gql`
    fragment Project_goToAddJob on Project {
      id
      uuid
    }
  `,
);

const Project = {
  hasEstimateJob,
  getName,
  getDate,
  getLocation,
  getDisplayAllJobsExcludingChildJobs,
  getDisplayText,
  getEstimateDate,
  getEstimateArrivalWindow,
  getProjectsSearchDropdownOption,
  getProjectStatus,
  getProjectStatusColor,
  getProjectStatusIcon,
  getIsEnabledCreditCards,
  getJobIdDropdownOptions,
  getActiveJobsDropdownOptions,
  getMoverDropdownOptions,
  getMovingServicesText,
  getProjectAndActiveJobsText,
  getQuoteMessage,
  getStatusText,
  getSalesStatus,
  getSalesStatusColor,
  getSalesStatusIcon,
  getSalesStatusText,
  getProjectBillBalanceColor,
  getActiveJobsBalanceColor,
  getIsLongDistance,
  getAuthorizeDotNetUrlWithAmount,
  goToAddJob,
  getIsAtMaxJobs,

  BUILDING_TYPES,
  JOB_ACTIONS,
  MAX_JOB_COUNT,
};

export default Project;
