// Libraries
import React from 'react';

// Supermove
import {Emoji, Styled} from '@supermove/components';
import {Device} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  padding: 20px;
`;

const Content = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Title = Styled.H1`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const EmojiText = Styled.Text`
  margin-vertical: 40px;
  font-size: 100px;
`;

const Subtitle = Styled.H4`
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  margin-top: 40px;
`;

const Button = Styled.Button`
  height: 80px;
  padding-horizontal: 40px;
`;

const Text = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Link = Styled.Touchable`
  margin-top: 20px;
`;

const LinkText = Styled.H6`
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
  text-align: center;
`;

type OwnProps = {
  onSkip?: (...args: any[]) => any;
  isRequired?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateAppView.defaultProps;

// @ts-expect-error TS(7022): 'UpdateAppView' implicitly has type 'any' because ... Remove this comment to see the full error message
const UpdateAppView = ({isRequired, onSkip}: Props) => (
  <Container>
    <Content>
      <Title>Update Available</Title>
      <Emoji component={EmojiText} name={'gift'} />
      <Subtitle>A new version of Supermove is available. Tap the button below to update.</Subtitle>
      <Actions>
        <Button onPress={() => Device.openUpdateApp()}>
          <Text>Get Update</Text>
        </Button>
        {!isRequired && (
          <Link onPress={onSkip}>
            <LinkText>Skip</LinkText>
          </Link>
        )}
      </Actions>
    </Content>
  </Container>
);

UpdateAppView.defaultProps = {
  onSkip: () => {},
  isRequired: false,
};

export default UpdateAppView;
