// App
import CreateAttachmentForm, {
  CreateAttachmentFormType,
} from '@shared/modules/File/forms/CreateAttachmentForm';

export interface CreateAttachmentsFormType {
  createAttachmentForms: CreateAttachmentFormType[];
}

const toForm = ({createAttachmentForms}: CreateAttachmentsFormType) => {
  return {
    createAttachmentForms: createAttachmentForms.map(CreateAttachmentForm.toForm),
  };
};

const toMutation = ({createAttachmentForms}: CreateAttachmentsFormType) => {
  return {
    createAttachmentForms: createAttachmentForms.map((attachmentForm) => {
      return CreateAttachmentForm.toMutation(attachmentForm);
    }),
  };
};

const BulkCreateAttachmentsForm = {
  toForm,
  toMutation,
};

export default BulkCreateAttachmentsForm;
