// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import CrewJobPage from 'modules/Job/Crew/components/CrewJobPage';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  flex: 1;
  align-items: center;
  margin-top: 60px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Timeline = Styled.View`
  flex: 1;
  justify-content: center;
  width: 750px;
`;

const Instructions = Styled.H3`
  margin-horizontal: 80px;
  margin-bottom: 80px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.Button`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const PassFinishCrewJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({uuid: params.uuid})}
    query={PassFinishCrewJobPage.query}
  >
    {({navigator, params, loading, data}: any) => (
      <Loading loading={loading}>
        {() => (
          <Container>
            <Content>
              <Title>Great! All details have been confirmed.</Title>
              <Subtitle>
                {`Next, we'll have ${data.job.customer.firstName} review ` +
                  `the information entered.`}
              </Subtitle>
              <Timeline />
              <Instructions>
                {`Please hand this device to ${data.job.customer.firstName} to get started.`}
              </Instructions>
            </Content>
            <JobFooter>
              <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                <Text>Back</Text>
              </Button>
              <Button
                color={colors.blue.interactive}
                onPress={() => {
                  Navigation.navigateToPostMoveCustomerStart({navigator, job: data.job});
                }}
              >
                <Text>Okay</Text>
              </Button>
            </JobFooter>
          </Container>
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PassFinishCrewJobPage.query = gql`
  ${Navigation.navigateToPostMoveCustomerStart.fragment}
  ${CrewJobPage.fragment}

  query PassFinishCrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
        firstName
      }
      ...Navigation_navigateToPostMoveCustomerStart
      ...CrewJobPage
    }
  }
`;

export default PassFinishCrewJobPage;
