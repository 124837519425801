// Libraries
import React from 'react';

// Supermove
import {Loading, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useMountEffect} from '@supermove/hooks';
import {DeviceInfo, Permissions, ScreenOrientation} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import EmbeddedInventoryCapture from '@shared/modules/Inventory/components/EmbeddedInventoryCapture';
import UpdateInventoryIsFinalizedStatusForm from '@shared/modules/Inventory/forms/UpdateInventoryIsFinalizedStatusForm';
import useUpdateInventoryIsFinalizedStatusMutation from '@shared/modules/Inventory/hooks/useUpdateInventoryIsFinalizedStatusMutation';
import {PageLoadingIndicator} from 'modules/App/components';
import {CrewJobPage} from 'modules/Job/Crew/components';

const Container = Styled.View`
  flex: 1;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const FinalizeInventoryModal = ({finalizeInventoryModal, inventory, job, navigator}: any) => {
  const {id: jobId, uuid: jobUuid} = job;
  const {handleSubmit} = useUpdateInventoryIsFinalizedStatusMutation({
    updateInventoryIsFinalizedStatusForm: UpdateInventoryIsFinalizedStatusForm.new(inventory, {
      isFinalized: true,
      jobId,
    }),
    onSuccess: () => {
      navigator.navigate('ShowJob', {uuid: jobUuid});
    },
    onError: (errors: any) => console.log(errors),
  });

  return (
    <SmallModal isOpen={finalizeInventoryModal.isOpen} style={{alignItems: 'center'}}>
      <SmallModal.HeaderIcon source={Icon.ExclamationTriangle} color={colors.orange.status} />
      <Space height={12} />
      <SmallModal.HeaderText style={{color: colors.orange.status}}>
        Finalize inventory
      </SmallModal.HeaderText>
      <Space height={12} />
      <BodyText>You must finalize inventory before proceeding to the next step.</BodyText>
      <Space height={12} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={finalizeInventoryModal.handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.orange.status} onPress={handleSubmit}>
          Finalize Inventory
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const FinalizeInventoryButton = ({inventory, job, navigator}: any) => {
  const {uuid: jobUuid} = job;
  const finalizeInventoryModal = useModal({name: 'Exception Warning Modal'});

  const handleDone = () => {
    if (job.hasJobEventCaptureInventoryFinalized) {
      navigator.navigate('ShowJob', {uuid: jobUuid});
    } else {
      finalizeInventoryModal.handleOpen();
    }
  };

  return (
    <React.Fragment>
      <Button color={colors.blue.interactive} onPress={handleDone}>
        <Text>Done</Text>
      </Button>
      <FinalizeInventoryModal
        finalizeInventoryModal={finalizeInventoryModal}
        inventory={inventory}
        job={job}
        navigator={navigator}
      />
    </React.Fragment>
  );
};

const CrewCaptureInventoryPage = () => {
  useMountEffect(() => {
    // Prompt user for access to camera before entering the capture experience
    const isAndroid = DeviceInfo.getPlatform() === 'ANDROID';
    if (isAndroid) {
      Permissions.requestAndCheckIsCameraGranted();
    }

    // NOTE(cooper): We lock the screen orientation to portrait mode for this view only
    ScreenOrientation.lockToPortrait();

    // Revert to landscape mode when the component unmounts
    return () => {
      ScreenOrientation.lockToLandscape();
    };
  });

  return (
    <CrewJobPage
      fetchPolicy={'network-only'}
      getVariables={({params}: any) => ({uuid: params.uuid})}
      query={CrewCaptureInventoryPage.query}
    >
      {({navigator, params, loading, data, refetch}: any) => {
        return (
          <Loading loading={loading} as={PageLoadingIndicator}>
            {() => {
              return (
                <Container>
                  <EmbeddedInventoryCapture
                    inventoryUuid={data.job.driverInventory.uuid}
                    // @ts-expect-error TS(2322): Type '{ inventoryUuid: any; jobUuid: any; }' is no... Remove this comment to see the full error message
                    jobUuid={data.job.uuid}
                  />
                  <Footer>
                    <Group>
                      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                        <Text>Back</Text>
                      </Button>
                    </Group>
                    <FinalizeInventoryButton
                      inventory={data.job.driverInventory}
                      job={data.job}
                      navigator={navigator}
                    />
                  </Footer>
                </Container>
              );
            }}
          </Loading>
        );
      }}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewCaptureInventoryPage.query = gql`
  ${UpdateInventoryIsFinalizedStatusForm.new.fragment}

  query CrewCaptureInventoryPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      driverInventory {
        id
        uuid
        ...UpdateInventoryIsFinalizedStatusForm_new
      }
      hasJobEventCaptureInventoryFinalized: hasJobEvent(kind: "CAPTURE_INVENTORY_FINALIZED")
    }
  }
`;

export default CrewCaptureInventoryPage;
