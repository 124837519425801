/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import JobDocumentV2Item from 'modules/Job/Document/List/components/JobDocumentV2Item';

const Document = Styled.View`
`;

const JobDocumentV2List = ({job}: any) => {
  return (
    <FlatList
      data={job.crewAppDocuments}
      keyExtractor={(document: any) => document.id}
      renderItem={({item: document, index}: any) => (
        <Document>
          <JobDocumentV2Item
            isFirst={index === 0}
            isLast={index === job.crewAppDocuments.length - 1}
            document={document}
          />
        </Document>
      )}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDocumentV2List.fragment = gql`
  ${JobDocumentV2Item.fragment}

  fragment JobDocumentV2List on Job {
    id
    crewAppDocuments {
      id
      ...JobDocumentV2Item
    }
  }
`;

export default JobDocumentV2List;
