// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import PageV2 from 'modules/App/components/PageV2';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  align-items: center;
`;

const Button = Styled.LoadingButton`
  height: 60px;
  width: 400px;
`;

const ButtonText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ProjectBillingDefaultPaymentSection = ({
  handleSubmit,
  submitting,
  title,
  subtitle,
  payment,
  button,
}: any) => {
  return (
    <Container>
      <PageV2.Title>{title}</PageV2.Title>
      <PageV2.Subtitle>{subtitle}</PageV2.Subtitle>
      <Space height={20} />
      <PaymentAmountSection title={'Payment Amount:'} amount={payment} />
      <Space height={20} />
      <Button onPress={handleSubmit} loading={submitting}>
        <ButtonText>{button}</ButtonText>
      </Button>
      <Space height={16} />
    </Container>
  );
};

export default ProjectBillingDefaultPaymentSection;
