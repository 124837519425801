// Libraries
import _ from 'lodash';
import React from 'react';

// Components
import {FlatList, Styled} from '@supermove/components';

import TimeRangeFormItem from './TimeRangeFormItem';

const TimeRangeItem = Styled.View`
`;

const getTimeRangeForms = ({timeRangeForms}: any) => {
  return _.concat(timeRangeForms, _.last(timeRangeForms));
};

const TimeRangeFormsList = ({timeRangeForms}: any) => (
  <FlatList
    horizontal
    data={getTimeRangeForms({timeRangeForms})}
    keyExtractor={(timeRangeForm: any, index: any) => String(index)}
    renderItem={({item: timeRangeForm, index}: any) => (
      <TimeRangeItem>
        <TimeRangeFormItem
          isFirst={index === 0}
          isLast={index === getTimeRangeForms({timeRangeForms}).length - 1}
          index={index}
          previousTimeRangeForm={_.get(getTimeRangeForms({timeRangeForms}), index - 1)}
          timeRangeForm={timeRangeForm}
        />
      </TimeRangeItem>
    )}
  />
);

export default TimeRangeFormsList;
