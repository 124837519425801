const ITEM_TAG = 'ITEM_TAG' as const;
const EXCEPTION = 'EXCEPTION' as const;

const ITEM_TAG_ITEM_KINDS = [ITEM_TAG, EXCEPTION] as const;

export type ItemTagItemKindType = (typeof ITEM_TAG_ITEM_KINDS)[number];

const ItemTagItemKind = {
  ITEM_TAG,
  EXCEPTION,
};

export default ItemTagItemKind;
