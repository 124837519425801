// Libraries
import React from 'react';

// Supermove
import {Query} from '@supermove/components';
import {useIsFocused, useNavigation} from '@supermove/hooks';

const PageContent = ({loading, data, params, navigator, onFocus, refetch, children}: any) => {
  const isFocused = useIsFocused({
    onFocus: () => onFocus({refetch}),
  });

  return children({isFocused, params, navigator, loading, data, refetch});
};

type OwnPageProps = {
  getVariables?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'PageProps' circularly references itsel... Remove this comment to see the full error message
type PageProps = OwnPageProps & typeof Page.defaultProps;

// @ts-expect-error TS(7022): 'Page' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Page = ({fetchPolicy, getVariables, query, onFocus, children}: PageProps) => {
  const {navigator, params} = useNavigation();

  return (
    <Query
      notifyOnNetworkStatusChange
      fetchPolicy={fetchPolicy}
      variables={getVariables({navigator, params})}
      query={query}
    >
      {({loading, data, error, refetch}: any) => (
        <PageContent
          loading={loading}
          data={data}
          params={params}
          navigator={navigator}
          onFocus={onFocus}
          refetch={refetch}
          children={children}
        />
      )}
    </Query>
  );
};

Page.defaultProps = {
  getVariables: () => {},
  onFocus: () => {},
};

export default Page;
