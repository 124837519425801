// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CommercialMoveTimesheetTable from 'modules/Job/Document/components/CommercialMoveTimesheetTable';

const Container = Styled.View`
`;

const Content = Styled.View`
  z-index: 100;
`;

const FormActions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Actions = Styled.View`
  margin-top: 20px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const DocumentCommercialTimesheetFields = ({document, form: parentForm}: any) => {
  const {navigator} = useNavigation();

  return (
    <Container>
      <Content>
        <Space height={10} />
        <CommercialMoveTimesheetTable job={document.job} />
        <Space height={10} />
        <FormActions>
          <Button
            onPress={() => {
              navigator.push('EditCommercialTimesheetCrewJob', {
                uuid: document.job.uuid,
                success: 'BACK',
              });
            }}
          >
            <Text>Edit</Text>
          </Button>
        </FormActions>
      </Content>
      <Actions>
        <Button loading={parentForm.loading} onPress={parentForm.handleSubmit}>
          <Text>Save</Text>
        </Button>
      </Actions>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentCommercialTimesheetFields.fragment = gql`
  ${CommercialMoveTimesheetTable.fragment}

  fragment DocumentCommercialTimesheetFields on Document {
    id
    job {
      id
      uuid
      ...CommercialMoveTimesheetTable
    }
  }
`;

export default DocumentCommercialTimesheetFields;
