// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import ValidationError from 'modules/App/components/ValidationError';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Index = Styled.H5`
  margin-top: 7px;
  margin-right: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Field = Styled.View`
  margin-right: 10px;
`;

const TextInput = Styled.TextInput.H7`
  width: ${(props) => (props as any).width}px;
`;

const DocumentCommercialMaterialFields = ({isFirst, isLast, field, index, form}: any) => {
  const commercialMaterialForm = _.get(form, `values.${field}`);
  const isDisabled = false;

  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Index>{`${index + 1}.`}</Index>
        <Field>
          <TextInput
            disabled={isDisabled}
            width={350}
            name={`${field}.name`}
            placeholder={'Name'}
            value={_.get(commercialMaterialForm, 'name')}
            onChangeText={(text: any) => form.setFieldValue(`${field}.name`, text)}
            onBlur={() => form.setFieldTouched(`${field}.name`, true)}
          />
          <ValidationError name={`${field}.name`} touched={form.touched} errors={form.errors} />
        </Field>
        <Field>
          <TextInput
            disabled={isDisabled}
            width={150}
            name={`${field}.quantityRequested`}
            placeholder={'#'}
            value={_.get(commercialMaterialForm, 'quantityRequested')}
            onChangeText={(text: any) => form.setFieldValue(`${field}.quantityRequested`, text)}
            onBlur={() => form.setFieldTouched(`${field}.quantityRequested`, true)}
          />
          <ValidationError
            name={`${field}.quantityRequested`}
            touched={form.touched}
            errors={form.errors}
          />
        </Field>
        <Field>
          <TextInput
            disabled={isDisabled}
            width={150}
            name={`${field}.quantityDelivered`}
            placeholder={'#'}
            value={_.get(commercialMaterialForm, 'quantityDelivered')}
            onChangeText={(text: any) => form.setFieldValue(`${field}.quantityDelivered`, text)}
            onBlur={() => form.setFieldTouched(`${field}.quantityDelivered`, true)}
          />
          <ValidationError
            name={`${field}.quantityDelivered`}
            touched={form.touched}
            errors={form.errors}
          />
        </Field>
      </Content>
      <Line />
    </Container>
  );
};

export default DocumentCommercialMaterialFields;
