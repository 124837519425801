// Libraries
import React from 'react';

// App
import {Badge, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// Components

const Container = Styled.View`
  margin-horizontal: 40px;
  background-color: ${colors.white};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 20px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Info = Styled.View`
  flex: 1;
`;

const DeviceType = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const SerialNumber = Styled.H7`
  color: ${colors.gray.primary};
`;

const Button = Styled.LoadingButton`
  margin-left: 40px;
  height: 40px;
  width: 150px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const StripeReaderItem = ({isFirst, isSelected, loading, reader, onConnect}: any) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Info>
        <DeviceType>{reader.deviceType}</DeviceType>
        <SerialNumber>{`Serial Number: ${reader.serialNumber}`}</SerialNumber>
      </Info>
      {isSelected ? (
        <Badge color={colors.green.status} style={{height: 40, width: 150}}>
          Connected
        </Badge>
      ) : (
        <Button loading={loading} onPress={onConnect}>
          <Text>Connect</Text>
        </Button>
      )}
    </Content>
    <Line />
  </Container>
);

export default StripeReaderItem;
