// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  border-radius: 5px;
  background-color: ${(props) => colors.alpha((props as any).color, 0.1)}
`;

const Text = Styled.H6`
  flex: 1;
  color: ${(props) => (props as any).color};
`;

const Notification = ({color, text, style, children}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container color={color} style={style}>
    {React.isValidElement(text) ? (
      // @ts-expect-error TS(2769): No overload matches this call.
      React.cloneElement(text, {color})
    ) : (
      <Text color={color}>{text}</Text>
    )}
    {children}
  </Container>
);

Notification.Text = Text;

export default Notification;
