// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Index = Styled.H5`
  margin-right: 20px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Input = Styled.TextInput.H7`
  width: 500px;
`;

const DocumentAdditionalInfoListInput = ({
  isFirst,
  isLast,
  name,
  index,
  values,
  setFieldValue,
  setFieldTouched,
}: any) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Index>{`${index + 1}.`}</Index>
      <Input
        name={name}
        placeholder={'Enter the name of an item'}
        value={_.get(values, name)}
        onChangeText={(text: any) => setFieldValue(name, text)}
        onBlur={() => setFieldTouched(name, true)}
      />
    </Content>
    <Line />
  </Container>
);

export default DocumentAdditionalInfoListInput;
