// Libraries
import _ from 'lodash';

// Supermove
import {BillModifierForm, BillRuleForm, LineItemForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {Bill} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

// App
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';

const getBillItemFormsFromBillItemTypesForBill = withFragment(
  (bill) => {
    return (bill as any).project.projectType.billingLibrary.billItemTypes
      .filter((billItemType: any) => !billItemType.isParent)
      .map((billItemType: any) =>
        BillItemForm.editFromBillItemType(billItemType, {billId: (bill as any).id}),
      );
  },
  gql`
    ${BillItemForm.editFromBillItemType.fragment}
    fragment BillForm_getBillItemFormsFromBillItemTypesForBill on Bill {
      id
      project {
        id
        projectType {
          id
          billingLibrary {
            id
            billItemTypes {
              id
              isParent
              ...BillItemForm_editFromBillItemType
            }
          }
        }
      }
    }
  `,
);

const _new = withFragment(
  (project) => ({
    projectId: (project as any).id,
    customerId: (project as any).customerId,
    organizationId: (project as any).organization.id,
    companyId: (project as any).organization.company.id,
    jobId: null,
    title: '',
    description: '',
    isPrimary: false,
    billRuleForms: [],
    deletedBillRuleForms: [],
    lineItemForms: [],
    billModifierForms: [],
    billItemFormsPreSubtotal: [],
    billItemFormsPostSubtotal: [],
    deletedBillItemForms: [],
    index: (project as any).currentBills.filter((bill: any) => !bill.isPrimary).length,
    tip: 0,
    // Private
    allLineItemForms: [],
    allBillModifierForms: [],
    allBillRuleForms: [],
    allBillItemFormsPreSubtotal: [],
    allBillItemFormsPostSubtotal: [],
  }),
  gql`
    fragment BillForm_new on Project {
      id
      customerId
      organization {
        id
        company {
          id
        }
      }
      currentBills {
        id
        isPrimary
      }
    }
  `,
);

const newFromBillType = withFragment(
  (project, {billType}) => ({
    billTypeId: billType.id,
    projectId: (project as any).id,
    customerId: (project as any).customerId,
    organizationId: (project as any).organization.id,
    companyId: (project as any).organization.company.id,
    jobId: null,
    title: billType.name,
    description: billType.description,
    isPrimary: false,
    tip: 0,
    index: (project as any).currentBills.filter((bill: any) => !bill.isPrimary).length,
    billRuleForms: billType.billRuleTypes.map((billRuleType: any) => {
      return BillRuleForm.editFromBillRuleType(billRuleType);
    }),
    deletedBillRuleForms: [],
    billItemFormsPreSubtotal: billType.billItemTypes
      .map((billItemType: any, index: any) =>
        BillItemForm.toForm({
          ...BillItemForm.editFromBillItemType(billItemType, {billId: null}),
          index,
        }),
      )
      .filter((billItemForm: any) => billItemForm.billStage === BillStage.PRE_SUBTOTAL),
    billItemFormsPostSubtotal: billType.billItemTypes
      .map((billItemType: any, index: any) =>
        BillItemForm.toForm({
          ...BillItemForm.editFromBillItemType(billItemType, {billId: null}),
          index,
        }),
      )
      .filter((billItemForm: any) => billItemForm.billStage === BillStage.POST_SUBTOTAL),
    deletedBillItemForms: [],
    // NOTE(cooper): Line items and bill modifiers are deprecated in favor of bill items
    lineItemForms: [],
    billModifierForms: [],
    // Private
    allLineItemForms: [],
    allBillModifierForms: [],
    allBillRuleForms: [],
    allBillItemFormsPreSubtotal: [],
    allBillItemFormsPostSubtotal: [],
  }),
  gql`
    ${BillRuleForm.editFromBillRuleType.fragment}
    ${BillItemForm.editFromBillItemType.fragment}

    fragment BillForm_newFromBillType on Project {
      id
      customerId
      organization {
        id
        company {
          id
        }
      }
      currentBills {
        id
        isPrimary
      }
      projectType {
        id
        billingLibrary {
          id
          billTypes {
            id
            name
            description
            billItemTypes {
              id
              ...BillItemForm_editFromBillItemType
            }
            billRuleTypes {
              id
              ...BillRuleForm_editFromBillRuleType
            }
          }
        }
      }
    }
  `,
);

const edit = withFragment(
  (bill) => ({
    billId: (bill as any).id,
    projectId: (bill as any).projectId,
    customerId: (bill as any).customerId,
    organizationId: (bill as any).organizationId,
    companyId: (bill as any).companyId,
    jobId: (bill as any).jobId,
    title: (bill as any).title,
    description: (bill as any).description,
    kind: (bill as any).kind,
    isPrimary: (bill as any).isPrimary,
    tip: (bill as any).tip,
    index: (bill as any).index,
    billRuleForms: (bill as any).billRules.map((billRule: any) => {
      return BillRuleForm.edit(billRule);
    }),
    deletedBillRuleForms: [],
    lineItemForms: (bill as any).lineItems.map((lineItem: any) => {
      return LineItemForm.edit(lineItem);
    }),
    billModifierForms: (bill as any).billModifiers.map((billModifier: any) => {
      return BillModifierForm.edit(billModifier);
    }),
    billItemFormsPreSubtotal: (bill as any).billItemsPreSubtotal.map((billItem: any) => {
      return BillItemForm.edit(billItem);
    }),
    billItemFormsPostSubtotal: (bill as any).billItemsPostSubtotal.map((billItem: any) => {
      return BillItemForm.edit(billItem);
    }),
    deletedBillItemForms: [],
    // Private
    allLineItemForms: [],
    allBillModifierForms: [],
    allBillRuleForms: [],
    allBillItemFormsPreSubtotal: [],
    allBillItemFormsPostSubtotal: [],
  }),
  gql`
    ${BillItemForm.edit.fragment}
    ${BillRuleForm.edit.fragment}
    ${LineItemForm.edit.fragment}
    ${BillModifierForm.edit.fragment}

    fragment BillForm_edit on Bill {
      id
      projectId
      companyId
      jobId
      customerId
      organizationId
      title
      description
      kind
      isPrimary
      tip
      index
      billRules {
        ...BillRuleForm_edit
      }
      lineItems {
        ...LineItemForm_edit
      }
      billModifiers {
        ...BillModifierForm_edit
      }
      billItemsPreSubtotal {
        ...BillItemForm_edit
      }
      billItemsPostSubtotal {
        ...BillItemForm_edit
      }
    }
  `,
);

const editWithAllOptions = withFragment(
  (bill) => ({
    ...edit(bill),
    // Private
    allLineItemForms: Bill.getTemplateLineItemsForBill(bill).map((templateLineItem: any) => {
      return LineItemForm.editFromTemplateLineItem(templateLineItem);
    }),
    allBillModifierForms: Bill.getBillModifierTypesForBill(bill).map((billModifierType: any) => {
      return BillModifierForm.editFromBillModifierType(billModifierType);
    }),
    allBillRuleForms: Bill.getBillRuleTypesForBill(bill).map((billRuleType: any) => {
      return BillRuleForm.editFromBillRuleType(billRuleType);
    }),
    allBillItemFormsPreSubtotal: getBillItemFormsFromBillItemTypesForBill(bill).filter(
      (billItemForm: any) => billItemForm.billStage === BillStage.PRE_SUBTOTAL,
    ),
    allBillItemFormsPostSubtotal: getBillItemFormsFromBillItemTypesForBill(bill).filter(
      (billItemForm: any) => billItemForm.billStage === BillStage.POST_SUBTOTAL,
    ),
  }),
  gql`
    ${edit.fragment}
    ${getBillItemFormsFromBillItemTypesForBill.fragment}
    ${BillModifierForm.editFromBillModifierType.fragment}
    ${BillRuleForm.editFromBillRuleType.fragment}
    ${LineItemForm.editFromTemplateLineItem.fragment}
    ${Bill.getTemplateLineItemsForBill.fragment}
    ${Bill.getBillModifierTypesForBill.fragment}
    ${Bill.getBillRuleTypesForBill.fragment}

    fragment BillForm_editWithAllOptions on Bill {
      id
      organization {
        id
        settings {
          id
          templateLineItems {
            ...LineItemForm_editFromTemplateLineItem
          }
        }
        billModifierTypes {
          id
          ...BillModifierForm_editFromBillModifierType
        }
        billRuleTypes {
          id
          ...BillRuleForm_editFromBillRuleType
        }
      }
      ...BillForm_edit
      ...Bill_getTemplateLineItemsForBill
      ...Bill_getBillModifierTypesForBill
      ...Bill_getBillRuleTypesForBill
      ...BillForm_getBillItemFormsFromBillItemTypesForBill
    }
  `,
);

const toForm = ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  tip,
  index,
  billRuleForms,
  deletedBillRuleForms,
  lineItemForms,
  billModifierForms,
  billItemFormsPreSubtotal,
  billItemFormsPostSubtotal,
  deletedBillItemForms,
  allLineItemForms,
  allBillModifierForms,
  allBillRuleForms,
  allBillItemFormsPreSubtotal,
  allBillItemFormsPostSubtotal,
}: any) => ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  index,
  tip: Currency.toForm(tip),
  billRuleForms: billRuleForms.map((billRuleForm: any) => {
    return BillRuleForm.toForm(billRuleForm);
  }),
  deletedBillRuleForms: deletedBillRuleForms.map((billRuleForm: any) => {
    return BillRuleForm.toForm(billRuleForm);
  }),
  lineItemForms: lineItemForms.map((lineItemForm: any) => {
    return LineItemForm.toForm(lineItemForm);
  }),
  billModifierForms: billModifierForms.map((billModifierForm: any) => {
    return BillModifierForm.toForm(billModifierForm);
  }),
  billItemFormsPreSubtotal: billItemFormsPreSubtotal.map((billItemForm: any) => {
    return BillItemForm.toForm(billItemForm);
  }),
  billItemFormsPostSubtotal: billItemFormsPostSubtotal.map((billItemForm: any) => {
    return BillItemForm.toForm(billItemForm);
  }),
  deletedBillItemForms,

  // Private
  allLineItemForms: allLineItemForms.map((lineItemForm: any) => {
    return LineItemForm.toForm(lineItemForm);
  }),
  allBillModifierForms: allBillModifierForms.map((billModifierForm: any) => {
    return BillModifierForm.toForm(billModifierForm);
  }),
  allBillRuleForms: allBillRuleForms.map((billRuleForm: any) => {
    return BillRuleForm.toForm(billRuleForm);
  }),
  allBillItemFormsPreSubtotal: allBillItemFormsPreSubtotal.map((billItemForm: any) => {
    return BillItemForm.toForm(billItemForm);
  }),
  allBillItemFormsPostSubtotal: allBillItemFormsPostSubtotal.map((billItemForm: any) => {
    return BillItemForm.toForm(billItemForm);
  }),
});

const toMutation = ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  tip,
  index,
  billRuleForms,
  deletedBillRuleForms,
  lineItemForms,
  billModifierForms,
  billItemFormsPreSubtotal,
  billItemFormsPostSubtotal,
  deletedBillItemForms,
}: any) => ({
  billId,
  billTypeId,
  projectId,
  customerId,
  companyId,
  jobId,
  organizationId,
  title,
  description,
  kind,
  isPrimary,
  index,
  tip: Currency.toMutation(tip),
  billRuleForms: billRuleForms.map((billRuleForm: any) => {
    return BillRuleForm.toMutation(billRuleForm);
  }),
  deletedBillRuleForms: deletedBillRuleForms.map((billRuleForm: any) => {
    return BillRuleForm.toMutation(billRuleForm);
  }),
  lineItemForms: lineItemForms.map((lineItemForm: any) => {
    return LineItemForm.toMutation(lineItemForm);
  }),
  billModifierForms: billModifierForms.map((billModifierForm: any) => {
    return BillModifierForm.toMutation(billModifierForm);
  }),
  billItemFormsPreSubtotal: billItemFormsPreSubtotal.map((billItemForm: any, index: any) => {
    return BillItemForm.toMutation({...billItemForm, index});
  }),
  billItemFormsPostSubtotal: billItemFormsPostSubtotal.map((billItemForm: any, index: any) => {
    return BillItemForm.toMutation({...billItemForm, index});
  }),
  deletedBillItemForms: deletedBillItemForms.map((billItemForm: any) => {
    return BillItemForm.toMutation({...billItemForm, isDeleted: true, index: null});
  }),
});

const handleRemoveBillRuleForm = ({form, field, indexToRemove, billRuleForm}: any) => {
  const isConditional = billRuleForm.kind === BillRuleKind.CONDITIONAL_BILL_ITEM;
  if (isConditional) {
    // Remove bill rule form, add it to deleted bill rule forms.
    // The related bill item will be removed on the backend.
    const deletedBillRuleFormsField = `${field}.deletedBillRuleForms`;
    const existingDeletedBillRuleForms = _.get(form.values, deletedBillRuleFormsField, []);
    form.setFieldValue(deletedBillRuleFormsField, [...existingDeletedBillRuleForms, billRuleForm]);
  }

  const billRuleFormsField = `${field}.billRuleForms`;
  const billRuleForms = _.get(form.values, billRuleFormsField);
  const updatedBillRuleForms = _.remove(billRuleForms, (form, i) => i !== indexToRemove);
  form.setFieldValue(billRuleFormsField, updatedBillRuleForms);
};

const handleRemoveBillItemForm = ({form, billFormField, billItemFormsField, billItemForm}: any) => {
  // If the bill item is conditional, remove the associated bill rule form
  const billRuleFormsField = `${billFormField}.billRuleForms`;
  const billRuleForms = _.get(form.values, billRuleFormsField);
  const billRuleFormIndex = _.findIndex(
    billRuleForms,
    (billRuleForm) =>
      _.toString(billItemForm.billItemId) === _.toString((billRuleForm as any).billItemId),
  );
  if (billRuleFormIndex !== -1) {
    const conditionalBillRuleForm = billRuleForms[billRuleFormIndex];
    const deletedBillRuleFormsField = `${billFormField}.deletedBillRuleForms`;
    const existingDeletedBillRuleForms = _.get(form.values, deletedBillRuleFormsField, []);
    form.setFieldValue(deletedBillRuleFormsField, [
      ...existingDeletedBillRuleForms,
      conditionalBillRuleForm,
    ]);

    // @ts-expect-error TS(2367): This condition will always return 'true' since the... Remove this comment to see the full error message
    const newBillRuleForms = _.filter(billRuleForms, (form, index) => index !== billRuleFormIndex);
    form.setFieldValue(billRuleFormsField, newBillRuleForms);
  }

  // Append billItemForm to deletedBillItemForms
  const deletedBillItemFormsField = `${billFormField}.deletedBillItemForms`;
  form.setFieldValue(deletedBillItemFormsField, [
    ..._.get(form.values, deletedBillItemFormsField),
    billItemForm,
  ]);

  // Remove billItemForm from billItemForms
  const field = `${billFormField}.${billItemFormsField}`;
  const updatedBillItemForms = _.filter(
    _.get(form.values, field),
    (form) => form.index !== billItemForm.index,
  );
  form.setFieldValue(
    field,
    updatedBillItemForms.map((billItemForm, index) => ({...billItemForm, index})),
  );
};

const BillForm = {
  handleRemoveBillRuleForm,
  handleRemoveBillItemForm,

  edit,
  editWithAllOptions,
  new: _new,
  newFromBillType,
  toForm,
  toMutation,
};

export default BillForm;
