// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import Field from 'modules/App/components/Field';
import PageV2 from 'modules/App/components/PageV2';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  align-items: center;
`;

const Button = Styled.LoadingButton`
  height: 60px;
  width: 400px;
`;

const ButtonText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const TextInput = Styled.TextInput.H3`
`;

const ProjectBillingPaymentWithDescriptionSection = ({
  form,
  handleSubmit,
  submitting,
  descriptionField,
  amountField,
  button,
  pageTitle,
  pageSubtitle,
  descriptionInputPlaceholder,
  inputLabel,
  PaymentAmountHeader,
}: any) => {
  const paymentMethod = _.get(form.values, 'beginPaymentV3Form.method');
  const isNumeric =
    paymentMethod &&
    _.includes([PaymentMethodKind.CHECK, PaymentMethodKind.AUTHORIZE_DOT_NET], paymentMethod);
  return (
    <Container>
      <PageV2.Title>{pageTitle}</PageV2.Title>
      <PageV2.Subtitle>{pageSubtitle}</PageV2.Subtitle>
      {PaymentAmountHeader ? <PaymentAmountHeader /> : <Space height={20} />}
      <PaymentAmountSection title={'Payment Amount:'} amount={_.get(form.values, amountField)} />
      <Space height={20} />
      <Field
        {...form}
        component={TextInput}
        label={inputLabel}
        name={descriptionField}
        input={{
          returnKeyType: 'done',
          placeholder: descriptionInputPlaceholder,
          keyboardType: isNumeric ? 'numeric' : 'default',
          style: {
            height: 55,
            width: 400,
            borderRadius: 5,
            fontSize: 20,
          },
        }}
      />
      <Space height={20} />
      <Button onPress={handleSubmit} loading={submitting}>
        <ButtonText>{button}</ButtonText>
      </Button>
      <Space height={16} />
    </Container>
  );
};

export default ProjectBillingPaymentWithDescriptionSection;
