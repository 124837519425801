// Libraries
import React from 'react';

// Supermove
import {Checkbox, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import TextBadge from 'modules/App/components/TextBadge';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  margin-horizontal: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.blue.accent};
`;

const Actions = Styled.View`
  margin-horizontal: 5px;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Name = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  margin-left: 5px;
  flex: 2;
`;

const BranchCode = Styled.H6`
  color: ${colors.gray.secondary};
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  flex: 3;
`;

const Position = ({user}: any) => {
  return (
    <Row>
      <TextBadge
        key={user.moverPosition.id}
        text={user.moverPosition.name}
        color={colors.gray.secondary}
      />
      <Space width={4} />
    </Row>
  );
};

type OwnCreateJobUserItemProps = {
  user: any;
};

// @ts-expect-error TS(2456): Type alias 'CreateJobUserItemProps' circularly ref... Remove this comment to see the full error message
type CreateJobUserItemProps = OwnCreateJobUserItemProps & typeof CreateJobUserItem.defaultProps;

// @ts-expect-error TS(7022): 'CreateJobUserItem' implicitly has type 'any' beca... Remove this comment to see the full error message
const CreateJobUserItem = ({isSelected, user, onSelect}: CreateJobUserItemProps) => {
  return (
    <Container>
      <Actions>
        <Checkbox
          checked={isSelected}
          color={colors.blue.interactive}
          size={20}
          onChange={onSelect}
        />
      </Actions>
      <Touchable onPress={() => onSelect(user)}>
        <Content>
          <Name numberOfLines={1}>{user.firstName}</Name>
          <Position user={user} />
          <BranchCode numberOfLines={1}>{user.branchCode}</BranchCode>
        </Content>
      </Touchable>
    </Container>
  );
};

CreateJobUserItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateJobUserItem.fragment = gql`
  fragment CreateJobUserItem on User {
    id
    firstName
    branchCode
    moverPosition {
      id
      name
    }
  }
`;

export default CreateJobUserItem;
