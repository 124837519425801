// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Collection, Datetime, Duration} from '@supermove/utils';

const Container = Styled.View`
`;

const sortJobUsers = ({jobUsers}: any) => {
  return _.sortBy(jobUsers, ['user.firstName', 'user.lastName']);
};

const Item = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Text = Styled.H7`
`;

const formatTimeRanges = ({timeRanges}: any) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getJobUserTimes = ({jobUser}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(jobUser, 'timesheet.timeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).end : (timeRange as any).start;
  });
};

const CommercialJobUser = ({jobUser, job}: any) => (
  <Item>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <Cell key={0} style={{width: 80}}>
        <Text>{jobUser.branchCode}</Text>
      </Cell>
    )}
    <Cell key={1} style={{width: 140}}>
      <Text numberOfLines={1}>{User.getFullName(jobUser.user)}</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell key={2} style={{width: 140}}>
        <Text numberOfLines={1}>{jobUser.position}</Text>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <Text numberOfLines={1}>{jobUser.user.organization.name}</Text>
        )}
      </Cell>
    )}
    {getJobUserTimes({jobUser}).map((time, index) => (
      <Cell key={`time-${index}`} style={{width: 80}}>
        <Text numberOfLines={1}>{time ? Datetime.convertToDisplayTime(time) : 'N/A'}</Text>
      </Cell>
    ))}
    <Cell key={3} style={{width: 80}}>
      <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeDrive)}</Text>
    </Cell>
    <Cell key={4} style={{width: 80}}>
      <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeWithoutBreak)}</Text>
    </Cell>
  </Item>
);

const TableHeader = ({names, job}: any) => (
  <Item>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <Cell style={{width: 80}}>
        <Text>Branch / IC Code</Text>
      </Cell>
    )}
    <Cell style={{width: 140}}>
      <Text>Name</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell style={{width: 140}}>
        <Text>Position</Text>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <Text>Labor Source</Text>
        )}
      </Cell>
    )}
    {names.map((name: any, index: any) => (
      <Cell key={index} style={{width: 80}}>
        <Text>{name}</Text>
      </Cell>
    ))}
    <Cell style={{width: 80}}>
      <Text>Travel Hours</Text>
    </Cell>
    <Cell style={{width: 80}}>
      <Text>Total Hours</Text>
    </Cell>
  </Item>
);

const getColumnNames = ({job}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(job, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).endName : (timeRange as any).startName;
  });
};

type OwnCommercialMoveTimesheetTableProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'CommercialMoveTimesheetTableProps' cir... Remove this comment to see the full error message
type CommercialMoveTimesheetTableProps = OwnCommercialMoveTimesheetTableProps &
  typeof CommercialMoveTimesheetTable.defaultProps;

// @ts-expect-error TS(7022): 'CommercialMoveTimesheetTable' implicitly has type... Remove this comment to see the full error message
const CommercialMoveTimesheetTable = ({job}: CommercialMoveTimesheetTableProps) => (
  <ScrollView horizontal>
    <Container>
      <TableHeader names={getColumnNames({job})} job={job} />
      <FlatList
        data={sortJobUsers({jobUsers: job.confirmedJobUsers})}
        keyExtractor={(jobUser: any) => jobUser.id}
        renderItem={({item: jobUser, index}: any) => (
          <CommercialJobUser jobUser={jobUser} job={job} />
        )}
      />
    </Container>
  </ScrollView>
);

CommercialMoveTimesheetTable.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialMoveTimesheetTable.fragment = gql`
  fragment CommercialMoveTimesheetTable on Job {
    id
    hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo: hasJobFeature(
      kind: "CREW_COMMERCIAL_TIMESHEET_SHOW_ADDITIONAL_JOB_USER_INFO"
    )
    organization {
      id
      defaultTimesheetTimeRanges {
        startName
        endName
      }
      features {
        isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        isEnabledJobUserShowOrganizationName: isEnabled(feature: "JOB_USER_SHOW_ORGANIZATION_NAME")
      }
    }
    confirmedJobUsers {
      id
      position
      branchCode
      timesheet {
        timeRanges {
          name
          start
          end
          kind
          startName
          endName
          date
          timestamp
          length
        }
        totalTimeDrive
        totalTimeWithoutBreak
      }
      user {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
  }
`;

export default CommercialMoveTimesheetTable;
