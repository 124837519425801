// Supermove
import {Currency} from '@supermove/utils';

const Payment = {
  toForm: ({paymentId, method, totalAmount}: any) => ({
    paymentId,
    method,
    totalAmount: Currency.toForm(totalAmount),
  }),

  toMutation: ({paymentId, method, totalAmount}: any) => ({
    paymentId,
    method,
    totalAmount: Currency.toMutation(totalAmount),
  }),
};

export default Payment;
