// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import {PageLoadingIndicator} from 'modules/App/components';
import {CustomerJobProgress} from 'modules/Job/Customer/components';
import CustomerJobPageV2 from 'modules/Job/Customer/components/CustomerJobPageV2';
import ProjectBillsList from 'modules/Project/Billing/components/ProjectBillsList';

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Button = Styled.Button`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

const FooterContent = ({job}: any) => {
  const {navigator} = useNavigation();

  return (
    <FooterContainer>
      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
        <Text>Back</Text>
      </Button>
      <Button
        color={colors.blue.interactive}
        onPress={() => {
          Navigation.navigateToPostMovePayments({job, navigator});
        }}
      >
        <Text>Confirm</Text>
      </Button>
    </FooterContainer>
  );
};

const PageContent = ({job}: any) => {
  return (
    <ScrollView style={{flex: 1}}>
      <CustomerJobProgress selected={'bill'} items={CustomerJobProgress.items.finish} />
      <Content>
        <Title>{`Congrats, ${job.customer.firstName}. Your move is complete.`}</Title>
        <Subtitle>{`Please review and confirm the details below.`}</Subtitle>
        <Space height={20} />
        <ProjectBillsList project={job.project} showPrices showGrandTotal showBalance />
      </Content>
    </ScrollView>
  );
};

const ReviewBillingProjectCustomerJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(ReviewBillingProjectCustomerJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <CustomerJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <PageContent refetch={refetch} job={data.job} />;
        }}
      </Loading>
      <CustomerJobPageV2.Footer>
        <Loading loading={loading}>{() => <FooterContent job={data.job} />}</Loading>
      </CustomerJobPageV2.Footer>
    </CustomerJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReviewBillingProjectCustomerJobPage.query = gql`
  ${Navigation.navigateToPostMovePayments.fragment}
  ${ProjectBillsList.fragment}

  query ReviewBillingProjectCustomerJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      isLastActiveJob
      organization {
        id
        shouldSkipTipPrompt
      }
      customer {
        id
        firstName
      }
      project {
        id
        currentPrimaryBill {
          id
          uuid
        }
        ...ProjectBillsList
      }
      ...Navigation_navigateToPostMovePayments
    }
  }
`;

export default ReviewBillingProjectCustomerJobPage;
