// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

export interface CancelAddPhotosModalProps {
  isOpen: boolean;
  handleGoBack: () => void;
  handleStay: () => void;
}

const CancelAddPhotosModal = ({isOpen, handleGoBack, handleStay}: CancelAddPhotosModalProps) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={'Quit adding photos?'}
      message={'By leaving this page, any uploaded photos will be lost. This cannot be undone.'}
      primaryActionText={'Close'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleGoBack}
      handlePressOutside={handleStay}
      handleSecondaryAction={handleStay}
      isMobileSheet={false}
    />
  );
};

export default CancelAddPhotosModal;
