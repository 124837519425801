// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useMountEffect, useNavigation} from '@supermove/hooks';
import {DeviceInfo, Storage, Permissions} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import LogInEmployeeForm from '@shared/modules/Authentication/forms/LogInEmployeeForm';
import useLogInEmployeeMutation from '@shared/modules/Authentication/hooks/useLogInEmployeeMutation';
import Page from 'modules/App/components/Page';
import LogInEmployeeTabletPinModal from 'modules/Authentication/Users/components/LogInEmployeeTabletPinModal';
import LogOutOrganizationModal from 'modules/Authentication/Users/components/LogOutOrganizationModal';
import {OrganizationUsersGrid, SelectableUser} from 'modules/Organization/components';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Content = Styled.View`
  margin-top: 20px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-vertical: 10px;
  margin-horizontal: 40px;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Info = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Users = Styled.View`
  padding-top: 40px;
  padding-horizontal: 10px;
`;

const FlexContainer = Styled.View`
  flex: ${1 / 6};
`;

const User = Styled.View`
  padding-horizontal: 10px;
  padding-bottom: 20px;
`;

const AcknowledgeLocationTrackingModal = ({isOpen, handleClose}: any) => {
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.BoldText>
        {`Supermove collects location data to enable the ` +
          `consumer to track the location of the truck even ` +
          `when the app is closed or not in use.`}
      </SmallModal.BoldText>
      <Space height={16} />
      <Row>
        <ExternalLink url={`https://supermove.co/privacy-policy`}>
          <SmallModal.ButtonText color={colors.blue.interactive}>
            Privacy Policy
          </SmallModal.ButtonText>
        </ExternalLink>
      </Row>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Deny</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          color={colors.blue.interactive}
          onPress={() => {
            Permissions.handleAndroidLocationTrackingPermission();
            handleClose();
          }}
        >
          Accept
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const UserItem = ({organization, user}: any) => {
  const {navigator} = useNavigation();
  const {isOpen, handleOpen, handleClose} = useModal();
  const logInEmployeeForm = LogInEmployeeForm.new({authToken: user.authToken});
  const {form, submitting, handleSubmit} = useLogInEmployeeMutation({
    logInEmployeeForm,
    onSuccess: () => {
      // Navigate to the authenticated portion of the app.
      navigator.navigate('Authenticated');
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <FlexContainer>
        <User>
          <SelectableUser
            user={user}
            size={60}
            onSelect={() => {
              // We only open up the modal if the user has a tablet pin.
              if (user.hasTabletPin) {
                return handleOpen();
              }

              // Otherwise submit the mutation and log in right away.
              handleSubmit();
            }}
          />
        </User>
      </FlexContainer>
      <LogInEmployeeTabletPinModal
        isOpen={isOpen}
        submitting={submitting}
        form={form}
        user={user}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

const UsersAuthenticationPageContent = ({organization}: any) => {
  const {navigator} = useNavigation();
  const acknowledgeLocationTrackingModal = useModal({name: 'Acknowledge Location Tracking Modal'});
  const logoutOrganizationModal = useModal({name: 'Logout Organization Modal'});

  useMountEffect(() => {
    // If there is no organization it means that the user has been logged out
    if (!organization) {
      navigator.navigate('OrganizationAuthentication');
      return;
    }

    // Handle location tracking permission on tablet if needed
    const isAndroid = DeviceInfo.getPlatform() === 'ANDROID';
    if (isAndroid) {
      Permissions.handleAndroidCheckLocationTrackingPermission({
        handleGetPermission: acknowledgeLocationTrackingModal.handleOpen,
      });
    }
  });

  return (
    <Container>
      {!!organization && (
        <ScrollView style={{flex: 1}}>
          <Content>
            <Actions>
              <Button onPress={logoutOrganizationModal.handleOpen}>
                <Text>Reset Device</Text>
              </Button>
            </Actions>
            <Info>
              <Title>Welcome back, please select your profile.</Title>
              <Subtitle>{`After selecting, you'll be able to manage your jobs.`}</Subtitle>
            </Info>
            <Users>
              <OrganizationUsersGrid
                users={organization.filteredUsers}
                renderUser={({user}: any) => <UserItem organization={organization} user={user} />}
              />
            </Users>
          </Content>
        </ScrollView>
      )}
      <LogOutOrganizationModal
        isOpen={logoutOrganizationModal.isOpen}
        onClose={logoutOrganizationModal.handleClose}
        onConfirm={async () => {
          // NOTE(mark): Right now, we don't need anything to persist once a user logs out.
          // This seems correct but there might be rare cases where we don't want this.
          await Storage.clear();
          (navigator as any).popToTop();
        }}
      />
      <AcknowledgeLocationTrackingModal
        isOpen={acknowledgeLocationTrackingModal.isOpen}
        handleClose={acknowledgeLocationTrackingModal.handleClose}
      />
    </Container>
  );
};

const UsersAuthenticationPage = () => {
  return (
    <Page
      fetchPolicy={'network-only'}
      getVariables={() => ({role: 'employee'})}
      query={UsersAuthenticationPage.query}
    >
      {({loading, data}: any) => (
        <Loading loading={loading}>
          {() => <UsersAuthenticationPageContent organization={data.organization} />}
        </Loading>
      )}
    </Page>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UsersAuthenticationPage.query = gql`
  ${LogInEmployeeTabletPinModal.fragment}
  ${SelectableUser.fragment}

  query UsersAuthenticationPage($role: String!) {
    ${gql.query}
    organization {
      id
      filteredUsers(role: $role) {
        id
        authToken
        hasTabletPin
        ...LogInEmployeeTabletPinModal
        ...SelectableUser
      }
    }
  }
`;

export default UsersAuthenticationPage;
