// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {Page} from 'modules/App/components';
import {ViewerJobBanner} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const CrewJobPage = ({fetchPolicy, getVariables, query, onFocus, children}: any) => (
  <Page fetchPolicy={fetchPolicy} getVariables={getVariables} query={query} onFocus={onFocus}>
    {(props: any) => (
      <Container>
        <ViewerJobBanner view={'EMPLOYEE'} />
        {children(props)}
      </Container>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobPage.fragment = gql`
  fragment CrewJobPage on Job {
    id
  }
`;

export default CrewJobPage;
