// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';
import {Collection} from '@supermove/utils';

// Components
import CommercialJobTimesheetSetTimeModal from './CommercialJobTimesheetSetTimeModal';
import NoJobUserTimesheetFormsErrorModal from './NoJobUserTimesheetFormsErrorModal';

const Container = Styled.View`
  flex-direction: row;
  padding-horizontal: 10px;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const UserInfo = Styled.View`
  justify-content: center;
  width: 305px;
  padding-left: 20px;
`;

const SelectedText = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-top: 5px;
`;

const Button = Styled.Button`
  height: 30px;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.blue.interactive};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Touchable = Styled.Touchable`
  width: 80px;
  padding-vertical: 8px;
  background-color: ${(props) => ((props as any).isRequired ? colors.alpha(colors.yellow.hover, 0.2) : colors.white)};
  border-left-width: 1px;
  border-right-width: 1px;
  border-left-color: ${(props) => ((props as any).isFirst ? colors.blue.accent : colors.transparent)};
  border-right-color: ${colors.blue.accent};
`;

const Item = Styled.View`
`;

const Name = Styled.H7`
  padding-horizontal: 6px;
  height: 40px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const EditWrapper = Styled.View`
  height: 20px;
`;

const Indicator = Styled.Loading`
`;

const EditText = Styled.H7`
  margin-top: 5px;
  ${fontWeight(500)}
  color: ${colors.blue.accentDark};
  text-align: center;
`;

const Totals = Styled.View`
  flex-direction: row;
`;

const Total = Styled.View`
  flex: 1;
  width: 65px;
  padding-vertical: 8px;
  padding-horizontal: 5px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-left-color: ${(props) => ((props as any).isFirst ? colors.blue.accent : colors.transparent)};
  border-right-color: ${colors.blue.accent};
`;

const TotalName = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const getTimeRanges = ({commercialJobTimesheetForm}: any) => {
  const {timeRanges} = commercialJobTimesheetForm;
  return _.concat(timeRanges, _.last(timeRanges));
};

const getIsRequired = ({timeRange, isFirst, isLast}: any) => {
  return isLast ? timeRange.endIsRequired : timeRange.startIsRequired;
};

const getSelectedJobUserTimesheetForms = ({commercialJobTimesheetForm}: any) => {
  return commercialJobTimesheetForm.jobUserTimesheetForms.filter((jobUserTimesheetForm: any) => {
    return jobUserTimesheetForm.isSelected;
  });
};

const getUsersCount = ({commercialJobTimesheetForm}: any) => {
  return getSelectedJobUserTimesheetForms({commercialJobTimesheetForm}).length;
};

const getSelectedText = ({commercialJobTimesheetForm}: any) => {
  const count = getUsersCount({commercialJobTimesheetForm});
  switch (count) {
    case 0:
      return 'No crew selected to edit';
    default:
      return `Selected ${count} crew to edit`;
  }
};

const setIsSelectedForAll = ({form, isSelected}: any) => {
  const newCommercialJobTimesheetForm = _.cloneDeep(form.values.commercialJobTimesheetForm);
  const {jobUserTimesheetForms} = newCommercialJobTimesheetForm;

  jobUserTimesheetForms.forEach((jobUserTimesheetForm: any, index: any) => {
    _.set(newCommercialJobTimesheetForm, `jobUserTimesheetForms.${index}.isSelected`, isSelected);
  });

  form.setFieldValue('commercialJobTimesheetForm', newCommercialJobTimesheetForm);
};

const selectAll = ({form}: any) => {
  setIsSelectedForAll({form, isSelected: true});
};

const deselectAll = ({form}: any) => {
  setIsSelectedForAll({form, isSelected: false});
};

const CommercialJobTimesheetHeader = ({
  loading,
  commercialJobTimesheetForm,
  form,
  onUpdate,
}: any) => {
  const timeRanges = getTimeRanges({commercialJobTimesheetForm});

  return (
    <Container>
      <UserInfo>
        <SelectedText>{getSelectedText({commercialJobTimesheetForm})}</SelectedText>
        <Actions>
          <Button color={colors.blue.accent} onPress={() => selectAll({form})}>
            <Text>Select All</Text>
          </Button>
          <ButtonSpace />
          <Button color={colors.blue.accent} onPress={() => deselectAll({form})}>
            <Text>Deselect All</Text>
          </Button>
        </Actions>
      </UserInfo>
      {/* @ts-expect-error TS(2740): Type 'ReactElement<any, any>' is missing the follo... Remove this comment to see the full error message */}
      {Collection.mapWith(timeRanges, (timeRange, index, {isFirst, isLast}) => (
        <CommercialJobTimesheetSetTimeModal
          key={index}
          isFirst={isFirst}
          isLast={isLast}
          index={index}
          initialTime={''}
          timeRange={timeRange}
          commercialJobTimesheetForm={commercialJobTimesheetForm}
          form={form}
          onUpdate={onUpdate}
          // @ts-expect-error TS(7031): Binding element 'handleOpenUpdateModal' implicitly... Remove this comment to see the full error message
          trigger={({handleOpen: handleOpenUpdateModal}) => (
            <NoJobUserTimesheetFormsErrorModal
              // @ts-expect-error TS(7031): Binding element 'handleOpenErrorModal' implicitly ... Remove this comment to see the full error message
              trigger={({handleOpen: handleOpenErrorModal}) => (
                <Touchable
                  disabled={loading}
                  // @ts-expect-error TS(2769): No overload matches this call.
                  isFirst={isFirst}
                  isLast={isLast}
                  isRequired={getIsRequired({timeRange, isFirst, isLast})}
                  onPress={() => {
                    if (getUsersCount({commercialJobTimesheetForm}) === 0) {
                      handleOpenErrorModal();
                    } else {
                      handleOpenUpdateModal();
                    }
                  }}
                >
                  <Item>
                    <Name>{isLast ? timeRange.endName : timeRange.startName}</Name>
                    <EditWrapper>
                      {loading ? (
                        <Indicator size={'small'} color={colors.gray.secondary} />
                      ) : (
                        <EditText>Edit Time</EditText>
                      )}
                    </EditWrapper>
                  </Item>
                </Touchable>
              )}
            />
          )}
        />
      ))}
      <Totals>
        <Total>
          <TotalName>Travel Hours</TotalName>
        </Total>
        <Total>
          <TotalName>Total Hours</TotalName>
        </Total>
      </Totals>
    </Container>
  );
};

export default CommercialJobTimesheetHeader;
