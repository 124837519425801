// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';

// App
import JobStepKind from '@shared/modules/Job/enums/JobStepKind';
import CompleteJobStepForm from '@shared/modules/Job/forms/CompleteJobStepForm';
import useCompleteJobStepMutation from '@shared/modules/Job/hooks/useCompleteJobStepMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import PayrollTimesheetPageContent from 'modules/Job/Crew/Timesheet/components/PayrollTimesheetPageContent';
import CrewJobPageV2 from 'modules/Job/Crew/components/CrewJobPageV2';

const PayrollTimesheetAfterMovePageContent = ({job, refetch}: any) => {
  const {navigator} = useNavigation();
  const {submitting, handleSubmit} = useCompleteJobStepMutation({
    completeJobStepForm: CompleteJobStepForm.new({
      jobId: job.id,
      jobStepKind: JobStepKind.CREW_AFTER_MOVE_PAYROLL_TIMESHEET,
    }),
    onSuccess: () => {
      navigator.navigate('ShowJob', {uuid: job.uuid});
    },
    onError: (errors: any) => console.log(errors),
  });

  return (
    <PayrollTimesheetPageContent
      job={job}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      refetch={refetch}
    />
  );
};

const PayrollTimesheetAfterMovePage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(PayrollTimesheetAfterMovePage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <CrewJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <PayrollTimesheetAfterMovePageContent job={data.job} refetch={refetch} />;
        }}
      </Loading>
    </CrewJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayrollTimesheetAfterMovePage.query = gql`
  ${PayrollTimesheetPageContent.fragment}

  query PayrollTimesheetAfterMovePage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      ...PayrollTimesheetPageContent
    }
  }
`;

export default PayrollTimesheetAfterMovePage;
