/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobNavigation from 'modules/Job/components/JobNavigation';

const Button = Styled.Button`
  height: 40px;
  width: 100px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const CrewJobNavigation = (props: any) => {
  return (
    <JobNavigation
      {...props}
      getSideWidth={({responsive}: any) => 110}
      renderRight={() => {
        if (!props.data.job.isComplete) {
          return null;
        }

        return (
          <Button
            color={colors.blue.interactive}
            onPress={() => props.navigator.navigate('ActionsJob', {uuid: props.params.uuid})}
          >
            <Text>Actions</Text>
          </Button>
        );
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobNavigation.fragment = gql`
  ${JobNavigation.fragment}

  fragment CrewJobNavigation on Job {
    id
    isComplete
    ...JobNavigation
  }
`;

export default CrewJobNavigation;
