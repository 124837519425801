// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import ConfirmCrewSlotsForJobForm from '@shared/modules/Dispatch/forms/ConfirmCrewSlotsForJobForm';
import CrewSlotForm from '@shared/modules/Dispatch/forms/CrewSlotForm';
import useConfirmCrewSlotsForJobMutation from '@shared/modules/Dispatch/hooks/useConfirmCrewSlotsForJobMutation';
import BackButton from 'components/BackButton';
import Navigation from 'core/Navigation';
import {Notification, PageLoadingIndicator} from 'modules/App/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';
import TruckItem from 'modules/Job/CrewSlot/Confirm/components/TruckItem';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
`;

const Info = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const JobUsers = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CrewDetails = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const RequiredTrucksBadge = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 2px;
  padding-horizontal: 6px;
  background-color: ${colors.gray.tertiary};
  border-radius: 2px;
`;

const RequiredTrucksCount = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const RequiredTrucksCountBadge = ({count}: any) => {
  return (
    <RequiredTrucksBadge>
      <RequiredTrucksCount>{count}</RequiredTrucksCount>
      <Space width={4} />
      <Icon source={Icon.Truck} color={colors.white} size={16} />
    </RequiredTrucksBadge>
  );
};

const RoundBadge = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 8px;
  background-color: ${colors.orange.status};
  border-radius: 20px;
`;

const RoundText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PrimaryCrewBadge = () => {
  return (
    <RoundBadge>
      <RoundText>Primary</RoundText>
    </RoundBadge>
  );
};

const CrewHeader = ({crew}: any) => {
  return (
    <Header>
      <CrewDetails>
        <Name>{crew.organization.name}</Name>
        <Space width={8} />
        <RequiredTrucksCountBadge count={crew.numberOfRequiredTrucks} />
        <Space width={8} />
        {crew.isPrimary && <PrimaryCrewBadge />}
      </CrewDetails>
    </Header>
  );
};

const handleSetCrewSlotFormIsPrimary = ({crewSlotForms}: any) => {
  // @ts-expect-error TS(7006): Parameter 'crewSlotForm' implicitly has an 'any' t... Remove this comment to see the full error message
  return crewSlotForms.map((crewSlotForm, index) => ({
    ...crewSlotForm,

    // The first CrewSlotForm is always the primary.
    isPrimary: index === 0,
  }));
};

const getNewCrewSlotForms = ({isSelected, crewId, truckId, slotId, crewSlotForms}: any) => {
  if (isSelected) {
    // Remove the CrewSlotForm.
    return crewSlotForms.filter((crewSlotForm: any) => String(crewSlotForm.truckId) !== truckId);
  }

  // Create a new CrewSlotForm with truckId and (optional) slotId.
  const crewSlotForm = CrewSlotForm.new({
    crewId,
    truckId,
    slotId,
    driverId: undefined,
    isPrimary: false,
  });

  return [...crewSlotForms, CrewSlotForm.toForm(crewSlotForm)];
};

const handleSelectTruck = ({isSelected, field, crewId, truckId, slotId, form}: any) => {
  const crewSlotForms = _.get(form.values, field);
  const newCrewSlotForms = getNewCrewSlotForms({
    isSelected,
    crewId,
    truckId,
    slotId,
    crewSlotForms,
  });

  const updatedCrewSlotForms = handleSetCrewSlotFormIsPrimary({crewSlotForms: newCrewSlotForms});
  form.setFieldValue(field, updatedCrewSlotForms);
};

const getIsTruckSelected = ({crewSlotForms, truckId}: any) => {
  const crewSlotForm = _.find(crewSlotForms, (crewSlotForm) => {
    return String(crewSlotForm.truckId) === String(truckId);
  });
  return !!crewSlotForm;
};

const getSlotIdForTruckId = ({slots, truckId}: any) => {
  return _.get(
    _.find(slots, (slot) => String(slot.truckId) === String(truckId)),
    'id',
  );
};

const Section = Styled.View`
`;

const CrewSection = ({crew, setCrewSlotsToCrewForm, organization, form}: any) => {
  const {crewId, crewSlotForms, index: formIndex} = setCrewSlotsToCrewForm;
  const field = `confirmCrewSlotsForJobForm.setCrewSlotsToCrewForms.${formIndex}.crewSlotForms`;
  const {
    slots,
    organization: {activeTrucks, overflowTrucks},
  } = crew;
  const trucks = [...activeTrucks, ...overflowTrucks];

  return (
    <Section>
      <CrewHeader crew={crew} />
      <Space height={10} />
      <FlatList
        data={trucks}
        keyExtractor={(truck: any) => String(truck.id)}
        extraData={form}
        renderItem={({item: truck, index}: any) => {
          const isSelected = getIsTruckSelected({crewSlotForms, truckId: truck.id});
          const slotId = getSlotIdForTruckId({slots, truckId: truck.id});

          return (
            <TruckItem
              isFirst={index === 0}
              isLast={index === trucks.length - 1}
              isSelected={isSelected}
              truck={truck}
              onSelect={() => {
                handleSelectTruck({isSelected, field, crewId, truckId: truck.id, slotId, form});
              }}
            />
          );
        }}
        ListEmptyComponent={() => (
          <Notification
            color={colors.orange.status}
            text={`This crew does not have any trucks.`}
            style={{
              marginVertical: 10,
            }}
          />
        )}
      />
      <Space height={10} />
    </Section>
  );
};

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CountBadge = Styled.View`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.blue.accent};
  border-radius: 4px;
`;

const CountText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const CrewsCountBadge = ({count}: any) => {
  return (
    <CountBadge>
      <CountText>{pluralize('Labor Source', count, true)}</CountText>
    </CountBadge>
  );
};

const getSortedCrews = ({crews}: any) => {
  return _.sortBy(crews, ['organization.name']);
};

const getSetCrewSlotsToCrewFormsByCrewId = ({setCrewSlotsToCrewForms}: any) => {
  const setCrewSlotsToCrewFormsWithIndex = _.map(
    setCrewSlotsToCrewForms,
    (setCrewSlotsToCrewForm, index) => {
      return {...setCrewSlotsToCrewForm, index};
    },
  );

  return _.reduce(
    setCrewSlotsToCrewFormsWithIndex,
    (all, setCrewSlotToCrewForm) => ({
      ...all,
      [String(setCrewSlotToCrewForm.crewId)]: setCrewSlotToCrewForm,
    }),
    {},
  );
};

const ConfirmCrewSlotsForJobPageContent = ({job}: any) => {
  const {navigator, params} = useNavigation();

  const confirmCrewSlotsForJobForm = ConfirmCrewSlotsForJobForm.initialize(job);
  const {form, submitting, handleSubmit} = useConfirmCrewSlotsForJobMutation({
    confirmCrewSlotsForJobForm,
    onSuccess: () => {
      Navigation.navigateFromTruckSuccess({navigator, params, job});
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const {crews, organization} = job;
  const {setCrewSlotsToCrewForms} = form.values.confirmCrewSlotsForJobForm;
  const sortedCrews = getSortedCrews({crews});
  const setCrewSlotsToCrewFormsByCrewId = getSetCrewSlotsToCrewFormsByCrewId({
    setCrewSlotsToCrewForms,
  });

  return (
    <Container>
      <ScrollView style={{flex: 1}}>
        <CrewJobProgress selected={'select-user'} items={CrewJobProgress.items.pre} />
        <Content>
          <Info>
            <Row>
              <Title>Confirm which truck(s) your movers are using</Title>
              {organization.hasMultipleOrganizations && (
                <React.Fragment>
                  <Space width={8} />
                  <CrewsCountBadge count={crews.length} />
                </React.Fragment>
              )}
            </Row>
            <Subtitle>Select from the list of trucks below.</Subtitle>
          </Info>
          <JobUsers>
            {sortedCrews.map((crew) => {
              const crewId = String(crew.id);
              const setCrewSlotsToCrewForm = _.get(setCrewSlotsToCrewFormsByCrewId, crewId);

              return (
                <CrewSection
                  key={crew.id}
                  crew={crew}
                  setCrewSlotsToCrewForm={setCrewSlotsToCrewForm}
                  organization={organization}
                  form={form}
                />
              );
            })}
          </JobUsers>
        </Content>
      </ScrollView>
      <Footer>
        <BackButton />
        <Button loading={submitting} color={colors.blue.interactive} onPress={handleSubmit}>
          <Text>Next</Text>
        </Button>
      </Footer>
    </Container>
  );
};

const ConfirmCrewSlotsForJobPage = () => {
  return (
    <CrewJobPage
      fetchPolicy={'network-only'}
      getVariables={({params}: any) => ({uuid: params.uuid})}
      query={ConfirmCrewSlotsForJobPage.query}
    >
      {({loading, data}: any) => (
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => <ConfirmCrewSlotsForJobPageContent job={data.job} />}
        </Loading>
      )}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmCrewSlotsForJobPage.query = gql`
  ${ConfirmCrewSlotsForJobForm.initialize.fragment}
  ${Navigation.navigateFromTruckSuccess.fragment}
  ${CrewJobPage.fragment}
  ${TruckItem.fragment}

  query ConfirmCrewSlotsForJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      organization {
        id
        hasMultipleOrganizations
      }
      crews {
        id
        isPrimary
        numberOfRequiredTrucks
        slots {
          id
          truckId
        }
        organization {
          id
          name
          activeTrucks {
            id
            ...TruckItem
          }
          overflowTrucks {
            id
            ...TruckItem
          }
        }
      }
      ...ConfirmCrewSlotsForJobForm_initialize
      ...Navigation_navigateFromTruckSuccess
      ...CrewJobPage
    }
  }
`;

export default ConfirmCrewSlotsForJobPage;
