// Libraries
import React from 'react';

import {Permissions} from '@supermove/sdk';

// APIs
import StripeTerminal from '../../apis/StripeTerminal';

interface StripeTerminalProviderProps {
  host: string;
  endpoint: string;
  stripeAccountId: string;
  children: (args: {isInitialized: boolean}) => React.ReactNode;
}

class StripeTerminalProvider extends React.Component<StripeTerminalProviderProps> {
  protected mounted: boolean = false;

  state = {
    isInitialized: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.checkAndInitialize();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async checkAndInitialize() {
    const status = await Permissions.check(Permissions.LOCATION);
    if (this.mounted && status === Permissions.RESULTS.AUTHORIZED) {
      await this.initialize();
    }
  }

  async initialize() {
    const {host, endpoint, stripeAccountId} = this.props;
    try {
      console.log('Initializing Stripe Terminal');
      await StripeTerminal.initialize({
        host,
        endpoint,
        stripeAccountId,
      });

      if (this.mounted) {
        console.log('Successfully initialized Stripe Terminal');
        this.setState({isInitialized: true});
      }
    } catch (error) {
      console.log('Initialize error', {error});
    }
  }

  render() {
    const {isInitialized} = this.state;
    return this.props.children({isInitialized});
  }
}

export default StripeTerminalProvider;
