// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SkipDocumentV2Form from '@shared/modules/Document/forms/SkipDocumentV2Form';

const useSkipDocumentV2Mutation = ({skipDocumentV2Form, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      skipDocumentV2Form: SkipDocumentV2Form.toForm(skipDocumentV2Form),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSkipDocumentV2Mutation.mutation,
    variables: {
      skipDocumentV2Form: SkipDocumentV2Form.toMutation(form.values.skipDocumentV2Form),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSkipDocumentV2Mutation.mutation = gql`
  mutation useSkipDocumentV2Mutation($skipDocumentV2Form: SkipDocumentV2Form!) {
    response: skipDocumentV2(skipDocumentV2Form: $skipDocumentV2Form) {
      ${gql.errors}
      document {
        id
        job {
          id
          crewAppDocuments {
            id
            isSkipped
          }
        }
      }
    }
  }
`;

export default useSkipDocumentV2Mutation;
