// Libraries
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import {Notification} from 'modules/App/components';

import CrewJobDocumentItem from './CrewJobDocumentItem';

const Document = Styled.View`
`;

const CrewJobDocumentsList = ({step, job}: any) => (
  <FlatList
    data={job.filteredDocuments}
    keyExtractor={(document: any) => document.id}
    renderItem={({item: document, index}: any) => (
      <Document>
        <CrewJobDocumentItem
          isFirst={index === 0}
          isLast={index === job.filteredDocuments.length - 1}
          index={index}
          step={step}
          document={document}
        />
      </Document>
    )}
    ListEmptyComponent={() => (
      <Notification
        color={colors.orange.status}
        text={'This job does not currently have any additional documents.'}
      />
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobDocumentsList.fragment = gql`
  ${CrewJobDocumentItem.fragment}

  fragment CrewJobDocumentsList on Job {
    id
    filteredDocuments(step: $step) {
      id
      uuid
      template
      ...CrewJobDocumentItem
    }
  }
`;

export default CrewJobDocumentsList;
