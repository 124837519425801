// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {OrganizationModel, PaymentMethodModel} from '@supermove/models';
import {Percent, withFragment} from '@supermove/utils';

// App
import PaymentMethodKind, {
  PaymentMethodKindType,
} from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';

const sortByName = (
  leftPaymentMethod: PaymentMethodModel,
  rightPaymentMethod: PaymentMethodModel,
) => {
  return leftPaymentMethod.name.localeCompare(rightPaymentMethod.name);
};

// Sorts payment methods be kind then by name
// This is used to render payment methods in a consistent order
const getSortedPaymentMethods = withFragment(
  (paymentMethods: PaymentMethodModel[]) => {
    const sections = PaymentMethodKind.getSections();
    const kindOrderMap = new Map<PaymentMethodKindType, number>();

    sections.forEach((section, sectionIndex) => {
      section.kinds.forEach((kind, kindIndex) => {
        kindOrderMap.set(kind, sectionIndex * 1000 + kindIndex);
      });
    });

    return paymentMethods.sort((leftPaymentMethod, rightPaymentMethod) => {
      const leftPaymentMethodOrder = kindOrderMap.get(leftPaymentMethod.kind) ?? Infinity;
      const rightPaymentMethodOrder = kindOrderMap.get(rightPaymentMethod.kind) ?? Infinity;

      if (leftPaymentMethodOrder !== rightPaymentMethodOrder) {
        return leftPaymentMethodOrder - rightPaymentMethodOrder;
      }

      return sortByName(leftPaymentMethod, rightPaymentMethod);
    });
  },
  gql`
    fragment PaymentMethod_getSortedPaymentMethods on PaymentMethod {
      id
      kind
      name
    }
  `,
);

// Groups payment methods by sections
const groupPaymentMethodsBySection = (sortedMethods: PaymentMethodModel[]) => {
  const sections = PaymentMethodKind.getSections();
  const result = sections.map((section) => {
    const sectionMethods = section.kinds.flatMap((kind) =>
      sortedMethods.filter((method) => method.kind === kind),
    );

    return {
      label: section.label,
      paymentMethods: sectionMethods,
    };
  });

  // Collect methods that don't belong to any defined sections
  const otherMethods = sortedMethods.filter(
    (method) => !sections.some((section) => section.kinds.includes(method.kind)),
  );

  // Only add to the existing "Other" section if there are other methods
  const otherSectionIndex = result.findIndex((section) => section.label === 'Other');
  if (otherMethods.length > 0 && otherSectionIndex !== -1) {
    result[otherSectionIndex].paymentMethods.push(...otherMethods);
  }

  return result;
};

// Groups and sorts payment methods by sections
// This is used when we want to render the section label with the fields
const getSortedPaymentMethodsBySection = withFragment(
  (paymentMethods: PaymentMethodModel[]) => {
    const sortedMethods = getSortedPaymentMethods(paymentMethods);
    return groupPaymentMethodsBySection(sortedMethods);
  },
  gql`
    ${getSortedPaymentMethods.fragment}
    fragment PaymentMethod_getSortedPaymentMethodsBySection on PaymentMethod {
      id
      ...PaymentMethod_getSortedPaymentMethods
    }
  `,
);

const getPaymentMethodByUuid = withFragment(
  ({
    organization,
    paymentMethodUuid,
  }: {
    organization: OrganizationModel;
    paymentMethodUuid: string;
  }) => {
    const {paymentMethods} = organization;
    return paymentMethodUuid
      ? paymentMethods.find((paymentMethod) => paymentMethod.uuid === paymentMethodUuid)
      : null;
  },
  gql`
    fragment PaymentMethod_getPaymentMethodByUuid on Organization {
      paymentMethods {
        id
        uuid
      }
    }
  `,
);

const getPaymentMethodDisplay = withFragment(
  (paymentMethod: PaymentMethodModel, {defaultValue = ''}: {defaultValue?: string} = {}) => {
    const {feePercentage} = paymentMethod;
    if (!feePercentage) {
      return defaultValue;
    }
    const isDiscount = feePercentage < 0;
    return `${Percent.display(Math.abs(feePercentage))} ${isDiscount ? 'discount' : 'fee'}`;
  },
  gql`
    fragment PaymentMethod_getPaymentMethodDisplay on PaymentMethod {
      id
      feePercentage
    }
  `,
);

const getOfficePaymentMethodOptions = withFragment(
  ({organization}: {organization: OrganizationModel}) => {
    const filteredPaymentMethods = organization.paymentMethodsForOffice.filter(
      (paymentMethod) => !PaymentMethodKind.getCanProcessPayments(paymentMethod.kind),
    );
    return getSortedPaymentMethods(filteredPaymentMethods).map(
      (paymentMethod: PaymentMethodModel) => ({
        label: paymentMethod.name,
        value: paymentMethod.id,
        description: getPaymentMethodDisplay(paymentMethod),
        // Include kind so that it can be set on payment forms
        kind: paymentMethod.kind,
      }),
    );
  },
  gql`
    ${getPaymentMethodDisplay.fragment}
    ${getSortedPaymentMethods.fragment}
    fragment PaymentMethod_getOfficePaymentMethodOptions on Organization {
      id
      paymentMethodsForOffice {
        id
        name
        kind
        ...PaymentMethod_getPaymentMethodDisplay
        ...PaymentMethod_getSortedPaymentMethods
      }
    }
  `,
);

// Returns the fee amount for a given payment method in cents
const computePaymentFeeAmount = withFragment(
  (paymentMethod: PaymentMethodModel, {amount}: {amount: number}) => {
    if (!paymentMethod.feePercentage) {
      return 0;
    }
    return _.round(amount * paymentMethod.feePercentage);
  },
  gql`
    fragment PaymentMethod_computePaymentFeeAmount on PaymentMethod {
      id
      feePercentage
    }
  `,
);

const PaymentMethod = {
  getSortedPaymentMethods,
  getSortedPaymentMethodsBySection,
  getPaymentMethodByUuid,
  getOfficePaymentMethodOptions,
  getPaymentMethodDisplay,
  computePaymentFeeAmount,
};

export default PaymentMethod;
