// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const ConfirmTimesForMoveMutation = ({move, onSuccess, onError, children}: any) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      moveId: move.id,
    }}
    mutation={ConfirmTimesForMoveMutation.mutation}
    onCompleted={({confirmTimesForMove: {move, errors}}: any) => {
      if (move) {
        onSuccess(move);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmTimesForMoveMutation.fragment = gql`
  fragment ConfirmTimesForMoveMutation on Move {
    id
  }
`;

ConfirmTimesForMoveMutation.mutation = gql`
  mutation ConfirmTimesForMoveMutation(
    $moveId: Int!,
  ) {
    confirmTimesForMove(
      moveId: $moveId,
    ) {
      ${gql.errors}
      move {
        id
      }
    }
  }
`;

export default ConfirmTimesForMoveMutation;
