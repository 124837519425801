// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useState} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {DeviceInfo} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import ConfirmJobUsersForJobForm from '@shared/modules/Dispatch/forms/ConfirmJobUsersForJobForm';
import StartEstimatedRangeJobForm from '@shared/modules/Dispatch/forms/StartEstimatedRangeJobForm';
import useConfirmJobUsersForJobMutation from '@shared/modules/Dispatch/hooks/useConfirmJobUsersForJobMutation';
import useStartEstimatedRangeJobMutation from '@shared/modules/Dispatch/hooks/useStartEstimatedRangeJobMutation';
import BackButton from 'components/BackButton';
// @ts-expect-error TS(2307): Cannot find module 'config' or its corresponding t... Remove this comment to see the full error message
import {AppInfo} from 'config';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CrewJobPage from 'modules/Job/Crew/components/CrewJobPage';
import CrewJobProgress from 'modules/Job/Crew/components/CrewJobProgress';
import ConfirmJobCrewsSection from 'modules/Job/JobUser/Confirm/components/ConfirmJobCrewsSection';
import EstimatedRangeJobAlreadyStartedModal from 'modules/Job/JobUser/Confirm/components/EstimatedRangeJobAlreadyStartedModal';
import ConfirmCrewForJobUserErrorModal from 'modules/Job/User/components/ConfirmCrewForJobUserErrorModal';
import ConfirmJobErrorModal from 'modules/Job/User/components/ConfirmJobErrorModal';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
`;

const Info = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const CrewNotesButton = Styled.Button`
  height: 30px;
  width: 100px;
`;

const CrewNotesButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const CrewNotesContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 8px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.blue.interactive};
  border-radius: 4px;
`;

const Bold = Styled.H6`
  ${fontWeight(700)}
`;

const CrewNotesText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CountBadge = Styled.View`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.blue.accent};
  border-radius: 4px;
`;

const CountText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const CrewsContainer = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const CrewsCountBadge = ({count}: any) => {
  return (
    <CountBadge>
      <CountText>{pluralize('Labor Source', count, true)}</CountText>
    </CountBadge>
  );
};

const ConfirmJobUsersForJobPageContent = ({data, handleOpenUser, handleOpenNoUsers}: any) => {
  const {navigator, params} = useNavigation();
  const [isExpanded, setIsExpanded] = useState(true);
  const appInstallId = _.get(data, 'device.appInstall.id');
  const estimatedRangeJobAlreadyStartedModal = useModal();
  const handleOnError = (errors: any) => {
    const firstError = _.first(errors);
    switch ((firstError as any).field) {
      case 'confirm_job_users_for_job_form.viewer_id':
        return handleOpenUser();
      case 'confirm_job_users_for_job_form.job_user_forms':
        return handleOpenNoUsers();
      case 'start_estimated_range_job_form.already_started':
        return estimatedRangeJobAlreadyStartedModal.handleOpen();
      default:
        return;
    }
  };
  const confirmJobUsersForJobForm = ConfirmJobUsersForJobForm.initialize(data.job, {appInstallId});
  const {form, submitting, handleSubmit} = useConfirmJobUsersForJobMutation({
    confirmJobUsersForJobForm,
    onSuccess: ({job}: any) => {
      navigator.navigate(params.success, {uuid: params.uuid});
    },
    onError: (errors: any) => {
      handleOnError(errors);
    },
  });
  const startEstimatedRangeJobForm = StartEstimatedRangeJobForm.new(data.job, {appInstallId});
  const startEstimatedRangeJob = useStartEstimatedRangeJobMutation({
    startEstimatedRangeJobForm,
    onSuccess: ({job}: any) => {
      navigator.navigate(params.success, {uuid: job.uuid});
    },
    onError: (errors: any) => {
      handleOnError(errors);
    },
  });
  const handleStartEstimatedRangeJob = () => {
    startEstimatedRangeJob.form.setFieldValue(
      'startEstimatedRangeJobForm.confirmJobUsersForJobForm',
      _.get(form.values, 'confirmJobUsersForJobForm'),
    );
    // Use setTimeout to ensure form completes updating before submitting
    setTimeout(() => startEstimatedRangeJob.handleSubmit(), 0);
  };

  const {crews, organization} = data.job;
  const isEstimatedRange = Job.getIsEstimatedRange(data.job);

  return (
    <Container>
      <ScrollView style={{flex: 1}}>
        <CrewJobProgress selected={'select-user'} items={CrewJobProgress.items.pre} />
        <Content>
          <Info>
            <Row>
              <Title>Confirm Movers</Title>
              {organization.hasMultipleOrganizations && (
                <React.Fragment>
                  <Space width={8} />
                  <CrewsCountBadge count={crews.length} />
                </React.Fragment>
              )}
            </Row>
            <Row>
              <Subtitle>
                These members are assigned to this job. Tap "Add" to modify this list.
              </Subtitle>
              <Space width={8} />
              <CrewNotesButton onPress={() => setIsExpanded(!isExpanded)}>
                <CrewNotesButtonText>
                  {isExpanded ? 'Hide Notes' : 'Show Notes'}
                </CrewNotesButtonText>
              </CrewNotesButton>
            </Row>
            {isExpanded && (
              <React.Fragment>
                <Space height={8} />
                <CrewNotesContainer>
                  <CrewNotesText>
                    <Bold style={{marginRight: 4}}>Notes:</Bold>
                    {data.job.additionalNotes}
                  </CrewNotesText>
                </CrewNotesContainer>
              </React.Fragment>
            )}
          </Info>
          <CrewsContainer>
            <ConfirmJobCrewsSection job={data.job} form={form} />
          </CrewsContainer>
        </Content>
      </ScrollView>
      <Footer>
        <BackButton />
        {isEstimatedRange ? (
          <Button
            loading={startEstimatedRangeJob.submitting}
            color={colors.blue.interactive}
            onPress={handleStartEstimatedRangeJob}
          >
            <Text>Confirm</Text>
          </Button>
        ) : (
          <Button loading={submitting} color={colors.blue.interactive} onPress={handleSubmit}>
            <Text>Confirm</Text>
          </Button>
        )}
      </Footer>
      <EstimatedRangeJobAlreadyStartedModal isOpen={estimatedRangeJobAlreadyStartedModal.isOpen} />
    </Container>
  );
};

const ConfirmJobUsersForJobPage = () => {
  return (
    <CrewJobPage
      fetchPolicy={'network-only'}
      getVariables={({params}: any) => ({
        uuid: params.uuid,
        appName: 'Crew',
        appPlatform: DeviceInfo.getPlatform(),
        releaseBuild: AppInfo.getBuildNumber(),
        deviceUniqueId: DeviceInfo.getUniqueId(),
      })}
      query={ConfirmJobUsersForJobPage.query}
    >
      {({loading, data}: any) => (
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <ConfirmCrewForJobUserErrorModal
              name={data.viewer.fullName}
              // @ts-expect-error TS(7031): Binding element 'handleOpenUser' implicitly has an... Remove this comment to see the full error message
              trigger={({handleOpen: handleOpenUser}) => (
                <ConfirmJobErrorModal
                  // @ts-expect-error TS(7031): Binding element 'handleOpenNoUsers' implicitly has... Remove this comment to see the full error message
                  trigger={({handleOpen: handleOpenNoUsers}) => (
                    <ConfirmJobUsersForJobPageContent
                      data={data}
                      handleOpenUser={handleOpenUser}
                      handleOpenNoUsers={handleOpenNoUsers}
                    />
                  )}
                />
              )}
            />
          )}
        </Loading>
      )}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmJobUsersForJobPage.query = gql`
  ${ConfirmJobUsersForJobForm.initialize.fragment}
  ${StartEstimatedRangeJobForm.new.fragment}
  ${CrewJobPage.fragment}
  ${ConfirmJobCrewsSection.fragment}
  ${Job.getIsEstimatedRange.fragment}

  query ConfirmJobUsersForJobPage(
    $uuid: String!,
    $appName: String!,
    $appPlatform: String!,
    $releaseBuild: Int!,
    $deviceUniqueId: String!,
  ) {
    ${gql.query}
    viewer {
      id
      fullName
    }
    device(uniqueId: $deviceUniqueId) {
      id
      appInstall(
        appName: $appName,
        appPlatform: $appPlatform,
        releaseBuild: $releaseBuild,
      ) {
        id
      }
    }
    job(uuid: $uuid) {
      id
      additionalNotes
      organization {
        id
        hasMultipleOrganizations
      }
      crews {
        id
      }
      ...ConfirmJobUsersForJobForm_initialize
      ...CrewJobPage
      ...ConfirmJobCrewsSection
      ...Job_getIsEstimatedRange
      ...StartEstimatedRangeJobForm_new
    }
  }
`;

export default ConfirmJobUsersForJobPage;
