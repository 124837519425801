// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import UserRole from '@shared/modules/User/enums/UserRole';

const Wrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Container = Styled.View`
  padding-vertical: 20px;
  width: 600px;
  background-color: ${colors.white};
`;

const Name = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 3px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
  height: 280px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  z-index: 1;
`;

const Rows = Styled.View`
  padding: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const RowSpace = Styled.View`
  height: 10px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-horizontal: 20px;
  z-index: 0;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PositionField = ({organization, form, field}: any) => {
  const moverPositions = organization.features.isEnabledMoverPositionMultibranch
    ? Organization.getCompanySettingsMoverPositionsByRole({organization, role: UserRole.EMPLOYEE})
    : organization.moverPositions;
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.moverPositionIds.0`}
      label={'Position'}
      isRequired
      input={{
        options: moverPositions.map((moverPosition: any) => ({
          label: moverPosition.name,
          value: Number(moverPosition.id),
        })),
        placeholder: 'Select one',
        setFieldValue: form.setFieldValue,
        onChangeValue: (moverPositionId: any) =>
          form.setFieldValue(`${field}.primaryMoverPositionId`, moverPositionId),
        style: {
          width: '100%',
        },
      }}
      style={{width: '100%'}}
    />
  );
};

const EditJobUserModal = ({
  field,
  form,
  isOpen,
  organization,
  onClose,
  onDelete,
  primaryActionText = 'Save',
}: any) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Wrapper pointerEvents={'box-none'}>
        <Container>
          <Name>Edit Crew Member</Name>
          <Subtitle>Change details related to this crew member.</Subtitle>
          <Content>
            <Rows>
              <Row>
                <PositionField organization={organization} form={form} field={field} />
              </Row>
              {organization.features.isEnabledJobUserShowBranchCode && (
                <React.Fragment>
                  <RowSpace />
                  <Row>
                    <FieldInput
                      {...form}
                      name={`${field}.branchCode`}
                      label={'Branch Code'}
                      input={{
                        placeholder: 'Enter branch code',
                      }}
                      style={{
                        flex: 1,
                      }}
                    />
                  </Row>
                </React.Fragment>
              )}
            </Rows>
          </Content>
          <Actions>
            {onDelete ? (
              <Button
                color={colors.red.warning}
                onPress={() => {
                  // Close the modal, then delete the row.
                  onClose();
                  onDelete();
                }}
              >
                <Text>Delete</Text>
              </Button>
            ) : (
              <Space />
            )}
            <Button onPress={onClose}>
              <Text>{primaryActionText}</Text>
            </Button>
          </Actions>
        </Container>
      </Wrapper>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobUserModal.fragment = gql`
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment EditJobUserModal on Organization {
    id
    features {
      isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
    }
    moverPositions {
      id
      name
    }
    ...Organization_getCompanySettingsMoverPositionsByRole
  }
`;

export default EditJobUserModal;
