// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Modal, ScrollView, Space, Styled, Tabs} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState, useTabs} from '@supermove/hooks';
import {BillItem} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillStage from '@shared/modules/Billing/enums/BillStage';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import useUpdateBillMutation from '@shared/modules/Billing/hooks/useUpdateBillMutation';
import EmptyListImage from 'modules/Project/Billing/Edit/assets/EmptyListImage';
import BillItemRowPostSubtotal from 'modules/Project/Billing/Edit/components/BillItemRowPostSubtotal';
import BillItemRowPreSubtotal from 'modules/Project/Billing/Edit/components/BillItemRowPreSubtotal';
import EditBillItemModal from 'modules/Project/Billing/Edit/components/EditBillItemModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ScreenContainer = Styled.View`
  flex: 1;
  padding-horizontal: 64px;
  padding-vertical: 32px;
`;

const ModalContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 16px;
  flex: 1;
`;

const EmptyStateContainer = Styled.View`
  align-items: center;
`;

const EmptyTitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EmptyCaption = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
`;

const Image = Styled.Image`
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const ButtonText = Styled.H6`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CustomItemButton = Styled.ButtonV2`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-vertical: 8px;
  padding-horizontal: 12px;
`;

const CustomItemText = Styled.H7`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const Pill = Styled.View`
  padding-horizontal: 8px;
  padding-vertical: 4px;
  background-color: ${colors.blue.accent};
  border-radius: 20px;
`;

const PillText = Styled.H8`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 20px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const ActivityIndicator = Styled.Loading`
`;

const getFieldForBillItemForm = ({billItemForm}: any) => {
  if (billItemForm.billStage === BillStage.PRE_SUBTOTAL) {
    return 'billForm.billItemFormsPreSubtotal';
  }
  return 'billForm.billItemFormsPostSubtotal';
};

const handleEditBillItemForm = ({form, field, billItemForm}: any) => {
  const updateField = field || getFieldForBillItemForm({billItemForm});
  const isNewCustomItem = _.isNil(billItemForm.index);
  if (isNewCustomItem) {
    const billItemForms = _.get(form.values, updateField);
    form.setFieldValue(updateField, [
      ...billItemForms,
      {...billItemForm, index: billItemForms.length},
    ]);
  } else {
    form.setFieldValue(`${updateField}.${billItemForm.index}`, billItemForm);
  }
};

const handleRemoveBillItemForm = ({form, field, billItemForm}: any) => {
  const {index} = billItemForm;
  const billItemForms = _.get(form.values, field);
  const updatedForms = _.filter(billItemForms, (form, formIndex) => formIndex !== index);

  form.setFieldValue(
    field,
    updatedForms.map((billItemForm, index) => ({...billItemForm, index})),
  );
};

const getBillItemFormsPreSubtotal = ({form}: any) => {
  return _.get(form.values, 'billForm.billItemFormsPreSubtotal');
};

const getBillItemFormsPostSubtotal = ({form}: any) => {
  return _.get(form.values, 'billForm.billItemFormsPostSubtotal');
};

const getTabCount = ({tab}: any) => {
  const {label, form, filter} = tab;
  const billItemFormsPreSubtotal = getBillItemFormsPreSubtotal({form});
  const billItemFormsPostSubtotal = getBillItemFormsPostSubtotal({form});
  if (label === 'Summary') {
    return billItemFormsPostSubtotal.length + billItemFormsPreSubtotal.length;
  }
  if (filter.billStage === BillStage.POST_SUBTOTAL) {
    const matchingBillItemFormsPostSubtotal = _.filter(billItemFormsPostSubtotal, (billItemForm) =>
      getBillItemFormMatchesFilter({billItemForm, filter}),
    );
    return matchingBillItemFormsPostSubtotal.length;
  }
  const matchingBillItemFormsPreSubtotal = _.filter(billItemFormsPreSubtotal, (billItemForm) =>
    getBillItemFormMatchesFilter({billItemForm, filter}),
  );
  return matchingBillItemFormsPreSubtotal.length;
};

const getBillItemFormMatchesFilter = ({billItemForm, filter}: any) => {
  return (
    billItemForm.billStage === filter.billStage &&
    billItemForm.category === filter.category &&
    billItemForm.kind === filter.kind &&
    !billItemForm.unit === !filter.unit
  );
};

const BillItemsModalHeader = ({bill, form, isEnabledTbdBillItems}: any) => {
  const customItemModal = useModal();
  const billItemFormsPreSubtotal = getBillItemFormsPreSubtotal({form});
  const billItemFormsPostSubtotal = getBillItemFormsPostSubtotal({form});
  const totalItems = billItemFormsPreSubtotal.length + billItemFormsPostSubtotal.length;

  return (
    <HeaderContainer>
      <Row>
        <Space width={16} />
        <TitleText>{bill.title}</TitleText>
        <Space width={12} />
        <Pill>
          <PillText>{`${totalItems} Billing Items`}</PillText>
        </Pill>
      </Row>
      <Row>
        <CustomItemButton onPress={customItemModal.handleOpen}>
          <Row>
            <Icon source={Icon.Plus} size={11} color={colors.blue.interactive} />
            <Space width={8} />
            <CustomItemText>Add Custom Item</CustomItemText>
          </Row>
        </CustomItemButton>
        <Space width={16} />
      </Row>
      <EditBillItemModal
        key={customItemModal.isOpen}
        isOpen={customItemModal.isOpen}
        title={'Add Custom Item'}
        handleClose={customItemModal.handleClose}
        handleEditBillItemForm={handleEditBillItemForm}
        billForm={form}
        billItemForm={BillItemForm.new({billId: bill.id})}
        isEnabledTbdBillItems={isEnabledTbdBillItems}
      />
    </HeaderContainer>
  );
};

const EmptyState = ({title, caption}: any) => {
  return (
    <EmptyStateContainer>
      <Space height={32} />
      <Image source={EmptyListImage} style={{width: 120, height: 157}} />
      <EmptyTitle>{title}</EmptyTitle>
      <EmptyCaption>{caption}</EmptyCaption>
    </EmptyStateContainer>
  );
};

// Use TouchableDrag to wrap each slide with activeOpacity set to 1.
// This is because the ScrollView for the list items only drags when
// pressing on a touchable element. Wrapping the whole slide allows
// the entire space to be draggable.
const TouchableDrag = Styled.Touchable`
`;

const Tab = ({tab, isSelected, handlePress}: any) => {
  const count = getTabCount({tab});

  return (
    <Tabs.TabContainer
      onPress={handlePress}
      isSelected={isSelected}
      style={{paddingHorizontal: 12}}
    >
      <Tabs.TabText icon={tab.icon} isSelected={isSelected}>
        {tab.label}
      </Tabs.TabText>
      {count > 0 && (
        <React.Fragment>
          <Space width={8} />
          <Pill>
            <PillText>{count}</PillText>
          </Pill>
        </React.Fragment>
      )}
    </Tabs.TabContainer>
  );
};

const SummarySlide = ({form, isEnabledTbdBillItems}: any) => {
  const billItemFormsPreSubtotal = getBillItemFormsPreSubtotal({form});
  const billItemFormsPostSubtotal = getBillItemFormsPostSubtotal({form});
  const hasItems = billItemFormsPreSubtotal.length > 0 || billItemFormsPostSubtotal.length > 0;
  return (
    <ScrollView style={{flex: 1}} contentContainerStyle={{paddingVertical: 12}}>
      <TouchableDrag activeOpacity={1}>
        {!hasItems && <EmptyState title={'There are no items selected.'} />}
        {billItemFormsPreSubtotal.map((billItemForm: any, index: any) => {
          return (
            <BillItemRowPreSubtotal
              key={index}
              form={form}
              billItemForm={billItemForm}
              index={index}
              handleEditBillItemForm={handleEditBillItemForm}
              handleRemoveBillItemForm={handleRemoveBillItemForm}
              isEditable
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
          );
        })}
        {billItemFormsPostSubtotal.length > 0 && (
          <React.Fragment>
            <Space height={16} />
            <Row>
              <Space width={16} />
              <ButtonText color={colors.gray.primary}>Items After Subtotal</ButtonText>
            </Row>
            <Space height={8} />
          </React.Fragment>
        )}
        {billItemFormsPostSubtotal.map((billItemForm: any, index: any) => {
          return (
            <BillItemRowPostSubtotal
              key={index}
              form={form}
              billItemForm={billItemForm}
              index={index}
              handleEditBillItemForm={handleEditBillItemForm}
              handleRemoveBillItemForm={handleRemoveBillItemForm}
              isEditable
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
          );
        })}
      </TouchableDrag>
    </ScrollView>
  );
};

const BillItemsPreSubtotalSlide = ({form, filter, isEnabledTbdBillItems}: any) => {
  const tabBillItemForms = _.filter(
    form.values.billForm.allBillItemFormsPreSubtotal,
    (billItemForm) => getBillItemFormMatchesFilter({billItemForm, filter}),
  );
  const tabCustomBillItemForms = _.filter(
    _.get(form.values, 'billForm.billItemFormsPreSubtotal'),
    (billItemForm) => {
      const {name, amount} = billItemForm;
      return (
        getBillItemFormMatchesFilter({billItemForm, filter}) &&
        !_.some(tabBillItemForms, {name, amount})
      );
    },
  );
  const allTabBillItemForms = _.orderBy(
    [...tabBillItemForms, ...tabCustomBillItemForms],
    [(form) => form.name.toLowerCase()],
    ['asc'],
  );
  return (
    <ScrollView style={{flex: 1}} contentContainerStyle={{paddingVertical: 12}}>
      <TouchableDrag activeOpacity={1}>
        {allTabBillItemForms.length === 0 && (
          <EmptyState
            title={'There are no items in this category.'}
            caption={`Press 'Add Custom Item' to add new items.`}
          />
        )}
        {allTabBillItemForms.map((billItemForm, index) => {
          return (
            <BillItemRowPreSubtotal
              form={form}
              billItemForm={billItemForm}
              key={index}
              index={index}
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
          );
        })}
      </TouchableDrag>
    </ScrollView>
  );
};

const BillItemsPostSubtotalSlide = ({form, filter, isEnabledTbdBillItems}: any) => {
  const tabBillItemForms = _.filter(
    _.get(form.values, 'billForm.allBillItemFormsPostSubtotal'),
    (billItemForm) => getBillItemFormMatchesFilter({billItemForm, filter}),
  );
  const tabCustomBillItemForms = _.filter(getBillItemFormsPostSubtotal({form}), (billItemForm) => {
    const {name, percentage, amount} = billItemForm;
    return (
      getBillItemFormMatchesFilter({billItemForm, filter}) &&
      !_.some(tabBillItemForms, {name, percentage, amount})
    );
  }).map((billItemForm) => ({...billItemForm, isDisabledAdd: true}));
  const allTabBillItemForms = _.orderBy(
    [...tabBillItemForms, ...tabCustomBillItemForms],
    [(billItemForm) => billItemForm.name.toLowerCase()],
    ['asc'],
  );
  return (
    <ScrollView style={{flex: 1}} contentContainerStyle={{paddingVertical: 12}}>
      <TouchableDrag activeOpacity={1}>
        {allTabBillItemForms.length === 0 && (
          <EmptyState
            title={'There are no items in this category.'}
            caption={`Press 'Add Custom Item' to add new items.`}
          />
        )}
        {allTabBillItemForms.map((billItemForm, index) => {
          return (
            <BillItemRowPostSubtotal
              form={form}
              billItemForm={billItemForm}
              key={index}
              index={index}
              isEnabledTbdBillItems={isEnabledTbdBillItems}
            />
          );
        })}
      </TouchableDrag>
    </ScrollView>
  );
};

const BillItemsSlide = ({form, filter, isEnabledTbdBillItems}: any) => {
  if (filter.billStage === BillStage.PRE_SUBTOTAL) {
    return (
      <BillItemsPreSubtotalSlide
        form={form}
        filter={filter}
        isEnabledTbdBillItems={isEnabledTbdBillItems}
      />
    );
  }
  return (
    <BillItemsPostSubtotalSlide
      form={form}
      filter={filter}
      isEnabledTbdBillItems={isEnabledTbdBillItems}
    />
  );
};

const LineItemsModalFooter = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <FooterContainer>
      <Row>
        <Button color={colors.gray.border} onPress={handleClose} disabled={submitting}>
          <ButtonText color={colors.gray.tertiary}>Cancel</ButtonText>
        </Button>
        <Space width={16} />
        <Button
          color={submitting ? colors.gray.disabled : colors.blue.interactive}
          disabled={submitting}
          onPress={handleSubmit}
        >
          {submitting ? (
            <ActivityIndicator size={'small'} color={colors.white} />
          ) : (
            <ButtonText color={colors.white}>Confirm</ButtonText>
          )}
        </Button>
      </Row>
    </FooterContainer>
  );
};

const EditBillItemsModal = ({handleClose, isOpen, refetch, bill}: any) => {
  const {isEnabledTbdBillItems} = bill.organization.features;
  const initialIndex = bill.organization.features.isEnabledSuppliesButtonCrewApp ? 1 : 0;
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const tabs = useTabs({onChangeIndex: setCurrentIndex, initialIndex});
  const billForm = BillForm.editWithAllOptions(bill);
  const {form, submitting, handleSubmit} = useUpdateBillMutation({
    billForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  return (
    // Modal.Content requires an onClose function, but we do not want the
    // modal to close when pressed outside, so we pass in a dummy function.
    <Modal.Content onClose={() => {}} isOpen={isOpen}>
      <ScreenContainer>
        <ModalContainer>
          <Space height={20} />
          <BillItemsModalHeader
            bill={bill}
            form={form}
            handleClose={handleClose}
            isEnabledTbdBillItems={isEnabledTbdBillItems}
          />
          <Space height={20} />
          <Tabs
            tabs={tabs}
            TabComponent={Tab}
            style={{flex: 1}}
            // @ts-expect-error TS(2322): Type '{ children: (Element | Element[])[]; tabs: {... Remove this comment to see the full error message
            onChangeIndex={(index: any) => setCurrentIndex(index)}
          >
            <Tabs.Slide
              // @ts-expect-error TS(2769): No overload matches this call.
              tab={{
                label: 'Summary',
                icon: Icon.Check,
                form,
              }}
              key={`SUMMARY_0`}
            >
              {currentIndex === 0 && (
                <SummarySlide form={form} isEnabledTbdBillItems={isEnabledTbdBillItems} />
              )}
            </Tabs.Slide>
            {BillItem.FILTERS.map((filter, index) => (
              <Tabs.Slide
                // @ts-expect-error TS(2769): No overload matches this call.
                tab={{
                  label: filter.displayName,
                  value: filter.name,
                  form,
                  filter,
                }}
                key={`BILL_MODIFIER_${index}`}
              >
                {currentIndex === index + 1 && (
                  <BillItemsSlide
                    form={form}
                    filter={filter}
                    isEnabledTbdBillItems={isEnabledTbdBillItems}
                  />
                )}
              </Tabs.Slide>
            ))}
          </Tabs>
          <LineItemsModalFooter
            form={form}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillItemsModal.fragment = gql`
  ${BillForm.editWithAllOptions.fragment}

  fragment EditBillItemsModal on Bill {
    id
    title
    organization {
      id
      features {
        isEnabledSuppliesButtonCrewApp: isEnabled(feature: "SUPPLIES_BUTTON_CREW_APP")
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
    ...BillForm_editWithAllOptions
  }
`;

export default EditBillItemsModal;
