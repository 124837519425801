// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';

// Components
import {Notification} from 'modules/App/components';

// NOTE(mark): Sometimes we do not have a currentEmployee if the user has been
// unset from the job.
const AlreadyPreparedJobNotification = ({job}: any) => (
  <Notification
    color={colors.red.warning}
    text={
      job.currentEmployee
        ? `${User.getFullName(job.currentEmployee)} has already started this ` +
          `job. Please contact the office to resolve this issue.`
        : 'This job has already been started. Please contact the office to resolve this issue.'
    }
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
AlreadyPreparedJobNotification.fragment = gql`
  fragment AlreadyPreparedJobNotification on Job {
    id
    currentEmployee {
      id
      firstName
      lastName
    }
  }
`;

export default AlreadyPreparedJobNotification;
