// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ExternalLink, Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Event, Location, User} from '@supermove/models';
import {DeviceInfo} from '@supermove/sdk';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Location as LocationUtils} from '@supermove/utils';

// App
import {PageLoadingIndicator} from 'modules/App/components';
import {useLogDeviceCurrentPosition} from 'modules/App/logic';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';
import {LogEventForJobMutation} from 'modules/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Line = Styled.View`
  margin-vertical: 30px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobFooter = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const SectionWrapper = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.H4`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SectionBody = Styled.H6`
  margin-top: 10px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isLink ? colors.blue.accentDark : colors.gray.primary)};
`;

const Section = ({title, body, style}: any) => (
  <SectionWrapper style={style}>
    <SectionTitle>{title}</SectionTitle>
    {body}
  </SectionWrapper>
);

const LocationCrewJobPage = () => {
  // TODO(mark): Hooks migration.
  const [deviceId, setDeviceId] = useState();
  useLogDeviceCurrentPosition({deviceId});

  return (
    <CrewJobPage
      fetchPolicy={'network-only'}
      getVariables={({params}: any) => ({uuid: params.uuid, uniqueId: DeviceInfo.getUniqueId()})}
      query={LocationCrewJobPage.query}
    >
      {({navigator, params, loading, data}: any) => {
        // TODO(mark): Hooks migration.
        setDeviceId(_.get(data, 'device.id'));

        return (
          <Loading loading={loading} as={PageLoadingIndicator}>
            {() => (
              <Container>
                <CrewJobProgress selected={'location'} items={CrewJobProgress.items.pre} />
                <ScrollView style={{flex: 1}}>
                  <Content>
                    <Title>You're all set to drive to the customer.</Title>
                    <Subtitle>
                      Press "Next" once you've arrived at the Origin location. If you need to
                      contact the customer, use the contact information below.
                    </Subtitle>
                    <Line />
                    <Info>
                      <Row>
                        <Section
                          title={'Contact Info:'}
                          // @ts-expect-error TS(2769): No overload matches this call.
                          body={<SectionBody>{User.getContactInfo(data.job.customer)}</SectionBody>}
                        />
                        <Section
                          title={'Origin:'}
                          body={
                            <ExternalLink
                              url={LocationUtils.createGoogleMapsUrl(
                                data.job.startLocation.address,
                              )}
                            >
                              <SectionBody vars={{isLink: true}}>
                                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                                {Location.getDisplayLocation(data.job.startLocation)}
                              </SectionBody>
                            </ExternalLink>
                          }
                          style={{
                            flex: 1,
                          }}
                        />
                      </Row>
                    </Info>
                  </Content>
                </ScrollView>
                <JobFooter>
                  <Group>
                    <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
                      <Text>Back</Text>
                    </Button>
                  </Group>
                  <LogEventForJobMutation
                    jobId={data.job.id}
                    name={Event.MOVE_PREPARE_JOB_FINISHED}
                    onSuccess={() => navigator.navigate('ShowJob', {uuid: params.uuid})}
                    onError={(errors: any) => console.log({errors})}
                  >
                    {({loading, handleSubmit}: any) => (
                      <Button
                        loading={loading}
                        color={colors.blue.interactive}
                        onPress={handleSubmit}
                      >
                        <Text>Next</Text>
                      </Button>
                    )}
                  </LogEventForJobMutation>
                </JobFooter>
              </Container>
            )}
          </Loading>
        );
      }}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationCrewJobPage.query = gql`
  ${CrewJobPage.fragment}
  ${User.getContactInfo.fragment}

  query LocationCrewJobPage($uuid: String!, $uniqueId: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      customer {
        id
        ...User_getContactInfo
      }
      startLocation {
        id
        address
        zipCode
        unit
      }
      ...CrewJobPage
    }
    device(uniqueId: $uniqueId) {
      id
    }
  }
`;

export default LocationCrewJobPage;
