// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import SetCrewSlotsToCrewForm from '@shared/modules/Dispatch/forms/SetCrewSlotsToCrewForm';

const initialize = withFragment(
  (job) => ({
    jobId: (job as any).id,
    setCrewSlotsToCrewForms: (job as any).crews.map((crew: any) => {
      // On the crew app where this is used, we always allow the crew to create new slots as-needed.
      return SetCrewSlotsToCrewForm.edit(crew, {canCreateNewSlot: true});
    }),
  }),
  gql`
    ${SetCrewSlotsToCrewForm.edit.fragment}

    fragment ConfirmCrewSlotsForJobForm_initialize on Job {
      id
      crews {
        id
        ...SetCrewSlotsToCrewForm_edit
      }
    }
  `,
);

const toForm = ({jobId, setCrewSlotsToCrewForms}: any) => ({
  jobId,
  setCrewSlotsToCrewForms: setCrewSlotsToCrewForms.map((setCrewSlotsToCrewForm: any) => {
    return SetCrewSlotsToCrewForm.toForm(setCrewSlotsToCrewForm);
  }),
});

const toMutation = ({jobId, setCrewSlotsToCrewForms}: any) => ({
  jobId,
  setCrewSlotsToCrewForms: setCrewSlotsToCrewForms.map((setCrewSlotsToCrewForm: any) => {
    return SetCrewSlotsToCrewForm.toMutation(setCrewSlotsToCrewForm);
  }),
});

const ConfirmCrewSlotsForJobForm = {
  initialize,
  toForm,
  toMutation,
};

export default ConfirmCrewSlotsForJobForm;
