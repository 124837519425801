// Libraries
import React from 'react';

// Supermove
import {MaintenancePage, SupermoveApp} from '@supermove/app';
import {StatusBar, UpdateChecker, ToastContainer} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useQuery} from '@supermove/hooks';
import {Environment, ScreenOrientation} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
// @ts-expect-error TS(2307): Cannot find module 'config' or its corresponding t... Remove this comment to see the full error message
import {AppInfo} from 'config';
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppView from 'modules/Settings/Update/components/UpdateAppView';

const CrewAppContent = ({children}: any) => {
  const name = 'Crew';
  const version = AppInfo.getVersion();
  const buildNumber = AppInfo.getBuildNumber();
  const {data} = useQuery(CrewApp.query, {fetchPolicy: 'cache-first'});

  return (
    <ErrorModal
      trigger={({handleOpen}: any) => (
        <SupermoveApp
          name={name}
          version={version}
          buildNumber={buildNumber}
          showOfflineOverlay={false}
          onError={(error) => {
            handleOpen();
          }}
          isAuthenticated={data?.isAuthenticated}
          viewer={data?.viewer}
        >
          <StatusBar barStyle={'light-content'} backgroundColor={colors.black} />
          {/* @ts-expect-error TS(2741): Property 'pollInterval' is missing in type '{ chil... Remove this comment to see the full error message */}
          <UpdateChecker showLoading name={name} buildNumber={buildNumber}>
            {({isSkipped, isVisible, isRequired, onSkip}) =>
              isVisible && !isSkipped ? (
                <UpdateAppView isRequired={isRequired} onSkip={onSkip} />
              ) : (
                <React.Fragment>{children}</React.Fragment>
              )
            }
          </UpdateChecker>
          <ToastContainer />
        </SupermoveApp>
      )}
    />
  );
};

// @ts-expect-error TS(7031): Binding element 'Router' implicitly has an 'any' t... Remove this comment to see the full error message
const CrewApp = ({router: Router}) => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');

  useMountEffect(() => {
    // NOTE(cooper): We lock the screen orientation to landscape mode for the entire app
    // Many crew app views are compatible with portrait mode, but some are not, in the future
    // we may address these specific views and unlock the screen orientation at that time
    ScreenOrientation.lockToLandscape();
  });

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <CrewAppContent>
      <Router />
    </CrewAppContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewApp.query = gql`
  ${SupermoveApp.fragment}

  query CrewApp {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      ...SupermoveApp
    }
  }
`;

export default CrewApp;
