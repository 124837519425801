// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {TimesheetPayrollEntry} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Duration} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import FieldInput from '@shared/design/components/Field/FieldInput';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import TextTooltip from '@shared/design/components/TextTooltip';
import EditTimesheetPayrollEntryTimesheetBlocks from '@shared/modules/Timesheet/components/EditTimesheetPayrollEntryTimesheetBlocks';
import CreateTimesheetBlockForm from '@shared/modules/Timesheet/forms/CreateTimesheetBlockForm';
import UpdateTimesheetPayrollEntryForm from '@shared/modules/Timesheet/forms/UpdateTimesheetPayrollEntryForm';
import useUpdateTimesheetPayrollEntryMutation from '@shared/modules/Timesheet/hooks/useUpdateTimesheetPayrollEntryMutation';
import UserRole from '@shared/modules/User/enums/UserRole';

const TIMESHEET_ADJUSTMENT_COLUMN_WIDTH = 348;

const Row = Styled.View`
  flex-direction: row;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 24px;
`;

const SectionHeader = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const SectionCaption = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const Divider = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const TimesheetAdjustmentColumn = Styled.View`
  width: ${TIMESHEET_ADJUSTMENT_COLUMN_WIDTH}px;
`;

const TimesheetAdjustmentLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const TimesheetAdjustmentText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const AdjustmentInputLabel = Styled.Text`
  ${Typography.Responsive.Body}
  position: absolute;
  right: 12px;
  top: ${({responsive}) =>
    // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
    responsive.desktop ? 8 : 12}px;
  color: ${colors.gray.secondary}
  z-index: 99999;
`;

const getDisplayTime = (minutes: any) => {
  const timeString = Duration.toDisplayTime(Math.abs(minutes), {
    hoursLabel: 'H',
    minutesLabel: 'M',
    alwaysShowMinutes: true,
    alwaysShowHours: true,
  });

  return minutes < 0 ? `(${timeString})` : timeString;
};

const getTimesheetMinutes = ({form, field, timesheetBillableEntryMinutes}: any) => {
  const updateTimesheetPayrollEntryForm = _.get(form.values, field);
  const timesheetPayrollEntryMinutes = UpdateTimesheetPayrollEntryForm.getTimesheetMinutes(
    updateTimesheetPayrollEntryForm,
  );
  return timesheetBillableEntryMinutes + timesheetPayrollEntryMinutes;
};

const getTotalMinutes = ({
  form,
  field,
  timesheetMinutes,
  timesheetPayrollEntry,
  crewHoursAdjustmentMinutes,
}: any) => {
  const updateTimesheetPayrollEntryForm = _.get(form.values, field);
  return (
    timesheetMinutes +
    TimesheetPayrollEntry.getBillRuleAdjustmentMinutes(timesheetPayrollEntry) +
    UpdateTimesheetPayrollEntryForm.getAdjustmentAmountMinutes(updateTimesheetPayrollEntryForm) +
    crewHoursAdjustmentMinutes
  );
};

const handleAddTimesheetBlock = ({form, field, rangeFromDate}: any) => {
  const createBlocksField = `${field}.timesheetBlocksCreate`;
  const timesheetBlockForms = _.get(form.values, createBlocksField);
  const newBlockForm = CreateTimesheetBlockForm.toForm(
    CreateTimesheetBlockForm.new({rangeFromDate}),
  );
  form.setFieldValue(createBlocksField, [...timesheetBlockForms, newBlockForm]);
};

const BillableTimesheetBlocks = ({form, timesheetPayrollEntry}: any) => {
  const billableTimesheetBlocks = timesheetPayrollEntry.effectiveTimesheetBlocks.filter(
    (block: any) => block.isBillable,
  );

  return (
    <EditTimesheetPayrollEntryTimesheetBlocks
      form={form}
      timesheetBlocks={billableTimesheetBlocks}
      showLabels
    />
  );
};

const PayrollTimesheetBlocks = ({form, field, rangeFromDate, isDisabled}: any) => {
  return (
    <React.Fragment>
      <EditTimesheetPayrollEntryTimesheetBlocks form={form} field={field} isDisabled={isDisabled} />
      {!isDisabled && (
        <TertiaryButton
          iconLeft={Icon.Plus}
          text={'Add Time'}
          style={{paddingVertical: 8}}
          onPress={() => handleAddTimesheetBlock({form, field, rangeFromDate})}
          isResponsive
        />
      )}
    </React.Fragment>
  );
};

const TimeUnitInput = ({form, field, isMinutes}: any) => {
  const responsive = useResponsive();

  return (
    <Row>
      <FieldInput
        {...form}
        name={`${field}.adjustmentAmount${isMinutes ? 'Minutes' : 'Hours'}`}
        isResponsive
        style={{width: responsive.desktop ? 64 : 80}}
        input={{
          placeholder: '0',
          style: {paddingLeft: 12, paddingRight: responsive.desktop ? 28 : 40},
        }}
      />
      <AdjustmentInputLabel responsive={responsive}>{isMinutes ? 'M' : 'H'}</AdjustmentInputLabel>
    </Row>
  );
};

const TimeAdjustmentInputs = ({form, field}: any) => {
  const multiplierField = `${field}.adjustmentAmountMultiplier`;
  const isNegative = _.get(form.values, multiplierField) < 0;

  return (
    <Row>
      <ContextSwitcher
        contextDefinitions={[
          {
            iconSource: Icon.Minus,
            isSelected: isNegative,
            onPress: () => form.setFieldValue(multiplierField, -1),
          },
          {
            iconSource: Icon.Plus,
            isSelected: !isNegative,
            onPress: () => form.setFieldValue(multiplierField, 1),
          },
        ]}
      />
      <Space width={8} />
      <TimeUnitInput form={form} field={field} />
      <Space width={8} />
      <TimeUnitInput form={form} field={field} isMinutes />
    </Row>
  );
};

const TimesheetAdjustmentValue = ({label, value, responsive, tooltip}: any) => {
  return (
    <TimesheetAdjustmentColumn>
      <TextTooltip text={tooltip} placement={'right'} isEnabledMobileToast={false}>
        <Row style={{width: '100%'}}>
          <TimesheetAdjustmentLabel responsive={responsive}>{label}</TimesheetAdjustmentLabel>
          <Space style={{flex: 1}} />
          <TimesheetAdjustmentText responsive={responsive}>{value}</TimesheetAdjustmentText>
        </Row>
      </TextTooltip>
    </TimesheetAdjustmentColumn>
  );
};

const TimesheetAdjustmentEditor = ({form, field, responsive, isDisabled}: any) => {
  const adjustmentMinutes = UpdateTimesheetPayrollEntryForm.getAdjustmentAmountMinutes(
    _.get(form.values, field),
  );

  return (
    <Row style={{alignItems: 'center', flexWrap: 'wrap'}}>
      <TimesheetAdjustmentValue
        label={'Manual Crew Hours'}
        value={getDisplayTime(adjustmentMinutes)}
        responsive={responsive}
      />
      {!isDisabled && (
        <React.Fragment>
          <Space width={16} />
          <TimeAdjustmentInputs form={form} field={field} />
        </React.Fragment>
      )}
    </Row>
  );
};

const TimesheetAdjustmentSection = ({
  form,
  field,
  timesheetPayrollEntry,
  crewHoursAdjustmentMinutes,
  displayTimesheetHours,
  displayTotalHours,
  responsive,
  isDisabled,
}: any) => {
  const hasAdjustment = UpdateTimesheetPayrollEntryForm.getHasAdjustment(_.get(form.values, field));
  const billRulesAdjustmentMinutes =
    TimesheetPayrollEntry.getBillRuleAdjustmentMinutes(timesheetPayrollEntry);

  return (
    <React.Fragment>
      <SectionHeader responsive={responsive}>Timesheet Adjustment</SectionHeader>
      <Space height={12} />
      <TimesheetAdjustmentValue
        label={'Timesheet Hours'}
        value={displayTimesheetHours}
        responsive={responsive}
        tooltip={'Includes all time from timesheets'}
      />
      {!!crewHoursAdjustmentMinutes && (
        <React.Fragment>
          <Space height={12} />
          <TimesheetAdjustmentValue
            label={'Base Crew Hours'}
            value={getDisplayTime(crewHoursAdjustmentMinutes)}
            responsive={responsive}
            tooltip={'Includes adjustments for minimum hours, rounding, and/or double drive time'}
          />
        </React.Fragment>
      )}
      {!!billRulesAdjustmentMinutes && (
        <React.Fragment>
          <Space height={12} />
          <TimesheetAdjustmentValue
            label={'Additional Crew Hours'}
            value={getDisplayTime(billRulesAdjustmentMinutes)}
            responsive={responsive}
            tooltip={'Includes time from bill rules for "additional hours"'}
          />
        </React.Fragment>
      )}
      <Space height={12} />
      <TimesheetAdjustmentEditor
        form={form}
        field={field}
        responsive={responsive}
        isDisabled={isDisabled}
      />
      <Space height={12} />
      <Divider style={{width: TIMESHEET_ADJUSTMENT_COLUMN_WIDTH}} />
      <Space height={12} />
      <TimesheetAdjustmentValue
        label={'Total Hours'}
        value={displayTotalHours}
        responsive={responsive}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        name={`${field}.adjustmentReason`}
        label={'Reason for Adjustment'}
        isRequired={hasAdjustment}
        isResponsive
        input={{
          isDisabled,
          placeholder: 'Enter reason for adjustment',
          style: {paddingVertical: 12, height: 80},
          multiline: true,
        }}
      />
    </React.Fragment>
  );
};

const EditTimesheetPayrollEntryModalContent = ({
  form,
  field,
  timesheetPayrollEntry,
  timesheetBillableEntryMinutes,
  crewHoursAdjustmentMinutes,
  rangeFromDate,
  responsive,
  isDisabled,
  viewerRole,
}: any) => {
  const timesheetMinutes = getTimesheetMinutes({form, field, timesheetBillableEntryMinutes});
  const totalMinutes = getTotalMinutes({
    form,
    field,
    timesheetMinutes,
    timesheetPayrollEntry,
    crewHoursAdjustmentMinutes,
  });
  const displayTimesheetHours = getDisplayTime(timesheetMinutes);
  const displayTotalHours = getDisplayTime(totalMinutes);
  const hasManualAdjustment = UpdateTimesheetPayrollEntryForm.getHasAdjustment(
    _.get(form.values, field),
  );
  const hasBillRuleAdjustment =
    !!timesheetPayrollEntry.timeWorked.adjustmentsFromBillRules || !!crewHoursAdjustmentMinutes;
  const hasAdjustment = hasManualAdjustment || hasBillRuleAdjustment;
  const hasPayrollTimesheetBlocks = _.some(_.get(form.values, `${field}.timesheetBlocksUpdate`));

  return (
    <ContentContainer>
      <Space height={16} />
      <SectionHeader responsive={responsive}>{timesheetPayrollEntry.user.fullName}</SectionHeader>
      <Space height={4} />
      {hasAdjustment ? (
        <React.Fragment>
          <Row style={{alignItems: 'center'}}>
            <Icon source={Icon.ExclamationTriangle} size={14} color={colors.orange.hover} />
            <Space width={8} />
            <SectionCaption style={{textDecorationLine: 'line-through'}} responsive={responsive}>
              {displayTimesheetHours}
            </SectionCaption>
            <Space width={8} />
            <SectionCaption responsive={responsive}>{displayTotalHours}</SectionCaption>
          </Row>
          <Space height={4} />
          <Callout text={'Timesheet for this crew member has been adjusted.'} />
        </React.Fragment>
      ) : (
        <SectionCaption responsive={responsive}>{displayTimesheetHours}</SectionCaption>
      )}
      {isDisabled && (
        <React.Fragment>
          <Space height={4} />
          <Callout text={UserRole.getJobActionDisabledTooltip(viewerRole)} />
        </React.Fragment>
      )}
      <Space height={16} />
      <Divider />
      <Space height={16} />
      <SectionHeader responsive={responsive}>Time Blocks</SectionHeader>
      <Space height={4} />
      <SectionCaption responsive={responsive}>
        {`Add and edit time blocks for this crew member. You cannot `}
        {`make changes to time blocks added in the job timesheet.`}
      </SectionCaption>
      <Space height={12} />
      <BillableTimesheetBlocks form={form} timesheetPayrollEntry={timesheetPayrollEntry} />
      <Space height={16} />
      <Divider />
      <Space height={16} />
      {(!isDisabled || hasPayrollTimesheetBlocks) && (
        <React.Fragment>
          <PayrollTimesheetBlocks
            form={form}
            field={field}
            rangeFromDate={rangeFromDate}
            isDisabled={isDisabled}
          />
          <Space height={16} />
          <Divider />
          <Space height={16} />
        </React.Fragment>
      )}
      <TimesheetAdjustmentSection
        form={form}
        field={field}
        timesheetPayrollEntry={timesheetPayrollEntry}
        crewHoursAdjustmentMinutes={crewHoursAdjustmentMinutes}
        displayTimesheetHours={displayTimesheetHours}
        displayTotalHours={displayTotalHours}
        responsive={responsive}
        isDisabled={isDisabled}
      />
      <Space height={16} />
    </ContentContainer>
  );
};

const EditTimesheetPayrollEntryModal = ({
  isOpen,
  handleClose,
  timesheetPayrollEntry,
  timesheetBillableEntryMinutes,
  crewHoursAdjustmentMinutes,
  rangeFromDate,
  onUpdate,
  isDisabled,
  viewerRole,
}: any) => {
  const responsive = useResponsive();
  const updateTimesheetPayrollEntryForm =
    UpdateTimesheetPayrollEntryForm.edit(timesheetPayrollEntry);
  const {form, handleSubmit, submitting} = useUpdateTimesheetPayrollEntryMutation({
    updateTimesheetPayrollEntryForm,
    onSuccess: () => {
      handleClose();
      onUpdate();
    },
    onError: (error: any) => console.log({error}),
  });

  return (
    <LargeModal
      isOpen={isOpen}
      title={'Edit Timesheet'}
      handlePrimaryAction={isDisabled ? handleClose : handleSubmit}
      handleSecondaryAction={isDisabled ? null : handleClose}
      primaryActionText={isDisabled ? 'Close' : 'Save'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      // @ts-expect-error TS(2322): Type '{ children: Element; isOpen: any; title: str... Remove this comment to see the full error message
      screenContainerStyle={{paddingHorizontal: responsive.mobile ? 0 : 96}}
      bodyStyle={{padding: 0}}
      isScrollable
      isResponsive
    >
      <EditTimesheetPayrollEntryModalContent
        form={form}
        field={'updateTimesheetPayrollEntryForm'}
        timesheetPayrollEntry={timesheetPayrollEntry}
        timesheetBillableEntryMinutes={timesheetBillableEntryMinutes}
        crewHoursAdjustmentMinutes={crewHoursAdjustmentMinutes}
        rangeFromDate={rangeFromDate}
        responsive={responsive}
        isDisabled={isDisabled}
        viewerRole={viewerRole}
      />
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTimesheetPayrollEntryModal.fragment = gql`
  ${EditTimesheetPayrollEntryTimesheetBlocks.fragment}
  ${UpdateTimesheetPayrollEntryForm.edit.fragment}
  ${TimesheetPayrollEntry.getBillRuleAdjustmentMinutes.fragment}

  fragment EditTimesheetPayrollEntryModal on TimesheetPayrollEntry {
    id
    user {
      id
      fullName
    }
    effectiveTimesheetBlocks {
      id
      isBillable
      ...EditTimesheetPayrollEntryTimesheetBlocks
    }
    timeWorked {
      withoutAdjustments
      adjustmentsFromBillRules
    }
    ...UpdateTimesheetPayrollEntryForm_edit
    ...TimesheetPayrollEntry_getBillRuleAdjustmentMinutes
  }
`;

export default EditTimesheetPayrollEntryModal;
