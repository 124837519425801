// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import {
  useSkipSignatureForDocumentStepMutation,
  useSkipSignatureForAllDocumentsForStepMutation,
} from 'modules/Job/Customer/Document/Sign/logic';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: center;
  margin-vertical: 20px;
`;

const Button = Styled.LoadingButton`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Links = Styled.View`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

const Link = Styled.Touchable`
`;

const LinkText = Styled.H6`
  color: ${colors.blue.interactive};
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SkipSignatureForDocumentStepModal.defaultProps;

// @ts-expect-error TS(7022): 'SkipSignatureForDocumentStepModal' implicitly has... Remove this comment to see the full error message
const SkipSignatureForDocumentStepModal = ({
  jobId,
  documentId,
  step,
  onSuccess,
  onError,
  children,
}: Props) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const skipDocument = useSkipSignatureForDocumentStepMutation({
    documentId,
    step,
    onSuccess: handleSuccess,
    onError,
  });

  const skipAllDocuments = useSkipSignatureForAllDocumentsForStepMutation({
    jobId,
    step,
    onSuccess: handleSuccess,
    onError,
  });

  const submitting = skipDocument.submitting || skipAllDocuments.submitting;

  return (
    <React.Fragment>
      <Modal.Content isOpen={isOpen} onClose={handleClose}>
        <Container pointerEvents={'box-none'}>
          <Wrapper width={400}>
            <Circle>
              <Emoji component={EmojiText} name={'warning'} />
            </Circle>
            <Content>
              <Title>Are you sure?</Title>
              <Subtitle>
                You should only use this option if the customer is not available to sign right now.
              </Subtitle>
              <Actions>
                <Button disabled={submitting} color={colors.gray.secondary} onPress={handleClose}>
                  <Text>Cancel</Text>
                </Button>
                <ButtonSpace />
                <Button
                  loading={submitting}
                  color={colors.blue.interactive}
                  onPress={skipDocument.handleSubmit}
                >
                  <Text>Skip</Text>
                </Button>
              </Actions>
              <Links>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Link disabled={submitting} onPress={skipAllDocuments.handleSubmit}>
                  <LinkText>Skip All</LinkText>
                </Link>
              </Links>
            </Content>
          </Wrapper>
        </Container>
      </Modal.Content>
      {children({handleOpen})}
    </React.Fragment>
  );
};

SkipSignatureForDocumentStepModal.defaultProps = {};

export default SkipSignatureForDocumentStepModal;
