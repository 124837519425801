// Supermove
import {Currency} from '@supermove/utils';

const _new = ({name, amount}: any) => ({
  name,
  amount,
});

const toForm = ({name, amount}: any) => {
  return {
    name,
    amount: Currency.toForm(amount),
  };
};

const PaymentForm = {
  new: _new,
  toForm,
};

export default PaymentForm;
