// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useEffect, useRef} from '@supermove/hooks';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const DocumentV2Text = ({
  html,
  index,
  isEnabledRichTextEditorV2,
}: {
  html: string;
  index: number;
  isEnabledRichTextEditorV2: boolean;
}) => {
  const divRef = useRef(null);
  useEffect(() => {
    if (!!html && divRef.current) {
      const htmlWithLineBreaks = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
      (divRef.current as any).innerHTML = htmlWithLineBreaks;
    }
  }, [html]);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      {!!html && (
        <div
          className={`document-text-item${isEnabledRichTextEditorV2 ? ' document-text-item-v2-editor' : ''}`}
          ref={divRef}
        />
      )}
    </Wrapper>
  );
};

export default DocumentV2Text;
