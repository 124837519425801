// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '3px' : '12px')};
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '2px' : '8px')};
  border-radius: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '2px' : '5px')};
  background-color: ${(props) => (props as any).color};
`;

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => (props as any).color};
`;

const SmallText = Styled.H8`
  color: ${(props) => (props as any).color};
  ${fontWeight(500)}
`;

type OwnProps = {
  text: string;
  color: string;
  isSmall?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TextBadge.defaultProps;

// @ts-expect-error TS(7022): 'TextBadge' implicitly has type 'any' because it d... Remove this comment to see the full error message
const TextBadge = ({text, color, isSmall, icon}: Props) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container color={colors.getBackgroundColor(color)} isSmall={isSmall}>
      {icon && (
        <React.Fragment>
          <Icon source={icon} color={color} size={isSmall ? 12 : 14} />
          <Space width={8} />
        </React.Fragment>
      )}
      {isSmall ? <SmallText color={color}>{text}</SmallText> : <Text color={color}>{text}</Text>}
    </Container>
  );
};

TextBadge.defaultProps = {
  isSmall: false,
};

export default TextBadge;
