/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// App
import FieldValue from 'modules/App/components/FieldValue';

const Container = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 30px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
`;

const ColumnSpace = Styled.View`
  width: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
  margin-top: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isFirst ? 10 : 15)}px;
`;

const getSalespersonInfo = ({job}: any) => {
  const {bookedBy} = job.project;
  if (!bookedBy) {
    return '';
  }

  if (!bookedBy.phoneNumber) {
    return bookedBy.fullName;
  }

  return `${bookedBy.fullName} ${Phone.display(bookedBy.phoneNumber)}`;
};

const getCoordinatedByInfo = ({job}: any) => {
  const {coordinatedBy} = job.project;
  if (!coordinatedBy) {
    return '';
  }

  if (!coordinatedBy.phoneNumber) {
    return coordinatedBy.fullName;
  }

  return `${coordinatedBy.fullName} ${Phone.display(coordinatedBy.phoneNumber)}`;
};

const JobCustomerBlock = ({job}: any) => {
  const {primaryContact} = job.project.client;

  return (
    <Container>
      <Title>Customer Info</Title>
      <Section>
        <Column>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Row vars={{isFirst: true}}>
            <FieldValue label={'Contact Name'} value={primaryContact.fullName} style={{flex: 1}} />
          </Row>
          <Row>
            <FieldValue
              label={'Phone Number'}
              value={Phone.display(primaryContact.phoneNumber)}
              empty={'None'}
              style={{flex: 1}}
            />
          </Row>
          <Row>
            <FieldValue
              label={'Email'}
              value={primaryContact.email}
              empty={'None'}
              style={{flex: 1}}
            />
          </Row>
        </Column>
        <ColumnSpace />
        <Column>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Row vars={{isFirst: true}}>
            <FieldValue
              label={'Salesperson'}
              value={getSalespersonInfo({job})}
              empty={'None'}
              style={{flex: 1}}
            />
          </Row>
          <Row>
            <FieldValue
              label={'Coordinator'}
              value={getCoordinatedByInfo({job})}
              empty={'None'}
              style={{flex: 1}}
            />
          </Row>
          <Row>
            <FieldValue label={'Move Size'} value={job.project.size} style={{flex: 1}} />
          </Row>
          <Row>
            <FieldValue
              label={job.organization.jobStartTimeField}
              value={
                `${Datetime.convertToDisplayTime(job.startTime1)} - ` +
                `${Datetime.convertToDisplayTime(job.startTime2)}`
              }
              style={{flex: 1}}
            />
          </Row>
        </Column>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCustomerBlock.fragment = gql`
  fragment JobCustomerBlock on Job {
    id
    startTime1
    startTime2
    organization {
      id
      jobStartTimeField
    }
    project {
      id
      size
      bookedBy {
        id
        fullName
        phoneNumber
      }
      coordinatedBy {
        id
        fullName
        phoneNumber
      }
      client {
        id
        primaryContact {
          id
          fullName
          phoneNumber
          email
        }
      }
    }
  }
`;

export default JobCustomerBlock;
