// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Body3}
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const BackButton = () => {
  const {navigator} = useNavigation();

  return (
    <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
      <Text>Back</Text>
    </Button>
  );
};

export default BackButton;
