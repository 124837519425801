// Pre-move
const CREW_LOCATION_START_TRACKING = 'CREW_LOCATION_START_TRACKING';
const CREW_LOCATION_HAS_ARRIVED = 'CREW_LOCATION_HAS_ARRIVED';
const CREW_PRE_MOVE_BILLABLE_TIMESHEET = 'CREW_PRE_MOVE_BILLABLE_TIMESHEET';
const CREW_PRE_MOVE_PAYROLL_TIMESHEET = 'CREW_PRE_MOVE_PAYROLL_TIMESHEET';
const CREW_PRE_MOVE_BILLING = 'CREW_PRE_MOVE_BILLING';

// During-move
const CREW_DURING_MOVE_BILLABLE_TIMESHEET = 'CREW_DURING_MOVE_BILLABLE_TIMESHEET';

// After-move
const CREW_AFTER_MOVE_PAYROLL_TIMESHEET = 'CREW_AFTER_MOVE_PAYROLL_TIMESHEET';

const JobStepKind = {
  // Pre-move
  CREW_LOCATION_START_TRACKING,
  CREW_LOCATION_HAS_ARRIVED,
  CREW_PRE_MOVE_BILLABLE_TIMESHEET,
  CREW_PRE_MOVE_PAYROLL_TIMESHEET,
  CREW_PRE_MOVE_BILLING,

  // During-move
  CREW_DURING_MOVE_BILLABLE_TIMESHEET,

  // Post-move
  // This placeholder serves to explicitly show that there
  // is a post-move phase followed by an after-move phase.

  // After-move
  CREW_AFTER_MOVE_PAYROLL_TIMESHEET,
};

export default JobStepKind;
