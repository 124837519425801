/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import PrepareDocumentSignatureForm from 'modules/Job/Crew/Document/Show/components/PrepareDocumentSignatureForm';
import CrewPrepareDocumentSignatureFields from 'modules/Job/Crew/DocumentSignature/components/CrewPrepareDocumentSignatureFields';
import CrewJobPage from 'modules/Job/Crew/components/CrewJobPage';
import CrewJobProgress from 'modules/Job/Crew/components/CrewJobProgress';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
`;

const Header = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const DocumentSignatures = Styled.View`
  padding-vertical: 20px;
  padding-horizontal: 40px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const STEP = 'PRE_MOVE';

const PreparePreMoveShowDocumentSignatureCrewJobPageContent = ({
  index,
  document,
  job,
  refetch,
}: any) => {
  const {navigator} = useNavigation();
  const documentSignature = _.get(document, `filteredDocumentSignatures.${index}`);

  return (
    <PrepareDocumentSignatureForm
      documentSignatureId={documentSignature.id}
      additionalInfo={documentSignature.additionalInfo}
      refetchQueries={['PreparePreMoveShowDocumentSignatureCrewJobPage']}
      onSuccess={() => Navigation.navigateFromPreMovePrepareDocumentSignature({navigator, job})}
      onError={(errors: any) => console.log({errors})}
    >
      {(form: any) => (
        <Container>
          <ScrollView nestedScrollEnabled style={{flex: 1}}>
            <CrewJobProgress selected={'documents'} items={CrewJobProgress.items.start} />
            <Content>
              <Header>
                <Title>{document.displayName}</Title>
                <Subtitle>{documentSignature.documentSection.title}</Subtitle>
              </Header>
              <DocumentSignatures>
                <CrewPrepareDocumentSignatureFields
                  documentSignature={documentSignature}
                  document={document}
                  form={form}
                />
              </DocumentSignatures>
            </Content>
          </ScrollView>
          <Footer>
            <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
              <Text>Back</Text>
            </Button>
            <Button
              loading={form.loading}
              color={colors.blue.interactive}
              onPress={form.handleSubmit}
            >
              <Text>Save</Text>
            </Button>
          </Footer>
        </Container>
      )}
    </PrepareDocumentSignatureForm>
  );
};

const PreparePreMoveShowDocumentSignatureCrewJobPage = () => {
  const {navigator} = useNavigation();

  return (
    <CrewJobPage
      key={navigator.state.key}
      fetchPolicy={'network-only'}
      getVariables={({params}: any) => ({
        uuid: params.uuid,
        documentUuid: params.documentUuid,
        step: STEP,
      })}
      query={PreparePreMoveShowDocumentSignatureCrewJobPage.query}
      onFocus={({refetch}: any) => refetch()}
    >
      {({params, loading, data, refetch}: any) => (
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <PreparePreMoveShowDocumentSignatureCrewJobPageContent
              index={params.index}
              document={data.document}
              job={data.job}
              refetch={refetch}
            />
          )}
        </Loading>
      )}
    </CrewJobPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PreparePreMoveShowDocumentSignatureCrewJobPage.query = gql`
  ${Navigation.navigateFromPreMovePrepareDocumentSignature.fragment}
  ${CrewJobPage.fragment}
  ${CrewPrepareDocumentSignatureFields.fragment}

  query PreparePreMoveShowDocumentSignatureCrewJobPage(
    $uuid: String!,
    $documentUuid: String!,
    $step: String!,
  ) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      displayName
      indexForStep(step: $step)
      filteredDocumentSignatures(step: $step) {
        id
        isPrepared
        documentSection {
          title
        }
        ...CrewPrepareDocumentSignatureFields
      }
      ...CrewPrepareDocumentSignatureFields_Document
    }
    job(uuid: $uuid) {
      id
      ...Navigation_navigateFromPreMovePrepareDocumentSignature
      ...CrewJobPage
    }
  }
`;

export default PreparePreMoveShowDocumentSignatureCrewJobPage;
